import Utils from '@libs/utils/Utils';

import CE_Aggrid from '@libs/customElement/AgGrid';
import CE_Select from '@libs/customElement/Select';

import S_User from '@services/User/UserService';

class UsersTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-customers-users-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	private cacheUsers: { [key: string]: any } = {};

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || UsersTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="group/line" icon-active="group/fill" text="Personnels"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid!.sizeColumnsToFit();
		});

		this.id = '';
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Personnels
			</div>
			<div class="page-tabs-content d-flex flex-column ">
				<div class="d-flex">
					<ap-button class="btn-add mb-2" id="add">Ajouter</ap-button>
					<ap-select class="d-none" style="width: 200px"></ap-select>
				</div>
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
		`;

		const N_add = this.N_el.querySelector('#add')!;
		const N_select = this.N_el.querySelector<CE_Select>('ap-select')!;

		N_select.options = {
			ajax: {
				url: S_User.getInstance().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({}),
						companyID: Utils.companyID
					};
				}
			}
		};

		N_select.addEventListener('change', async () => {
			N_select.classList.add('d-none');
			N_add.classList.remove('d-none');

			const userID = N_select.value as string;

			const data = this.N_grid!.value;

			for (const item of data) {
				if (item.user.id === userID) {
					return;
				}
			}

			await this.getUser(userID);

			this.N_grid?.addValues([{ user: userID }]);
			this.dispatchEvent(new CustomEvent('update'));
		});

		N_select.addEventListener('close', () => {
			N_select.classList.add('d-none');
			N_add.classList.remove('d-none');
		});

		N_add?.addEventListener('click', () => {
			N_select.classList.remove('d-none');
			N_add.classList.add('d-none');

			N_select.open();
		});

		N_container.append(this.N_el);

		this.initGrid();
	}

	private initGrid() {
		this.N_grid = this.N_el?.querySelector<CE_Aggrid>('#grid')!;

		this.N_grid.setGridOptions({
			localeText: { noRowsToShow: 'Aucun intervenant' },
			columnDefs: [
				{
					headerName: 'Utilisateur',
					field: 'user',
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params) => {
						return this.cacheUsers[params.data.user];
					},
					cellRenderer: (params) => {
						return params.value.text;
					}
				},
				{
					headerName: 'Responsable Achat',
					field: 'purchaseManager',
					cellRenderer: (params) => {
						return this.cellRenderer(params, true);
					}
				},
				{
					headerName: 'Responsable planning',
					field: 'planningManager',
					cellRenderer: (params) => {
						return this.cellRenderer(params, true);
					}
				},
				{
					headerName: 'Personnes informées',
					field: 'informedPeople',
					cellRenderer: (params) => {
						return this.cellRenderer(params);
					}
				},
				{
					headerName: 'Intervenants',
					field: 'constributors',
					cellRenderer: (params) => {
						return this.cellRenderer(params);
					}
				}
			]
		});
	}

	private cellRenderer(params: any, uniq: boolean = false) {
		const field = params.colDef.field;

		const N_checkbox = document.createElement('div');
		N_checkbox.classList.add('text-center');

		if (params.value) {
			N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
		} else {
			N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
		}

		N_checkbox.addEventListener('click', () => {
			const value = !params.value;

			if (uniq) {
				params.api.forEachNode((node: any) => {
					node.setDataValue(field, false);
				});
			}

			params.node.setDataValue(field, value);

			if (value) {
				N_checkbox.innerHTML = '<i class="icon icon-checkbox-checked"></i>';
			} else {
				N_checkbox.innerHTML = '<i class="icon icon-checkbox-unchecked"></i>';
			}
		});

		return N_checkbox;
	}

	public async initData(data: { [key: string]: any }) {
		const users: { [key: string]: any } = {};

		users[data.purchaseManager] = users[data.purchaseManager] || {};
		users[data.purchaseManager].purchaseManager = true;

		users[data.planningManager] = users[data.planningManager] || {};
		users[data.planningManager].planningManager = true;

		for (const item of data.constributors) {
			users[item] = users[item] || {};
			users[item].constributors = true;
		}

		for (const item of data.informedPeople) {
			users[item] = users[item] || {};
			users[item].informedPeople = true;
		}

		const lines: { [key: string]: any }[] = [];

		for (const idUser in users) {
			await this.getUser(idUser);
			lines.push({
				user: idUser,
				...users[idUser]
			});
		}

		this.N_grid!.value = lines;
	}

	private async getUser(user: string) {
		if (user) {
			if (!this.cacheUsers[user]) {
				const userData = await S_User.getInstance().getDataToSelect2ByID(user);
				this.cacheUsers[user] = userData;
			}
		}
	}

	public get data(): { [key: string]: any } {
		const res = {
			constributors: [] as string[],
			informedPeople: [] as string[],
			purchaseManager: '',
			planningManager: ''
		};

		const data = this.N_grid!.value;

		for (const item of data) {
			if (item.constributors) {
				res.constributors.push(item.user);
			}
			if (item.informedPeople) {
				res.informedPeople.push(item.user);
			}
			if (item.purchaseManager) {
				res.purchaseManager = item.user;
			}
			if (item.planningManager) {
				res.planningManager = item.user;
			}
		}

		return res;
	}

	public static register() {
		customElements.define(UsersTab.tagName, UsersTab);
	}
}

export default UsersTab;
