// CORE
import { Controller, Router } from '@autoprog/core-client';
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import _ from 'lodash';
import moment from 'moment';

// TEMPLATE
// LIBS
import { AllModules, GetContextMenuItemsParams, GetQuickFilterTextParams, Grid, GridOptions, MenuItemDef } from '@ag-grid-enterprise/all-modules';
import Loader from '@libs/Loader';
import ModalManager from '@managers/ModalManager';
import ProductContextMenu from '@modules/Products/js/libs/ProductContextMenu';
import ProductImageCellRenderer from '@modules/Products/js/libs/ProductImageCellRenderer';
import StateSaver from '@libs/agGrid/StateSaver';

// PRINTER

// UTILS
import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/Utils';

// MODAL
import M_Edit from '../modals/Edit';
import M_Export from '../modals/Export';
import M_Input from '../modals/Input';
import M_M_Input from '../modals/multiple/Input';
import M_M_Output from '../modals/multiple/Output';
import M_Output from '../modals/Output';

// CUSTOM_ELEMENT
import CE_FilterButton from '@libs/customElement/FilterButton';
import CE_GridSidebar from '@libs/customElement/GridSidebar';
import CE_HeaderDashboard from '@libs/customElement/HeaderDashboard';
import CE_SearchBar from '@libs/customElement/searchBar';

// SERVICE
import S_Product from '@services/Product/ProductService';
import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';
import S_StockInventory from '@services/StockInventoryService';

class StockController extends Controller {
	private gridOptions: GridOptions = {};

	private currentStock: string = '';
	private lastInventory: string = '';

	private el: HTMLElement;

	private stateSaver: StateSaver | null = null;

	private viewAllProductsChecked = false;

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;

		$(document).on('click', ':not(.popover)', function () {
			//@ts-ignore
			$('.popover').popover('hide');
		});

		this.gridOptions = agUtils.french<GridOptions>({
			columnDefs: [
				{
					headerComponentParams: {
						template: `
							<div class="ag-cell-label-container" role="presentation">
								<div ref="eLabel" class="ag-header-cell-label" role="presentation">
									<i class="icon h5 icon-solid-ellipsis-h"></i>
								</div>
							</div>
						`
					},
					headerClass: 'ag-theme-custom-text-center',
					field: '_icons_',
					filter: false,
					width: 70,
					suppressSizeToFit: true,
					suppressMovable: true,
					resizable: false,
					pinned: 'left',
					sortable: false,
					suppressColumnsToolPanel: true,
					valueGetter: (params) => {
						return params.data.product._id;
					},
					cellRenderer: ProductImageCellRenderer
				},
				{
					headerName: 'Référence',
					field: 'product.reference',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Code Produit',
					field: 'product.internalCode',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Désignation',
					field: 'product.name',
					filterParams: {
						textFormatter: (result: string) => {
							if (result == null) return null;
							return _.deburr(result.toLowerCase());
						},
						debounceMS: 200
					}
				},
				{
					field: 'stock',
					hide: true,
					suppressColumnsToolPanel: true
				},
				{
					field: 'displayStock',
					hide: true,
					suppressColumnsToolPanel: true
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					width: 150,
					suppressSizeToFit: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: {
						applyMiniFilterWhileTyping: true
					}
				},
				{
					headerName: 'Empl.',
					field: 'product.locationStock',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Alerte',
					field: 'typeAlert',
					width: 150,
					hide: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: {
						applyMiniFilterWhileTyping: true
					},
					suppressSizeToFit: true
				},
				{
					headerName: 'Dernière Vérification',
					field: 'lastUpdate.date',
					width: 200,
					suppressSizeToFit: true,
					getQuickFilterText(params: GetQuickFilterTextParams) {
						return moment(params.value, 'x').format('DD/MM/YYYY');
					},
					cellRenderer: (params) => {
						if (params.data.lastUpdate && params.data.lastUpdate.user) {
							return `
                                ${moment(params.value).format('DD/MM/YYYY HH:mm')} - 
                                <span class="text-xs text-muted text-center w-100">Par ${params.data.lastUpdate.user}</span>        
                            `;
						} else {
							return `
                                <span class="text-muted">Aucune vérification</span>
                            `;
						}
					}
				},
				{
					headerName: 'Qté',
					field: 'quantity',
					width: 80,
					cellClass: 'text-right text-monospace',
					filter: 'agNumberColumnFilter',
					suppressSizeToFit: true,
					comparator: (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: Utils.getPriceFilterParams(),
					valueGetter: (params) => {
						return params.data.quantity.value;
					},
					cellRenderer: (params) => {
						return params.data.quantity.formattedValue;
					}
				},
				{
					headerName: 'Qté commandée',
					suppressSizeToFit: true,
					width: 200,
					field: 'lastOrder',
					cellClass: 'text-monospace text-right',
					valueGetter: (params) => {
						const data = params.data.lastOrder || {};
						let total = 0;
						if (Object.keys(data.customer || {}).length) {
							for (const id in data.customer) {
								total += data.customer[id].quantity;
							}
						}

						if (Object.keys(data.internal || {}).length) {
							for (const id in data.internal) {
								total += data.internal[id].quantity;
							}
						}

						return total;
					},
					cellRenderer: (params) => {
						let total = 0;
						let numberOrder = 0;

						const tooltip: string[] = [];

						const data = params.data.lastOrder || {};

						if (Object.keys(data.customer || {}).length) {
							tooltip.push('<div class="d-flex font-weight-bold my-2 border-bottom-2x border-dark">Livraison direct client</div>');

							for (const id in data.customer) {
								total += data.customer[id].quantity;
								numberOrder++;

								tooltip.push(`<div class="d-flex">${data.customer[id].text} <div class="ml-auto pl-2">(Qté: ${data.customer[id].quantity})</div></div>`);
							}
						}

						if (Object.keys(data.internal || {}).length) {
							tooltip.push('<div class="d-flex font-weight-bold my-2 border-bottom-2x border-dark">Livraison interne</div>');

							for (const id in data.internal) {
								total += data.internal[id].quantity;
								numberOrder++;

								tooltip.push(`<div class="d-flex">${data.internal[id].text} <div class="ml-auto pl-2">(Qté: ${data.internal[id].quantity})</div></div>`);
							}
						}

						const N_div = document.createElement('div');

						N_div.classList.add('w-100');
						N_div.setAttribute('popover', JSON.stringify({ title: 'Détail commande:', content: tooltip.join('') || 'Aucune commande', trigger: 'hover', placement: 'right', sanitize: false }));

						N_div.innerHTML = `${total} (${numberOrder} Commande${numberOrder > 1 ? 's' : ''})`;

						return N_div;
					}
				},
				{
					headerName: 'PU d\'achat',
					field: 'product.purchasePrice',
					width: 100,
					cellClass: 'text-right text-monospace',
					suppressSizeToFit: true,
					filter: 'agNumberColumnFilter',
					comparator: (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: Utils.getPriceFilterParams(),
					valueGetter: (params) => {
						return params.data.product.purchasePrice.value;
					},
					cellClassRules: {
						'bg-amber-200': (params: any) => {
							return params.data.product.purchasePrice.error;
						},
						'text-center': (params: any) => {
							return params.data.product.purchasePrice.error;
						}
					},
					cellRenderer: (params) => {
						if (params.data.product.purchasePrice.error) {
							return '<ap-icon class="h2 mt-1" name="alert/line" tooltip="Aucun prix d\'achat"></ap-icon>';
						}

						return params.data.product.purchasePrice.formattedValue;
					}
				},
				{
					headerName: 'PU de vente',
					field: 'product.price',
					width: 100,
					cellClass: 'text-right text-monospace',
					suppressSizeToFit: true,
					filter: 'agNumberColumnFilter',
					comparator: (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: Utils.getPriceFilterParams(),
					valueGetter: (params) => {
						return params.data.product.price.value;
					},
					cellRenderer: (params) => {
						return params.data.product.price.formattedValue;
					}
				},
				{
					headerName: 'Prix total d\'achat',
					field: 'totalPurchasePrice',
					width: 150,
					cellClass: 'text-right text-monospace',
					suppressSizeToFit: true,
					filter: 'agNumberColumnFilter',
					comparator: (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: Utils.getPriceFilterParams(),
					valueGetter: (params) => {
						return params.data.totalPurchasePrice.value;
					},
					cellClassRules: {
						'bg-amber-200': (params: any) => {
							return params.data.product.purchasePrice.error;
						},
						'text-center': (params: any) => {
							return params.data.product.purchasePrice.error;
						}
					},
					cellRenderer: (params) => {
						if (params.data.product.purchasePrice.error) {
							return '<ap-icon class="h2 mt-1" name="alert/line" tooltip="Aucun prix d\'achat"></ap-icon>';
						}

						return params.data.totalPurchasePrice.formattedValue;
					}

				},
				{
					headerName: 'Prix total de vente',
					field: 'totalPrice',
					width: 150,
					cellClass: 'text-right text-monospace',
					suppressSizeToFit: true,
					filter: 'agNumberColumnFilter',
					comparator: (valueA, valueB) => {
						valueA = Decimal.setDisplayNumber(valueA).toNumber();
						valueB = Decimal.setDisplayNumber(valueB).toNumber();
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					filterParams: Utils.getPriceFilterParams(),
					valueGetter: (params) => {
						return params.data.totalPrice.value;
					},

					cellRenderer: (params) => {
						return params.data.totalPrice.formattedValue;
					}
				},
				{
					headerName: 'Action',
					field: '_buttons_',
					width: 80,
					suppressSizeToFit: true,
					suppressMovable: true,
					resizable: false,
					suppressColumnsToolPanel: true,
					filter: false,
					pinned: 'right',
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						const N_edit = document.createElement('button');

						N_edit.classList.add('btn', 'btn-transparent');
						N_edit.innerHTML = '<i class="icon icon-solid-ellipsis-v"></i>';

						N_edit.setAttribute('permission', 'STOCK.INPUT || STOCK.OUTPUT || STOCK.UPDATE');

						N_edit.addEventListener('click', (e: MouseEvent) => {
							e.stopImmediatePropagation();
							e.stopPropagation();
							e.preventDefault();

							//@ts-ignore
							$('.popover').popover('hide');
							//@ts-ignore
							$(N_edit).popover('toggle');
						});

						//@ts-ignore
						$(N_edit).popover({
							html: true,
							placement: 'top',
							boundary: 'window',
							trigger: 'manual',
							sanitize: false,
							title: '',
							content: `<div class="d-flex flex-column">
                                <button class="btn btn-blue-50" id="input"><i class="icon icon-solid-sign-in-alt mr-2 text-indigo"></i>Entrée</button>
                                <button class="btn btn-blue-50" id="output"><i class="icon icon-solid-sign-out-alt mr-2 text-red"></i>Sortie</button>
                                <button class="btn btn-blue-50" id="edit"><i class="icon icon-solid-sync-alt mr-2"></i>Mettre à jour</button>
                            </div>`
						}).on('shown.bs.popover', (e: any) => {
							const popoverId = e.target.getAttribute('aria-describedby');

							const N_popover = document.querySelector('#' + popoverId) as HTMLElement;

							const N_input = N_popover.querySelector('#input') as HTMLButtonElement;
							const N_output = N_popover.querySelector('#output') as HTMLButtonElement;
							const N_update = N_popover.querySelector('#edit') as HTMLButtonElement;

							N_input.setAttribute('permission', 'STOCK.INPUT');
							N_output.setAttribute('permission', 'STOCK.OUTPUT');
							N_update.setAttribute('permission', 'STOCK.UPDATE');

							N_input.addEventListener('click', () => {
								//@ts-ignore
								$(N_popover).popover('hide');

								new M_Input(params.data.product._id, params.data.quantity.value, params.data.stock).open().then(() => {
									this.getData();
								});
							});

							N_output.addEventListener('click', () => {
								//@ts-ignore
								$(N_popover).popover('hide');

								new M_Output(params.data.product._id, params.data.quantity.value, params.data.stock).open().then(() => {
									this.getData();
								});
							});

							N_update.addEventListener('click', () => {
								//@ts-ignore
								$(N_popover).popover('hide');

								new M_Edit(params.data.product._id, params.data.quantity.value, params.data.stock).open().then(() => {
									this.getData();
								});
							});
						});

						const N_product = document.createElement('button');
						N_product.classList.add('btn', 'btn-transparent');
						N_product.setAttribute('tooltip', 'Éditer Produit');
						N_product.setAttribute('permission', 'PRODUCTS.OPEN');
						N_product.innerHTML = '<i class="icon icon-solid-pen"></i>';

						N_product.addEventListener('click', async () => {
							await ModalManager.getInstance().open('products', params.data.product._id);
							this.getData();
						});

						N_div.appendChild(N_edit);
						N_div.appendChild(N_product);

						return N_div;
					}
				}
			],
			defaultColDef: {
				filter: 'agTextColumnFilter',
				filterParams: { newRowsAction: 'keep' },
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			suppressDragLeaveHidesColumns: true,
			floatingFilter: true,
			getContextMenuItems: this.getContextMenu.bind(this),
			onGridReady: () => {
				this.initGridSidebar();
				this.stateSaver = new StateSaver(this.gridOptions as any, 'stock-view');
				this.getData();
			},
			getRowStyle: (params: any) => {
				if (params.node.data.typeAlert === 'alert' || params.node.data.typeAlert === 'negative') {
					return {
						'background-color': 'var(--ap-red-50)',
						color: 'var(--ap-red-900)'
					};
				}

				if (params.node.data.typeAlert === 'warning') {
					return {
						'background-color': 'var(--ap-orange-50)',
						color: 'var(--ap-orange-900)'
					};
				}

				return {};
			},
			onFilterChanged: (params: any) => {
				params.columnApi.setColumnsVisible(['displayStock', 'stock'], false);
				this.updatePrice();
			},
			rowData: []
		});

		this.initList(el);
		this.initMultipleOutput();
		this.initMultipleInput();
		this.initInventory();

		const N_grid = el.querySelector('#grid') as HTMLElement;

		new Grid(N_grid, this.gridOptions, { modules: AllModules });

		this.initHeaderDashboard();

		const N_viewAllProducts = el.querySelector('#viewAllProducts') as HTMLInputElement;

		N_viewAllProducts.addEventListener('change', async () => {
			if (this.gridOptions.api) {
				this.viewAllProductsChecked = N_viewAllProducts.checked;

				const filterInstance = this.gridOptions.api?.getFilterInstance('displayStock');

				filterInstance?.setModel({
					type: 'equals',
					filter: this.viewAllProductsChecked ? '' : 'true'
				});

				this.gridOptions.api?.onFilterChanged();
			}
		});

		const N_export = el.querySelector('#export') as HTMLButtonElement;

		N_export.addEventListener('click', () => {
			new M_Export(this.currentStock).open().then(() => {

			});
		});

		this.initFilterButton();
	}

	private initFilterButton() {
		const N_filter = this.el.querySelector('ap-filter-button') as CE_FilterButton;
		N_filter.setConfig([[{
			type: 'title',
			title: 'Alerte : '
		}, {
			column: 'typeAlert',
			type: 'checkbox',
			filters: [{
				color: 'orange-500',
				value: 'warning',
				text: 'A réapprovisionner'
			}, {
				color: 'red-500',
				value: 'alert',
				text: 'Alerte'
			}, {
				color: 'red-500',
				value: 'negative',
				text: 'Négative'
			}]
		}]], this.gridOptions, ['stock', 'displayStock']);

		const N_search = this.el.querySelector('ap-search-bar') as CE_SearchBar;
		N_search.setGridOptions(this.gridOptions);
	}

	private initGridSidebar() {
		const N_GridSidebar = this.el.querySelector('ap-grid-sidebar') as CE_GridSidebar;
		N_GridSidebar.initSideBar(this.gridOptions);
	}

	private async getLastInventory() {
		const N_inventory = this.el.querySelector('#inventory') as HTMLButtonElement;
		const data = await S_StockInventory.getInstance().getLastInventory(this.currentStock);

		if (data.length) {
			this.lastInventory = data[0]._id;
			N_inventory.innerHTML = '<i class="icon icon-ri-shopping-bag-3-fill mr-2"></i>Continuer l\'inventaire';
		} else {
			this.lastInventory = '';
			N_inventory.innerHTML = '<i class="icon icon-ri-shopping-bag-3-line mr-2"></i>Créer un inventaire';
		}
	}

	private initMultipleOutput() {
		const N_multiple_output = this.el.querySelector('#multiple_output') as HTMLButtonElement;

		N_multiple_output.addEventListener('click', () => {
			new M_M_Output(this.currentStock).open().then(() => {
				this.getData();
			});
		});
	}

	private initMultipleInput() {
		const N_multiple_input = this.el.querySelector('#multiple_input') as HTMLButtonElement;

		N_multiple_input.addEventListener('click', () => {
			new M_M_Input(this.currentStock).open().then(() => {
				this.getData();
			});
		});
	}

	private initInventory() {
		const N_inventory = this.el.querySelector('#inventory') as HTMLButtonElement;

		N_inventory.addEventListener('click', () => {
			Router.getInstance().navigate(`/#module/stock/inventory?stock=${this.currentStock}${this.lastInventory ? '&id=' + this.lastInventory : ''}`);
		});
	}

	private initList(el: HTMLElement) {
		const N_list = el.querySelector('#list') as HTMLSelectElement;

		S_Stock.getInstance().getRealStock().then((data) => {
			for (const item of data) {
				const option = new Option(item.name, item._id, item.default, item.default);

				N_list.append(option);

				if (item.default) {
					this.currentStock = item._id;
				}
			}

			this.getLastInventory();
		});

		N_list.addEventListener('change', () => {
			if (this.gridOptions.api) {
				this.currentStock = N_list.value;

				const filterInstance = this.gridOptions.api.getFilterInstance('stock');

				filterInstance?.setModel({
					type: 'equals',
					filter: this.currentStock
				});

				this.gridOptions.api.onFilterChanged();

				this.getLastInventory();

				this.updatePrice();
			}
		});
	}

	private initHeaderDashboard() {
		const N_HeaderDashboard = this.el.querySelector('ap-header-dashboard') as CE_HeaderDashboard;
		N_HeaderDashboard.setTitle('Stock');
		N_HeaderDashboard.setGridOptions(this.gridOptions);
	}

	private async getData() {
		Loader.getInstance().open();

		const data = await S_StockEvent.getInstance().getCurrentStock({
			user: Utils.userID
		});

		this.gridOptions.api?.setRowData(data.rowData);
		this.stateSaver?.setData(data.settings);

		Loader.getInstance().close();

		const filterInstanceStock = this.gridOptions.api?.getFilterInstance('stock');

		filterInstanceStock?.setModel({
			type: 'equals',
			filter: this.currentStock
		});

		const filterInstanceDisplay = this.gridOptions.api?.getFilterInstance('displayStock');

		filterInstanceDisplay?.setModel({
			type: 'equals',
			filter: this.viewAllProductsChecked ? '' : 'true'
		});

		this.gridOptions.api?.onFilterChanged();
	}

	private getContextMenu(params: GetContextMenuItemsParams): MenuItemDef[] {
		const result: any[] = [];

		result.push({
			name: 'Action Stock',
			subMenu: [
				{
					name: 'Entrée',
					icon: '<i class="icon icon-solid-sign-in-alt text-indigo"></i>',
					action: () => {
						new M_Input(params.node.data.product._id, params.node.data.quantity.value, params.node.data.stock).open().then(() => {
							this.getData();
						});
					}
				},
				{
					name: 'Sortie',
					icon: '<i class="icon icon-solid-sign-out-alt text-red"></i>',
					action: () => {
						new M_Output(params.node.data.product._id, params.node.data.quantity.value, params.node.data.stock).open().then(async () => {
							this.getData();
						});
					}
				},
				{
					name: 'Mettre à jour',
					icon: '<i class="icon icon-solid-sync-alt"></i>',
					action: () => {
						new M_Edit(params.node.data.product._id, params.node.data.quantity.value, params.node.data.stock).open().then(async () => {
							this.getData();
						});
					}
				}
			]
		});

		result.push('separator');

		result.push(ProductContextMenu.orderProduct([params.node.data.product], !params.node));

		result.push('separator');

		result.push({
			name: 'Éditer Produit',
			icon: '<i class="icon icon-edit"></i>',
			action: async () => {
				await ModalManager.getInstance().open('products', params.node.data.product._id);
				this.getData();
			}
		});

		let title = `${params.node.data.product[S_Product.getInstance().referenceKey]} - ${params.node.data.product.name}`;
		if (title.length > 30) {
			title = title.slice(0, 30) + '...';
		}

		return [
			{
				name: title,
				disabled: true,
				cssClasses: ['title-context-menu']
			},
			...result
		];
	}

	private updatePrice() {
		const N_HeaderDashboard = this.el.querySelector('ap-header-dashboard') as CE_HeaderDashboard;

		const calculatePrice = {
			purchasePrice: new Decimal(0),
			price: new Decimal(0),
			numberProduct: 0,
			numberProductFiltered: 0
		};

		const notCalculatePrice = {
			purchasePrice: new Decimal(0),
			price: new Decimal(0),
			numberProduct: 0,
			numberProductFiltered: 0
		};

		this.gridOptions.api?.forEachNodeAfterFilter((node) => {
			if (node.data.quantity.value >= 0 && this.currentStock === node.data.stock) {
				if (node.data.product.stock.calculatePrice) {
					calculatePrice.purchasePrice = calculatePrice.purchasePrice.plus(Decimal.setDisplayNumber(node.data.totalPurchasePrice.value));
					calculatePrice.price = calculatePrice.price.plus(Decimal.setDisplayNumber(node.data.totalPrice.value));
					calculatePrice.numberProductFiltered++;
				} else {
					notCalculatePrice.purchasePrice = notCalculatePrice.purchasePrice.plus(Decimal.setDisplayNumber(node.data.totalPurchasePrice.value));
					notCalculatePrice.price = notCalculatePrice.price.plus(Decimal.setDisplayNumber(node.data.totalPrice.value));
					notCalculatePrice.numberProductFiltered++;
				}
			}
		});

		this.gridOptions.api?.forEachNode((node) => {
			if (node.data.quantity.value >= 0 && this.currentStock === node.data.stock) {
				if ((!this.viewAllProductsChecked && node.data.displayStock) || this.viewAllProductsChecked) {
					if (node.data.product.stock.calculatePrice) {
						calculatePrice.numberProduct++;
					} else {
						notCalculatePrice.numberProduct++;
					}
				}
			}
		});

		const total = {
			purchasePrice: calculatePrice.purchasePrice.plus(notCalculatePrice.purchasePrice),
			price: calculatePrice.price.plus(notCalculatePrice.price),
			numberProduct: calculatePrice.numberProduct + notCalculatePrice.numberProduct,
			numberProductFiltered: calculatePrice.numberProductFiltered + notCalculatePrice.numberProductFiltered
		};

		N_HeaderDashboard.setData([
			{ text: 'Total', class: 'font-weight-bold h6', price: '' },
			{ text: 'Nombre de produits', price: this.displayNumberProduct(total.numberProductFiltered, total.numberProduct) },
			{ text: 'Prix d\'achat', price: total.purchasePrice.setSuffixAndHumanizeNumber('€') },
			{ text: 'Prix de vente', price: total.price.setSuffixAndHumanizeNumber('€') },
			{ text: 'Valorisé', class: 'font-weight-bold h6', price: '' },
			{ text: 'Nombre de produits', price: this.displayNumberProduct(calculatePrice.numberProductFiltered, calculatePrice.numberProduct) },
			{ text: 'Prix d\'achat', price: calculatePrice.purchasePrice.setSuffixAndHumanizeNumber('€') },
			{ text: 'Prix de vente', price: calculatePrice.price.setSuffixAndHumanizeNumber('€') },
			{ text: 'Non valorisé', class: 'font-weight-bold h6', price: '' },
			{ text: 'Nombre de produits', price: this.displayNumberProduct(notCalculatePrice.numberProductFiltered, notCalculatePrice.numberProduct) },
			{ text: 'Prix d\'achat', price: notCalculatePrice.purchasePrice.setSuffixAndHumanizeNumber('€') },
			{ text: 'Prix de vente', price: notCalculatePrice.price.setSuffixAndHumanizeNumber('€') }
		]);
	}

	private displayNumberProduct(number: number, total: number) {
		if (number === total) {
			return total.toString();
		} else {
			return `${number} / ${total}`;
		}
	}

	public destructor() {

	}
}

export default StockController;
