import { Alert, Tab, toaster } from '@autoprog/core-client';

import _ from 'lodash';

import CsvService from '@services/CsvService';
import ImportProduct from '../libs/ImportProduct';
import Loader from '@libs/Loader';

// MANAGER
import ConfigManager from '@libs/ConfigManager';
import ServiceManager from '@managers/ServiceManager';

// SERVICE
import ConfigService from '@services/ConfigService';
import ProductProviderService from '@js/services/Product/ProductProviderService';

// AG GRID
import { AllModules, ColDef, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

// TYPE
import Product from '@js/types_old/product/product';
import ProductProvider from '@js/types_old/product/productProvider';

class ImportDataTab extends Tab {
	private el: HTMLElement;
	private gridOptions: GridOptions;
	private configService: ConfigService;
	private configManager: ConfigManager;
	private tableConfig: { [key: string]: any };
	private isError: boolean;

	private table: string;
	private separator: string;
	private fileData: { [key: string]: any } | null;

	private listTable: { [key: string]: string } = {};

	constructor(el: HTMLElement) {
		super(el);

		this.el = el;
		this.gridOptions = {};
		this.tableConfig = {};
		this.configService = ConfigService.getInstance();
		this.configManager = ConfigManager.getInstance();
		this.isError = false;

		this.table = '';
		this.separator = '';
		this.fileData = null;

		this.init();
	}

	/**
	 * Initialise les éléments du controller
	 */
	private init() {
		const N_importButton = this.el.querySelector('#btn-import-data') as HTMLButtonElement;
		const N_separator = this.el.querySelector('#separator') as HTMLSelectElement;
		const N_table = this.el.querySelector('#table') as HTMLSelectElement;
		const N_fileSelector = this.el.querySelector('#import-file') as HTMLInputElement;
		const N_colRef = this.el.querySelector('#colRef') as HTMLSelectElement;

		// On change le titre de la page
		const title = document.querySelector('#title') as HTMLTitleElement;
		title.textContent = 'Importation de données';

		this.separator = N_separator.value;

		// On initialise la sélection des tables
		this.configService.getEditableTable().then((data) => {
			this.listTable = data as { [key: string]: string };

			for (const table in this.listTable) {
				const option = new Option(this.listTable[table], table);
				N_table.append(option);
			}
		});

		// On initialise l'agGrid
		this.initGrid();

		N_table.addEventListener('change', async () => {
			this.table = N_table.value;
			N_colRef.value = '';

			if (this.table) {
				// On récupère la configuration de la table
				this.tableConfig = this.configManager.getConfig(this.table);

				const colRef = this.tableConfig.import.colRef;
				const displayColRef = [];
				for (const item of colRef) {
					displayColRef.push(_.find(this.tableConfig.columns, { key: item }).name);
				}
				N_colRef.value = displayColRef.join(' et ');
				N_fileSelector.disabled = false;
			} else {
				N_fileSelector.disabled = true;
			}

			if (this.table && this.separator && this.fileData) {
				await this.updateAgGridWithData(this.fileData);
				!this.isError ? N_importButton.disabled = false : N_importButton.disabled = true;
			} else {
				N_importButton.disabled = true;
			}
		});

		N_separator.addEventListener('change', async () => {
			this.separator = N_separator.value;

			if (this.fileData && this.separator && this.table) {
				await this.updateAgGridWithData(this.fileData);
				N_importButton.disabled = false;
			} else {
				N_importButton.disabled = true;
			}
		});

		N_fileSelector.addEventListener('change', async () => {
			if (N_fileSelector) {
				if (this.table && this.separator && N_fileSelector.files) {
					const file = (N_fileSelector.files || [])[0];

					const fileSize = (file.size / 1000) / 1000;

					if (fileSize < 10) {
						const fileExtension = file.name.replace(/^.*\./, '');

						if (fileExtension === 'csv' || fileExtension === 'txt') {
							const formData = new FormData();
							formData.append('csv', file);
							formData.append('separator', this.separator);

							try {
								Loader.getInstance().open();
								const parseFileResult = await CsvService.getInstance().parseCsv(formData);

								if (parseFileResult.ok) {
									this.fileData = parseFileResult.data;
									this.updateAgGridWithData(parseFileResult.data);
									!this.isError ? N_importButton.disabled = false : N_importButton.disabled = true;
								}
							} catch (e) {
								toaster.error('Une erreur est survenue lors de la lecture du fichier.');
								this.fileData = null;
								this.gridOptions.api?.setRowData([]);
								N_importButton.disabled = true;
							} finally {
								Loader.getInstance().close();
							}
						} else {
							Alert.warning('Fichier incompatible', 'Veuillez ajouter un fichier .csv ou .txt');
						}
					} else {
						Alert.warning('Fichier trop volumineux', `Votre fichier fait <b>${fileSize}Mo </b>. <br> <br> Veuillez importer un fichier de moins de <b>10 Mo</b>.`);
					}
				} else {
					N_importButton.disabled = true;
				}
			}
		});

		N_fileSelector.addEventListener('click', async () => {
			N_fileSelector.value = '';
		});

		N_importButton.addEventListener('click', async () => {
			if (this.table && this.fileData && this.separator) {
				if (this.isError) {
					toaster.error('Ligne(s) non valide.');
				} else {
					Loader.getInstance().open();
					await this.importData();
					Loader.getInstance().close();
				}
			} else {
				toaster.error('Paramétres d\'importation non renseignés.');
			}
		});
	}

	/**
	 * Initialise l'agGrid
	 */
	private initGrid() {
		this.gridOptions = agUtils.french({
			localeText: {
				noRowsToShow: 'Paramètres d\'importation non définis'
			},
			rowData: [],
			defaultColDef: {
				resizable: true
			},
			pinnedTopRowData: [{}, {}],
			onCellEditingStopped: (params) => {
				if (params.node.rowPinned) {
					const row0 = params.api.getPinnedTopRow(0);
					const row1 = params.api.getPinnedTopRow(1);

					params.api.refreshCells({ rowNodes: [row0, row1], force: true });
				}
			},
			getRowClass: (params) => {
				if (!params.node.rowPinned) {
					if (this.getLineError(params.node.data)) {
						return ['bg-red-200'];
					}
				}
				return [];
			}
		});

		const N_grid = this.el.querySelector('#grid') as HTMLElement;

		new Grid(N_grid, this.gridOptions, { modules: AllModules });
	}

	/**
	 * Remplit le tableau Ag Grid avec les données issues du fichier
	 * @param separator
	 * @param table
	 */
	private async updateAgGridWithData(data: { [key: string]: any }) {
		if (this.gridOptions.api) {
			// On récupère le nom des colonnes
			const columns = _.keys(data[0]);
			// Valeurs des colonnes correspondantes par défaut
			const selectedColDefaultValue = {} as { [key: string]: string };
			// Valeurs des références correspondantes par défaut
			const selectedRefDefaultValue = {} as { [key: string]: string };

			// définition des colonnes AgGrid
			const columnDefs = [];

			for (const item of columns) {
				// On récupère la configuration des colonnes de la table
				const colConfig = _.find(this.tableConfig.columns, { name: item }) || {};
				/**
				 * On vérifie s'il y a bien un nom de colonne car le CSV contient une colonne vide à la fin
				 * Dû à la configuration de SILOBOX
				 */
				// On exclut également la colonne fichier
				if (colConfig.type !== 'file') {
					// On préremplit la ligne des correspondances des colonnes du fichier avec les clés dans la table
					selectedColDefaultValue[item] = colConfig.key || '';

					// On préremplit la ligne des correspondances des colonnes avec une référence sur un autre table
					if (colConfig.type === 'table') {
						selectedRefDefaultValue[item] = ServiceManager.get(colConfig.table)?.getInstance().getExportKey() || '';
					}

					const colDef: ColDef = {
						field: item,
						headerName: item,
						suppressMenu: true,
						editable: (params) => {
							if (params.node.rowPinned && params.api) {
								if (params.node.rowIndex === 1) {
									const linePinned = params.api.getPinnedTopRow(0);
									const key = params.colDef.field || '';
									const valueFirstLine = linePinned.data[key];

									if (valueFirstLine) {
										const col = _.find<any>(this.tableConfig.columns, { key: valueFirstLine });

										return col.type === 'table';
									}
								}
							}

							return true;
						},
						cellRenderer: (params) => {
							let config = this.tableConfig;

							let isTable = false;

							if (params.node.rowPinned && params.node.rowIndex === 1 && params.api) {
								const linePinned = params.api.getPinnedTopRow(0);
								const key = params.colDef.field || '';
								const valueFirstLine = linePinned.data[key];

								if (valueFirstLine) {
									const col = _.find<any>(this.tableConfig.columns, { key: valueFirstLine });
									isTable = col.type === 'table';
									config = this.configManager.getConfig(col.table);
								}
							}

							const col = _.find<any>(config.columns, { key: params.value });

							if (col) {
								return col.name;
							} else {
								if (params.node.rowPinned) {
									if (params.node.rowIndex === 0) {
										return '<span class="text-muted">Sélectionner une colonne</span>';
									}

									if (params.node.rowIndex === 1) {
										return isTable ? '<span class="text-muted">Sélectionner la référence</span>' : '';
									}
								}

								return params.value;
							}
						},
						cellEditorSelector: (params) => {
							if (params.node.rowPinned) {
								let config = this.configManager.getConfig(this.table);

								if (params.node.rowIndex === 1 && params.api) {
									const linePinned = params.api.getPinnedTopRow(0);
									const key = params.colDef.field || '';
									const valueFirstLine = linePinned.data[key];

									const col = _.find<any>(config.columns, { key: valueFirstLine });
									config = this.configManager.getConfig(col.table);
								}

								const values = [] as string[];
								config.columns.forEach((col: any) => {
									if (!col.notExportable) {
										values.push(col.key);
									}
								});

								return {
									component: 'agRichSelect',
									params: {
										values,
										formatValue: (value: any) => {
											const col = _.find<any>(config.columns, { key: value });
											return col ? col.name : value;
										}
									}
								};
							}

							return {};
						}
					};
					columnDefs.push(colDef);
				}
			}

			let lineError = 0;
			for (const item of data as [{ [key: string]: any }]) {
				if (this.getLineError(item)) {
					lineError++;
				}
			}

			// On set les colonnes de l'AgGrid
			this.gridOptions.api.setColumnDefs(columnDefs);
			// On set les données de l'AgGrid
			this.gridOptions.api.setRowData(data as any);

			this.gridOptions.api.setPinnedTopRowData([selectedColDefaultValue, selectedRefDefaultValue]);

			if (lineError) {
				this.isError = true;
				toaster.error(`${lineError} ligne(s) sans référence(s).`);
			} else {
				this.isError = false;
			}
		}
	}

	/**
	 * Importe les données sélectionnées
	 * @param table
	 * @param colRef
	 * @param type
	 */
	private async importData() {
		try {
			if (this.gridOptions.api) {
				const attributeName = this.gridOptions.api.getPinnedTopRow(0).data;
				const refName = this.gridOptions.api.getPinnedTopRow(1).data;

				if (this.checkUndefinedValue(attributeName) || this.checkUndefinedValue(refName)) {
					throw Error('Attributs ou Références non définis');
				}

				const rowData: { [key: string]: any }[] = [];

				// On récupère les lignes du tableau (chaque ligne correspond à une donnée à importer)
				this.gridOptions.api.forEachNode(async ({ data }) => {
					rowData.push(data);
				});

				// Import des données en fonction de la table
				if (this.table === ImportProduct.PRODUCTS_PROVIDER_TABLE) {
					await ImportProduct.importProductProvider(rowData, attributeName, refName);
				} else {
					await this.importGenericData(rowData, attributeName, refName);
				}
			}

			toaster.success('Données importées avec succès');
		} catch (e: any) {
			console.error(e.message);
			toaster.error(e.message, 'Erreur lors de l\'importation des données');
		}
	}

	private checkUndefinedValue(object: { [key: string]: string }): boolean {
		let isUndefinedValue = false;
		for (const value of Object.values(object)) {
			if (!value) {
				isUndefinedValue = true;
			}
		}
		return isUndefinedValue;
	}

	/**
	 * Convertit les données du tableau pour l'import de données "générique"
	 * @param data les données du fichier d'import
	 * @param attributeName le nom des attributs
	 * @param refName le nom des références
	 * @returns
	 */
	private async importGenericData(data: { [key: string]: any }[], attributeName: { [key: string]: string }, refName: { [key: string]: string }) {
		const config = this.tableConfig;

		// On déclare le tableau de données à importer
		const dataToImport: any[] = [];

		for (const item of data) {
			let obj: { [key: string]: any } = {};

			for (const key in item) {
				if (key) {
					const col = _.find<any>(config.columns, { key: attributeName[key] });

					if (!col) {
						throw Error('Clé introuvable dans le fichier de configuration !');
					}

					let value = item[key];

					if (col.type === 'table') {
						const selector = {
							[refName[key]]: { $eq: item[key] },
							$or: [{
								deleted: {
									$eq: false
								}
							}, {
								deleted: {
									$exists: false
								}
							}]
						};

						const externalData = await ServiceManager.get(col.table)!.getInstance().find(selector, 1);

						// Gestion des cas spécifiques comme la marque des produits
						if (!externalData.docs[0] && col.acceptNewElement) {
							const id = col.toUpperCase ? item[key].toString().toUpperCase() : item[key].toString();

							// On créée la donnée si elle n'existe pas
							ServiceManager.get(col.table)?.getInstance().create({ _id: id });

							value = id;
						} else {
							value = (externalData.docs[0] || {})._id || '';
						}
					}

					if (col.type === 'string' || col.type === 'primaryKey' || col.type === 'number') {
						if (col.type === 'number') {
							value = item[key].replace(',', '.');
						} else {
							value = item[key];
						}
					}

					if (col.type === 'object') {
						const values = _.values(col.object);
						const keys = _.keys(col.object);

						const index = values.indexOf(item[key]);
						value = keys[index];
					}

					if (col.type === 'boolean') {
						if (['true', 'y', 'yes', 'o', 'oui', 'x', '1', 1, true].indexOf(item[key].toLowerCase()) !== -1) {
							value = true;
						} else {
							value = false;
						}
					}

					if (col.upperCase) {
						value = item[key].toString().upperCase();
					}

					_.set(obj, attributeName[key], value);
				}
			}

			try {
				const colRef = config.import.colRef;

				const selector = {
					$or: [{
						deleted: {
							$eq: false
						}
					}, {
						deleted: {
							$exists: false
						}
					}]
				} as { [key: string]: any };

				for (const item of colRef) {
					selector[item] = { $eq: obj[item] };
				}

				const oldData = await ServiceManager.get(this.table)?.getInstance().find(selector, 1);

				const findDocs = oldData.docs[0] || {};

				const _id = findDocs._id || _.uniqueId(Date.now().toString(36) + '_');

				obj = {
					...findDocs,
					...obj,
					_id
				};

				if (this.table === 'products') {
					obj = await this.getDefaultProviderPrice(obj as Product);
				}
			} catch (e: any) {
				if (e.response.status !== 404) {
					throw e;
				}
			}
			dataToImport.push(obj);
		}

		const result = await ServiceManager.get(this.table)?.getInstance().updateMultiple(dataToImport);

		return result;
	}

	/**
	 * Met à jour les prix fournisseur par défaut si il y a un produit fournisseur par défaut
	 * @param product
	 */
	private async getDefaultProviderPrice(product: Product): Promise<Product> {
		try {
			const defaultProvider = await ProductProviderService.getInstance().getByProductAndProvider(product._id, product.defaultProvider.id) as ProductProvider;
			if (defaultProvider) {
				product.defaultProvider.purchasePrice = defaultProvider.purchase_price;
				product.defaultProvider.costPrice = defaultProvider.costPrice;
				product.defaultProvider.recommendedSellPrice = defaultProvider.providerSellPrice;
			}
			return product;
		} catch (e: any) {
			if (e.message === 'Aucun produit fournisseur trouvé') {
				return product;
			} else {
				throw e;
			}
		}
	}

	/**
	 * Permet de savoir si une ligne du tableau est non valide
	 * @param data une ligne du tableau
	 * @returns
	 */
	private getLineError(data: { [key: string]: any }) {
		const colRef = this.tableConfig?.import?.colRef || [];

		const displayColRef = [];
		for (const item of colRef) {
			displayColRef.push(_.find(this.tableConfig.columns, { key: item }).name);
		}

		if (_.compact(_.at(data, displayColRef)).length < displayColRef.length) {
			return true;
		}

		return false;
	}

	public destructor() { }
}

export default ImportDataTab;
