import { Controller, Tabs } from '@autoprog/core-client';

import C_Customers from '@modules/OrdersCustomer/js/tabs/OrdersCustomerTabs';
import T_TabsCustomer from '@modules/OrdersCustomer/tpl/tabs/ordersCustomerTabs.html';

import C_Providers from '@modules/OrdersProvider/js/tabs/ListCommand';
import C_Tab from '@js/controllers/TabDatabase';
import T_blockTabs from '@tpl/blocks/tabs.html';

import History from '@libs/History';

class OrdersCtrl extends Controller {
	private tabs: Tabs;

	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		params = params || {};

		const defaultTab = params.tabs ? params.tabs : 'customers';

		const navElement = el.querySelector('.tabs-container') as HTMLElement;

		this.tabs = new Tabs({
			navElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		this.tabs.add({
			name: 'Clients',
			id: 'customers',
			default: defaultTab === 'customers',
			permission: 'ORDERS._CUSTOMERS.SHOW',
			controller: C_Customers,
			template: T_TabsCustomer
		});

		this.tabs.add({
			name: 'Fournisseurs',
			id: 'providers',
			default: defaultTab === 'providers',
			permission: 'ORDERS._PROVIDERS.SHOW',
			controller: C_Tab,
			data: {
				instance: C_Providers
			},
			template: T_blockTabs
		});

		let firstLoad = true;
		this.tabs.on('change', (data: { [key: string]: any }) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				History.getInstance().pushState(`#module/orders/${data.id}`);
			}
		});
	}

	public destructor() { }
}

export default OrdersCtrl;
