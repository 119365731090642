import '@css/customElements/inputWithAddon.scss';

class InputWithAddon extends HTMLElement {
	public static readonly tagName: string = 'ap-input-addon';

	private isValid: boolean = false;

	public connectedCallback() {
		const id = this.getAttribute('id-input') as string;
		const name = this.getAttribute('name') as string;
		const type = this.getAttribute('type') as string;
		const suffix = this.getAttribute('suffix') as string;
		const required = this.hasAttribute('required');

		if (type === 'number') {
			const step = this.getAttribute('step') as string;
			const min = this.getAttribute('min') as string;
			const max = this.getAttribute('max') as string;

			this.innerHTML = `
			<input type="${type || 'text'}" id="${id}" name="${name}" max="${max}" min="${min}" step="${step}" ${required ? 'required' : ''}>
			<div class="suffix">${suffix}</div>
        	`;
		} else {
			this.innerHTML = `
			<input type="${type || 'text'}" id="${id}" name="${name}" ${required ? 'required' : ''}>
			<div class="suffix">${suffix}</div>
        `;
		}

		this.removeAttribute('id-input');
		this.removeAttribute('name');

		this.initEvent();
	}

	/**
	 * Initialise les événements sur l'input
	 */
	private initEvent() {
		const input = this.querySelector<HTMLInputElement>('input');

		this.isValid = input!.checkValidity();
		this.toggleClass();

		input?.addEventListener('input', () => {
			this.isValid = input!.checkValidity();

			this.toggleClass();
		});
	}

	/**
	 * Change les classes sur le composant 
	 */
	private toggleClass() {
		if (this.isValid) {
			this.classList.add('valid');
			this.classList.remove('invalid');
		} else {
			this.classList.add('invalid');
			this.classList.remove('valid');
		}
	}

	public static register() {
		customElements.define(InputWithAddon.tagName, InputWithAddon);
	}
}

export default InputWithAddon;
