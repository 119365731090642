// CORE
import { Alert, services } from '@autoprog/core-client';

// NODE_MODULE
import h from 'hyperscript';

// LIBS
import C_User from './User';

import AgGridStateSaver from '@libs/agGrid/StateSaver';
import Permissions from '@libs/Permissions';
import Utils from '@libs/utils/Utils';

import GridOptionsManager from '@js/managers/GridOptionsManagers';

import CE_Aggrid from '@libs/customElement/AgGrid';
import CE_Button from '@libs/customElement/Button';
import CE_GridSidebar from '@libs/customElement/GridSidebar';

import S_Users from '@services/User/UserService';

class Users {
	private el: HTMLElement;
	private permissionKey: string;
	private N_grid: CE_Aggrid;
	private abortController: AbortController;

	constructor(el: HTMLElement) {
		this.el = el;
		this.permissionKey = Permissions.users;

		this.abortController = new AbortController();

		this.N_grid = this.el.querySelector<CE_Aggrid>('#grid')!;

		const N_add = document.querySelector<CE_Button>('#add')!;

		N_add.setAttribute('permission', `${this.permissionKey}.CREATE`);

		N_add.addEventListener('click', () => {
			C_User.open(null, {
				add: true
			});
		}, {
			signal: this.abortController.signal
		});

		this.initGrid();

		this.getData();
	}

	private initGrid() {
		const gridOptionsManager = GridOptionsManager.getInstance();

		const gridOptions = gridOptionsManager.get('users', {
			sideBar: {
				hiddenByDefault: true
			},
			suppressContextMenu: true
		});

		gridOptions.columnDefs?.push({
			headerName: '',
			filter: false,
			pinned: 'right',
			suppressColumnsToolPanel: true,
			suppressSizeToFit: true,
			cellRenderer: this.cellRendererActionCol.bind(this)
		});

		this.N_grid.setGridOptions(gridOptions);

		const N_GridSidebar = this.el.querySelector('ap-grid-sidebar') as CE_GridSidebar;
		N_GridSidebar.initSideBar(this.N_grid!.gridOptions);
	}

	private cellRendererActionCol(params: any) {
		const N_div = h<HTMLElement>('div.container-action-aggrid');

		const N_edit = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { permission: `${this.permissionKey}.EDIT`, type: 'edit' } });

		N_edit.addEventListener('click', () => {
			this.buttonEdit(params.data._id);
		});

		N_div.appendChild(N_edit);

		const N_delete = h<CE_Button>('ap-button.btn-action-aggrid', { attrs: { permission: `${this.permissionKey}.DELETE`, confirmation: 'true', type: 'delete' } });

		N_delete.addEventListener('click', () => {
			this.buttonDelete(params.data._id);
		});

		N_div.appendChild(N_delete);

		const N_fakeLoginButton = h<CE_Button>('ap-button.btn-action-aggrid.btn-default', { attrs: { permission: 'MAIN_SETTINGS.USERS_SYSTEM.USERS.IMPERSONATE_USER', confirmation: 'true', icon: 'ghost/line' } });

		if (!params.data.isSuperAdmin) {
			N_fakeLoginButton.addEventListener('click', async () => {
				try {
					await services.UserService.getInstance().fakeLogin(params.data._id);
					window.location.reload();
				} catch (e: any) {
					Alert.error(`Impossible de se faire passer pour ${params.data._id}`, '', e);
				}
			});
		} else {
			N_fakeLoginButton.disabled = true;
		}

		N_div.appendChild(N_fakeLoginButton);

		return N_div;
	}

	private async getData() {
		this.N_grid.resetValue();

		const { rowData, settings } = await S_Users.getInstance().getDataToAgGrid(this.abortController);
		this.N_grid.value = rowData;

		const stateSaver = new AgGridStateSaver(this.N_grid.gridOptions, 'users');
		stateSaver.setData(settings);
	}

	protected buttonEdit(id: string) {
		C_User.open(id);
	}

	protected async buttonDelete(id: string) {
		if (Utils.userID === id) {
			Alert.error('Erreur', 'Impossible de ce supprimer soi-même');
		} else {
			await S_Users.getInstance().delete(id);
			this.getData();
		}
	}

	public destructor() {
		this.abortController.abort('destroy');
	}
}

export default Users;
