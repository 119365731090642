import { utils } from '@autoprog/core-client';

import { GroupCellRenderer } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// LIBS
import C_OrdersProvider from '@modules/OrdersProvider/js/controllers/Orders.Provider';

// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
import M_AddProduct from '../modals/AddProduct';

import P_Print from '../printer/MaterialsByBrand';

import CE_Aggrid from '@libs/customElement/AgGrid';

// SERVICE
import S_Product from '@services/Product/ProductService';
import S_Provider from '@services/Provider/ProviderService';
import S_Quote from '@services/QuoteService';

import EditCellRenderer from '@libs/agGrid/cellRenderer/EditCellRenderer';
import NumericCellRenderer from '@libs/agGrid/cellRenderer/NumericCellRenderer';
import ProductImageCellRenderer from '@modules/Products/js/libs/ProductImageCellRenderer';

class MaterialsTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-customers-materials-tab';

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	private selectorTab: string = '';

	private idTab: string = '';

	private products: {
		[id: string]: { reference: string, unit: string, brand: string, price: string, name: string, provider: string }
	} = {};

	private providers: { [id: string]: string } = {};

	private N_add_order: HTMLButtonElement | null = null;

	private N_btnDisable: HTMLButtonElement[] = [];

	private _suppressContextMenu: () => boolean = () => { return false; };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || MaterialsTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="product/line" icon-active="product/fill" text="Liste matériels"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid!.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public disableButton() {
		for (const N_el of this.N_btnDisable) {
			N_el!.classList.add('d-none');
		}
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Liste du matériels par devis
				<div class="ml-2 text-md text-muted" id="price"></div>
				<div class="page-tabs-title-right">
					<ap-button class="btn-icon" type="print" id="print"></ap-button>
					<ap-button class="btn-icon" type="add" id="add_materials" tooltip="Ajouter matériel"></ap-button>
					<ap-button class="d-none" type="add" permission="ORDERS._PROVIDERS.ADD" id="add_order">Créer une commande fournisseur</ap-button>
				</div>
				<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
		`;

		const N_print = this.N_el.querySelector('#print') as HTMLButtonElement;
		const N_add_materials = this.N_el.querySelector('#add_materials') as HTMLButtonElement;
		this.N_add_order = this.N_el.querySelector('#add_order') as HTMLButtonElement;

		N_print.addEventListener('click', () => {
			new P_Print(this.idOrder, this.N_grid!.value, this.products);
		});

		N_add_materials.addEventListener('click', () => {
			new M_AddProduct().open().then(async (data) => {
				const product = await S_Product.getInstance().getById(data.product);

				this.products[product._id] = {
					reference: product[S_Product.getInstance().referenceKey],
					unit: product.unit,
					brand: product.brand,
					name: product.name,
					price: product.price,
					provider: product.defaultProvider?.id
				};

				if (product?.defaultProvider?.id && !this.providers[product.defaultProvider.id]) {
					this.providers[product.defaultProvider.id] = await S_Provider.getInstance().getDisplayRefByID(product.defaultProvider.id);
				}

				const res = {
					quote: '_add_product',
					productID: product._id,
					quantity: data.quantity,
					comment: ''
				};

				this.N_grid!.addValues([res]);

				this.dispatchEvent(new CustomEvent('update'));
			});
		});

		this.N_add_order.addEventListener('click', () => {
			this.createOrder();
		});

		this.N_btnDisable.push(N_print, N_add_materials, this.N_add_order);

		N_container.append(this.N_el);

		this.initGrid();
	}

	public get idOrder() {
		return utils.getQuery().id;
	}

	public active() {
		this.N_el?.classList.add('active');
	}

	public setSuppressContextMenu(cb: () => boolean) {
		this._suppressContextMenu = cb;
	}

	private initGrid() {
		this.N_grid = this.N_el?.querySelector<CE_Aggrid>('#grid')!;

		this.N_grid.setGridOptions({
			localeText: { noRowsToShow: 'Aucun produit' },
			columnDefs: [
				{
					headerName: 'Devis',
					field: 'quote',
					rowGroup: true,
					hide: true
				},
				{
					headerName: '',
					checkboxSelection: true,
					suppressSizeToFit: true,
					headerCheckboxSelection: true,
					resizable: false,
					width: 80,
					pinned: 'left',
					field: 'productID',
					cellRenderer: ProductImageCellRenderer
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params: any) => {
						return this.products[params.data.productID]?.reference || '';
					}
				},
				{
					headerName: 'Libellé',
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params) => {
						return this.products[params.data.productID]?.name || '';
					}
				},
				{
					headerName: 'Marque',
					floatingFilter: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					valueGetter: (params) => {
						return this.products[params.data.productID]?.brand || '';
					}
				},
				{
					headerName: 'Fournisseur',
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params) => {
						return this.providers[this.products[params.data.productID]?.provider] || '';
					}
				},
				{
					headerName: 'Prix de vente',
					field: 'product.price',
					width: 150,
					suppressSizeToFit: true,
					valueGetter: (params) => {
						return this.products[params.data.productID]?.price || '';
					},
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 2,
						suffix: '€'
					}
				},
				{
					headerName: 'Quantité',
					field: 'quantity',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				},
				{
					headerName: 'Unité',
					width: 80,
					valueGetter: (params) => {
						return this.products[params.data.productID]?.unit || '';
					}
				},
				{
					headerName: 'Commentaire',
					field: 'comment',
					floatingFilter: false,
					editable: true,
					cellRenderer: EditCellRenderer
				}
			],
			suppressClickEdit: true,
			groupRowRendererParams: {
				innerRenderer: (params: any) => {
					const el = document.createElement('div');
					if (params.value === '_add_product') {
						return 'Ajout materiel';
					} else if (params.value) {
						S_Quote.getInstance().getById(params.value).then((data) => {
							el.innerHTML = `${data.infos.number} : ${data.infos.label}`;
						}).catch(() => {
							el.innerHTML = 'Autres';
						});
					} else {
						el.innerHTML = 'Autres';
					}
					return el;
				}
			},
			groupRowRenderer: GroupCellRenderer,
			groupSelectsChildren: true,
			groupUseEntireRow: true,
			groupDefaultExpanded: 1,
			rowSelection: 'multiple',
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			suppressContextMenu: this._suppressContextMenu(),
			onRowSelected: (params) => {
				const rows = params.api.getSelectedRows();
				if (rows.length === 0) {
					this.N_add_order?.classList.add('d-none');
				} else {
					this.N_add_order?.classList.remove('d-none');
				}
			},
			onCellEditingStopped: () => {
				this.dispatchEvent(new CustomEvent('update'));
			},
			getContextMenuItems: (params: any) => {
				const rowSelected = params.api?.getSelectedNodes();

				return [
					{
						name: 'Créer un bon de commande fournisseur',
						disabled: rowSelected.length === 0,
						action: async () => {
							this.createOrder();
						}
					}
				];
			}
		});
	}

	private async createOrder() {
		const rowSelected = this.getSelectedNodes();

		C_OrdersProvider.open(null, {
			type: 'command',
			materials: _.map(rowSelected, (item) => {
				return {
					quoteCustomer: item.data.quote,
					reference: item.data.productID,
					maxQuantity: item.data.quantity,
					quantity: item.data.quantity
				};
			}),
			idOrder: this.idOrder
		});
	}

	private updatePrice() {
		let result = new Decimal(0);

		this.N_grid!.api!.forEachNode((node) => {
			if (!node.group) {
				const quantity = Decimal.setDisplayNumber(node.data.quantity);
				const tmp = Decimal.setDisplayNumber(this.products[node.data.productID]?.price).times(quantity);
				result = result.plus(tmp);
			}
		});

		const N_el = this.N_el!.querySelector('#price') as HTMLElement;
		N_el.innerHTML = 'Prix de vente : ' + result.setSuffixAndHumanizeNumber('€');
	}

	public set data(data: any[]) {
		this.getAdditionalData().then(() => {
			this.N_grid!.value = data;
			this.updatePrice();
		});
	}

	public get data(): any[] {
		const results: any[] = [];

		this.N_grid!.api!.stopEditing();

		this.N_grid!.api!.forEachNode((node) => {
			if (!node.group) {
				const item = _.cloneDeep(node.data);
				results.push(item);
			}
		});

		return results;
	}

	public getSelectedNodes() {
		return this.N_grid!.api?.getSelectedNodes();
	}

	private async getAdditionalData() {
		const products = await S_Product.getInstance().getAll();

		this.products = {};

		for (const item of products) {
			this.products[item._id] = {
				reference: item[S_Product.getInstance().referenceKey],
				unit: item.unit,
				brand: item.brand,
				name: item.name,
				price: item.price,
				provider: item.defaultProvider?.id
			};

			if (item.defaultProvider?.id && !this.providers[item.defaultProvider.id]) {
				this.providers[item.defaultProvider.id] = await S_Provider.getInstance().getDisplayRefByID(item.defaultProvider.id);
			}
		}
	}

	public static register() {
		customElements.define(MaterialsTab.tagName, MaterialsTab);
	}
}

export default MaterialsTab;
