// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import moment, { Moment } from 'moment';
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../tpl/modals/addLinePayment.html';

// LIBS
import Modal from '@libs/Modal';
import Utils from '@libs/utils/Utils';

// UTILS
import Decimal from '@libs/utils/Decimal';

// SERVICE
import S_C_Bills from '@services/Customer/CustomerBillService';
import S_Companies from '@services/CompaniesService';

class AddLinePaymentBill extends Modal {
	constructor(idBill: string = '', data: { [key: string]: any } = {}) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		data = _.cloneDeep(data);

		this.on('opened', async () => {
			let max = new Decimal(0);

			if (idBill) {
				const tmp = await S_C_Bills.getInstance().getNotPaidById(idBill);
				max = Decimal.setDisplayNumber(tmp);
			}

			if (data.price) {
				const price = Decimal.setDisplayNumber(data.price);
				max = max.plus(price);
				data.price = price.toNumber();
			} else {
				data.price = max;
			}

			const N_type = this.element.querySelector('[name="type"]') as HTMLSelectElement;
			const N_bank = this.element.querySelector('[name="bank"]') as HTMLSelectElement;
			const N_container_numberCheque = this.element.querySelector('#container_numberCheque') as HTMLSelectElement;
			const N_container_bank = this.element.querySelector('#container_bank') as HTMLSelectElement;

			const company = await S_Companies.getInstance().getById(Utils.companyID);

			for (const item of (company.bankList || [])) {
				N_bank.appendChild(new Option(item.name, item.name));
			}

			N_type.addEventListener('change', () => {
				if (N_type.value === 'Chèque') {
					N_container_numberCheque.classList.remove('d-none');
					N_container_bank.classList.remove('d-none');
				} else if (N_type.value === 'Espèce') {
					N_container_bank.classList.add('d-none');
					N_container_numberCheque.classList.add('d-none');
				} else if (N_type.value === 'Virement') {
					N_container_bank.classList.remove('d-none');
					N_container_numberCheque.classList.add('d-none');
				} else if (N_type.value === 'Paypal') {
					N_container_bank.classList.add('d-none');
					N_container_numberCheque.classList.add('d-none');
				}
			});

			if (data.type) {
				if (data.type === 'Chèque') {
					N_container_numberCheque.classList.remove('d-none');
					N_container_bank.classList.remove('d-none');
				} else if (data.type === 'Espèce') {
					N_container_bank.classList.add('d-none');
					N_container_numberCheque.classList.add('d-none');
				} else if (N_type.value === 'Paypal') {
					N_container_bank.classList.add('d-none');
					N_container_numberCheque.classList.add('d-none');
				} else {
					data.type = 'Virement';

					N_container_bank.classList.remove('d-none');
					N_container_numberCheque.classList.add('d-none');
				}
			}

			const N_form = this.element.querySelector('form') as HTMLFormElement;
			const N_max = this.element.querySelector('#max') as HTMLElement;

			const form = new Form(N_form);

			if (data.date) {
				data.date = moment(data.date, 'x');
			} else {
				data.date = moment();
			}

			data.price = Decimal.setDisplayNumber(data.price).humanizeNumber();

			N_max.innerHTML = max.setSuffixAndHumanizeNumber('€');

			form.setData(data);

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			const N_error = this.element.querySelector('#error') as HTMLElement;

			if (max.equals(0)) {
				N_form.innerHTML = `
					<div class="h-100 w-100 text-center text-danger">
						<i class="icon icon-warning text-danger display-1"></i>
						<div class="font-weight-bold mt-4"> 
							Ajout paiement impossible <br>
							Facture payée entièrement 
						</div>
					</div>
				`;
				N_save.classList.add('d-none');
			}

			N_save.addEventListener('click', () => {
				const data = form.getData();

				if (data.type === 'Espèce') {
					data.numberCheque = '';
					data.bank = '';
				} else if (data.type === 'Virement') {
					data.numberCheque = '';
				}

				data.date = (data.date as Moment).format('x');
				data.user = Utils.userID;

				data.price = Decimal.setDisplayNumber(data.price as string).toNumber();

				if (data.price > max.toNumber()) {
					N_error.classList.remove('d-none');
				} else {
					this.resolve(data);
				}
			});
		});
	}
}

export default AddLinePaymentBill;
