
class ProductImagePreviewer extends HTMLElement {
	public static readonly tagName: string = 'ap-product-image-previewer';

	private N_spinner: HTMLDivElement | null = null;
	private N_image: HTMLImageElement | null = null;
	private N_default: HTMLDivElement | null = null;
	private N_invalid: HTMLDivElement | null = null;

	private _src: string = '';

	constructor() {
		super();

		this._src = this.getAttribute('src') || '';
		this.removeAttribute('src');
	}

	public async connectedCallback() {
		this.innerHTML = `
			<div id="img-default">Aucune photo</div>

			<img id="img-preview" class="d-none" style="max-height: 100px">

			<div id="img-invalid" class="d-none">Lien invalide</div>
			
			<div id="loading-spinner" class="d-none">
				<i class="icon icon-solid-spinner icon-spin text-muted" style="font-size:35px"></i>
			</div>
		`;

		this.N_spinner = this.querySelector('#loading-spinner') as HTMLDivElement;
		this.N_image = this.querySelector('#img-preview') as HTMLImageElement;
		this.N_default = this.querySelector('#img-default') as HTMLDivElement;
		this.N_invalid = this.querySelector('#img-invalid') as HTMLDivElement;

		this.initPreviewEvent();

		this.setURL(this._src);
	}

	public setURL(src: string | null) { //base64 || url
		this._src = src || '';
		this.displaySpinner();

		if (this._src === null || this._src.trim() === '' || this._src === 'data:') {
			this.displayDefault();
		} else {
			this.N_image!.src = this._src;
		}
	}

	private displayImage() {
		this.N_spinner!.classList.add('d-none');
		this.N_invalid!.classList.add('d-none');
		this.N_default!.classList.add('d-none');

		this.N_image!.classList.remove('d-none');
	}

	public displayDefault() {
		this.N_spinner!.classList.add('d-none');
		this.N_image!.classList.add('d-none');
		this.N_invalid!.classList.add('d-none');

		this.N_default!.classList.remove('d-none');
	}

	public displayInvalid() {
		this.N_spinner!.classList.add('d-none');
		this.N_image!.classList.add('d-none');
		this.N_default!.classList.add('d-none');

		this.N_invalid!.classList.remove('d-none');
	}

	public displaySpinner() {
		this.N_image!.classList.add('d-none');
		this.N_invalid!.classList.add('d-none');
		this.N_default!.classList.add('d-none');

		this.N_spinner!.classList.remove('d-none');
	}

	private async initPreviewEvent() {
		this.N_image!.addEventListener('load', () => {
			this.displayImage();
		});

		this.N_image!.addEventListener('error', () => {
			this.displayInvalid();
		});
	}

	public static register() {
		customElements.define(ProductImagePreviewer.tagName, ProductImagePreviewer);
	}
}

export default ProductImagePreviewer;
