import { utils } from '@autoprog/core-client';

import h from 'hyperscript';
import moment from 'moment';

import C_OrdersProvider from '@modules/OrdersProvider/js/controllers/Orders.Provider';

import CE_Aggrid from '@libs/customElement/AgGrid';

import S_P_Order from '@services/Provider/ProviderOrderService';
import S_Provider from '@services/Provider/ProviderService';

import NumericCellRenderer from '@libs/agGrid/cellRenderer/NumericCellRenderer';

class ProviderOrderTab extends HTMLElement {
	public static readonly tagName: string = 'ap-provider-order-tab';

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	private providers: { [id: string]: { name: string } } = {};

	private selectorTab: string = '';

	private idTab: string = '';

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || ProviderOrderTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="file-text/line" icon-active="file-text/fill" text="Commandes fournisseurs" hasNumber></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid!.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Commande Fournisseur
				<div class="page-tabs-title-right">
					<ap-button type="add" id="add" permission="ORDERS._PROVIDERS.ADD"></ap-button>
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
		`;

		N_container.append(this.N_el);

		this.initGrid();
		this.initButton();
	}

	private initGrid() {
		this.N_grid = this.N_el?.querySelector<CE_Aggrid>('#grid')!;

		this.N_grid.setGridOptions({
			localeText: { noRowsToShow: 'Aucune Commande Fournisseur' },
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Numero',
					field: 'infos.number',
					pinned: 'left',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Fournisseur',
					field: 'infos.provider',
					width: 200,
					suppressSizeToFit: true,
					valueGetter: (params) => {
						return this.providers[params.data.infos.provider]?.name;
					}
				},
				{
					headerName: 'Date commande',
					field: 'infos.date',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (params.value) {
							return moment(params.value, 'x').format('DD/MM/YY');
						}
						return '';
					}

				},
				{
					headerName: "Nom d'Affaire",
					field: 'infos.businessName'
				},
				{
					headerName: 'Montant',
					field: 'globalPrice',
					width: 200,
					suppressSizeToFit: true,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 2,
						suffix: '€'
					}
				},
				{
					headerName: '',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_div = h('div');

						const N_edit = document.createElement('button');

						N_edit.classList.add('h-100', 'py-0', 'btn-transparent');

						N_edit.setAttribute('permission', 'ORDERS._PROVIDERS.EDIT');

						N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';
						N_edit.setAttribute('tooltip', 'Éditer');
						N_edit.type = 'button';

						N_edit.addEventListener('click', () => {
							C_OrdersProvider.open(params.data._id);
						});

						N_div.appendChild(N_edit);

						return N_div;
					}
				}
			],
			getContextMenuItems: (params) => {
				return [
					{
						name: `N° ${params.node.data.infos.number}`,
						disabled: true,
						cssClasses: ['title-context-menu']
					}, {
						name: 'Éditer',
						icon: '<i class="icon icon-edit"></i>',
						disabled: !params.node,
						action: () => {
							C_OrdersProvider.open(params.node.data._id);
						}
					}
				];
			},
			onRowDataChanged: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		});
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector<HTMLElement>('#number')!;

		if (this.N_grid!.isLoad) {
			if (number) {
				N_number.innerHTML = number.toString();
				N_number.classList.remove('d-none');
			} else {
				N_number.classList.add('d-none');
			}
		} else {
			N_number.classList.add('d-none');
		}
	}

	private initButton() {
		const N_btn = this.N_el!.querySelector<HTMLButtonElement>('#add')!;

		N_btn.addEventListener('click', () => {
			C_OrdersProvider.open(null, {
				type: 'command',
				materials: [],
				idOrder: this.idOrder
			});
		});
	}

	private get idOrder() {
		return utils.getQuery().id;
	}

	public async initData() {
		await this.getAdditionalData();

		if (this.idOrder) {
			const data = await S_P_Order.getInstance().getDataByOrderCustomer(this.idOrder);
			this.N_grid!.value = data;
		} else {
			this.N_grid!.value = [];
		}
	}

	private async getAdditionalData() {
		const providers = await S_Provider.getInstance().getAll();

		for (const item of providers) {
			this.providers[item._id] = {
				name: item.name
			};
		}
	}

	public static register() {
		customElements.define(ProviderOrderTab.tagName, ProviderOrderTab);
	}
}

export default ProviderOrderTab;
