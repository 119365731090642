import { utils } from '@autoprog/core-client';

import h from 'hyperscript';
import moment from 'moment';

import C_Delivery from '@modules/Deliveries_Receipts/js/controllers/Delivery';

import M_details from '../modals/DetailsDeliveries';

import CE_Aggrid from '@libs/customElement/AgGrid';
import CE_Button from '@libs/customElement/Button';

import S_Deliveries from '@services/DeliveryService';

import NumericCellRenderer from '@js/libs/agGrid/cellRenderer/NumericCellRenderer';

class DeliveryTab extends HTMLElement {
	public static readonly tagName: string = 'ap-delivery-tab';

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	private selectorTab: string = '';

	private idTab: string = '';

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || DeliveryTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="truck/line" icon-active="truck/fill" text="Bons de livraison" hasNumber></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid!.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Bon de livraison
				<div class="page-tabs-title-right">					
					<ap-button class="btn-info" icon="file-list/line" id="detail">Voir détails</ap-button>
					<ap-button class="btn-icon" type="add" permission="DELIVERIES.ADD" id="add"></ap-button>
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
		`;

		N_container.append(this.N_el);

		this.initGrid();
		this.initButton();
	}

	private initGrid() {
		this.N_grid = this.N_el?.querySelector<CE_Aggrid>('#grid')!;

		this.N_grid.setGridOptions({
			localeText: { noRowsToShow: 'Aucun Bon de livraison' },
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true
			},
			columnDefs: [
				{
					headerName: 'Numéro',
					field: 'infos.number',
					pinned: 'left',
					width: 150,
					suppressSizeToFit: true
				},
				{
					headerName: 'Date de livraison',
					field: 'infos.deliveryDate',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params: any) => {
						return params.value ? moment(params.value, 'YYYY-MM-DD').format('DD/MM/YYYY') : '';
					}
				},
				{
					headerName: 'Nom Affaire',
					field: 'infos.businessName',
					width: 100
				},
				{
					headerName: 'État',
					field: 'state',
					width: 150,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						switch (params.value) {
							case '0':
								return 'En cours de préparation';
							case '1':
								return 'Prêt à expédier';
							case '2':
								return 'Expédié';
							default:
								return '';
						}
					}
				},
				{
					headerName: 'Qté livrée',
					field: 'infos.totalDelivered',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				},
				{
					headerName: 'Qté restante',
					field: 'infos.totalRemaining',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				},
				{
					headerName: '',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					width: 50,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_edit = h('ap-button.btn-action-aggrid', { attrs: { type: 'edit', permission: 'DELIVERIES.OPEN' } });

						N_edit.addEventListener('click', () => {
							C_Delivery.open(params.data._id);
						});

						const N_div = h<HTMLElement>('div.container-action-aggrid', N_edit);

						return N_div;
					}
				}
			],
			getRowStyle: (params: any) => {
				switch (params.data.state) {
					case '0':
						return {
							'background-color': 'var(--ap-red-50)',
							color: 'var(--ap-red-900)'
						};
					case '1':
						return {
							'background-color': 'var(--ap-orange-50)',
							color: 'var(--ap-orange-900)'
						};
					case '2':
						return {
							'background-color': 'var(--ap-green-50)',
							color: 'var(--ap-green-900)'
						};
				}

				return {
					'background-color': 'var(--ap-teal-50)',
					color: 'var(--ap-teal-900)'
				};
			},
			getContextMenuItems: (params) => {
				return [
					{
						name: `N° ${params.node.data.infos.number}`,
						disabled: true,
						cssClasses: ['title-context-menu']
					}, {
						name: 'Éditer',
						icon: '<i class="icon icon-edit"></i>',
						disabled: !params.node,
						action: () => {
							C_Delivery.open(params.node.data._id);
						}
					},
					'separator',
					{
						name: 'Créer un bon de livraison',
						icon: '<i class="icon icon-solid-plus"></i>',
						action: () => {
							C_Delivery.open(null, {
								from: 'order',
								idOrder: this.idOrder
							});
						}
					}
				];
			},
			onRowDataChanged: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		});
	}

	private initButton() {
		const N_detail = this.N_el!.querySelector('#detail') as HTMLButtonElement;

		N_detail.addEventListener('click', () => {
			new M_details(this.idOrder).open();
		});
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector<HTMLElement>('#number')!;

		if (this.N_grid!.isLoad) {
			if (number) {
				N_number.innerHTML = number.toString();
				N_number.classList.remove('d-none');
			} else {
				N_number.classList.add('d-none');
			}
		} else {
			N_number.classList.add('d-none');
		}
	}

	private updateButton() {
		const N_btn = this.N_el!.querySelector<CE_Button>('#add')!;
		let idFind: null | string = null;
		let numberFind: null | string = null;

		this.N_grid?.forEachNode((node) => {
			if (node.data.state === '0') {
				idFind = node.data._id;
				numberFind = node.data.infos.number;
			}
		});

		if (idFind) {
			N_btn.type = 'open';
			N_btn.setAttribute('tooltip', `Ouvrir le BL n° ${numberFind}`);
		}

		N_btn.addEventListener('click', () => {
			if (idFind) {
				C_Delivery.open(idFind);
			} else {
				C_Delivery.open(idFind, {
					from: 'order',
					idOrder: this.idOrder
				});
			}
		});
	}

	private get idOrder() {
		return utils.getQuery().id;
	}

	public async initData() {
		const data = await S_Deliveries.getInstance().getByCustomerOrder(this.idOrder);

		this.N_grid!.value = data;

		this.updateButton();
	}

	public static register() {
		customElements.define(DeliveryTab.tagName, DeliveryTab);
	}
}

export default DeliveryTab;
