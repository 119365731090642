import T_modal from '../../tpl/modals/searchOrder.html';

import Modal from '@libs/EditPageModal';

import CE_AgGrid from '@libs/customElement/AgGrid';
import CE_Select2 from '@libs/customElement/Select2';

import S_C_Contact from '@services/Customer/CustomerContactService';
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';
import S_Quotes from '@services/QuoteService';
import S_User from '@services/User/UserService';

type GeneralInformationData = {
	quoteID: string,
	quoteNumber: string,
	infosCustomer: {
		orderNumber: string
		addonNumber: string
		customer: string
		contact: string
	}
	manager: string
};

class SearchOrder extends Modal<GeneralInformationData> {
	private quoteInformation: GeneralInformationData;

	constructor(data: GeneralInformationData) {
		super(data, T_modal);
		this.quoteInformation = data;
	}

	protected preInit() {
		const N_grid = this.element.querySelector<CE_AgGrid>('#grid')!;

		const stateBill: { [key: number]: string } = {
			0: 'Pas commencée',
			1: 'En cours',
			2: 'Facturée à 100%',
			3: 'Non valorisé'
		};

		N_grid.setGridOptions({
			localeText: { noRowsToShow: 'Aucune commande trouvée' },
			rowSelection: 'single',
			suppressContextMenu: true,
			columnDefs: [{
				headerName: '',
				checkboxSelection: true,
				pinned: 'left',
				width: 50,
				suppressSizeToFit: true
			}, {
				headerName: 'N° Affaire',
				field: 'internalNumber'
			}, {
				headerName: 'Client',
				field: 'infosCustomer.customer',
				hide: true
			}, {
				headerName: 'N° commande client',
				field: 'infosCustomer.orderNumber'
			}, {
				headerName: 'Complément',
				field: 'infosCustomer.addonNumber'
			}, {
				headerName: 'Etat facture',
				field: 'bill.state',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: Object.keys(stateBill),
					valueFormatter: (params: any) => {
						return stateBill[params.value] || params.value;
					}
				},
				hide: true
			}, {
				headerName: 'Objet',
				field: 'label'
			}, {
				headerName: 'Sites',
				field: 'sites'
			}],
			defaultColDef: {
				suppressMovable: true,
				sortable: true,
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			},
			onFilterChanged: () => {
				if (N_grid.api?.getDisplayedRowCount() === 0) {
					N_grid.api?.showNoRowsOverlay();
				} else {
					N_grid.api?.hideOverlay();
				}
				this.updateButtonSave();
			},
			onRowSelected: () => {
				this.updateButtonSave();
			}
		});

		const N_customer = this.element.querySelector<CE_Select2>('[name="infosCustomer.customer"]')!;
		const N_contact = this.element.querySelector<CE_Select2>('[name="infosCustomer.contact"]')!;

		const N_manager = this.element.querySelector<CE_Select2>('[name="manager"]')!;

		N_manager.create(this.element);

		N_customer.create(this.element);

		N_contact.setRef({ id_customer: N_customer.selectElement! });
		N_contact.create(this.element);

		this.selectPostinit['infosCustomer.customer'] = N_customer;
		this.selectPostinit['infosCustomer.contact'] = N_contact;
		this.selectPostinit.manager = N_manager;
	}

	protected async setData(data: GeneralInformationData) {
		const res: { [key: string]: any } = {
			infosCustomer: {
				orderNumber: data.infosCustomer.orderNumber,
				addonNumber: data.infosCustomer.addonNumber,
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infosCustomer.customer),
				contact: await S_C_Contact.getInstance().getDataToSelect2ByID(data.infosCustomer.contact)
			},
			manager: await S_User.getInstance().getDataToSelect2ByID(data.manager)
		};

		if (data.quoteID) {
			const N_addontTitle = this.element.querySelector<HTMLElement>('.modal-title-addon')!;
			const quoteText = await S_Quotes.getInstance().getDisplayRefByID(data.quoteID);

			N_addontTitle.innerHTML = quoteText;
		} else {
			const N_copyQuoteNumberBtn = this.element.querySelector<HTMLButtonElement>('#copyQuoteNumber');
			N_copyQuoteNumberBtn?.classList.add('d-none');
		}

		this.form?.setData(res);

		const N_grid = this.element.querySelector<CE_AgGrid>('#grid')!;
		const { rowData } = await S_C_Order.getInstance().getDataToAgGrid();

		N_grid.value = rowData;

		this.changeFilter();

		this.updateButtonSave();
	}

	protected initEventButton() {
		const N_create = this.element.querySelector<HTMLButtonElement>('#create')!;
		const N_add = this.element.querySelector<HTMLButtonElement>('#save')!;

		const N_grid = this.element.querySelector<CE_AgGrid>('#grid')!;

		N_create.addEventListener('click', async () => {
			const data = this.form?.getData() as { [key: string]: any };

			const res: { [key: string]: any } = {
				infosCustomer: {
					orderNumber: data.infosCustomer.orderNumber,
					addonNumber: data.infosCustomer.addonNumber,
					customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infosCustomer.customer),
					contact: await S_C_Contact.getInstance().getDataToSelect2ByID(data.infosCustomer.contact)
				},
				manager: await S_User.getInstance().getDataToSelect2ByID(data.manager)
			};

			this.resolve({ type: 'create', data: res });
		});

		N_add.addEventListener('click', async () => {
			let id: string = '';
			N_grid.api?.forEachNodeAfterFilter((node) => {
				if (node.isSelected()) {
					id = node.data._id.value;
				}
			});

			this.resolve({ type: 'close', id });
		});
	}

	private async changeFilter() {
		const N_grid = this.element.querySelector<CE_AgGrid>('#grid')!;

		const filterInstanceState = N_grid.api?.getFilterInstance('bill.state');

		await this.updateFilterCustomer(this.form!.getDataByName('infosCustomer.customer') as string);

		filterInstanceState?.setModel({
			values: ['0', '1', '3']
		});

		N_grid.api?.onFilterChanged();
	}

	protected async getDataForm(data: { [key: string]: any }) {
		data.infosCustomer.customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infosCustomer.customer);

		return data;
	}

	protected postInit() {
		super.postInit();

		const N_grid = this.element.querySelector<CE_AgGrid>('#grid')!;

		const N_orderNumber = this.element.querySelector<HTMLInputElement>('[name="infosCustomer.orderNumber"]')!;
		const N_addonNumber = this.element.querySelector<HTMLInputElement>('[name="infosCustomer.addonNumber"]')!;
		const N_copyQuoteNumberBtn = this.element.querySelector<HTMLButtonElement>('#copyQuoteNumber');

		N_copyQuoteNumberBtn?.addEventListener('click', () => {
			N_orderNumber.value = this.quoteInformation.quoteNumber;

			const filterInstanceOrderNumber = N_grid.api?.getFilterInstance('infosCustomer.orderNumber');

			filterInstanceOrderNumber?.setModel({
				type: 'contains',
				filter: N_orderNumber.value
			});

			N_grid.api?.onFilterChanged();
		});

		N_orderNumber.addEventListener('input', () => {
			const filterInstanceOrderNumber = N_grid.api?.getFilterInstance('infosCustomer.orderNumber');

			filterInstanceOrderNumber?.setModel({
				type: 'contains',
				filter: N_orderNumber.value
			});

			N_grid.api?.onFilterChanged();
		});

		N_addonNumber.addEventListener('input', () => {
			const filterInstanceAddonNumber = N_grid.api?.getFilterInstance('infosCustomer.addonNumber');

			filterInstanceAddonNumber?.setModel({
				type: 'contains',
				filter: N_addonNumber.value
			});

			N_grid.api?.onFilterChanged();
		});

		this.selectPostinit['infosCustomer.customer'].on('change', async (customer) => {
			await this.updateFilterCustomer(customer as string);

			const contacts = await S_C_Contact.getInstance().getByCustomerToSelect2(customer as string);

			if (contacts.length && customer) {
				this.form?.setDataByName('infosCustomer.contact', contacts[0]);
			} else {
				this.form?.setDataByName('infosCustomer.contact', { id: '', text: '' });
			}

			this.updateButtonSave();
		});

		this.selectPostinit.manager.on('change', () => {
			this.updateButtonSave();
		});
	}

	private async updateFilterCustomer(customer: string) {
		const value = await S_Customer.getInstance().getDisplayRefByID(customer);

		const N_grid = this.element.querySelector<CE_AgGrid>('#grid')!;

		const filterInstanceCustomer = N_grid.api?.getFilterInstance('infosCustomer.customer');

		filterInstanceCustomer?.setModel({
			type: 'contains',
			filter: value
		});

		N_grid.api?.onFilterChanged();
	}

	private updateButtonSave() {
		const N_create = this.element.querySelector<HTMLButtonElement>('#create')!;
		const N_add = this.element.querySelector<HTMLButtonElement>('#save')!;

		const N_grid = this.element.querySelector<CE_AgGrid>('#grid')!;

		const data: any[] = [];
		let dataSelected: number = 0;
		N_grid.api?.forEachNodeAfterFilter((node) => {
			data.push(node);

			if (node.isSelected()) {
				dataSelected++;
			}
		});

		N_create.classList.add('d-none');
		N_add.classList.add('d-none');

		if (data.length === 1 || dataSelected === 1) {
			N_add.classList.remove('d-none');
		} else {
			N_create.classList.remove('d-none');
		}

		const manager = this.form?.getDataByName('manager');
		const orderNumber = this.form?.getDataByName('infosCustomer.orderNumber');
		const customer = this.form?.getDataByName('infosCustomer.customer');

		N_add.disabled = (dataSelected !== 1);
		N_create.disabled = !customer || !orderNumber || !manager;
	}
}

export default SearchOrder;
