import '@css/customElements/checkbox.scss';

class Checkbox extends HTMLElement {
	public static readonly tagName: string = 'ap-checkbox';

	public connectedCallback() {
		const name = this.getAttribute('name') as string;
		const label = this.getAttribute('label') as string;
		const disabled = this.hasAttribute('disabled');

		this.innerHTML = `
			<input type="checkbox" ${disabled ? 'disabled' : ''}  name="${name}" />
			<label>${label}</label>
        `;

		const N_input = this.querySelector<HTMLInputElement>('input')!;

		this.addEventListener('click', () => {
			N_input.checked = !N_input.checked;
		});

		this.removeAttribute('name');
	}

	public static register() {
		customElements.define(Checkbox.tagName, Checkbox);
	}
}

export default Checkbox;
