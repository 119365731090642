
export interface ISettingsController {
	new(el: HTMLElement, data?: any): SettingsController;
}

class SettingsController {
	private _resolve: (value?: any) => void;
	private _reject: (value?: any) => void;

	protected abortController: AbortController;
	protected el: HTMLElement;

	// eslint-disable-next-line unused-imports/no-unused-vars
	constructor(el: HTMLElement, data?: object) {
		this.el = el;

		this.abortController = new AbortController();

		this._resolve = () => { };
		this._reject = () => { };
	}

	protected get abortSignal(): AbortSignal {
		return this.abortController.signal;
	}

	public open() {
		return new Promise<any>((resolve, reject) => {
			this._resolve = resolve;
			this._reject = reject;

			this.init();
		});
	}

	protected init() {

	}

	protected resolve() {
		this.destructor();
		this._resolve();
	}

	protected reject() {
		this.destructor();
		this._reject();
	}

	public destructor() {

	}
}

export default SettingsController;
