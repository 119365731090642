class PageTabsMenuTitle extends HTMLElement {
	public static readonly tagName: string = 'ap-page-tabs-menu-title';

	private icon: string = '';

	public connectedCallback() {
		this.icon = this.getAttribute('icon') as string;
		const text = this.getAttribute('text') || 'Menu';

		this.innerHTML = `<ap-icon name="${this.icon}" tooltip='{"title" : "${text}", "placement" : "right"}'></ap-icon>${text}`;

		this.removeAttribute('icon');
		this.removeAttribute('text');
	}

	public static register() {
		customElements.define(PageTabsMenuTitle.tagName, PageTabsMenuTitle);
	}
}

export default PageTabsMenuTitle;
