import { Form, toaster } from '@autoprog/core-client';

import SettingsApps from '@libs/Settings';

import _ from 'lodash';

import ConfigManager from '@libs/ConfigManager';

import CE_Select from '@libs/customElement/Select';

import S_Account from '@modules/Comptabilité/js/services/AccountsService';

import QuoteData from '../QuoteData';

import '../../../css/quoteOption.scss';
import Settings from '@modules/Settings/js/libs/Settings';

export type SettingQuoteOption = {
	hourlyPriceType: {
		[key: string]: string
	},
	marginType: {
		[key: string]: string
	}
};

class InputDeleteBtn extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes-options-input';

	// eslint-disable-next-line unused-imports/no-unused-vars
	private callback: (name: string, type: string) => void = () => { };

	private settingsAccounting: { [key: string]: any } = {};

	public async connectedCallback() {
		const text = this.getAttribute('text');
		const name = this.getAttribute('name') || '';
		const type = this.getAttribute('type') || '';
		const isDefault = !!this.getAttribute('default');

		const nameCompta = name.replace('price', 'comptaVente');

		this.settingsAccounting = Settings.getInstance().get('accountings');

		this.innerHTML = `
			<div class="block-input-quote-options">
				<label>${text}</label>
				<ap-input-addon type="number" step="0.01" name="${name}" suffix="${type === 'hourlyPrice' ? '€' : '%'}"></ap-input-addon>
				${name.split('.')[1] === 'price' ? '<ap-select name="' + nameCompta + '"></ap-select>' : ''}
				<ap-button class="btn-icon d-none" type="delete" id="${type === 'hourlyPrice' ? 'delete-price-hour-type' : 'delete-margin-type'}" confirmation></ap-button>
			</div>
		`;

		const N_deleteBtn = this.querySelector(`#${type === 'hourlyPrice' ? 'delete-price-hour-type' : 'delete-margin-type'}`) as HTMLElement;

		if (isDefault && name.split('.')[2] !== QuoteData.KILOMETRAGE_COST) {
			N_deleteBtn.classList.remove('d-none');

			N_deleteBtn.addEventListener('click', () => {
				this.remove();
				this.callback(name.split('.')[2], type);
			});
		}

		if (!isDefault && !this.settingsAccounting?.enabled) {
			this.classList.add('no-compta');
		}

		this.initSelect();

		this.removeAttribute('name');
	}

	private initSelect() {
		const N_select = this.querySelector<CE_Select>('ap-select')!;

		if (N_select) {
			N_select.options = {
				ajax: {
					url: new S_Account().createSelect2URL(),
					getParams: (search) => {
						return {
							search,
							refData: JSON.stringify({ forHourlyRate: true })
						};
					}
				}
			};
		}
	}

	public async setData(data: { [key: string]: any }) {
		const N_Apselect = this.querySelector<CE_Select>('ap-select');

		if (N_Apselect) {
			const value = _.get(data, N_Apselect.name) || this.settingsAccounting?.defaultAccount?.sale?.hourlyRate;

			const tmp = await new S_Account().getDataToSelect2ByID(value);

			N_Apselect.value = tmp;
		}
	}

	public setCallback(cb: (name: string, type: string) => void) {
		this.callback = cb;
	}

	public static register() {
		customElements.define(InputDeleteBtn.tagName, InputDeleteBtn);
	}
}

class QuoteOption extends HTMLElement {
	public static readonly tagName: string = 'ap-quotes-options-tab';

	private selectorTab: string = '';

	private idTab: string = '';
	protected isDefault: boolean = false;

	protected contentOnly: boolean = false;

	private N_tab: HTMLElement | null = null;

	private settingQuoteOptions: SettingQuoteOption | undefined;

	private N_editPriceHoursBlock: HTMLDivElement | null = null;
	private N_editMarginBlock: HTMLDivElement | null = null;

	private N_selectTypeMat: HTMLSelectElement | null = null;
	private N_selectType: HTMLSelectElement | null = null;

	private form: Form | null = null;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || '';

		this.contentOnly = this.hasAttribute('contentOnly');

		if (this.contentOnly) {
			this.classList.add('content-only');
			this.innerHTML = '';
		} else {
			this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="file-settings/line" icon-active="file-settings/fill" text="Paramètres devis"></ap-page-tabs-menu-item>`;
		}

		this.id = '';
	}

	public async setParentElement(parent: HTMLElement) {
		this.isDefault = !!this.getAttribute('default');

		this.N_tab = document.createElement('div');

		this.N_tab.classList.add('tab-pane', 'page-tabs-container', 'page-tabs-quote-options');

		this.N_tab.id = this.idTab;
		this.N_tab.innerHTML = `
			<div class="page-tabs-title">
				Paramètres devis
				<div class="page-tabs-title-right">
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<div class="row w-100">

					<div class="col-md-4 mb-2">

						<div class="p-3">
							<div class="title-quote-options"> Taux Horaire (€) </div>
							<div class="quote-options-list" id="price-list"></div>
							<div id="editHoursTypeBlock">
								<div class="line"></div>
								<div class="d-flex justify-content-between align-items-start">
									<div class="form-group flex-grow-1 mr-2">
										<input type="text" class="form-control mr-2" id="hourTypeInput" placeholder="Saissisez le nom du taux horaire">
										<div class="invalid-feedback">
											Taux horaire déjà existant.
										</div>
									</div>									
									<ap-button class="btn-add" id="add-hours-type" confirmation icon="add/line"></ap-button>
								</div>
							</div>
						</div>
					</div>

					<div class="col-md-4 mb-2">
						<div class="p-3">
							<div class="title-quote-options">Marges (%)</div>
							<div class="quote-options-list" id="margin-list"></div>
							<div id="editMarginTypeBlock">
								<div class="line"></div>
								<div class="d-flex justify-content-between align-items-start">
									<div class="form-group flex-grow-1 mr-2">
										<input type="text" class="form-control" id="marginTypeInput" placeholder="Saissisez le nom de la marge">
										<div class="invalid-feedback">
											Type de marges déjà existante.
										</div>
									</div>
									<ap-button class="btn-add" id="add-margin-type" confirmation icon="add/line"></ap-button>
								</div>

							</div>
						</div>

						<div class="pt-3 mt-3 card shadow-none rounded" id="defaultValueContainer">
							<div class="w-100 text-center mb-3">
								<button class="btn btn-blue-600" id="default-value-pref" confirmation>
									Valeurs par défaut
								</button>
							</div>
						</div>
					</div>

					<div class="col-md-4 mb-2">
						<div class="p-3">
							<div class="title-quote-options">Informations</div>
							
							<div class="quote-options-list">
								<div class="block-input-quote-options">
									<label>Nombre de décimales :</label>
									<select name="pref.decimalNumber" class="form-control" style="width: 100px;"></select>
								</div>

								<div class="block-input-quote-options">
									<label>Coef total :</label>
									<input type="number" min="0.01" step="0.01" name="pref.coefTotal" class="form-control" pattern="^[1-9][0-9]*$" style="width: 100px;">
									<div class="invalid-feedback">
										La valeur doit être différente de 0
									</div>
								</div>
							</div>

							<div class="line"></div>

							<div class="title-quote-options">Type par défaut</div>

							<div class="quote-options-list">
								<div class="block-input-quote-options">
									<label>Marge :</label>
									<select name="pref.typeMat" class="form-control"></select>
								</div>

								<div class="block-input-quote-options">
									<label>Taux horaire :</label>
									<select name="pref.type" class="form-control"></select>
								</div>
							</div>

							<div class="line"></div>

							<div class="title-quote-options">Calcul de la marge</div>

							<div class="container-ap-switch">
								<ap-switch name="pref.calculMarkUp"></ap-switch>
								<label>Appliquer une marge commerciale </label>
							</div>
							<div class="text-info-quote-options">
								Si désactivé, un coefficient simple est appliqué au prix.
							</div>

							<div class="line"></div>

							<div class="title-quote-options">Option d'affichage du groupe</div>

							<div class="container-ap-switch">
								<ap-switch name="pref.displayPUDetails"></ap-switch>
								<label>Afficher la colonne PU total</label>
							</div>
							<div class="text-info-quote-options">
								Prix unitaire avec marge
							</div>

						</div>
					</div>
				</div>
			</div>
        `;

		if (this.contentOnly) {
			this.appendChild(this.N_tab);
		} else {
			const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
			N_container.appendChild(this.N_tab);
		}

		this.N_editPriceHoursBlock = this.N_tab!.querySelector('#editHoursTypeBlock') as HTMLDivElement;
		this.N_editMarginBlock = this.N_tab!.querySelector('#editMarginTypeBlock') as HTMLDivElement;

		this.N_selectTypeMat = this.N_tab!.querySelector('[name="pref.typeMat"]') as HTMLSelectElement;
		this.N_selectType = this.N_tab!.querySelector('[name="pref.type"]') as HTMLSelectElement;

		this.form = new Form(this.N_tab as HTMLFormElement);
	}

	public set quoteOptions(quoteOptions: SettingQuoteOption) {
		this.settingQuoteOptions = quoteOptions;

		const N_priceHoursList = this.N_tab!.querySelector('#price-list') as HTMLElement;

		for (const [key, value] of Object.entries(this.settingQuoteOptions!.hourlyPriceType)) {
			if (key === QuoteData.NO_MO) {
				const defaultMarginOption = new Option(value, key);
				this.N_selectType!.append(defaultMarginOption);
			}

			if (![QuoteData.NO_MO].includes(key)) {
				const N_div = this.createTemplateType(`pref.price.${key}`, value, 'hourlyPrice');

				N_priceHoursList.appendChild(N_div);

				const option = new Option(value, key);
				this.N_selectType!.append(option);
			}
		}

		const N_marginList = this.N_tab!.querySelector('#margin-list') as HTMLElement;

		for (const [key, value] of Object.entries(this.settingQuoteOptions!.marginType)) {
			//On ajoute l'option aucune marge par défaut dans le select
			if (key === QuoteData.NO_MARGIN) {
				const defaultMarginOption = new Option(value, key);
				this.N_selectTypeMat?.append(defaultMarginOption);
			}

			if (![QuoteData.NO_MARGIN, QuoteData.LICENCE, QuoteData.DISCOUNT].includes(key)) {
				const N_div = this.createTemplateType(`pref.marges.${key}`, value, 'margin');

				N_marginList.appendChild(N_div);

				const option = new Option(value, key);

				this.N_selectTypeMat!.append(option);
			}
		}

		// On met à jour le formulaire
		this.form?.updateInputs();

		const config = ConfigManager.getInstance().getConfig('quotes');

		const listDecimalNumber = _.find<any>(config.columns, { key: 'pref.decimalNumber' } as any);
		const selectDecimalNumber = this.N_tab!.querySelector('[name="pref.decimalNumber"]') as HTMLSelectElement;

		for (const key of listDecimalNumber.array) {
			const option = new Option(key, key);
			selectDecimalNumber.append(option);
		}

		const N_defaultValue = this.N_tab!.querySelector('#default-value-pref') as HTMLButtonElement;

		if (!this.isDefault) {
			// On masque les blocks d'ajout de taux horaire et de marges
			this.N_editPriceHoursBlock!.classList.add('d-none');
			this.N_editMarginBlock!.classList.add('d-none');

			// TODO: Gérer le cas ou une option a été supprimée
			N_defaultValue.addEventListener('click', async () => {
				try {
					const tmp = SettingsApps.getInstance().get('QUOTE.PREF') as any;

					const data = {
						price: tmp.price || {},
						marges: tmp.marges || {},
						coefTotal: tmp.coefTotal || '',
						displayPUDetails: tmp.displayPUDetails || '',
						decimalNumber: tmp.decimalNumber || '',
						typeMat: tmp.typeMat || '',
						type: tmp.type || ''
					};

					this.form?.setData({
						pref: data
					} as any);
				} catch (e) {
					toaster.error('Paramètre introuvable');
				}
			});
		} else {
			const N_addHoursType = this.N_editPriceHoursBlock!.querySelector('#add-hours-type') as HTMLButtonElement;

			N_addHoursType.addEventListener('click', () => {
				const N_hourPriceInput = this.N_tab!.querySelector('#hourTypeInput') as HTMLInputElement;
				if (N_hourPriceInput.value) {
					this.addPriceHourInput(N_hourPriceInput.value);
				}
			});

			const N_addMarginType = this.N_editMarginBlock!.querySelector('#add-margin-type') as HTMLButtonElement;
			N_addMarginType.addEventListener('click', () => {
				const N_marginInput = this.N_tab!.querySelector('#marginTypeInput') as HTMLInputElement;
				if (N_marginInput.value) {
					this.addMarginInput(N_marginInput.value);
				}
			});

			this.N_tab!.querySelector('#defaultValueContainer')?.remove();
		}
	}

	public get quoteOptions(): SettingQuoteOption {
		return this.settingQuoteOptions!;
	}

	public get data(): { [key: string]: any } {
		const data = this.form!.getData() as { [key: string]: any };

		const N_apSelect = this.querySelectorAll<CE_Select>('ap-select');

		for (const N_el of N_apSelect) {
			_.set(data, N_el.name, N_el.value);
		}

		return data.pref;
	}

	public set data(value: { [key: string]: any }) {
		this.form!.setData({ pref: value } as any);

		const N_apQuotes = this.querySelectorAll<InputDeleteBtn>('ap-quotes-options-input');

		for (const N_el of N_apQuotes) {
			N_el.setData({ pref: value });
		}
	}

	/**
	 * Ajoute un type de marge sur le formulaire
	 * @param name le nom du type de marge saisie par l'utilisateur
	 */
	private addMarginInput(name: string) {
		const N_marginList = this.N_tab!.querySelector('#margin-list') as HTMLElement;

		const N_marginInput = this.N_editMarginBlock!.querySelector('#marginTypeInput') as HTMLInputElement;

		const key = _.deburr(_.upperCase(name)).replace(/\s/gmi, '_');
		name = _.capitalize(name);

		// On vérifie si la clé existe
		if (!_.get(this.settingQuoteOptions!.marginType, key)) {
			// On update le select
			this.settingQuoteOptions!.marginType[key] = name;
			const option = new Option(name, key);
			this.N_selectTypeMat!.append(option);

			N_marginList.appendChild(this.createTemplateType(`pref.marges.${key}`, name, 'margin'));

			this.form?.updateInputs();

			N_marginInput.classList.remove('is-invalid');
			N_marginInput.value = '';
		} else {
			if (!N_marginInput.classList.contains('is-invalid')) {
				N_marginInput.classList.add('is-invalid');
			}
		}
	}

	/**
	 * Ajoute un type de taux horaire sur le formulaire
	 * @param name le nom du taux horaire saisie par l'utilisateur
	 */
	private addPriceHourInput(name: string) {
		const N_priceHourList = this.N_tab!.querySelector('#price-list') as HTMLElement;

		const N_priceHourInput = this.N_editPriceHoursBlock!.querySelector('#hourTypeInput') as HTMLInputElement;

		const key = _.deburr(_.upperCase(name)).replace(/\s/gmi, '_');
		name = _.capitalize(name);

		if (!_.get(this.settingQuoteOptions!.hourlyPriceType, key)) {
			// On update le select
			this.settingQuoteOptions!.hourlyPriceType[key] = name;
			const option = new Option(name, key);

			this.N_selectType!.append(option);

			N_priceHourList.appendChild(this.createTemplateType(`pref.price.${key}`, name, 'hourlyPrice'));

			this.form?.updateInputs();

			N_priceHourInput.classList.remove('is-invalid');
			N_priceHourInput.value = '';
		} else {
			if (!N_priceHourInput.classList.contains('is-invalid')) {
				N_priceHourInput.classList.add('is-invalid');
			}
		}
	}

	/**
	 * Créer le block pour les types de marges ou les taux horaires
	 */
	private createTemplateType(key: string, value: string, type: 'margin' | 'hourlyPrice'): InputDeleteBtn {
		const N_div = document.createElement('ap-quotes-options-input') as InputDeleteBtn;

		N_div.setAttribute('name', key);
		N_div.setAttribute('text', value);
		N_div.setAttribute('type', type);

		if (this.isDefault) {
			N_div.setAttribute('default', 'true');
		}

		N_div.setCallback((name: string, type: string) => {
			if (type === 'hourlyPrice') {
				delete this.settingQuoteOptions!.hourlyPriceType[name];

				this.N_selectType!.querySelectorAll('option').forEach(opt => {
					if (opt.value === name) {
						opt.remove();
					}
				});
			} else {
				delete this.settingQuoteOptions!.marginType[name];

				this.N_selectTypeMat!.querySelectorAll('option').forEach(opt => {
					if (opt.value === name) {
						opt.remove();
					}
				});
			}

			this.form?.updateInputs();
		});

		return N_div;
	}

	public static register() {
		customElements.define(QuoteOption.tagName, QuoteOption);
		InputDeleteBtn.register();
	}
}

export default QuoteOption;
