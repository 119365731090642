class QuoteData {
	public static readonly KILOMETRAGE_COST = 'FRAIS_KM';
	public static readonly DISCOUNT = 'REMISE';
	public static readonly LICENCE = 'LICENCE';
	public static readonly NO_MARGIN = 'AUCUNE_MARGE';
	public static readonly NO_MO = 'AUCUNE_MO';

	public static readonly CURRENT_VERSION = '3';

	public static readonly DEFAULT_UNIT = 'U';

	public static readonly LIST_STATE: { [key: string]: string } = {
		0: 'En Cours',
		1: 'Accepté',
		2: 'Refusé',
		4: 'Attente Commande',
		5: 'Prêt à être envoyé',
		6: 'À Valider',
		7: 'Révisé',
		send: 'Envoyé'
	};

	public static readonly DURATION_TYPES: { [key: string]: string } = {
		'1_week': '1 semaine',
		'2_week': '2 semaines',
		'3_week': '3 semaines',
		'1_month': '1 mois',
		'2_month': '2 mois'
	};
}

export default QuoteData;
