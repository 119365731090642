
import { cacheItem } from '@type/_app/cache';

class DisplayRef {
	private value: string;

	private cache: cacheItem;

	constructor(value: string, cache: cacheItem) {
		this.value = value;
		this.cache = cache;
	}

	public getText() {
		return this.cache[this.value] || this.value;
	}

	public toDashboard() {
		return {
			formattedValue: this.getText(),
			value: this.getText(),
			quickFilter: this.getText()
		};
	}

	public toSave() {
		return this.value;
	}

	public toDocument() {
		return {
			id: this.value,
			text: this.getText()
		};
	}

	public toPrint() {
		return this.getText();
	}
}

export default DisplayRef;
