// CORE
import CSV from '@autoprog/csv';
import { Controller } from '@autoprog/core-client';

// NODE_MODULE
import { AllModules, ColDef, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import h from 'hyperscript';
import moment from 'moment';

// LIBS
import ExportUtils from '@libs/utils/ExportUtils';
import Loader from '@libs/Loader';
import agUtils from '@libs/agGrid/french';

// CONTROLLERS
import C_C_Order from './Orders.Customer';

// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Stock from '@services/StockService';

class MaterialCustomerController extends Controller {
	private element: HTMLElement;

	private gridOptionsProducts: GridOptions = {};
	private gridOptionsOrders: GridOptions = {};

	private dataByProduct: { [key: string]: any[] } = {};
	private dataProducts: { [key: string]: any }[] = [];

	private currentProductRow: { [key: string]: any } = {};

	private locations: { [key: string]: any }[] = [];

	constructor(el: HTMLElement) {
		super(el);

		this.element = el;

		this.getLocations().then(() => {
			this.init();
			this.initEvents();
			this.getData();
		});
	}

	private async getLocations() {
		this.locations = await S_Stock.getInstance().getStockToCommandCustomer();
	}

	private init() {
		this.gridOptionsProducts = agUtils.french({
			localeText: { noRowsToShow: 'Pas de produit' },
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			rowSelection: 'single',
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				sortable: true,
				floatingFilter: true
			},
			columnDefs: [
				{
					headerName: 'Référence',
					field: 'ref',
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					}
				},
				{
					headerName: 'Marque',
					field: 'brand',
					filter: 'agSetColumnFilter'
				},
				{
					headerName: 'Quantité globale',
					field: 'globalQuantity',
					cellClass: ['text-right', 'text-monospace'],
					width: 150,
					suppressSizeToFit: true
				}
			],
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			onSelectionChanged: (params) => {
				const selectedRows = params.api.getSelectedRows();

				this.currentProductRow = selectedRows[0];

				const N_exportByProduct = this.element.querySelector('#exportByProduct') as HTMLButtonElement;
				N_exportByProduct.removeAttribute('disabled');

				const N_productSelected = this.element.querySelector('#product-selected') as HTMLElement;
				N_productSelected.innerHTML = `Produit selectionné : ${this.currentProductRow.ref}`;

				const data: { [key: string]: any }[] = [];

				for (const order of this.dataByProduct[this.currentProductRow.id]) {
					data.push(order);
				}

				this.gridOptionsOrders.api?.setRowData(data || []);
				this.gridOptionsOrders.api?.sizeColumnsToFit();
			}
		} as GridOptions);

		const childrenLocation: ColDef[] = [];

		for (const item of this.locations) {
			((item: any) => {
				childrenLocation.push({
					headerName: item.name,
					field: 'stocks.' + item._id,
					width: 120,
					cellClass: ['text-right', 'text-monospace'],
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.stocks = params.data.stocks || {};
						return params.data.stocks[item._id] || 0;
					},
					cellRenderer: (params) => {
						return params.value || 0;
					}
				});
			})(item);
		}

		childrenLocation.push({
			headerName: 'Direct client',
			field: 'deliveryToCustomer',
			width: 120,
			cellClass: ['text-right', 'text-monospace'],
			suppressSizeToFit: true,
			cellRenderer: (params) => {
				return params.value || 0;
			}
		});

		this.gridOptionsOrders = agUtils.french({
			localeText: { noRowsToShow: 'Aucune commande ne contient ce produit' },
			rowData: [],
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				sortable: true
			},
			columnDefs: [
				{
					headerName: 'N° Commande',
					field: 'commandNumber',
					suppressSizeToFit: true
				},
				{
					headerName: 'Client',
					field: 'customer',
					suppressSizeToFit: true
				},
				{
					headerName: 'Site(s)',
					field: 'sites',
					suppressSizeToFit: true
				},
				{
					headerName: 'Objet de la commande',
					field: 'label'
				},
				{
					headerName: 'Facturé',
					field: 'price',
					cellClass: ['text-right', 'text-monospace'],
					width: 200,
					cellRenderer: (params) => {
						return params.value;
					},
					suppressSizeToFit: true
				},
				{
					headerName: 'Quantité',
					field: 'quantity',
					cellClass: ['text-right', 'text-monospace'],
					width: 80,
					suppressSizeToFit: true
				},
				{
					headerName: 'Sortie stock',
					children: childrenLocation
				},
				{
					headerName: '',
					width: 50,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_edit = h('ap-button.btn-action-aggrid', { attrs: { type: 'edit', tooltip: 'Ouvrir la commande n°' + params.data.commandNumber } });

						N_edit.addEventListener('click', () => {
							C_C_Order.open(params.data.commandId);
						});

						const N_div = h<HTMLElement>('div.container-action-aggrid', N_edit);

						return N_div;
					}
				}
			],
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			}
		} as GridOptions);

		new Grid(this.element.querySelector('#products .grid') as HTMLDivElement, this.gridOptionsProducts, { modules: AllModules });
		new Grid(this.element.querySelector('#orders .grid') as HTMLDivElement, this.gridOptionsOrders, { modules: AllModules });

		const N_export = this.element.querySelector('#export') as HTMLButtonElement;
		const N_exportByProduct = this.element.querySelector('#exportByProduct') as HTMLButtonElement;

		N_export.addEventListener('click', () => {
			const data = this.getDataExportProductList();

			const title = `Export-Materiel-${moment().format('DD-MM-YYYY')}`;

			this.exportDataList(data, title);
		});

		N_exportByProduct.addEventListener('click', () => {
			const data = this.getDataExportByProduct(this.currentProductRow.id);
			const title = `Export-Materiel-Produit-${this.currentProductRow.ref}-${moment().format('DD-MM-YYYY')}`;

			this.exportData(data, title);
		});
	}

	private initEvents() {
		const N_select = this.element.querySelector('#stateBill') as HTMLSelectElement;

		N_select.addEventListener('change', () => {
			this.getData();
		});
	}

	private getDataExportProductList() {
		const tmp: { [key: string]: any }[] = [];

		const orders: { [commandNumber: string]: string } = {};

		for (const id in this.dataByProduct) {
			if (Array.isArray(this.dataByProduct[id])) {
				for (const order of this.dataByProduct[id]) {
					if (!orders[order.commandNumber]) {
						orders[order.commandNumber] = '';
					}
				}
			}
		}

		for (const id in this.dataByProduct) {
			const dataProduct = this.dataProducts.find(current => current.id === id) || {};

			let res: any = {
				ref: dataProduct.ref,
				brand: dataProduct.brand,
				globalQuantity: dataProduct.globalQuantity
			};

			res = { ...res, ...orders };

			if (Array.isArray(this.dataByProduct[id])) {
				for (const order of this.dataByProduct[id]) {
					res[order.commandNumber] = order.quantity;
				}
			}

			tmp.push(res);
		}

		return [...tmp];
	}

	private getDataExportByProduct(id: string) {
		const tmp: { [key: string]: any }[] = [];

		const dataProduct = this.dataProducts.find(current => current.id === id) || {};

		if (Array.isArray(this.dataByProduct[id])) {
			for (const order of this.dataByProduct[id]) {
				const price = order.price.replace(/&nbsp;/g, '');
				tmp.push({
					ref: dataProduct.ref,
					brand: dataProduct.brand,
					globalQuantity: dataProduct.globalQuantity,
					commandNumber: order.commandNumber,
					customer: order.customer,
					sites: order.sites,
					label: order.label,
					price,
					quantity: order.quantity
				});
			}
		}

		return [...tmp];
	}

	private exportDataList(data: { [key: string]: any }[] = [], title: string) {
		const orderNumbers: string[] = [];

		for (const id in this.dataByProduct) {
			if (Array.isArray(this.dataByProduct[id])) {
				for (const order of this.dataByProduct[id]) {
					if (!orderNumbers.includes(order.commandNumber)) {
						orderNumbers.push(order.commandNumber);
					}
				}
			}
		}

		const header: any = {
			ref: 'Référence',
			brand: 'Marque',
			globalQuantity: 'Quantité globale'
		};

		for (const orderNumber of orderNumbers) {
			header[orderNumber] = orderNumber;
		}

		const csv = CSV.stringify(data, header, '\n', ';');

		const a = ExportUtils.createFileLink(csv);
		// On supprime les points (<, >, :, “, /, \, |, ?, .) dans le nom du fichier
		a.download = title.replace(/[.<>:/“\\|?]/gmi, '');
		a.click();
		a.remove();
	}

	private exportData(data: { [key: string]: any }[] = [], title: string) {
		const csv = CSV.stringify(data, {
			ref: 'Référence',
			brand: 'Marque',
			globalQuantity: 'Quantité globale',
			commandNumber: 'N° Commande',
			customer: 'Client',
			sites: 'Site(s)',
			label: 'Objet de la commande',
			price: 'Facture',
			quantity: 'Quantité'
		}, '\n', ';');

		const a = ExportUtils.createFileLink(csv);
		// On supprime les points (<, >, :, “, /, \, |, ?, .) dans le nom du fichier
		a.download = title.replace(/[.<>:/“\\|?]/gmi, '');
		a.click();
		a.remove();
	}

	private async getData() {
		Loader.getInstance().open();
		const N_select = this.element.querySelector('#stateBill') as HTMLSelectElement;
		const stateBill = N_select.options[N_select.selectedIndex].value;

		const { data, products } = await S_C_Order.getInstance().getDataToMaterial(stateBill);

		this.dataByProduct = data;
		this.dataProducts = products;

		this.gridOptionsProducts.api?.setRowData(this.dataProducts);
		this.gridOptionsProducts.api?.sizeColumnsToFit();

		if (this.currentProductRow?.id) {
			const data: { [key: string]: any }[] = [];

			for (const order of this.dataByProduct[this.currentProductRow.id]) {
				data.push(order);
			}

			this.gridOptionsOrders.api?.setRowData(data || []);
			this.gridOptionsOrders.api?.sizeColumnsToFit();
		}

		Loader.getInstance().close();
	}

	public destructor() {
	}
}

export default MaterialCustomerController;
