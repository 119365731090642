
import { LoggedUser, utils } from '@autoprog/core-client';

import { ColDef } from '@ag-grid-enterprise/all-modules';

import SettingsApps from '@libs/Settings';

import M_EditStock from '@modules/OrdersCustomer/js/modals/EditStockOutput';
import M_PrintPreview from '@libs/modals/PrintPreview';

import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Product from '@services/Product/ProductService';
import S_StockEvent from '@services/StockEventService';

import CE_Aggrid from '@libs/customElement/AgGrid';

import LoadingCellRenderer from '@js/libs/agGrid/cellRenderer/LoadingCellRenderer';
import NumericCellRenderer from '@libs/agGrid/cellRenderer/NumericCellRenderer';
import ProductImageCellRenderer from '@modules/Products/js/libs/ProductImageCellRenderer';

class OutputStockTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-customers-output-stock-tab';

	private N_el: HTMLElement | null = null;
	private N_grid: CE_Aggrid | null = null;

	private selectorTab: string = '';

	private idTab: string = '';

	private locations: { [key: string]: any }[] = [];

	private products: {
		[id: string]: { unit: string, brand: string, price: string, name: string, reference: string }
	} = {};

	private isEditable: boolean = false;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || OutputStockTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="database-2/line" icon-active="database-2/fill" text="Suivi sortie de stock"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.N_grid?.sizeColumnsToFit();
		});

		this.removeAttribute('id');
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Suivi sortie stock

				<div class="flex-wrap align-items-center text-md d-none" id="legend">
					<span class="font-weight-bold text-grey ml-2">Légende :</span>
						
					<small class="d-flex align-items-center ml-3 text-red-900">
						<div class="badge-legend bg-red-100"></div>
						Produit non sorti
					</small>

					<small class="d-flex align-items-center ml-3 text-orange-900">
						<div class="badge-legend bg-orange-100"></div>
						Produit sorti partiellement
					</small>

					<small class="d-flex align-items-center ml-3 text-green-900">
						<div class="badge-legend bg-green-100"></div>
						Produit sorti
					</small>
				</div>

				<div class="page-tabs-title-right">
				
					<ap-button class="btn-icon" type="print" id="print"></ap-button>
					<ap-button class="btn-icon d-none" type="edit" id="edit"></ap-button>

					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<ap-aggrid id="grid" mode="edit"></ap-aggrid>
			</div>
		`;

		N_container.append(this.N_el);

		this.N_grid = this.N_el?.querySelector<CE_Aggrid>('#grid')!;
		this.N_grid?.setGridOptions({
			columnDefs: [{
				headerName: ''
			}]
		});

		this.initButton();
	}

	private initButton() {
		const N_edit = this.N_el!.querySelector('#edit') as HTMLButtonElement;
		const N_print = this.N_el!.querySelector('#print') as HTMLButtonElement;
		const N_legend = this.N_el!.querySelector('#legend') as HTMLElement;

		this.isEditable = (SettingsApps.getInstance().get('APPLICATION') as any)?.orderCustomer?.stockOutput?.disabled && LoggedUser.getInstance().hasPermission('STOCK.OUTPUT');

		if (this.isEditable) {
			N_edit.classList.remove('d-none');
			N_legend.classList.add('d-flex');
			N_legend.classList.remove('d-none');
		}

		N_edit.addEventListener('click', () => {
			new M_EditStock(this.idOrder).open().then((data) => {
				this.N_grid!.value = data;
				this.initStock();
			});
		});

		N_print.addEventListener('click', () => {
			new M_PrintPreview('commands-customer', this.idOrder, 'pdfStock').open();
		});
	}

	private initGrid() {
		const childrenStock: ColDef[] = [];
		const childrenLocation: ColDef[] = [];

		for (const item of this.locations) {
			((item: any) => {
				childrenStock.push({
					headerName: item.name,
					width: 100,
					field: 'currentStock.' + item._id + '.quantity',
					cellClass: 'text-center',
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.currentStock = params.data.currentStock || {};
						params.data.currentStock[item._id] = params.data.currentStock[item._id] || {};
						return params.data.currentStock[item._id].quantity;
					},
					cellRenderer: LoadingCellRenderer,
					cellRendererParams: {
						CellRenderer: NumericCellRenderer,
						decimalNumber: 0
					}
				});

				childrenLocation.push({
					headerName: item.name,
					field: 'stocks.' + item._id + '.quantity',
					width: 120,
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.stocks = params.data.stocks || {};
						params.data.stocks[item._id] = params.data.stocks[item._id] || { quantity: 0 };
						return params.data.stocks[item._id]?.quantity || 0;
					},
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				});
			})(item);
		}

		childrenLocation.push({
			headerName: 'Direct client',
			field: 'deliveryToCustomer.quantity',
			width: 120,
			cellClass: ['text-right', 'text-monospace'],
			suppressSizeToFit: true,
			cellRenderer: (params) => {
				return params.value || 0;
			}
		});

		this.N_grid!.setGridOptions({
			localeText: { noRowsToShow: 'Aucun produit' },
			columnDefs: [
				{
					headerName: '',
					field: 'productID',
					filter: false,
					width: 50,
					pinned: 'left',
					sortable: false,
					suppressSizeToFit: true,
					suppressMovable: true,
					resizable: false,
					suppressColumnsToolPanel: true,
					cellRenderer: ProductImageCellRenderer
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params) => {
						return this.products[params.data.productID]?.reference || 'Produit introuvable';
					}
				},
				{
					headerName: 'Libellé',
					floatingFilter: true,
					filter: 'agTextColumnFilter',
					valueGetter: (params) => {
						return this.products[params.data.productID]?.name;
					}
				},
				{
					headerName: 'Marque',
					floatingFilter: true,
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					valueGetter: (params) => {
						return this.products[params.data.productID]?.brand;
					}
				},
				{
					headerName: 'Quantité Cmd',
					field: 'quantity',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: NumericCellRenderer,
					cellRendererParams: {
						decimalNumber: 0
					}
				},
				{
					headerName: 'Unité',
					width: 80,
					suppressSizeToFit: true,
					cellClass: 'text-center',
					valueGetter: (params) => {
						return this.products[params.data.productID]?.unit;
					}
				},
				...(childrenStock.length === 1 ? childrenStock : [{ headerName: 'Stock', children: childrenStock }]),
				{
					headerName: 'Sortie stock',
					children: childrenLocation
				}
			],
			getRowStyle: (params: any) => {
				if (this.isEditable) {
					const quantity = params.data.quantity;
					let outputQuantity = (params.data.deliveryToCustomer?.quantity || 0);

					for (const idStock in params.data.stocks) {
						outputQuantity += (params.data.stocks[idStock]?.quantity || 0);
					}

					//stock sortie
					if (outputQuantity >= quantity) {
						return {
							'background-color': 'var(--ap-green-50)',
							color: 'var(--ap-green-900)'
						};
					}

					//aucun stock de sortie
					if (outputQuantity === 0) {
						return {
							'background-color': 'var(--ap-red-50)',
							color: 'var(--ap-red-900)'
						};
					}

					//en cours de sortie
					if (outputQuantity < quantity) {
						return {
							'background-color': 'var(--ap-orange-50)',
							color: 'var(--ap-orange-900)'
						};
					}
				}
			},
			defaultColDef: {
				resizable: true,
				sortable: true,
				suppressMenu: true,
				floatingFilterComponentParams: {
					suppressFilterButton: true
				}
			}
		});
	}

	private get idOrder() {
		return utils.getQuery().id;
	}

	public async initData() {
		if (this.idOrder) {
			const { locations, content } = await S_C_Order.getInstance().getStockById(this.idOrder);

			this.locations = locations;

			this.initGrid();

			await this.getAdditionalData();

			this.N_grid!.value = content;

			this.initStock();
		} else {
			this.N_grid!.value = [];
		}
	}

	private async getAdditionalData() {
		const products = await S_Product.getInstance().getAll();

		this.products = {};

		for (const item of products) {
			this.products[item._id] = {
				reference: item[S_Product.getInstance().referenceKey],
				unit: item.unit,
				brand: item.brand,
				name: item.name,
				price: item.price
			};
		}
	}

	private async initStock() {
		const { rowData } = await S_StockEvent.getInstance().getCurrentStock();

		const stock: { [key: string]: any } = {};
		for (const item of rowData) {
			stock[item.reference] = stock[item.reference] || {};
			stock[item.reference][item.stock] = stock[item.reference][item.stock] || { quantity: 0 };
			stock[item.reference][item.stock].quantity = item.quantity.formattedValue;
		}

		this.N_grid!.forEachNode((node) => {
			for (const item of this.locations) {
				node.data.currentStock = node.data.currentStock || {};
				node.data.currentStock[item._id] = stock[node.data.productID]?.[item._id] || { quantity: 0 };
			}

			node.setData(node.data);
		});
	}

	public static register() {
		customElements.define(OutputStockTab.tagName, OutputStockTab);
	}
}

export default OutputStockTab;
