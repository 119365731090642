
import T_modal from '../../../tpl/modals/Favorite/addEdit.html';

import Options from '../../libs/GridOptions';

import Modal from '@libs/Modal';

import S_Q_Favorite from '@services/Quotes/FavoriteQuoteService';

import CE_Aggrid from '@libs/customElement/AgGrid';

class AddEdit extends Modal {
	private N_gridDetails: CE_Aggrid | null = null;
	private N_grid: CE_Aggrid | null = null;
	private optionsGrid: Options;

	constructor(id?: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.optionsGrid = new Options();
		this.optionsGrid.init();

		this.on('opened', async () => {
			let data = {
				details: [],
				_id: Date.now().toString(36),
				title: '',
				unit: 'U',
				quantity: 1
			};

			this.N_gridDetails = this.element.querySelector<CE_Aggrid>('#grid-group')!;
			this.N_grid = this.element.querySelector<CE_Aggrid>('#grid')!;

			const N_title = this.element.querySelector('[name="title"]') as HTMLInputElement;
			const N_titleModal = this.element.querySelector('.modal-title') as HTMLElement;
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			this.N_gridDetails.setGridOptions({
				...this.optionsGrid.gridDetails('modal', () => {
					const data: any[] = this.N_gridDetails!.value;

					//peut y avoir qu'une ligne
					this.N_grid!.forEachNode((node) => {
						node.data.details = data;
					});

					this.N_grid!.refreshCells();
				})
			});

			this.N_grid?.setGridOptions({
				...this.optionsGrid.gridMaster('modal'),
				getRowHeight: () => {
					return 165;
				},
				onCellEditingStopped: (params) => {
					params.api.resetRowHeights();
					params.api.refreshCells({ force: true });
				},
				getContextMenuItems: (params) => {
					return [this.optionsGrid.styleContextMenu(params)];
				}
			});

			if (id) {
				data = await S_Q_Favorite.getInstance().getById(id);
				N_titleModal.innerHTML = 'Modification Favoris';
			}

			N_title.value = data.title;

			this.N_gridDetails.value = data.details;
			this.N_grid.value = [data];

			N_save.addEventListener('click', async () => {
				let newData: { [key: string]: any } = this.N_grid!.value[0] || {};

				newData.details = this.N_gridDetails!.value;

				newData = {
					_id: id,
					...newData,
					title: N_title.value
				};

				const data = await S_Q_Favorite.getInstance().save(newData);

				this.resolve(data.data.data._id);
			});
		});
	}
}

export default AddEdit;
