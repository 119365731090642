
import { Module, Modules, Router } from '@autoprog/core-client';

import Navigation from '@libs/Navigation';
import NavigationMobile from '@libs/NavigationMobile';

import C_Products from './js/controllers/Products';
import C_UpdateBuyingPriceProducts from './js/controllers/UpdateBuyingPriceProducts';
import C_UpdatePriceProducts from './js/controllers/UpdatePriceProducts';

//CUSTOM ELEMENTS
import CE_ProductImagePreviewer from './js/libs/customElement/ProductImagePreviewer';
import CE_SelectProductsTabs from './js/libs/customElement/SelectProductTabs';

//TABS

import T_UpdateBuyingPriceProducts from './tpl/UpdateBuyingPriceProducts.html';
import T_UpdatePriceProducts from './tpl/UpdatePriceProducts.html';
import T_tabs from '@tpl/tabs.html';

class ProductsModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_ProductImagePreviewer.register();
		CE_SelectProductsTabs.register();
	}

	public async preInit() {
		const page_product = {
			category: 'Données',
			order: 2,
			name: 'Produits',
			type: 'module',
			module: 'apps/products',
			permissions: ['PRODUCTS', 'PRODUCTS._GROUPS', 'PRODUCTS._CATEGORIES', 'PRODUCTS._BRANDS'],
			icon: 'product/line'
		};

		Navigation.getInstance().addCategory({
			name: 'Données',
			order: 0
		});

		Navigation.getInstance().addLink(page_product);

		NavigationMobile.getInstance().addLink({
			icon: 'icon-solid-box-open',
			name: 'Produits',
			order: 1,
			dataset: {
				link: 'products'
			}
		});
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/apps/products',
			controller: C_Products as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/apps/products/:tabs',
			controller: C_Products as any,
			tpl: T_tabs,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/apps/updateProducts/price',
			controller: C_UpdatePriceProducts as any,
			tpl: T_UpdatePriceProducts,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/apps/updateProducts/buyingPrice',
			controller: C_UpdateBuyingPriceProducts as any,
			tpl: T_UpdateBuyingPriceProducts,
			requireValidUser: true
		});
	}

	public async postInit() {
	}
}

Modules.registerModule('Produits', ProductsModule);
