// CORE
import CSV from '@autoprog/csv';
import { Controller } from '@autoprog/core-client';
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import moment, { Moment } from 'moment';
// LIBS
import Decimal from '@libs/utils/Decimal';
import ExportUtils from '@libs/utils/ExportUtils';
import drpC from '@libs/utils/daterangepickerConfig';

// SERVICE
import S_P_Order from '@services/Provider/ProviderOrderService';
import S_Product from '@services/Product/ProductService';

class RecapProviderController extends Controller {
	private element: HTMLElement;

	private gridOptionsProviders: GridOptions = {};
	private gridOptionsProducts: GridOptions = {};

	private dataByProvider: { [key: string]: any[] } = {};
	private dataProvider: { [key: string]: any }[] = [];

	private currentProviderRow: { [key: string]: any } = {};

	private startDate: Moment;
	private endDate: Moment;

	constructor(el: HTMLElement) {
		super(el);

		this.element = el;

		this.startDate = moment().subtract(1, 'year');
		this.endDate = moment();

		this.init();

		this.getData();
	}

	private init() {
		this.gridOptionsProviders = agUtils.french({
			localeText: { noRowsToShow: 'Aucun Fournisseur' },
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			rowSelection: 'single',
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				sortable: true,
				floatingFilter: true
			},
			columnDefs: [
				{
					headerName: 'Fournisseur',
					field: 'provider',
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					}
				},
				{
					headerName: 'Nb CF',
					field: 'number',
					cellClass: ['text-right', 'text-monospace'],
					width: 100,
					suppressSizeToFit: true
				}
			],
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			onSelectionChanged: (params) => {
				const selectedRows = params.api.getSelectedRows();

				this.updateProvider(selectedRows[0]);

				const data: { [key: string]: any }[] = [];
				let deliveryPrice: { [key: string]: any } = {};

				for (const item of this.dataByProvider[this.currentProviderRow.idProvider]) {
					if (item.product.name === 'deliveryPrice') {
						deliveryPrice = item;
					} else {
						data.push(item);
					}
				}

				this.gridOptionsProducts.api?.setRowData(data || []);
				this.gridOptionsProducts.api?.setPinnedBottomRowData([deliveryPrice]);
				this.gridOptionsProducts.api?.sizeColumnsToFit();
			}
		} as GridOptions);

		this.gridOptionsProducts = agUtils.french({
			localeText: { noRowsToShow: 'Aucun Produit' },
			rowData: [],
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				resizable: true,
				sortable: true,
				floatingFilter: true
			},
			columnDefs: [
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: 'product',
					filter: 'agTextColumnFilter',
					width: 200,
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						return params.data.product[S_Product.getInstance().referenceKey];
					},
					cellRenderer: (params: any) => {
						return S_Product.getInstance().cellRendererByData(params.data.product);
					}
				},
				{
					headerName: 'Désignation',
					field: 'product.name',
					filter: 'agTextColumnFilter',
					cellRenderer: (params) => {
						return params.value === 'deliveryPrice' ? 'Frais de port' : params.value;
					}
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					filter: 'agSetColumnFilter',
					width: 200,
					suppressSizeToFit: true
				},
				{
					headerName: 'Quantité',
					cellClass: ['text-right', 'text-monospace'],
					field: 'quantity',
					width: 100,
					suppressSizeToFit: true
				},
				{
					headerName: 'Montant total',
					cellClass: ['text-right', 'text-monospace'],
					field: 'price',
					width: 200,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						return params.value.formattedValue;
					}
				}
			],
			onGridReady: () => {
				this.gridOptionsProducts.api?.sizeColumnsToFit();
			}
		} as GridOptions);

		new Grid(this.element.querySelector('#providers .grid') as HTMLDivElement, this.gridOptionsProviders, { modules: AllModules });
		new Grid(this.element.querySelector('#products .grid') as HTMLDivElement, this.gridOptionsProducts, { modules: AllModules });

		const N_export = this.element.querySelector('#export') as HTMLButtonElement;
		const N_exportByProvider = this.element.querySelector('#exportByProvider') as HTMLButtonElement;
		const N_deliveryPrice = this.element.querySelector('#deliveryPrice') as HTMLButtonElement;
		const N_datePicker = this.element.querySelector('#date-picker') as HTMLInputElement;

		N_export.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			for (const provider in this.dataByProvider) {
				data.push(...this.getDataExportByProvider(provider));
			}

			const title = `${this.startDate.format('YYYY_MM_DD')}_${this.endDate.format('YYYY_MM_DD')}_Export-Commande-Fournisseur`;

			this.exportData(data, title);
		});

		N_deliveryPrice.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			for (const provider in this.dataByProvider) {
				data.push(...this.getDataExportByProvider(provider, true));
			}

			const title = `${this.startDate.format('YYYY_MM_DD')}_${this.endDate.format('YYYY_MM_DD')}_Export-Frais-de-Port`;

			this.exportData(data, title);
		});

		N_exportByProvider.addEventListener('click', () => {
			const data = this.getDataExportByProvider(this.currentProviderRow.idProvider);
			const title = `${this.startDate.format('YYYY_MM_DD')}_${this.endDate.format('YYYY_MM_DD')}_Export-Commande-Fournisseur-${this.currentProviderRow.provider}`;

			this.exportData(data, title);
		});

		const N_input = $(N_datePicker);

		N_input.daterangepicker(drpC({
			startDate: this.startDate,
			endDate: this.endDate
		}));

		N_input.on('apply.daterangepicker', (ev, picker) => {
			this.startDate = picker.startDate;
			this.endDate = picker.endDate;

			this.getData();
		});
	}

	private getDataExportByProvider(provider: string, onlyDelivery: boolean = false) {
		const tmp: { [key: string]: any }[] = [];
		let deliveryPrice: { [key: string]: any } = {};

		const dataProvider = this.dataProvider.find(l => l.idProvider === provider) || {};

		for (const item of this.dataByProvider[provider]) {
			if (item.product.name === 'deliveryPrice') {
				deliveryPrice = {
					provider: dataProvider.provider,
					reference: item.product[S_Product.getInstance().referenceKey],
					name: 'Frais de port',
					brand: item.product.brand,
					quantity: item.quantity,
					price: Decimal.setDisplayNumber(item.price).toExcel()
				};
			} else {
				if (!onlyDelivery) {
					tmp.push({
						provider: dataProvider.provider,
						reference: item.product[S_Product.getInstance().referenceKey],
						name: item.product.name,
						brand: item.product.brand,
						quantity: item.quantity,
						price: Decimal.setDisplayNumber(item.price).toExcel()
					});
				}
			}
		}

		return [...tmp, deliveryPrice];
	}

	private exportData(data: { [key: string]: any }[] = [], title: string) {
		const csv = CSV.stringify(data, {
			provider: 'Fournisseur',
			reference: S_Product.getInstance().columnNameReference,
			name: 'Nom',
			brand: 'Marque',
			quantity: 'Quantité',
			price: 'Montant Total'
		}, '\n', ';');

		const a = ExportUtils.createFileLink(csv);
		// On supprime les points (<, >, :, “, /, \, |, ?, .) dans le nom du fichier
		a.download = title.replace(/[.<>:/“\\|?]/gmi, '');
		a.click();
		a.remove();
	}

	private updateProvider(provider: { [key: string]: any } | null = null) {
		const N_providerSelected = this.element.querySelector('#provider-selected') as HTMLElement;
		const N_exportByProvider = this.element.querySelector('#exportByProvider') as HTMLButtonElement;

		if (provider) {
			this.currentProviderRow = provider;

			N_exportByProvider.disabled = false;
			N_providerSelected.innerHTML = `Fournisseur selectionné : ${this.currentProviderRow.provider}`;
		} else {
			this.currentProviderRow = {};

			N_exportByProvider.disabled = true;

			N_providerSelected.innerHTML = 'Fournisseur selectionné : aucun';

			this.gridOptionsProducts.api?.setRowData([]);
		}
	}

	private async getData() {
		const N_spinner = this.element.querySelector('#spinner') as HTMLElement;
		N_spinner.classList.remove('d-none');
		N_spinner.classList.add('d-flex');

		this.updateProvider();

		const { data, providers } = await S_P_Order.getInstance().getDataToRecap(this.startDate.valueOf(), this.endDate.valueOf());

		this.dataByProvider = data;
		this.dataProvider = providers;

		this.gridOptionsProviders.api?.setRowData(providers);
		this.gridOptionsProviders.api?.sizeColumnsToFit();

		N_spinner.classList.add('d-none');
		N_spinner.classList.remove('d-flex');
	}

	public destructor() {
	}
}

export default RecapProviderController;
