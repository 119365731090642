
class AddressReadonly extends HTMLElement {
	public static readonly tagName: string = 'ap-select-address-readonly';

	public async connectedCallback() {
		const nameSelect = this.getAttribute('name-select');
		const nameTextarea = this.getAttribute('name-textarea');

		this.classList.add('w-100');

		this.innerHTML = `
			<div class="font-weight-bold" name="${nameSelect}"></div>
            <div type="textarea" name="${nameTextarea}"></div>
        `;

		this.removeAttribute('name-select');
		this.removeAttribute('name-textarea');
	}

	public static register() {
		customElements.define(AddressReadonly.tagName, AddressReadonly);
	}
}

export default AddressReadonly;
