
import ConfigService from '@js/services/ConfigService';

class ConfigManager {
	private static instance: ConfigManager;

	private cache: { [key: string]: any } = {};

	private constructor() {
	}

	public async init() {
		this.cache = (await ConfigService.getInstance().getAll()).data;
	}

	public getConfig(key: string) {
		this.cache[key] = this.cache[key] || {};
		this.cache[key].columns = this.cache[key].columns || [];
		this.cache[key].grid = this.cache[key].grid || {};
		this.cache[key].displayRef = this.cache[key].displayRef || {};
		this.cache[key].agGrid = this.cache[key].agGrid || {};
		this.cache[key].modal = this.cache[key].modal || {};
		this.cache[key].mobile = this.cache[key].mobile || {};
		this.cache[key].options = this.cache[key].options || {};

		return this.cache[key] || {};
	}

	public getListTables() {
		return Object.keys(this.cache) || [];
	}

	public static getInstance() {
		if (!ConfigManager.instance) {
			ConfigManager.instance = new ConfigManager();
		}

		return ConfigManager.instance;
	}
}

export default ConfigManager;
