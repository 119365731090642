// CORE
import { Alert, Form, global } from '@autoprog/core-client';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import moment from 'moment';

// TEMPLATE
import T_modal from '../../tpl/modals/intervention/interventions.html';

// LIBS
import ConfigManager from '@libs/ConfigManager';
import Modal from '@libs/Modal';
import SelectEditor from '@libs/agGrid/SelectEditor';
import agUtils from '@libs/agGrid/french';

// PRINTER
// UTILS
import Select2Utils from '@libs/utils/select2Utils';

// MODAL
import M_PrintPreview from '@js/libs/modals/PrintPreview';
import M_addLine from '../modals/AddLineInterventions';
import M_products from '../modals/ProductEditInterventions';

// CUSTOM_ELEMENT
import CE_Select2 from '@libs/customElement/Select2';
import FilesTab from '@libs/customElement/FilesTab';
import NotesTab from '@libs/customElement/NotesTab';
import SignatureTab from '@libs/customElement/SignatureTab';

// SERVICE
import S_C_Contact from '@services/Customer/CustomerContactService';
import S_Interventions from '@services/Interventions';
import S_Product from '@services/Product/ProductService';
import S_Users from '@js/services/User/UserService';

class Interventions extends Modal {
	private gridOptionsMaterials: GridOptions = {};
	private gridOptionsTasks: GridOptions = {};
	private gridOptionsDetails: GridOptions = {};

	private idData = '';

	private form: Form | null = null;

	private users: { [key: string]: string }[] = [];

	private selectPostinit: { [key: string]: CE_Select2 } = {};
	private N_FilesTab: FilesTab | null = null;
	private N_NotesTab: NotesTab | null = null;
	private N_SignatureTab: SignatureTab | null = null;

	private dataToMobile: { [key: string]: any } = {};

	private mode: string | undefined;

	private companyRefObject: { id: string, text: string } = { id: '', text: '' };

	constructor(id: string = '') {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.idData = id;

		this.on('opened', async () => {
			this.users = await S_Users.getInstance().getAll();

			const N_modalDialog = this.element.querySelector('.modal-dialog') as HTMLElement;

			N_modalDialog.style.maxWidth = global.IS_MOBILE ? '' : '60%';

			const N_form = this.element.querySelector('.modal-body') as HTMLFormElement;

			this.form = new Form(N_form);

			this.initInfos();
			this.initFiles();
			this.initNotes();
			this.initSignature();

			if (!global.IS_MOBILE) {
				this.initMaterials();
				this.initTasks();
				this.initDetails();
			}

			const data = await this.getData();

			this.N_FilesTab!.data = data.attachments || {};
			this.N_NotesTab!.data = data.notes || [];
			this.N_SignatureTab!.data = data.signature || {};

			this.form.setData(data);

			this.postInit();

			if (global.IS_MOBILE) {
				this.dataToMobile.details = data.details || [];
				this.dataToMobile.tasks = data.tasks || [];
				this.dataToMobile.products = data.products || [];
				this.initDetailMobile();
				this.initTasksMobile();
				this.initMaterialsMobile();
			} else {
				this.gridOptionsDetails.api?.setRowData(data.details || []);
				this.gridOptionsTasks.api?.setRowData(data.tasks);
				this.gridOptionsMaterials.api?.setRowData(data.products);
			}

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				await N_save.loading(this.save());
				this.resolve();
			});

			const N_btnAdd = this.element.querySelector('#add-details') as HTMLButtonElement;

			N_btnAdd.addEventListener('click', () => {
				new M_addLine().open().then((data) => {
					if (global.IS_MOBILE) {
						this.dataToMobile.details.push(data);
						this.initDetailMobile();
					} else {
						this.gridOptionsDetails.api?.applyTransaction({
							add: [data]
						});
					}
				});
			});

			const N_print = this.element.querySelector('#print') as HTMLButtonElement;

			N_print.addEventListener('click', async () => {
				await this.save();

				new M_PrintPreview('interventions', this.idData).open();
			});
		});
	}

	public setMode(mode: 'duplicate') {
		this.mode = mode;
		return this;
	}

	private initInfos() {
		const config = ConfigManager.getInstance().getConfig('interventions');

		const N_state = this.element.querySelector('[name="infos.state"]') as HTMLSelectElement;
		const N_type = this.element.querySelector('[name="infos.type"]') as HTMLSelectElement;

		const N_site = this.element.querySelector('[name="infos.site"]') as CE_Select2;
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_contact = this.element.querySelector('[name="infos.contact"]') as CE_Select2;

		const listState = _.find<any>(config.columns, { key: 'infos.state' } as any);

		for (const key in listState.object) {
			const N_option = new Option(listState.object[key], key);
			N_state.append(N_option);
		}

		const listType = _.find<any>(config.columns, { key: 'infos.type' } as any);

		for (const key in listType.object) {
			const N_option = new Option(listType.object[key], key);
			N_type.append(N_option);
		}

		N_customer.create(this.element);

		N_contact.setRef({ id_customer: N_customer.selectElement! });
		N_contact.create(this.element);

		N_site.setRef({ customer: N_customer.selectElement! });
		N_site.create(this.element);

		this.selectPostinit['infos.site'] = N_site;
		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['infos.contact'] = N_contact;
	}

	//DETAILS
	private async initDetails() {
		this.gridOptionsDetails = agUtils.french<GridOptions>({
			rowData: [],
			rowDragManaged: true,
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				editable: false
			},
			columnDefs: [
				{
					headerName: 'Réalisé',
					field: 'location',
					rowDrag: true,
					cellRenderer: (params) => {
						return ({
							site: 'Sur site',
							desktop: 'Au bureau'
						} as any)[params.value] || params.value;
					}
				}, {
					headerName: 'Date',
					field: 'date',
					cellRenderer: (params) => {
						return moment(params.value, 'YYYY-MM-DD').format('DD/MM/YYYY');
					}
				}, {
					headerName: 'Horaires sur site',
					cellRenderer: (params) => {
						return params.data.startTime.replace(':', 'h') + ' - ' + params.data.endTime.replace(':', 'h');
					}
				}, {
					headerName: 'Tps trajet (Aller/Retour)',
					field: 'tripTime',
					cellRenderer: (params) => {
						return params.value.replace(':', 'h');
					}
				}, {
					headerName: 'Personnes',
					field: 'users',
					cellRenderer: (params) => {
						const result: string[] = [];

						for (const item of params.value) {
							const tmp = _.find(this.users, { _id: item }) as any;

							tmp && result.push(tmp.firstname);
						}

						return result.join(', ');
					}
				}, {
					headerName: 'Action',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_delete = document.createElement('button');

						N_delete.classList.add('h-100', 'btn-transparent');

						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
						N_delete.setAttribute('confirmation', '');
						N_delete.setAttribute('tooltip', 'Supprimer');

						N_delete.addEventListener('click', () => {
							params.api?.updateRowData({
								remove: [params.node.data]
							});
						});

						const N_edit = document.createElement('button');

						N_edit.classList.add('h-100', 'btn-transparent');

						N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';
						N_edit.setAttribute('tooltip', 'Éditer');

						N_edit.addEventListener('click', () => {
							if (this.form) {
								new M_addLine(params.node.data).open().then((data) => {
									params.node.data = data;

									for (const key in data) {
										params.node.setDataValue(key, data[key]);
									}

									params.api.refreshCells({ force: true });
								});
							}
						});

						const N_div = document.createElement('div');

						N_div.appendChild(N_edit);
						N_div.appendChild(N_delete);

						return N_div;
					}
				}
			],
			onGridReady: (params) => {
				params.api?.sizeColumnsToFit();
			},
			getContextMenuItems: (params) => {
				const result = [
					{
						name: 'Ajouter ligne',
						action: () => {
							if (this.form) {
								new M_addLine().open().then((data) => {
									params.api?.applyTransaction({
										add: [data]
									});
								});
							}
						}
					}
				];

				return result;
			}

		});

		const N_grid = this.element.querySelector('#gridDetails') as HTMLElement;

		new Grid(N_grid, this.gridOptionsDetails, { modules: AllModules });
	}

	private initDetailMobile() {
		const N_grid = this.element.querySelector('#gridDetails') as HTMLElement;

		N_grid.classList.add('scroll-y');

		N_grid.innerHTML = '';

		let index = 0;
		for (const item of this.dataToMobile.details) {
			const N_div = this.templateDetails(item, index);

			N_grid.appendChild(N_div);

			index++;
		}
	}

	private templateDetails(item: { [key: string]: any } = {}, index: number) {
		const N_div = document.createElement('div');

		N_div.classList.add('card-mobile');

		const users: string[] = [];
		for (const user of item.users) {
			const tmp = _.find(this.users, { _id: user }) as any;
			tmp && users.push(tmp.firstname);
		}

		N_div.innerHTML = `
			<div class="flex-grow-1">
				<div class="d-flex">
					${item.location === 'site' ? 'Sur site' : ''}${item.location === 'desktop' ? 'Au bureau' : ''}
					<div class="ml-auto mr-1">${(item.date || '').split('-').reverse().join('/')}</div>
				</div> 
				<div>${item.startTime.replace(':', 'h') + ' - ' + item.endTime.replace(':', 'h')} Trajets : ${item.tripTime.replace(':', 'h')}</div>
				<div>${users.join(', ')}</div>
			</div>
			<div>
				<button class="btn btn-danger" id="delete"  confirmation>
					<i class="icon icon-trash-alt"></i>
				</button>
			</div>
		`;

		N_div.addEventListener('click', () => {
			new M_addLine(this.dataToMobile.details[index]).open().then((data) => {
				this.dataToMobile.details[index] = data;
				this.initDetailMobile();
			});
		});

		const N_delete = N_div.querySelector('#delete') as HTMLButtonElement;

		N_delete.addEventListener('click', () => {
			this.dataToMobile.details.splice(index, 1);
			N_div.remove();
			this.initDetailMobile();
		});

		return N_div;
	}
	//END DETAILS

	//PRODUCT
	private initMaterials() {
		this.gridOptionsMaterials = agUtils.french<GridOptions>({
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				editable: true
			},
			columnDefs: [{
				headerName: S_Product.getInstance().columnNameReference,
				field: 'reference',
				width: 170,
				rowDrag: true,
				suppressSizeToFit: true,
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: Select2Utils.getOptions('products', this.element)
				},
				cellRenderer: (params) => {
					return S_Product.getInstance().cellRendererReference(params.value);
				}
			}, {
				field: 'label',
				headerName: 'Désignation'
			}, {
				field: 'quantity',
				headerName: 'Qté',
				width: 100,
				suppressSizeToFit: true
			}, {
				field: 'time',
				headerName: 'Tps',
				width: 100,
				suppressSizeToFit: true
			}, {
				field: '#',
				headerName: 'Action',
				width: 100,
				editable: false,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					const div = document.createElement('div');

					const N_delete = document.createElement('button');

					N_delete.classList.add('h-100', 'btn-transparent');

					N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
					N_delete.setAttribute('confirmation', '');
					N_delete.setAttribute('tooltip', 'Supprimer');

					N_delete.addEventListener('click', () => {
						params.api.updateRowData({
							remove: [params.node.data]
						});
					});

					div.appendChild(N_delete);

					return div;
				}
			}],
			getContextMenuItems: (params) => {
				const result = [{
					name: 'Ajouter ligne',
					action: () => {
						let index = 0;

						params.api?.forEachNode(() => {
							index++;
						});

						params.api?.applyTransaction({
							add: [{}]
						});

						params.api?.ensureIndexVisible(index);
					}
				}, {
					name: 'Inserer ligne',
					disabled: !params.node,
					action: () => {
						params.api?.applyTransaction({
							add: [{
							}],
							addIndex: (params.node.rowIndex || 0) + 1
						});
					}
				}];

				return result;
			},
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			onCellEditingStopped: (params) => {
				if (params.column.getColId() === 'reference') {
					S_Product.getInstance().getById(params.data.reference).then((data) => {
						params.node.setDataValue('label', data.name);
						params.node.setDataValue('quantity', 1);
					});
				}
			},
			rowData: []
		});

		const N_grid = this.element.querySelector('#products #grid') as HTMLElement;

		new Grid(N_grid, this.gridOptionsMaterials, { modules: AllModules });

		$('[data-toggle="tab"]').on('shown.bs.tab', (e) => {
			if (e.target.getAttribute('href') === '#products') {
				this.gridOptionsMaterials.api?.sizeColumnsToFit();
			}
		});
	}

	private async initMaterialsMobile() {
		const N_grid = this.element.querySelector('#products #grid') as HTMLElement;

		N_grid.classList.add('scroll-y');

		N_grid.innerHTML = `
			<button class="btn btn-success position-sticky mb-2" id="add" style="top:0px">
				<i class="icon icon-solid-plus"></i>
			</button>
		`;

		const N_add = N_grid.querySelector('#add') as HTMLButtonElement;

		N_add.addEventListener('click', () => {
			new M_products().open().then((data) => {
				this.dataToMobile.products.push(data);
				this.initMaterialsMobile();
			});
		});

		let index = 0;
		for (const item of this.dataToMobile.products) {
			const N_div = await this.templateMaterials(item, index);

			N_grid.appendChild(N_div);

			index++;
		}
	}

	private async templateMaterials(item: { [key: string]: any } = {}, index: number) {
		const N_div = document.createElement('div');

		N_div.classList.add('card-mobile');

		const product = await S_Product.getInstance().getById(item.reference);

		N_div.innerHTML = `
			<div class="flex-grow-1">
				<div class="d-flex">
					${product[S_Product.getInstance().referenceKey]}
				</div> 
				<div>${item.label}</div>
				<div>Quantité : ${item.quantity || '0'} - Temps : ${item.time || '0'}</div>
			</div>
			<div>
				<button class="btn btn-danger" id="delete"  confirmation>
					<i class="icon icon-trash-alt"></i>
				</button>
			</div>
		`;

		N_div.addEventListener('click', () => {
			new M_products(this.dataToMobile.products[index]).open().then((data) => {
				this.dataToMobile.products[index] = data;
				this.initMaterialsMobile();
			});
		});

		const N_delete = N_div.querySelector('#delete') as HTMLButtonElement;

		N_delete.addEventListener('click', () => {
			this.dataToMobile.products.splice(index, 1);
			N_div.remove();
			this.initMaterialsMobile();
		});

		return N_div;
	}

	//END PRODUCT

	//Task
	private initTasks() {
		this.gridOptionsTasks = agUtils.french<GridOptions>({
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				editable: true
			},
			columnDefs: [{
				field: 'label',
				headerName: 'Désignation'
			}, {
				field: '#',
				headerName: 'Action',
				width: 60,
				editable: false,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					const N_delete = document.createElement('button');

					N_delete.classList.add('h-100', 'btn-transparent');

					N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
					N_delete.setAttribute('confirmation', '');
					N_delete.setAttribute('tooltip', 'Supprimer');

					N_delete.addEventListener('click', () => {
						params.api.updateRowData({
							remove: [params.node.data]
						});
					});

					return N_delete;
				}
			}],
			getContextMenuItems: (params) => {
				const result = [{
					name: 'Ajouter ligne',
					action: () => {
						if (params.api) {
							let index = 0;

							params.api.forEachNode(() => {
								index++;
							});

							params.api.applyTransaction({
								add: [{
								}]
							});

							params.api.ensureIndexVisible(index);
						}
					}
				}, {
					name: 'Inserer ligne',
					action: () => {
						params.api?.applyTransaction({
							add: [{
							}],
							addIndex: (params.node.rowIndex || 0) + 1
						});
					}
				}];

				if (!params.node) {
					result.pop();
				}

				return result;
			},
			onGridReady: (params) => {
				params.api.sizeColumnsToFit();
			},
			rowData: []
		});

		const N_grid = this.element.querySelector('#tasks #grid') as HTMLElement;

		new Grid(N_grid, this.gridOptionsTasks, { modules: AllModules });

		$('[data-toggle="tab"]').on('shown.bs.tab', (e) => {
			if (e.target.getAttribute('href') === '#tasks') {
				this.gridOptionsTasks.api?.sizeColumnsToFit();
			}
		});
	}

	private initTasksMobile() {
		const N_grid = this.element.querySelector('#tasks #grid') as HTMLElement;

		N_grid.classList.add('scroll-y');

		N_grid.innerHTML = `
			<button class="btn btn-success position-sticky mb-2" id="add" style="top:0px">
				<i class="icon icon-solid-plus"></i>
			</button>
		`;

		const N_add = N_grid.querySelector('#add') as HTMLButtonElement;

		N_add.addEventListener('click', () => {
			Alert.prompt('Ajout d\'une tache', '', {
				type: 'textarea',
				value: ''
			}).then((data) => {
				this.dataToMobile.tasks.push({
					label: data
				});
				this.initTasksMobile();
			});
		});

		let index = 0;

		for (const item of this.dataToMobile.tasks) {
			const N_div = this.templateTasks(item, index);

			N_grid.appendChild(N_div);

			index++;
		}
	}

	private templateTasks(item: { [key: string]: any } = {}, index: number) {
		const N_div = document.createElement('div');

		N_div.classList.add('card-mobile');

		N_div.innerHTML = `
			<div class="flex-grow-1">
				<div class="d-flex">
					${item.label}
				</div>
			</div>
			<div>
				<button class="btn btn-danger" id="delete"  confirmation>
					<i class="icon icon-trash-alt"></i>
				</button>
			</div>
		`;

		N_div.addEventListener('click', () => {
			Alert.prompt('Modification d\'une tache', '', {
				type: 'textarea',
				value: this.dataToMobile.tasks[index].label
			}).then((data) => {
				this.dataToMobile.tasks[index].label = data;
				this.initTasksMobile();
			});
		});

		const N_delete = N_div.querySelector('#delete') as HTMLButtonElement;

		N_delete.addEventListener('click', () => {
			this.dataToMobile.tasks.splice(index, 1);
			N_div.remove();
			this.initTasksMobile();
		});

		return N_div;
	}
	//END TASK

	private initFiles() {
		this.N_FilesTab = this.element.querySelector(FilesTab.tagName) as FilesTab;

		this.N_FilesTab.setParentElement(this.element);

		this.N_FilesTab.setCallback(() => {
			this.save();
		});
	}

	private async initNotes() {
		this.N_NotesTab = this.element.querySelector(NotesTab.tagName) as NotesTab;

		this.N_NotesTab.setParentElement(this.element);

		this.N_NotesTab.setCallback(() => {
			this.save();
		});
	}

	private async initSignature() {
		this.N_SignatureTab = this.element.querySelector(SignatureTab.tagName) as SignatureTab;

		this.N_SignatureTab.setParentElement(this.element);
	}

	private postInit() {
		this.selectPostinit['infos.customer'].on('change', async (customer) => {
			const contacts = await S_C_Contact.getInstance().getByCustomerToSelect2(customer as string);

			if (contacts.length && customer) {
				this.form?.setDataByName('infos.contact', contacts[0]);
			} else {
				this.form?.setDataByName('infos.contact', { id: '', text: '' });
			}
		});

		this.selectPostinit['infos.site'].postInit();
		this.selectPostinit['infos.customer'].postInit();
		this.selectPostinit['infos.contact'].postInit();
		this.N_FilesTab?.postInit();
		this.N_NotesTab?.postInit();
	}

	private async save() {
		if (this.form) {
			const data: { [key: string]: any } = {
				_id: this.idData,
				...this.form.getData()
			};

			data.companyID = this.companyRefObject.id;
			data.attachments = this.N_FilesTab!.data;
			data.notes = this.N_NotesTab!.data;
			data.signature = this.N_SignatureTab!.data;

			if (global.IS_MOBILE) {
				data.details = this.dataToMobile.details;
				data.tasks = this.dataToMobile.tasks;
				data.products = this.dataToMobile.products;
			} else {
				data.details = [];

				this.gridOptionsDetails.api?.forEachNode((node) => {
					data.details.push(node.data);
				});

				data.tasks = [];

				this.gridOptionsTasks.api?.forEachNode((node) => {
					data.tasks.push(node.data);
				});

				data.products = [];

				this.gridOptionsMaterials.api?.forEachNode((node) => {
					data.products.push(node.data);
				});
			}

			const newData = (await S_Interventions.getInstance().save(data)).data;

			this.idData = newData.data._id;
		}
	}

	private async getData() {
		let data: { [key: string]: any } = {};

		if (this.mode === 'duplicate') {
			const tmp = await S_Interventions?.getInstance().duplicate(this.idData) || {};
			data = tmp.data;
			this.idData = data.data._id;
		} else {
			const tmp = await S_Interventions?.getInstance().getDataToModal(this.idData) || {};
			data = tmp.data;
		}

		if (data.data.companyID) {
			this.companyRefObject = data.data.companyID;
			data.data.companyID = this.companyRefObject.text;
		}

		return data.data;
	}
}

export default Interventions;
