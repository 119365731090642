
// CORE
import { global, toaster } from '@autoprog/core-client';
import agUtils from '@libs/agGrid/french';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

// LIBS
import AggridUtils from '@libs/utils/Aggrid';
import NumericCellEditor from '@libs/agGrid/NumericCellEditor';
import SelectEditor from '@libs/agGrid/SelectEditor';
import TextareaEditor from '@libs/agGrid/TextareaEditor';

import Select2Utils from '@libs/utils/select2Utils';

// MODAL
import M_mobile from '../../modals/deliveries/ContentEdit';

// SERVICE
import S_C_Orders from '@services/Customer/CustomerOrderService';
import S_Delivery from '@services/DeliveryService';
import S_Products from '@services/Product/ProductService';

class ContentDeliveriesTab extends HTMLElement {
	public static readonly tagName: string = 'ap-deliveries-content-tab';

	private selectorTab: string = '';
	private idTab: string = '';
	private _data = (): any => { return {}; };
	private _update = (): void => { };

	private _gridOptions: GridOptions = {};

	private N_el: HTMLElement | null = null;
	private _state: string = '0';
	private _isLock: boolean = false;

	private allDataToMobile: any[] = [];

	constructor() {
		super();
		this.idTab = this.getAttribute('tabID') || ContentDeliveriesTab.tagName;
		this.selectorTab = this.dataset.tabContainer || '.tab-content';
	}

	public set isLock(value: boolean) {
		this._isLock = value;
	}

	public async connectedCallback() {
		if (global.IS_MOBILE) {
			this.innerHTML = `
				<div class="d-flex align-items-center page-tab-mobile bg-white p-3" data-type="open-mobile" href="${this.idTab}">
					<div tooltip="Contenus">
						<i class="icon icon-solid-list-ul mr-2"></i>
					</div>
					<span>Contenus</span>
					<div class="ml-auto d-flex">
						<div data-type="open-mobile-icon"></div>
					</div>
				</div>
			`;
		} else {
			this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="file-edit/line" icon-active="file-edit/fill" text="Contenus"></ap-page-tabs-menu-item>`;

			const N_item = this.querySelector('ap-page-tabs-menu-item');

			N_item?.addEventListener('open', () => {
				this._gridOptions.api?.sizeColumnsToFit();
			});
		}
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
		this.N_el = document.createElement('div');

		this.N_el.classList.add('page-tabs-container', 'tab-pane');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title" display="desktop">
				Contenus
				<div class="page-tabs-title-right">
					<button class="btn btn-blue d-none" type="button" id="collect-deliveries-btn" confirmation permission="DELIVERIES.EDIT">
						Importer produits de la commande
					</button>	
					<div class="mx-3">
						<button class="btn btn-info d-none mr-2" type="button" id="hidden_all" permission="DELIVERIES.EDIT"> Masquer tout </button>
						<button class="btn btn-info d-none" type="button" id="visible_all" permission="DELIVERIES.EDIT"> Démasquer tout </button>
					</div>					
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>

			<div class="page-tabs-title" display="mobile">
				<button class="btn btn-transparent" type="button" id="hidden_all"  permission="DELIVERIES.EDIT"> 
					<i class="icon icon-ri-eye-off-line"></i> 
				</button>
				<button class="btn btn-transparent" type="button" id="visible_all" permission="DELIVERIES.EDIT"> 
					<i class="icon icon-ri-eye-line"></i> 
				</button>
				<button class="btn btn-transparent" type="button" id="add-mobile" permission="DELIVERIES.EDIT">
					<i class="icon icon-solid-plus text-success"></i>
				</button>
			</div>

			<div class="page-tabs-content">
				<div class="h-100 ag-theme-alpine w-100" id="grid" display="desktop"></div>
				<div class="page-card bg-white p-3" id="grid" display="mobile"></div>
			</div>
        `;

		if (global.IS_MOBILE) {
			this.append(this.N_el);
		} else {
			N_container.append(this.N_el);
			this.initGrid();
			this.initButton();
		}
	}

	private initGrid() {
		this._gridOptions = agUtils.french<GridOptions>({
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				editable: (params) => {
					if (this._state === '1' || this._state === '2' || params.data.typeLine || params.node.isRowPinned() || this._isLock) {
						return false;
					} else {
						return true;
					}
				},
				resizable: true

			},
			columnDefs: [{
				headerName: '#',
				rowDrag: true,
				resizable: false,
				editable: false,
				width: 50,
				suppressSizeToFit: true
			}, {
				headerName: S_Products.getInstance().columnNameReference,
				field: 'reference',
				width: 170,
				suppressSizeToFit: true,
				cellEditor: SelectEditor,
				cellEditorParams: {
					options: Select2Utils.getOptions('products', this.N_el!)
				},
				cellRenderer: (params) => {
					return S_Products.getInstance().cellRendererReference(params.value);
				}
			}, {
				field: 'label',
				headerName: 'Désignation',
				autoHeight: true,
				wrapText: true,
				cellEditor: TextareaEditor,
				cellRenderer: (params) => {
					if (params.data.typeLine === 'shipping-address') {
						return '<span class="text-muted">Adresse Expédition</span>';
					} else {
						const value = (params.value || '').split('\n');
						let result = '';
						for (const item of value) {
							result += `<div style="line-height: 17px">${item || '&nbsp;'}</div>`;
						}

						return `<div style="margin-top:10px; margin-bottom:10px;" >${result}</div>`;
					}
				}
			}, {
				field: 'weight',
				headerName: 'Poids U',
				cellClass: 'text-right text-monospace',
				cellEditor: NumericCellEditor,
				width: 120,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					if (params.node.rowPinned) {
						return '';
					} else if (params.data.typeLine) {
						return '';
					} else {
						return (params.value || 0) + ' kg';
					}
				}
			}, {
				headerName: 'Poids Total',
				cellClass: 'text-right text-monospace',
				cellEditor: NumericCellEditor,
				width: 120,
				editable: false,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					if (params.node.rowPinned) {
						let totalWeight = 0;

						params.api.forEachNode((node: any) => {
							totalWeight += Number(node.data.weight || 0) * Number(node.data.deliveredQuantity || 0);
						});

						return totalWeight + ' kg';
					} else if (params.data.typeLine) {
						return '';
					} else {
						return Number(params.data.weight || 0) * Number(params.data.deliveredQuantity || 0) + ' kg';
					}
				}
			}, {
				field: 'quantity',
				headerName: 'Qté Total',
				cellClass: 'text-right text-monospace',
				cellEditor: NumericCellEditor,
				width: 100,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					if (params.node.rowPinned) {
						let totalQuantity = 0;

						params.api.forEachNode((node: any) => {
							totalQuantity += Number(node.data.quantity || 0);
						});

						return totalQuantity;
					} else {
						return params.value;
					}
				}

			}, {
				field: 'deliveredQuantity',
				headerName: 'Qté Livrée',
				cellClass: 'text-right text-monospace',
				cellEditor: NumericCellEditor,
				width: 100,
				suppressSizeToFit: true,
				cellStyle: (params) => {
					return {
						'background-color': (!params.node.rowPinned && !params.data.typeLine && (!params.value || params.value === '0')) ? 'var(--ap-red-200)' : ''
					};
				},
				cellRenderer: (params) => {
					if (params.node.rowPinned) {
						let totalDelivered = 0;

						params.api.forEachNode((node: any) => {
							node.data.deliveredQuantity = node.data.deliveredQuantity || 0;
							node.data.quantity = node.data.quantity || 0;

							if (node.data.deliveredQuantity > node.data.quantity) {
								totalDelivered += Number(node.data.quantity);
							} else {
								totalDelivered += Number(node.data.deliveredQuantity);
							}
						});

						return totalDelivered;
					} else {
						return params.value;
					}
				}

			}, {
				field: 'remainingQuantity',
				headerName: 'Qté à livrer',
				cellClass: 'text-right text-monospace',
				cellEditor: NumericCellEditor,
				editable: false,
				width: 120,
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					if (params.node.rowPinned) {
						let totalRemaining = 0;

						params.api.forEachNode((node: any) => {
							totalRemaining += Number(node.data.remainingQuantity || 0);
						});

						return totalRemaining;
					} else {
						return (params.value || 0);
					}
				}
			}, {
				field: 'button',
				headerName: 'Action',
				width: 100,
				editable: false,
				hide: this._state === '1' || this._state === '2',
				suppressSizeToFit: true,
				cellRenderer: (params) => {
					if (!params.node.rowPinned && !this._isLock) {
						const N_div = document.createElement('div');

						const N_disabledPrint = document.createElement('button');
						N_disabledPrint.classList.add('h-100', 'py-0', 'btn-transparent', 'text-red-300');
						N_disabledPrint.type = 'button';

						if (params.data.disabledPrint) {
							N_disabledPrint.setAttribute('tooltip', 'Masquer impression');
						} else {
							N_disabledPrint.setAttribute('tooltip', 'Afficher impression');
							N_disabledPrint.classList.add('text-green');
							N_disabledPrint.classList.remove('text-red-300');
						}

						N_disabledPrint.innerHTML = '<i class="h5 icon icon-printer-outiline "></i>';

						N_disabledPrint.addEventListener('click', () => {
							params.data.disabledPrint = !params.data.disabledPrint;

							if (params.data.disabledPrint) {
								N_disabledPrint.setAttribute('tooltip', 'Masquer impression');
							} else {
								N_disabledPrint.setAttribute('tooltip', 'Afficher impression');
							}

							N_disabledPrint.classList.toggle('text-red-300');
							N_disabledPrint.classList.toggle('text-green');

							params.api.applyTransaction({ update: [params.data] });
						});

						const N_delete = document.createElement('button');

						N_delete.classList.add('h-100', 'btn-transparent');
						N_delete.type = 'button';
						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
						N_delete.setAttribute('confirmation', '');
						N_delete.setAttribute('tooltip', 'Supprimer');

						N_delete.addEventListener('click', () => {
							params.api.updateRowData({
								remove: [params.node.data]
							});

							params.api.refreshCells({ force: true });
						});

						N_div.appendChild(N_disabledPrint);
						N_div.appendChild(N_delete);

						return N_div;
					} else {
						return '';
					}
				}
			}],
			getContextMenuItems: (params) => {
				const addAddress = {
					name: 'Ajout adresse expédition',
					disabled: this._state === '1' || this._state === '2' || this._isLock,
					action: () => {
						params.api?.applyTransaction({
							add: [{
								typeLine: 'shipping-address'
							}]
						});
					}
				};

				return [
					...AggridUtils.contextMenuAddLines(params, () => {
						return {
							quantity: 1,
							deliveredQuantity: 0,
							remainingQuantity: 1
						};
					}, this._isLock),
					addAddress
				];
			},
			onCellEditingStarted: (params) => {
				if (params.column.getColId() === 'label') {
					const value = (params.value || '').split('\n');

					params.node.setRowHeight((value.length + 2) * 20);
				}
			},
			onCellEditingStopped: (params) => {
				if (['label'].includes(params.column.getColId())) {
					params.node.setDataValue(params.column.getColId(), params.newValue.trim());
				}

				if (params.column.getColId() === 'reference') {
					S_Products.getInstance().getById(params.data.reference).then((data) => {
						params.node.setDataValue('label', data.name);
						params.node.setDataValue('weight', data.weight || 0);

						this._update();
					});
				}

				if (['quantity', 'deliveredQuantity'].includes(params.column.getColId())) {
					let deliveredQuantity = params.data.deliveredQuantity;

					if (deliveredQuantity > params.data.quantity) {
						deliveredQuantity = params.data.quantity;

						params.node.setDataValue('deliveredQuantity', deliveredQuantity);
					}

					params.node.setDataValue('remainingQuantity', params.data.quantity - deliveredQuantity);

					params.api.refreshCells({ force: true });

					this._update();
				}

				if (['label', 'weight'].includes(params.column.getColId())) {
					params.api?.resetRowHeights();
					params.api?.refreshCells({
						force: true,
						rowNodes: [params.node]
					});

					this._update();
				}
			},
			onColumnResized: (params) => {
				if (params.finished) {
					params.api.resetRowHeights();
				}
			},
			onRowDataUpdated: (params) => {
				this._update();
				params.api.setPinnedBottomRowData([{}]);
			},
			getRowStyle: (params: any) => {
				if (params.node.rowPinned) {
					return {
						'font-weight': 'bold',
						'font-size': '15px'
					};
				} else {
					if (params.data.disabledPrint) {
						return {
							'background-color': 'var(--ap-blue-50)',
							color: 'var(--ap-blue-900)'
						};
					}

					return { 'background-color': '', color: '' };
				}
			},
			rowData: []
		});

		const N_grid = this.N_el!.querySelector(`#${this.idTab} #grid`) as HTMLElement;

		new Grid(N_grid, this._gridOptions, { modules: AllModules });
	}

	private async initMobile(products: any[]) {
		const container = this.N_el!.querySelector(`#${this.idTab} #grid`) as HTMLDivElement;

		let index = 0;
		for (const item of products) {
			container.appendChild(await this.templateInitMobile(item, index++));
		}
	}

	private async templateInitMobile(item: { [key: string]: any }, index: number) {
		const N_div = document.createElement('div');
		N_div.classList.add('card-mobile', 'flex-column');

		const product = await S_Products.getInstance().getById(item.reference) || {};

		item.reference = product._id || '';

		this.allDataToMobile[index] = item;

		N_div.innerHTML = `
			<div class="font-weight-bold flex-grow-1 w-100 text-truncate">
				${product[S_Products.getInstance().referenceKey] || ''} ${item.label || product.name || '--'}
			</div>
			<div>
				Quantité : ${item.quantity || '0'}
			</div>
			<div>
				Quantité livrée : ${item.deliveredQuantity || '0'}
			</div>
			<div>
				Quantité à livrer : ${item.quantity - item.deliveredQuantity || '0'}
			</div>
		`;

		N_div.addEventListener('click', () => {
			new M_mobile(item).open().then(async (newData) => {
				N_div.parentNode?.insertBefore(await this.templateInitMobile(newData, index), N_div);

				N_div.remove();
			});
		});

		return N_div;
	}

	private initButton() {
		const N_hidden_all = this.N_el!.querySelector('#hidden_all') as HTMLButtonElement;
		const N_visible_all = this.N_el!.querySelector('#visible_all') as HTMLButtonElement;

		const N_addProducts = this.N_el!.querySelector('#collect-deliveries-btn') as HTMLButtonElement;
		N_addProducts?.addEventListener('click', async () => {
			N_addProducts.loading(this.fillDeliveriesProducts());
		});

		N_hidden_all.addEventListener('click', () => {
			const update: any[] = [];
			this._gridOptions.api?.forEachNode((node) => {
				node.data.disabledPrint = true;
				update.push(node.data);
			});
			this._gridOptions.api?.applyTransaction({
				update
			});
			this._gridOptions.api?.refreshCells({ force: true });
		});

		N_visible_all.addEventListener('click', () => {
			const update: any[] = [];
			this._gridOptions.api?.forEachNode((node) => {
				node.data.disabledPrint = false;
				update.push(node.data);
			});
			this._gridOptions.api?.applyTransaction({
				update
			});
			this._gridOptions.api?.refreshCells({ force: true });
		});
	}

	private async fillDeliveriesProducts() {
		const customerOrder = this._data().infos.order;

		const products = await S_C_Orders.getInstance().getProductToDelivery(customerOrder!);

		const order_Products_quantity: { [key: string]: number } = {};

		for (const item of products) {
			if (item.quantity) {
				order_Products_quantity[item.reference] = order_Products_quantity[item.reference] || 0;
				order_Products_quantity[item.reference] += item.quantity;
			}
		}

		//Pour chaque BL, récupérer tous les produits dans BL et MaJ sa quantité dans order_Products_Map
		if (customerOrder) {
			const listDeliveries = await S_Delivery.getInstance().getByCustomerOrder(customerOrder);

			for (const delivery of listDeliveries) {
				if (delivery._id !== this._data()._id && ((delivery.state === '1') || (delivery.state === '0'))) {
					toaster.info(`Bon de Livraison N° ${delivery.infos.number} pas encore expédié !`, 'Information');
					return false;
				}

				for (const product of delivery.products) {
					if (order_Products_quantity[product.reference]) {
						const remain_Qtt = order_Products_quantity[product.reference]! - product.deliveredQuantity;

						if (remain_Qtt > 0) {
							order_Products_quantity[product.reference] = remain_Qtt;
						} else {
							delete order_Products_quantity[product.reference];
						}
					}
				}
			}
		}

		//MaJ la quantité
		for (let i = 0; i < products.length; i++) {
			const product = products[i];
			if (order_Products_quantity[product.reference]) {
				product.quantity = order_Products_quantity[product.reference];
				product.remainingQuantity = order_Products_quantity[product.reference];
			} else {
				products.splice(i, 1);
				i--;
			}
		}

		if (products.length === 0) {
			toaster.info('Aucun produit restant à livrer', 'Information');
		} else {
			this._gridOptions.api?.setRowData(products);
			this._update();
		}
	}

	private refreshDisabledState() {
		const N_hidden_all = this.N_el!.querySelector('#hidden_all') as HTMLButtonElement;
		const N_visible_all = this.N_el!.querySelector('#visible_all') as HTMLButtonElement;

		this._gridOptions.columnApi?.setColumnVisible('button', !(this._state === '1' || this._state === '2'));

		if (!(this._state === '1' || this._state === '2')) {
			N_hidden_all.classList.remove('d-none');
			N_visible_all.classList.remove('d-none');
		} else {
			N_hidden_all.classList.add('d-none');
			N_visible_all.classList.add('d-none');
		}

		this._gridOptions.api?.sizeColumnsToFit();
	}

	public updateButtonFillProduct() {
		const order = this._data().infos.order;

		const N_collectDeliveriesBtn = this.N_el!.querySelector('#collect-deliveries-btn');

		if (order) {
			N_collectDeliveriesBtn?.classList.remove('d-none');
		} else {
			N_collectDeliveriesBtn?.classList.add('d-none');
		}
	}

	public set data(product: any) {
		this._gridOptions.rowData = product;
		this._gridOptions.api?.setRowData(product);
		this._gridOptions.api?.setPinnedBottomRowData([{}]);

		if (global.IS_MOBILE) {
			this.initMobile(product);
		}
	}

	public get gridOptions() {
		return this._gridOptions;
	}

	public get data(): any {
		if (global.IS_MOBILE) {
			return this.allDataToMobile;
		} else {
			const results: any[] = [];

			this._gridOptions.api?.stopEditing();

			this._gridOptions.api?.forEachNode((node: any) => {
				results.push({
					reference: node.data.reference,
					typeLine: node.data.typeLine,
					label: node.data.label,
					quantity: node.data.quantity,
					weight: node.data.weight,
					disabledPrint: node.data.disabledPrint,
					deliveredQuantity: node.data.deliveredQuantity,
					remainingQuantity: node.data.quantity - node.data.deliveredQuantity
				});
			});

			return results;
		}
	}

	public set state(state: string) {
		this._state = state || '0';
		this.refreshDisabledState();
	}

	public setGetData(cb: () => any) {
		this._data = cb;
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public static register() {
		customElements.define(ContentDeliveriesTab.tagName, ContentDeliveriesTab);
	}
}

export default ContentDeliveriesTab;
