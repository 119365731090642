// CORE
import { DOM, Form } from '@autoprog/core-client';

// NODE_MODULE
import _get from 'lodash/get';
import _set from 'lodash/set';

// LIBS
import Modal from '@libs/Modal';

// CUSTOM_ELEMENT
import CE_Address from '@libs/customElement/Address';
import CE_Select from '@libs/customElement/Select';
import CE_Select2 from '@libs/customElement/Select2';

type data = {
	_id?: string
};

class EditPageModal<T> extends Modal {
	protected id: string;

	protected previousCallback: null | (() => void) = null;
	protected nextCallback: null | ((data: any) => void) = null;
	protected selectPostinit: { [key: string]: CE_Select2 | CE_Address } = {};

	protected form: Form | null;

	constructor(data: T, tpl: string) {
		super({
			tpl,
			keyboard: false,
			backdrop: 'static'
		});

		this.id = (data as data)._id || '';

		this.form = null;

		this.on('opened', async () => {
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			this.element.classList.add('loading');
			N_save.disabled = true;

			this.preInit(data);

			this.form = new Form(this.formElement as HTMLFormElement);

			await this.setData(data);

			this.postInit();

			this.initEventButton();

			this.element.classList.remove('loading');
			N_save.disabled = false;
		});
	}

	// eslint-disable-next-line unused-imports/no-unused-vars
	protected preInit(data: T) {

	}

	protected initEventButton() {
		const N_previous = this.element.querySelector('#previous') as HTMLButtonElement;

		N_previous && N_previous.addEventListener('click', () => {
			if (this.previousCallback) {
				this.previousCallback();
			}
			DOM.nextTick(() => {
				this.reject(false);
			});
		});

		const N_save = this.element.querySelector('#save') as HTMLButtonElement;

		N_save && N_save.addEventListener('click', async () => {
			this.formElement.classList.add('submitted');

			if (this.checkValidityForm()) {
				N_save.loading(new Promise(async () => {
					let data = this.getData();

					data = await this.getDataForm(data);

					if (this.nextCallback) {
						this.nextCallback(data);
					}

					DOM.nextTick(() => {
						this.closeModal(data);
					});
				}));
			}
		});
	}

	protected async closeModal(data: { [key: string]: any }) {
		this.resolve(data);
	}

	private getData() {
		const data = this.form?.getData() as { [key: string]: any };

		if (this.id) {
			data._id = this.id;
		}

		const N_listSelect = this.element.querySelectorAll(CE_Select.tagName) as NodeListOf<CE_Select>;
		N_listSelect.forEach((N_el) => {
			const name = N_el.getAttribute('name') as string;
			const value = N_el.value;

			if (value) {
				_set(data, name, value);
			}
		});

		return data;
	}

	protected checkValidityForm() {
		return true;
	}

	protected get formElement(): HTMLFormElement {
		return this.element.querySelector('form')!;
	}

	protected async getDataForm(data: { [key: string]: any }) {
		return data;
	}

	protected async setData(data: T) {
		this._setFormData(data as { [key: string]: any });
	}

	protected _setFormData(data: { [key: string]: any }) {
		this.form?.setData(data);

		const N_listSelect = this.element.querySelectorAll(CE_Select.tagName) as NodeListOf<CE_Select>;

		N_listSelect.forEach((N_el) => {
			const name = N_el.getAttribute('name') as string;
			N_el.value = _get(data, name);
		});
	}

	public setPreviousCallback(cb: () => void) {
		this.previousCallback = cb;

		this.once('opened', () => {
			const N_previous = this.element.querySelector('#previous') as HTMLButtonElement;
			N_previous && N_previous.classList.remove('d-none');
		});

		return this;
	}

	public setNextCallback(cb: (data: any) => void) {
		this.nextCallback = cb;

		this.once('opened', () => {
			const N_save = this.element.querySelector('#save') as HTMLButtonElement;
			N_save && (N_save.innerHTML = 'Suivant');
		});

		return this;
	}

	protected postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default EditPageModal;
