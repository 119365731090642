// CORE
import { Alert, Router, toaster, utils } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
// LIBS
import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';
import Loader from '@libs/Loader';
import ModalManager from '@managers/ModalManager';
import OpenDocuments from '@libs/customElement/OpenDocuments';

// PRINTER

// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
import M_AdditionalInformation from '../modals/editPage/AdditionalInformation';
import M_Amounts from '../modals/editPage/Amounts';
import M_DetailsBill from '../modals/editPage/DetailsBill';
import M_GeneralInformation from '../modals/editPage/GeneralInformation';

import M_Advance from '../modals/Advance';
import M_Credit from '../..//js/modals/Credit';

// CUSTOM_ELEMENT
import CE_PaymentTab from '@modules/BillsProvider/js/customElements/Payment';

// SERVICE
import S_P_Address from '@services/Provider/ProviderAddressService';
import S_P_Bill from '@services/Provider/ProviderBillService';
import S_Provider from '@services/Provider/ProviderService';
import S_Taxes from '@modules/Comptabilité/js/services/TaxesService';

import C_OrdersProvider from '@modules/OrdersProvider/js/controllers/Orders.Provider';
import Settings from '@modules/Settings/js/libs/Settings';

class BillsProviderCtrl extends ControllerPageID {
	private N_PaymentTab: CE_PaymentTab | null = null;

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.options = BillsProviderCtrl.options || {};

		BillsProviderCtrl.options = {};

		this.routeReturn = 'module/bills/providers';

		this.init('bills-providers', id);
	}

	private static options: { [key: string]: any } = {};
	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		BillsProviderCtrl.options = options || {};

		if (id) {
			await OpenDocuments.checkOpen(id, 'bills-providers');
			Router.getInstance().navigate(`/module/billsPage/provider?id=${id}`);
		} else {
			Router.getInstance().navigate('/module/billsPage/provider');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);
		this.initTabs();
		this.initEvent();

		await this.fromOrder();

		const data = await this.getData();

		this.setData(data);

		this.postInit();

		this.initFullscreen();
		this.initEditButton();

		if (!this.options.idCommand && !this.id) {
			this.openGeneralInformation(true);
		}

		this.updateDisplayWithoutOrder();
	}

	private openGeneralInformation(isFirstOpen = false) {
		const res = {
			infos: {
				provider: this.form?.getDataByName('infos.provider') as string,
				contact: this.form?.getDataByName('infos.contact') as string,
				paymentType: this.form?.getDataByName('infos.paymentType') as string,
				label: this.form?.getDataByName('infos.label') as string
			}
		};

		let modal: M_GeneralInformation;

		if (isFirstOpen) {
			modal = new M_GeneralInformation(res, !this.options.idCommand).setNextCallback(() => {
				this.openDetailBill(true);
			});
		} else {
			modal = new M_GeneralInformation(res, !this.options.idCommand);
		}

		modal.open().then(async (data) => {
			this.setDataForm(data);
			await this.updateProvider(isFirstOpen);
			this.updateInfos();
			this.updateTitle();

			if (!isFirstOpen) {
				this.updateSaveButton();
			}
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private openDetailBill(isFirstOpen = false) {
		const res = {
			infos: {
				command: this.form?.getDataByName('infos.command') as string,
				number: this.form?.getDataByName('infos.number') as string,
				type: this.form?.getDataByName('infos.type') as string,
				waitingBill: this.form?.getDataByName('infos.waitingBill') as boolean
			}
		};

		let modal: M_DetailsBill;

		if (isFirstOpen) {
			modal = new M_DetailsBill(res).setPreviousCallback(() => {
				this.openGeneralInformation(true);
			}).setNextCallback(() => {
				this.openAmount(true);
			});
		} else {
			modal = new M_DetailsBill(res);
		}

		modal.open().then((data) => {
			this.setDataForm(data);
			this.updateTitle();

			if (!isFirstOpen) {
				this.updateSaveButton();
			}
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private openAmount(isFirstOpen: boolean = false) {
		const res = {
			infos: {
				tva: this.form?.getDataByName('infos.tva') as string
			},
			globalPrice: this.form?.getDataByName('globalPrice') as string,
			tva: this.form?.getDataByName('tva') as string,
			globalPriceTTC: this.form?.getDataByName('globalPriceTTC') as string
		};

		let modal: M_Amounts;

		if (isFirstOpen) {
			modal = new M_Amounts(res).setPreviousCallback(() => {
				this.openDetailBill(true);
			});
		} else {
			modal = new M_Amounts(res);
		}

		modal.open().then((data) => {
			this.setDataForm(data);
			this.updateInfos();
			this.updateSaveButton();
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private initEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsBill = this.el.querySelector('[data-edit="detailsBill"]') as HTMLButtonElement;
		const N_edit_Amounts = this.el.querySelector('[data-edit="amounts"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			this.openGeneralInformation();
		});

		N_edit_DetailsBill.addEventListener('click', () => {
			this.openDetailBill();
		});

		N_edit_Amounts.addEventListener('click', () => {
			this.openAmount();
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			const res = {
				infos: {
					provider: this.form?.getDataByName('infos.provider') as string,
					addressID: this.form?.getDataByName('infos.addressID') as string,
					fullAddress: this.form?.getDataByName('infos.fullAddress') as string,
					date: this.form?.getDataByName('infos.date') as Moment,
					datePayment: this.form?.getDataByName('infos.datePayment') as Moment,
					receiveDate: this.form?.getDataByName('infos.receiveDate') as Moment,
					comment: this.form?.getDataByName('infos.comment') as string
				},
				disputes: {
					comment: this.form?.getDataByName('disputes.comment') as string
				},
				relunch: {
					date: this.form?.getDataByName('relunch.date') as Moment
				}
			};

			new M_AdditionalInformation(res).open().then((data) => {
				this.setDataForm(data);
				this.updateSaveButton();
				this.updateInfos();
			});
		});
	}

	private async fromOrder() {
		try {
			if (this.options.idCommand) {
				Loader.getInstance().open();

				const tmp = (await S_P_Bill.getInstance().getDataToModal(this.id, { ...this.options, from: 'order' })).data;

				Loader.getInstance().close();

				if (!tmp.hasBillAddress) {
					toaster.info('Aucune adresse de facturation renseigné');
					await ModalManager.getInstance().open('providers', tmp.infos.provider);
				}

				if (this.options.type === 'advance' && !this.options.ignoreModalAdvance) {
					this.options.advance = await new M_Advance(tmp, 'provider').open();
				}

				if (this.options.type === 'credit') {
					this.options.credit = await new M_Credit(tmp).open();
				}

				this.updateSaveButton();
			}
		} catch (e) {
			this.return();
			throw new Error('');
		}
	}

	private initEvent() {
		const N_toOrderButton = this.el.querySelector('#to-order') as HTMLButtonElement;

		N_toOrderButton.addEventListener('click', () => {
			const idOrder = this.form?.getDataByName('infos.command');
			C_OrdersProvider.open(idOrder);
		});

		const N_disputes = this.el.querySelector('#disputes') as HTMLButtonElement;

		N_disputes.addEventListener('click', () => {
			const isDispute = this.form?.getDataByName('disputes.enabled') as boolean;
			if (!isDispute) {
				Alert.prompt('Litige', 'Commentaire', {
					type: 'textarea',
					value: ' ',
					confirmColor: 'validate-modal',
					cancelColor: 'close-modal',
					cancelText: 'Fermer'
				}).then((value) => {
					this.form?.setDataByName('disputes.enabled', true);
					this.form?.setDataByName('disputes.comments', (value as string).trim());

					N_disputes!.lastElementChild!.innerHTML = 'Clôturer le litige';
					//TODO: historiser l'action de passage en litige
					this.N_NotesTab!.addLine('Mise en litige de la facture.');

					this.updateSaveButton();
				});
			} else {
				Alert.confirm('Litige', 'Retirer le litige ?', {
					yesColor: 'validate-modal',
					yesText: 'Valider',
					noColor: 'close-modal',
					noText: 'Fermer'
				}).then(() => {
					this.form?.setDataByName('disputes.enabled', false);
					//? On ne retire pas le commentaire, est-ce que c'est voulu ?

					N_disputes!.lastElementChild!.innerHTML = 'Mettre en litige';

					//TODO: historiser l'action de suppression du litige
					this.N_NotesTab!.addLine('Suppression du litige');
					this.updateSaveButton();
				});
			}
		});
	}

	private initTabs() {
		this.initPayment();
	}

	private async initPayment() {
		this.N_PaymentTab = this.el.querySelector(CE_PaymentTab.tagName) as CE_PaymentTab;

		this.N_PaymentTab.setParentElement(this.el);

		this.N_PaymentTab.setUpdateInfos(async () => {
			this.updateInfos();
		});

		this.N_PaymentTab.setCallback(async () => {
			this.updateInfos();
			await this.save();
		});

		this.N_PaymentTab.setGetID(() => {
			return this.id;
		});

		this.N_PaymentTab.setGetglobalPriceTTC(() => {
			return this.form?.getDataByName('globalPriceTTC') as string;
		});
	}

	protected convertData(data: { [key: string]: any }) {
		const types: { [key: string]: string } = {
			'credit-error': 'Avoir (Erreur)',
			credit: 'Avoir (Remise)',
			normal: 'Avancement',
			advance: 'Acompte',
			last: 'Définitive'
		};

		if (data.infos.type) {
			data.infos.type = {
				id: data.infos.type,
				text: types[data.infos.type]
			};
		}

		return data;
	}

	protected setData(data: DataServer) {
		super.setData(data);

		this.N_PaymentTab!.iniData(data.data.payment || []);

		this.updateInfos();
	}

	protected postInit(): void {
		super.postInit();
	}

	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		newData.infos.date = newData.infos.date.format('x');

		if (newData.infos.datePayment) {
			newData.infos.datePayment = newData.infos.datePayment.format('x');
		}

		if (newData.infos.receiveDate) {
			newData.infos.receiveDate = newData.infos.receiveDate.format('x');
		}

		if (newData.relunch && newData.relunch.date) {
			newData.relunch.date = newData.relunch.date.format('x');
		}

		newData.payment = this.N_PaymentTab!.data;

		return newData;
	}

	private updateDisplayWithoutOrder() {
		const order = this.form?.getDataByName('infos.command') as string;

		if (!order) {
			const N_toOrderButton = this.el.querySelector('#to-order') as HTMLButtonElement;
			const N_containerFormOrder = this.el.querySelector('#container-form-order') as HTMLElement;
			const N_containerFormIndex = this.el.querySelector('#container-form-index') as HTMLElement;

			N_toOrderButton.classList.add('d-none');

			N_containerFormOrder.classList.add('d-none');
			N_containerFormOrder.classList.remove('d-flex');
			N_containerFormIndex.classList.add('d-none');
			N_containerFormIndex.classList.remove('d-flex');
		}
	}

	private updateInfos() {
		//TODO: a mettre dans Form en disabledSave
		const N_notPaidPrice = this.el.querySelector('#not_paid_price') as HTMLElement;
		const N_paidPrice = this.el.querySelector('#paid_price') as HTMLElement;

		//On met à jour le bouton de mise en litige
		const N_disputes = this.el.querySelector('#disputes') as HTMLButtonElement;
		const isDispute = this.form?.getDataByName('disputes.enabled') as boolean;

		N_disputes!.lastElementChild!.innerHTML = isDispute ? 'Clôturer le litige' : 'Mettre en litige';

		let paidPrice = new Decimal(this.N_PaymentTab!.getPaidPrice());

		const formData = this.form?.getDataByName('globalPriceTTC') as string;

		const globalPriceTTC = Decimal.setDisplayNumber(formData || '0');

		paidPrice = paidPrice.toDecimalPlaces(2);

		const notPaid = globalPriceTTC.minus(paidPrice).toDecimalPlaces(2);

		const percent_paidPrice = paidPrice.dividedBy(globalPriceTTC).times(100);
		const percent_notPaidPrice = notPaid.dividedBy(globalPriceTTC).times(100);

		N_paidPrice.innerHTML = `${paidPrice.setSuffixAndHumanizeNumber('€')} | ${percent_paidPrice.humanizePercent()}%`;
		N_notPaidPrice.innerHTML = `${notPaid.setSuffixAndHumanizeNumber('€')} | ${percent_notPaidPrice.humanizePercent()}%`;
	}

	private async updateProvider(isFirstOpen = false) {
		const providerID = this.form?.getDataByName('infos.provider');

		if (isFirstOpen) {
			const provider = await S_Provider.getInstance().getById(providerID);
			const tva = provider.tva || Settings.getInstance().get('accountings')?.defaultTaxe?.purchase;
			const taxe = await new S_Taxes().getDataToSelect2ByID(tva);
			this.form?.setDataByName('infos.tva', taxe);
		}

		const addresses = await S_P_Address.getInstance().getByProviderToSelect2(providerID as string);

		if (addresses.length && providerID) {
			const fullAddress = await S_P_Address.getInstance().getFullAddress(addresses[0]?.id);
			this.form?.setDataByName('infos.addressID', addresses[0]);
			this.form?.setDataByName('infos.fullAddress', fullAddress);
		} else {
			this.form?.setDataByName('infos.addressID', { id: '', text: '' });
			this.form?.setDataByName('infos.fullAddress', '');
		}
	}
}

export default BillsProviderCtrl;
