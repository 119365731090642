import { Controller, Tabs } from '@autoprog/core-client';

import C_Tab from '@js/controllers/TabDatabase';

import C_Customers from '@modules/BillsCustomer/js/tabs/Customers';
import T_TabsCustomer from '@modules/BillsCustomer/tpl/tabs/customer.html';

import C_Providers from '@modules/BillsProvider/js/tabs/Providers';
import T_blockTabs from '@tpl/blocks/tabs.html';

import History from '@libs/History';

class BillsCtrl extends Controller {
	private tabs: Tabs;

	constructor(el: HTMLElement, data: any, params: any) {
		super(el);

		params = params || {};

		const defaultTab = params.tabs ? params.tabs : 'customers';

		const navElement = el.querySelector('.tabs-container') as HTMLElement;

		this.tabs = new Tabs({
			navElement,
			renderElement: el.querySelector('#page-content') as HTMLElement
		});

		this.tabs.add({
			name: 'Clients',
			id: 'customers',
			default: defaultTab === 'customers',
			permission: 'BILLS._CUSTOMERS.SHOW',
			controller: C_Customers,
			template: T_TabsCustomer
		});

		this.tabs.add({
			name: 'Fournisseurs',
			id: 'providers',
			default: defaultTab === 'providers',
			permission: 'BILLS._PROVIDERS.SHOW',
			controller: C_Tab,
			data: {
				instance: C_Providers
			},
			template: T_blockTabs
		});

		let firstLoad = true;
		this.tabs.on('change', (data: { [key: string]: any }) => {
			if (firstLoad) {
				firstLoad = false;
			} else {
				History.getInstance().pushState(`#module/bills/${data.id}`);
			}
		});
	}

	public destructor() { }
}

export default BillsCtrl;
