
import GenericService from './GenericService';
import Utils from '@libs/utils/Utils';

class NotificationsService extends GenericService {
	private static instance: NotificationsService | null = null;

	constructor() {
		super('notifications');
	}

	public static getInstance(): NotificationsService {
		if (!NotificationsService.instance) {
			NotificationsService.instance = new NotificationsService();
		}

		return NotificationsService.instance;
	}

	public getActifByUser() {
		return this.get_function('getActifByUser', { user: Utils.userID });
	}

	public getByQuote(quoteID: string) {
		return this.get_function('getByQuote', { quoteID, user: Utils.userID });
	}
}

export default NotificationsService;
