import { Form, Modal } from '@autoprog/core-client';
import CSV from '@autoprog/csv';

import _ from 'lodash';

import T_Modal from '../../tpl/modals/Export.html';

import M_PrintPreview from '@libs/modals/PrintPreview';

import CE_Select from '@libs/customElement/Select2';
import Decimal from '@libs/utils/Decimal';

import S_Stock from '@services/StockService';
import S_StockEvent from '@services/StockEventService';
import moment from 'moment';

class Export extends Modal {
	private form: Form | null = null;

	constructor(stock: string) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_stock = this.element.querySelector('[name="stock"]') as CE_Select;

			N_stock.setRef({ realStock: true });
			N_stock.create(this.element);

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			this.form.setData({
				date: Date.now(),
				stock: await S_Stock.getInstance().getDataToSelect2ByID(stock)
			});

			const N_print = this.element.querySelector('#print') as HTMLButtonElement;
			const N_downloadCSV = this.element.querySelector('#export-csv') as HTMLButtonElement;
			const N_downloadExcel = this.element.querySelector('#export-excel') as HTMLButtonElement;

			N_print.addEventListener('click', async () => {
				const data = this.form!.getData();

				data.date = data.date.valueOf();

				(await new M_PrintPreview('stock_events', '').getToken(data)).open();

				this.resolve();
			});

			N_downloadCSV.addEventListener('click', () => {
				N_downloadCSV.loading(this.exportCSV());
			});

			N_downloadExcel.addEventListener('click', () => {
				N_downloadExcel.loading(this.exportExcel());
			});
		});
	}

	private async exportCSV() {
		const data = this.form!.getData();

		data.date = data.date.valueOf();

		const stockEventsData = await S_StockEvent.getInstance().getDataToExport(data);

		for (const item of stockEventsData.arrayProduct) {
			item.name = _.deburr(item.name).replace(/;/gmi, ' ');
			item.brand = _.deburr(item.brand).replace(/;/gmi, ' ');
			item.reference = _.deburr(item.reference).replace(/;/gmi, ' ');
			item.location = _.deburr(item.location).replace(/;/gmi, ' ');
			item.quantity = Decimal.setDisplayNumber(item.quantity).toExcel();
			item.price = Decimal.setDisplayNumber(item.price).toExcel();
			item.total = Decimal.setDisplayNumber(item.total).toExcel();
			item.separator = '';
		}

		let index = 0;
		for (const brand in stockEventsData.priceByBrand) {
			stockEventsData.arrayProduct[index].brandTotalName = _.deburr(brand).replace(/;/gmi, ' ');
			stockEventsData.arrayProduct[index].brandTotalPrice = Decimal.setDisplayNumber(stockEventsData.priceByBrand[brand].value).toExcel();
			index++;
		}

		const content = CSV.stringify(stockEventsData.arrayProduct, {
			reference: 'Réference',
			brand: 'Marque',
			name: 'Désignation',
			location: 'Emplacement',
			quantity: 'Qte',
			price: 'Prix',
			total: 'Total',
			separator: '',
			brandTotalName: 'Marque',
			brandTotalPrice: 'Prix'
		}, '\r\n', ';');

		//[0xEF, 0xBB, 0xBF] = encoding UTF8
		const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), content], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);

		// Create a link to download it
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${moment(data.date as number, 'x').format('YYYY_MM_DD')}_Export_Stock.csv`);
		link.click();
	}

	private async exportExcel() {
		const data = this.form!.getData();

		data.date = data.date.valueOf();

		const stockEventsData = await S_StockEvent.getInstance().getDataToExport(data);

		const content: string[] = [];

		const productGroupByBrand = _.groupBy(stockEventsData.arrayProduct, 'brand');

		const keysBrand = Object.keys(productGroupByBrand).sort();

		const styleBorder = '0.5pt solid black;';

		for (const brand of keysBrand) {
			content.push(`
				<tr>
					<td colspan="6" style="background: #a8a8a8; border:${styleBorder}; border-right: 0px">${_.deburr(brand)}</td>
					<td style="background: #a8a8a8; border:${styleBorder};border-left: 0px">${Decimal.setDisplayNumber(stockEventsData.priceByBrand[brand].value).toExcel()}</td>
				</tr>
			`);

			let index = 0;
			for (const item of productGroupByBrand[brand]) {
				const firstCell = [`border-left: ${styleBorder}`];
				const middleCell = [''];
				const lastCell = [`border-right: ${styleBorder}`];

				if (index === 0) {
					firstCell.push(`border-top: ${styleBorder}`);
					middleCell.push(`border-top: ${styleBorder}`);
					lastCell.push(`border-top: ${styleBorder}`);
				}

				if (index === (productGroupByBrand[brand].length - 1)) {
					firstCell.push(`border-bottom: ${styleBorder}`);
					middleCell.push(`border-bottom: ${styleBorder}`);
					lastCell.push(`border-bottom: ${styleBorder}`);
				}

				content.push(`
					<tr>
						<td style="${firstCell.join('')}">${_.deburr(item.reference)}</td>
						<td style="${middleCell.join('')}">${_.deburr(item.brand)}</td>
						<td style="${middleCell.join('')}">${_.deburr(item.name)}</td>
						<td style="${middleCell.join('')}">${_.deburr(item.location)}</td>
						<td style="${middleCell.join('')}">${Decimal.setDisplayNumber(item.quantity).toExcel()}</td>
						<td style="${middleCell.join('')}">${Decimal.setDisplayNumber(item.price).toExcel()}</td>
						<td style="${lastCell.join('')}">${Decimal.setDisplayNumber(item.total).toExcel()}</td>
					</tr>
				`);
				index++;
			}
		}

		const result = `
			<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">'
				<head>
					<meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">
					<!--[if gte mso 9]>
						<xml>
							<x:ExcelWorkbook>
								<x:ExcelWorksheets>
									<x:ExcelWorksheet>
										<x:Name>Stock</x:Name>
										<x:WorksheetOptions>
											<x:DisplayGridlines/>
										</x:WorksheetOptions>
									</x:ExcelWorksheet>
								</x:ExcelWorksheets>
							</x:ExcelWorkbook>
						</xml>
						<![endif]-->
				</head>
				<body>
					<table>
						<thead>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder} border-left: ${styleBorder}">Référence</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder}">Marque</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder}">Désignation</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder}">Emplacement</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder}">Qte</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder}">Prix</th>
							<th style="border-top:${styleBorder} border-bottom: ${styleBorder} border-right: ${styleBorder}">Total</th>
						</thead>
						<tbody>
							${content.join('')}
						</tbody>
					</table>
				</body>
			</html>
		`;

		const link = document.createElement('a');
		//[0xEF, 0xBB, 0xBF] = encoding UTF8
		const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), result], { type: 'data:application/vnd.ms-excel,' });
		const url = URL.createObjectURL(blob);
		link.href = url;
		link.download = `${moment(data.date as number, 'x').format('YYYY_MM_DD')}_Export_Stock.xls`;
		link.click();
	}
}

export default Export;
