import '@css/customElements/alert.scss';

class Alert extends HTMLElement {
	public static readonly tagName: string = 'ap-alert';

	public connectedCallback() {
		this.innerHTML = `
			<div class="content">
				${this.innerHTML}
			</div>
		`;

		const size = this.firstElementChild?.clientHeight;

		this.setAttribute('style', `--calc-height:${size}px`);
	}

	public toggle(value?: boolean) {
		this.classList.toggle('visible', value);
	}

	public static register() {
		customElements.define(Alert.tagName, Alert);
	}
}

export default Alert;
