import { Alert, LoggedUser, Router, toaster, utils } from '@autoprog/core-client';

import { Moment } from 'moment';

import OpenDocuments from '@libs/customElement/OpenDocuments';

import ControllerPageID, { DataServer } from '@js/controllers/ControllerPageID';

import BillTab, { updateFormEvent as updateBillData } from '@modules/BillsCustomer/js/customElements/BillsCustomersTab';
import DeliveryTab from '@modules/Deliveries/js/customElements/DeliveriesTab';
import ProviderOrderTab from '@modules/OrdersProvider/js/customElements/ProviderOrderTabs';

import QuotesTab, { updateFormEvent as updateQuoteData } from '../customElements/QuotesTab';
import BillingRequestTab from '../customElements/BillingRequestTab';
import ComptaTab from '../../../Comptabilité/js/customElements/ComptaTab';
import MaterialsTab from '../customElements/MaterialsTab';
import OutputStockTab from '../customElements/OutputStockTab';

import Decimal from '@libs/utils/Decimal';
import Loader from '@libs/Loader';
import Notifications from '@modules/Apps/js/libs/Notifications';

import M_AdditionalInformation from '../modals/editPage/AdditionalInformation';
import M_DetailsOrder from '../modals/editPage/DetailsOrder';
import M_EditQuote from '../modals/EditQuote';
import M_GeneralInformation from '../modals/editPage/GeneralInformation';
import M_SearchOrder from '../modals/SearchOrder';
import M_SelectQuote from '../modals/SelectQuote';

import M_PrintPreview from '@js/libs/modals/PrintPreview';

import S_C_Address from '@services/Customer/CustomerAddressService';
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';

import '../../css/pageControllerID.scss';
import PriceWithPercentModel from '@js/libs/model/_app/PriceWithPercent';

class CommandCustomerCtrl extends ControllerPageID {
	private N_BillTab: BillTab | null = null;
	private N_ProviderOrderTab: ProviderOrderTab | null = null;
	private N_DeliveryTab: DeliveryTab | null = null;
	private N_BillingRequestTab: BillingRequestTab | null = null;
	private N_MaterialsTab: MaterialsTab | null = null;
	private N_QuotesTab: QuotesTab | null = null;
	private N_OutputStockTab: OutputStockTab | null = null;

	private N_ComptaTab: ComptaTab | null = null;

	private price: { priceHT: Decimal, bill: Decimal, credit: Decimal, billingRequest: Decimal };

	constructor(el: HTMLElement) {
		super(el);

		const query = utils.getQuery();
		const id = query.id || '';

		this.price = {
			priceHT: new Decimal(0),
			bill: new Decimal(0),
			credit: new Decimal(0),
			billingRequest: new Decimal(0)
		};

		this.options = CommandCustomerCtrl.options || {};

		CommandCustomerCtrl.options = {};

		this.routeReturn = 'module/orders/customers';

		this.init('commands-customer', id);
	}

	private static options: { [key: string]: any } = {};
	public static async open(id: string | null, options: { [key: string]: any } = {}) {
		CommandCustomerCtrl.options = options || {};

		if (id) {
			await OpenDocuments.checkOpen(id, 'commands-customer');
			Router.getInstance().navigate(`/module/ordersPage/customer?id=${id}`);
		} else {
			Router.getInstance().navigate('/module/ordersPage/customer');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		this.initTabs();
		this.initEditButton();

		const data = await this.getData();

		this.setData(data);

		this.postInit();

		this.initFullscreen();

		this.updateEditButton();

		if (!this.id) {
			this.openSearchOrder(data.data.quoteNumber || '');
		} else {
			if (this.options.idQuote) {
				this.openAddQuote();
			}
		}
	}

	private openSearchOrder(quoteNumber: string) {
		const res = {
			quoteID: this.options.idQuote,
			quoteNumber,
			infosCustomer: {
				orderNumber: this.form?.getDataByName('infosCustomer.orderNumber') as string,
				addonNumber: this.form?.getDataByName('infosCustomer.addonNumber') as string,
				customer: this.form?.getDataByName('infosCustomer.customer') as string,
				contact: this.form?.getDataByName('infosCustomer.contact') as string
			},
			manager: this.form?.getDataByName('manager') as string
		};

		new M_SearchOrder(res).open().then(async ({ type, data, id }) => {
			if (type === 'close') {
				CommandCustomerCtrl.open(id, { idQuote: this.options.idQuote });
			} else {
				this.setDataForm(data);

				await this.updateDeadlinePayment();
				await this.updateAddress();

				this.openDetailsOrder(true);
			}
		}).catch(() => {
			this.return();
		});
	}

	private openGeneralInformation() {
		const res = {
			infosCustomer: {
				orderNumber: this.form?.getDataByName('infosCustomer.orderNumber') as string,
				addonNumber: this.form?.getDataByName('infosCustomer.addonNumber') as string,
				customer: this.form?.getDataByName('infosCustomer.customer') as string,
				contact: this.form?.getDataByName('infosCustomer.contact') as string
			},
			manager: this.form?.getDataByName('manager') as string
		};

		new M_GeneralInformation(res).open().then(async (data) => {
			this.setDataForm(data);

			if (res.infosCustomer.customer !== data.infosCustomer.customer.id) {
				await this.updateAddress();
			}

			this.updateTitle();
			this.updateSaveButton();
		});
	}

	private openDetailsOrder(isFirstOpen: boolean = false) {
		const res = {
			label: this.form?.getDataByName('label') as string,
			description: this.form?.getDataByName('description') as string
		};

		const modal = new M_DetailsOrder(res);

		if (isFirstOpen) {
			modal.setNextCallback((data) => {
				this.setDataForm(data);
				this.openAdditionalInformation(isFirstOpen);
			});
		}

		modal.open().then((data) => {
			this.setDataForm(data);

			if (!isFirstOpen) {
				this.updateSaveButton();
			}
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private openAdditionalInformation(isFirstOpen: boolean = false) {
		const res = {
			infosCustomer: {
				customer: this.form?.getDataByName('infosCustomer.customer') as string
			},
			autoliquidation: this.form?.getDataByName('autoliquidation') as boolean,
			billAddress: {
				id: this.form?.getDataByName('billAddress.id') as string,
				text: this.form?.getDataByName('billAddress.text') as string
			},
			billDeliveryAddress: {
				id: this.form?.getDataByName('billDeliveryAddress.id') as string,
				text: this.form?.getDataByName('billDeliveryAddress.text') as string
			},
			deadlinePayment: {
				day: this.form?.getDataByName('deadlinePayment.day') as number,
				type: this.form?.getDataByName('deadlinePayment.type') as string,
				fixedDate: this.form?.getDataByName('deadlinePayment.fixedDate') as Moment
			},
			finalCustomer: this.form?.getDataByName('finalCustomer') as string,
			detailsBill: this.form?.getDataByName('detailsBill') as string,
			comment: this.form?.getDataByName('comment') as string
		};

		let modal: M_AdditionalInformation;

		if (isFirstOpen) {
			modal = new M_AdditionalInformation(res).setPreviousCallback(() => {
				this.openDetailsOrder(isFirstOpen);
			});

			if (this.options.idQuote) {
				modal.setNextCallback((data) => {
					this.setDataForm(data);
					this.openAddQuote();
				});
			}
		} else {
			modal = new M_AdditionalInformation(res);
		}

		modal.open().then((data) => {
			this.setDataForm(data);

			this.updateTitle();

			if (!isFirstOpen || !this.options?.idQuote) {
				this.updateSaveButton();
			}
		}).catch((shouldClose = true) => {
			if (isFirstOpen && shouldClose) {
				this.return();
			}
		});
	}

	private openAddQuote() {
		new M_EditQuote(this.form?.getDataByName('infosCustomer.customer')).setAddData(this.options.idQuote).open().then(async (data) => {
			//si on a un id, on ne recreer pas la CC
			if (!this.id) {
				//sauvegarde de la CC
				await this.save(true);
			}
			//ajout du devis a la CC
			await this.service?.save(data, { idOrder: this.id, type: 'updateQuote', isNewOrder: true });
			//definition de l'url
			this.setUrl();
			//refresh de la page
			delete this.options.idQuote;
			this.reload();
		});
	}

	private initEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsOrder = this.el.querySelector('[data-edit="detailsOrder"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.addEventListener('click', () => {
			this.openGeneralInformation();
		});

		N_edit_DetailsOrder.addEventListener('click', () => {
			this.openDetailsOrder();
		});

		N_edit_AdditionalInformation.addEventListener('click', () => {
			this.openAdditionalInformation();
		});
	}

	protected initButton() {
		super.initButton();

		const N_cancel = this.el.querySelector('#cancel') as HTMLElement;
		const N_report = this.el.querySelector('#report') as HTMLDivElement;

		N_report.addEventListener('click', async () => {
			const quotes = this.N_QuotesTab?.data;

			if (quotes.length > 1) {
				const res = {
					quotes
				};

				new M_SelectQuote(res).open().then(async (data) => {
					(await new M_PrintPreview('commands-customer', this.id, 'pdfReport').getToken(data)).open();
				});
			} else {
				const ids = quotes.map((item: any) => item.quoteID);
				(await new M_PrintPreview('commands-customer', this.id, 'pdfReport').getToken(ids)).open();
			}
		});

		const title = '<i class="icon icon-warning text-danger"></i> Annulation de la commande <i class="icon icon-warning text-danger"></i>';
		const content = '<span class="font-weight-bold">Attention</span>, cette opération va réinitialiser le stock et les devis associés à la commande seront refusés.<br><br>Voulez-vous continuez ?';

		N_cancel.addEventListener('click', () => {
			Alert.confirm(title, content).then(async () => {
				Loader.getInstance().open();
				await this.save(true);

				await S_C_Order.getInstance().cancelOrder(this.id);

				toaster.success(`Commande N° ${this.form?.getDataByName('infos.number')} annulée`);

				Loader.getInstance().close();

				this.return();
			}).catch(() => {
				Loader.getInstance().close();
			});
		});

		const N_closeNotification = this.el.querySelector('#close_notification') as HTMLButtonElement;

		if (this.options.notification) {
			(N_closeNotification.parentNode as HTMLElement).classList.remove('d-none');
		}

		N_closeNotification.addEventListener('click', async () => {
			await Notifications.closeNotification(this.options.notification);
			this.return();
		});
	}

	private initTabs() {
		this.N_ProviderOrderTab = this.el.querySelector(ProviderOrderTab.tagName) as ProviderOrderTab;
		this.N_ProviderOrderTab.setParentElement(this.el);

		this.N_BillTab = this.el.querySelector(BillTab.tagName) as BillTab;
		this.N_BillTab.setParentElement(this.el);

		this.N_BillTab.addEventListener('update.form', ((e: CustomEvent<updateBillData>) => {
			this.price.bill = e.detail.priceHT;
			this.price.credit = e.detail.priceCredit;

			this.form?.setDataByName('paymentPrice', e.detail.paymentPrice);
			this.form?.setDataByName('notPaymentPrice', e.detail.notPaymentPrice);

			this.updatePrice();
		}) as EventListener);

		this.N_BillTab.addEventListener('load', ((e: CustomEvent<{ number: number }>) => {
			const N_cancel = this.el.querySelector('#cancel') as HTMLElement;

			if (e.detail.number !== 0) {
				N_cancel.classList.add('disabled');
			} else {
				N_cancel.classList.add('cursor-pointer');
			}
		}) as EventListener);

		this.N_DeliveryTab = this.el.querySelector(DeliveryTab.tagName) as DeliveryTab;
		this.N_DeliveryTab.setParentElement(this.el);

		this.N_BillingRequestTab = this.el.querySelector(BillingRequestTab.tagName) as BillingRequestTab;
		this.N_BillingRequestTab.setParentElement(this.el);

		this.N_BillingRequestTab.addEventListener('load', ((e: CustomEvent<{ sum: Decimal }>) => {
			this.price.billingRequest = e.detail.sum;

			this.updatePrice();
		}) as EventListener);

		this.initProducts();
		this.initQuotes();
		this.initCompta();
	}

	private initProducts() {
		this.N_MaterialsTab = this.el.querySelector(MaterialsTab.tagName) as MaterialsTab;

		this.N_MaterialsTab.setParentElement(this.el);

		this.N_MaterialsTab.addEventListener('update', () => {
			this.updateSaveButton();
		});

		this.N_OutputStockTab = this.el.querySelector(OutputStockTab.tagName) as OutputStockTab;

		this.N_OutputStockTab.setParentElement(this.el);
	}

	private initQuotes() {
		this.N_QuotesTab = this.el.querySelector(QuotesTab.tagName) as QuotesTab;

		this.N_QuotesTab.setParentElement(this.el);

		this.N_QuotesTab.setCustomer(() => {
			return this.form?.getDataByName('infosCustomer.customer');
		});

		this.N_QuotesTab.addEventListener('update.form', ((e: CustomEvent<updateQuoteData>) => {
			this.price.priceHT = e.detail.price;

			this.updatePrice();
		}) as EventListener);

		this.N_QuotesTab.addEventListener('refresh', () => {
			this.reload();
		});

		this.N_QuotesTab.setCheckDelete((quote) => {
			return new Promise<void>((resolve, reject) => {
				//si la commande n'est pas sauvegardé
				if (!this.isSave) {
					reject(new Error('Veuillez sauvegarder la commande'));
				}

				//si le montant non facturé > montant du devis
				const notBill = this.N_QuotesTab!.sum.minus(this.N_BillTab!.sum);
				if (notBill.toNumber() < quote.price) {
					reject(new Error('Le montant à facturer est inférieur au montant du devis'));
				}

				const billingRequests = this.N_BillingRequestTab?.data || [];

				let find = false;
				for (const br of billingRequests) {
					if (br.state === 'waiting' && !br.deleted && !find) {
						find = true;
						break;
					}
				}

				//si il y a des demandes de facturation en attente
				if (find) {
					reject(new Error('Veuillez terminer ou supprimer toutes les demandes de facturation en cours'));
				} else {
					resolve();
				}
			});
		});
	}

	private async initCompta() {
		this.N_ComptaTab = this.el.querySelector(ComptaTab.tagName) as ComptaTab;
		this.N_ComptaTab.setParentElement(this.el);
	}

	private get isLock() {
		const finish = this.form?.getDataByName('infos.finish');
		return finish || !LoggedUser.getInstance().hasPermission('ORDERS._CUSTOMERS.EDIT');
	}

	protected setDataForm(data: { [key: string]: any; }) {
		if (data.deliveries?.state) {
			const stateDeliveries: { [key: string]: any } = {
				0: 'À préparer',
				1: 'En cours de préparation',
				2: 'Prête partiellement',
				3: 'Prête en totalité',
				4: 'Expédiée partiellement',
				5: 'Expédiée en totalité'
			};

			data.deliveries.state = stateDeliveries[data.deliveries?.state];
		}

		super.setDataForm(data);

		//FIXME:
		/*
		const N_sites = this.el.querySelector(CE_SitesFinalCustomerReadonly.tagName) as CE_SitesFinalCustomerReadonly;
		N_sites.update(data.infos.hasFinalCustomer);
		*/
	}

	protected setData(data: DataServer) {
		super.setData(data);

		this.N_MaterialsTab!.data = data.data.content;
		this.N_QuotesTab!.data = data.data.orders;

		//this.N_ComptaTab!.data = data.compta.data || [];

		this.updateAmount();

		const N_report = this.el.querySelector('#report') as HTMLElement;
		N_report.classList.remove('disabled');

		/*const N_comptaErrorAlert = this.el.querySelector('#comptaError') as HTMLDivElement;

		if (data.compta.error) {
			N_comptaErrorAlert.classList.remove('d-none');
		} else {
			N_comptaErrorAlert.classList.add('d-none');
		}*/

		this.N_BillTab?.initData();
		this.N_ProviderOrderTab?.initData();
		this.N_OutputStockTab?.initData();
		this.N_DeliveryTab?.initData();
		this.N_BillingRequestTab?.setMode('order').initData();
	}

	private updateAmount() {
		/*const sumBillTab = this.N_BillTab!.sum;

		const data = this.N_BillingRequestTab!.data;

		let priceBillingRequest = new Decimal(0);

		const globalPrice = Decimal.setDisplayNumber(this.form?.getDataByName('infos.price') as string);

		const priceNotBill = this.notBill.minus(sumBillTab.price);

		for (const item of data) {
			if (item.state === 'waiting') {
				if (item.type === 'last') {
					priceBillingRequest = priceNotBill;
					break;
				} else {
					priceBillingRequest = priceBillingRequest.plus(Decimal.setDisplayNumber(item.price));
				}
			}
		}

		const percentBillingRequest = priceBillingRequest.times(100).dividedBy(globalPrice);
		const percentPriceNotBill = priceNotBill.times(100).dividedBy(globalPrice);

		const percentCredit = sumBillTab.credit.times(100).dividedBy(globalPrice);
		const percentPrice = sumBillTab.price.times(100).dividedBy(globalPrice);

		this.form?.setData({
			price: {
				credit: `${sumBillTab.credit.setSuffixAndHumanizeNumber('€')} | ${percentCredit.humanizePercent()}%`,
				price: `${sumBillTab.price.setSuffixAndHumanizeNumber('€')} | ${percentPrice.humanizePercent()}%`,
				billingRequest: `${priceBillingRequest.setSuffixAndHumanizeNumber('€')} | ${percentBillingRequest.humanizePercent()}%`,
				notBill: `${priceNotBill.setSuffixAndHumanizeNumber('€')} | ${percentPriceNotBill.humanizePercent()}%`
			}
		});*/
	}

	protected getPageData(newData: { [key: string]: any }): { [key: string]: any } {
		newData.content = this.N_MaterialsTab!.data || [];

		return newData;
	}

	protected updateSaveButton() {
		super.updateSaveButton();

		const N_report = this.el.querySelector('#report') as HTMLElement;

		N_report.classList.add('disabled');
	}

	private async updateAddress() {
		this.form?.setDataByName('infos.addressID', '');
		this.form?.setDataByName('infos.fullAddress', '');

		this.form?.setDataByName('infos.addressIDDelivery', '');
		this.form?.setDataByName('infos.fullAddressDelivery', '');

		const customer = this.form?.getDataByName('infos.customer') as string;

		if (customer) {
			const customerBillAddressList = await S_C_Address.getInstance().getBillAddressByCustomer(customer) || [];

			if (customerBillAddressList[0]) {
				const id = customerBillAddressList[0]._id as string;

				const addressIDCustomer = await S_C_Address.getInstance().getDataToSelect2ByID(id);
				const addressInfos = await S_C_Address.getInstance().getAddressInfos(id);

				this.form?.setDataByName('infos.addressID', addressIDCustomer);
				this.form?.setDataByName('infos.fullAddress', addressInfos.fullAddress);
			}
		}
	}

	private updatePrice() {
		const notBill = this.price.priceHT.plus(this.price.credit).minus(this.price.bill);

		const notBillTxt = PriceWithPercentModel.calculAndConvertToModel(notBill, this.price.priceHT).getText();
		const billTxt = PriceWithPercentModel.calculAndConvertToModel(this.price.bill, this.price.priceHT).getText();
		const creditTxt = PriceWithPercentModel.calculAndConvertToModel(this.price.credit, this.price.priceHT).getText();
		const billingRequestTxt = PriceWithPercentModel.calculAndConvertToModel(this.price.billingRequest, this.price.priceHT).getText();

		this.form?.setDataByName('billingRequest.priceHT', billingRequestTxt);

		this.form?.setDataByName('priceHT', this.price.priceHT);
		this.form?.setDataByName('bill.notBill', notBillTxt);
		this.form?.setDataByName('bill.priceHT', billTxt);
		this.form?.setDataByName('bill.priceCredit', creditTxt);

		const N_nonValoriseeAlert = this.el.querySelector<HTMLDivElement>('#nonValorisee')!;

		if (this.price.priceHT.equals(0) && this.N_QuotesTab!.data.length === 0) {
			N_nonValoriseeAlert.classList.remove('d-none');
		} else {
			N_nonValoriseeAlert.classList.add('d-none');
		}
	}

	private async updateDeadlinePayment() {
		const id_customer = this.form?.getDataByName('infosCustomer.customer') as string;

		const customer = await S_Customer.getInstance().getById(id_customer);

		if (customer) {
			this.form!.setDataByName('deadlinePayment.day', customer.deadlinePayment);
			this.form!.setDataByName('deadlinePayment.type', customer.typeDeadlinePayment);
			this.form!.setDataByName('deadlinePayment.fixedDate', customer.fixedDateDeadline);
		}
	}

	private updateEditButton() {
		const N_edit_GeneralInformation = this.el.querySelector('[data-edit="generalInformation"]') as HTMLButtonElement;
		const N_edit_DetailsOrder = this.el.querySelector('[data-edit="detailsOrder"]') as HTMLButtonElement;
		const N_edit_AdditionalInformation = this.el.querySelector('[data-edit="additionalInformation"]') as HTMLButtonElement;

		N_edit_GeneralInformation.disabled = this.isLock;
		N_edit_DetailsOrder.disabled = this.isLock;
		N_edit_AdditionalInformation.disabled = this.isLock;
	}
}

export default CommandCustomerCtrl;
