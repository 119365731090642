import CSV from '@autoprog/csv';

import { GridOptions } from '@ag-grid-enterprise/all-modules';

import ConfigManager from '@libs/ConfigManager';
import ExportUtils from '../utils/ExportUtils';
import _ from 'lodash';

class Export {
	protected _data: Record<string, any>[];
	protected _header: Record<string, string>;
	protected _tableName: string;
	protected _includedHeader: string[];

	constructor() {
		this._data = [];
		this._header = {};
		this._tableName = '';
		this._includedHeader = [];
		return this;
	}

	public included(fields: string[]) {
		this._includedHeader = fields;
		return this;
	}

	public fromTableName(table: string) {
		this._tableName = table;
		return this;
	}

	/**
	 * Récupère les entêtes de colonnes et les données à exporter à partir du tableau ag-grid
	 * @param gridOptions les paramètres de l'ag-grid
	 * @returns
	 */
	public fromGridOptions(gridOptions: GridOptions) {
		this._data = [];
		this._header = {};

		//On récupère les définitions des colonnes du tableau dans le fichier de configuration
		const colDefinitons = ConfigManager.getInstance().getConfig(this._tableName).columns as Array<any>;

		//On filtre les défintions de colonnes sur les colonnes à inclure
		const filteredColDefinitions: any[] = [];

		for (const colDef of colDefinitons) {
			if (this._includedHeader.length === 0) {
				filteredColDefinitions.push(colDef);
			} else {
				const index = this._includedHeader.indexOf(colDef.key);

				if (index !== -1) {
					filteredColDefinitions[index] = colDef;
				}
			}
		}

		//On parcourt les lignes à exporter et on récupère la valeur de chaque colonnes à exporter dans le csv
		gridOptions.api?.forEachNodeAfterFilterAndSort(node => {
			const row = {} as Record<string, any>;

			// Certaines valeurs sont de types differents (ex: Decimal, Date ou Boolean, ...)
			for (const colDef of filteredColDefinitions) {
				row[colDef.key] = ExportUtils.convertTo(_.get(node.data, colDef.key), colDef);
			}

			this._data.push(row);
		});

		// On construit l'objet headers pour l'export csv
		for (const colDef of filteredColDefinitions) {
			this._header[colDef.key] = colDef.name;
		}

		return this;
	}

	public fromGridOptionsNewDashboard(gridOptions: GridOptions, colDefinitons: any[]) {
		this._data = [];
		this._header = {};

		//On filtre les défintions de colonnes sur les colonnes à inclure
		const filteredColDefinitions: any[] = [];

		for (const colDef of colDefinitons) {
			if (this._includedHeader.length === 0) {
				filteredColDefinitions.push(colDef);
			} else {
				const index = this._includedHeader.indexOf(colDef.key);

				if (index !== -1) {
					filteredColDefinitions[index] = colDef;
				}
			}
		}

		//On parcourt les lignes à exporter et on récupère la valeur de chaque colonnes à exporter dans le csv
		gridOptions.api?.forEachNodeAfterFilterAndSort(node => {
			const row = {} as Record<string, any>;

			// Certaines valeurs sont de types differents (ex: Decimal, Date ou Boolean, ...)
			for (const colDef of filteredColDefinitions) {
				row[colDef.key] = _.get(node.data, colDef.key).export;
			}

			this._data.push(row);
		});

		// On construit l'objet headers pour l'export csv
		for (const colDef of filteredColDefinitions) {
			this._header[colDef.key] = colDef.name;
		}

		return this;
	}

	public createCSV() {
		const csv = CSV.stringify(this._data, this._header, undefined, ';');
		return csv;
	}
}

export default Export;
