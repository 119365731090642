import TaxesService from '../services/TaxesService';

import Decimal from '@libs/utils/Decimal';

class CalculTaxe {
	private taxeService: TaxesService;

	private id: string;
	private rate: number;

	private static cache: { [key: string]: number } = {};

	private static getCache(id: string) {
		const value = CalculTaxe.cache[id];
		CalculTaxe.destroyCache();
		return value;
	}

	private static setCache(id: string, value: number) {
		CalculTaxe.cache[id] = value;
		CalculTaxe.destroyCache();
	}

	private static debounce: any;
	private static destroyCache() {
		clearTimeout(CalculTaxe.debounce);
		CalculTaxe.debounce = setTimeout(() => {
			CalculTaxe.cache = {};
		}, 10 * 60000);
	}

	constructor(id: string) {
		this.id = id;
		this.rate = -1;

		this.taxeService = new TaxesService();
	}

	public async init() {
		if (!CalculTaxe.getCache(this.id)) {
			const data = await this.taxeService.getById(this.id);

			if (data) {
				this.rate = data.rate;
				CalculTaxe.setCache(this.id, this.rate);
			}
		} else {
			this.rate = CalculTaxe.getCache(this.id);
		}
	}

	public getID() {
		return this.id;
	}

	public calculatePriceTVAByHT(priceHT: Decimal) {
		const tvaRate = this.rate;

		return priceHT.times(tvaRate / 100).toDecimalPlaces(2);
	}

	public calculatePriceTVAByTTC(priceTTC: Decimal) {
		const tvaRate = this.rate;

		return priceTTC.dividedBy(100 + tvaRate).times(tvaRate).toDecimalPlaces(2);
	}

	public calculatePriceTTCByHT(priceHT: Decimal) {
		const tvaRate = this.rate;

		return priceHT.times(1 + tvaRate / 100).toDecimalPlaces(2);
	}

	public calculatePriceHTByTTC(priceTTC: Decimal) {
		const tvaRate = this.rate;

		return priceTTC.dividedBy(1 + tvaRate / 100).toDecimalPlaces(2);
	}
}

export default CalculTaxe;
