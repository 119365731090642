// TEMPLATE
import T_modal from '../../../tpl/modals/editPage/amounts.html';

// LIBS
import CE_Select from '@libs/customElement/Select';
import EditPageModal from '@libs/EditPageModal';

import CalculTaxe from '@modules/Comptabilité/js/libs/CalculTaxe';

import S_Taxes from '@modules/Comptabilité/js/services/TaxesService';

// UTILS
import Decimal from '@libs/utils/Decimal';

type AmountsData = {
	infos: {
		tva: string
	},
	globalPrice: string,
	tva: string,
	globalPriceTTC: string
};

class Amounts extends EditPageModal<AmountsData> {
	constructor(data: AmountsData) {
		super(data, T_modal);
	}

	protected preInit() {
		const N_select_tva = this.element.querySelector<CE_Select>('[name="infos.tva"]')!;

		N_select_tva.options = {
			ajax: {
				url: new S_Taxes().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ type: 'purchase' })
					};
				}
			}
		};
	}

	protected initEventButton() {
		super.initEventButton();

		const N_globalPrice = this.element.querySelector<HTMLInputElement>('[name="globalPrice"]')!;
		const N_globalPriceTTC = this.element.querySelector<HTMLInputElement>('[name="globalPriceTTC"]')!;
		const N_tvaPercent = this.element.querySelector<CE_Select>('[name="infos.tva"]')!;
		const N_tva = this.element.querySelector<HTMLInputElement>('[name="tva"]')!;
		const N_button_edit_tva = this.element.querySelector<HTMLButtonElement>('#editTva')!;

		//Les calculs de prix sont basées sur le site suivant : https://www.l-expert-comptable.com/calculateurs/calcul-tva.html
		//Pour vérifier que les montants sont bons : https://entreprendre.service-public.fr/simulateur/calcul/convertisseurPrixHTouTTC

		N_globalPrice.addEventListener('input', async () => {
			const priceHT = Decimal.setDisplayNumber(this.form?.getDataByName('globalPrice') as string);

			const tva = new CalculTaxe(N_tvaPercent.value as string);
			await tva.init();

			this.form?.setData({
				tva: tva.calculatePriceTVAByHT(priceHT).toDecimalPlaces(2).humanizeNumber(),
				globalPriceTTC: tva.calculatePriceTTCByHT(priceHT).toDecimalPlaces(2).humanizeNumber()
			});
		});

		N_globalPriceTTC.addEventListener('input', async () => {
			const priceTTC = Decimal.setDisplayNumber(this.form?.getDataByName('globalPriceTTC') as string);

			const tva = new CalculTaxe(N_tvaPercent.value as string);
			await tva.init();

			this.form?.setData({
				tva: tva.calculatePriceTVAByTTC(priceTTC).toDecimalPlaces(2).humanizeNumber(),
				globalPrice: tva.calculatePriceHTByTTC(priceTTC).toDecimalPlaces(2).humanizeNumber()
			});
		});

		N_tvaPercent.addEventListener('change', async () => {
			const priceHT = Decimal.setDisplayNumber(this.form?.getDataByName('globalPrice') as string);

			const tva = new CalculTaxe(N_tvaPercent.value as string);
			await tva.init();

			this.form?.setData({
				tva: tva.calculatePriceTVAByHT(priceHT).toDecimalPlaces(2).humanizeNumber(),
				globalPriceTTC: tva.calculatePriceTTCByHT(priceHT).toDecimalPlaces(2).humanizeNumber()
			});
		});

		N_tva.addEventListener('input', () => {
			const price = Decimal.setDisplayNumber(this.form?.getDataByName('globalPrice') as string);
			const tva = Decimal.setDisplayNumber(this.form?.getDataByName('tva') as string);

			this.form?.setDataByName('globalPriceTTC', price.plus(tva).toDecimalPlaces(2).humanizeNumber());
		});

		N_button_edit_tva.addEventListener('change', () => {
			if (N_tva.getAttribute('readonly') !== null) {
				N_tva.removeAttribute('readonly');
			} else {
				N_tva.setAttribute('readonly', '');
			}
		});
	}

	protected async setData(data: AmountsData) {
		const res: { [key: string]: any } = {
			infos: {
				tva: await new S_Taxes().getDataToSelect2ByID(data.infos.tva)
			},
			globalPrice: data.globalPrice,
			tva: data.tva,
			globalPriceTTC: data.globalPriceTTC
		};

		this._setFormData(res);
	}
}

export default Amounts;
