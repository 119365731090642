import { global } from '@autoprog/core-client';

import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import agUtils from '@libs/agGrid/french';

import S_File from '@services/FileService';
import S_Users from '@js/services/User/UserService';

import _ from 'lodash';
import moment from 'moment';

class FilesTab extends HTMLElement {
	public static readonly tagName: string = 'ap-files-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private _gridOptions: GridOptions = {};

	private fileData: { [key: string]: any } = {};
	private users: { [key: string]: any }[] = [];

	private N_el: HTMLElement | null = null;

	private callback: () => void = () => { };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || 'files';

		const type = this.getAttribute('type');

		if (type === 'page') {
			if (global.IS_MOBILE) {
				this.innerHTML = `
					<div class="d-flex page-tab-mobile bg-white p-3" data-type="open-mobile" href="${this.idTab}">
						<div tooltip="Fichiers">
							<ap-icon name="file/line"></ap-icon>
						</div>
						<span>Fichiers</span> 
						<span class="badge badge-pill" id="number">0</span>
						<div class="ml-auto">
							<div data-type="open-mobile-icon"></div>
						</div>
					</div>
				`;
			} else {
				this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="file/line" icon-active="file/fill" text="Fichier" hasNumber></ap-page-tabs-menu-item>`;

				const N_item = this.querySelector('ap-page-tabs-menu-item');

				N_item?.addEventListener('open', () => {
					this.gridOptions.api?.sizeColumnsToFit();
				});
			}
		} else {
			this.innerHTML = `
				<li class="nav-item border-right">
					<a class="nav-link px-3 position-relative" data-toggle="tab" href="#${this.idTab}" role="tab">
						Fichiers
						<span style="top: 5px;right: -2px;" class="badge badge-pill position-absolute" id="number">0</span>
					</a>
				</li>
			`;
		}

		this.removeAttribute('type');
		this.removeAttribute('id');
	}

	public postInit() {
		$('[data-toggle="tab"]').on('show.bs.tab', (e) => {
			if (e.target.getAttribute('href') === `#${this.idTab}`) {
				this.gridOptions.api?.sizeColumnsToFit();
			} else {
				const N_li = this.querySelector('[data-toggle="tab"]') as HTMLElement;

				N_li.classList.remove('active');
			}
		});
	}

	public async setParentElement(parent: HTMLElement) {
		this.N_el = document.createElement('div');
		this.N_el.classList.add('tab-pane', 'page-tabs-container');

		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Fichiers
				<div class="page-tabs-title-right">
					<div class="input-group">
						<input type="file" class="add form-control">
						<div class="input-group-append d-none" id="spinner">
							<span class="input-group-text">
								<i class="icon icon-solid-spinner icon-spin"></i>
							</span>
						</div>
					</div>
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<div class="page-card bg-white p-3" display="mobile">
					<div class="input-group">
						<input type="file" class="add form-control">
						<div class="input-group-append d-none" id="spinner">
							<span class="input-group-text">
								<i class="icon icon-solid-spinner icon-spin"></i>
							</span>
						</div>
					</div>
				</div>
                <div class="h-100 ag-theme-alpine grid w-100" display="desktop"></div>
                <div class="page-card bg-white p-3 grid" display="mobile"></div>
			</div>
        `;

		if (!global.IS_MOBILE) {
			const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
			N_container.appendChild(this.N_el);
			this.initGrid();
		} else {
			this.appendChild(this.N_el);
		}

		this.initEventInput();
	}

	private async initGrid() {
		this._gridOptions = agUtils.french<GridOptions>({
			localeText: { noRowsToShow: 'Aucun Fichier' },
			suppressContextMenu: true,
			animateRows: true,
			rowData: [],
			columnDefs: [
				{
					headerName: 'Nom',
					field: 'name'
				}, {
					headerName: 'Personne',
					field: 'user',
					cellRenderer: (params) => {
						const user = _.find(this.users, { _id: params.value }) as any;
						if (user) {
							return `${user.lastname} ${user.firstname}`;
						}
						return '--';
					}
				}, {
					headerName: 'Date',
					field: 'date',
					sort: 'asc',
					cellRenderer: (params) => {
						if (params.value) {
							return moment(params.value, 'x').format('DD/MM/YYYY HH:mm');
						}
						return '--';
					}
				}, {
					headerName: 'Action',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					field: '_id',
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						const N_div = document.createElement('div');

						const N_delete = document.createElement('button');

						N_delete.classList.add('h-100', 'py-0', 'btn-transparent');

						N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
						N_delete.setAttribute('confirmation', '');
						N_delete.setAttribute('tooltip', 'Supprimer');

						N_delete.type = 'button';

						N_delete.addEventListener('click', () => {
							this.fileData[params.value] = false;

							params.api?.updateRowData({
								remove: [params.data]
							});

							this.callback();
						});

						const N_view = document.createElement('button');
						N_view.classList.add('h-100', 'py-0', 'btn-transparent');
						N_view.setAttribute('tooltip', 'Voir');
						N_view.innerHTML = '<i class="h5 icon icon-eye"></i>';

						N_view.type = 'button';

						N_view.addEventListener('click', () => {
							S_File.getInstance().open(params.value, params.data.name);
						});

						N_div.appendChild(N_view);

						N_div.appendChild(N_delete);

						return N_div;
					}
				}
			],
			defaultColDef: {
				resizable: true,
				suppressMenu: true
			},
			onRowDataChanged: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onGridReady: () => {
				this.setData();
			}
		});

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector('#number') as HTMLElement;

		if (number) {
			N_number.innerHTML = number.toString();
			N_number.classList.remove('d-none');
		} else {
			N_number.classList.add('d-none');
		}
	}

	private initEventInput() {
		const N_file = document.querySelector(`#${this.idTab} .add`) as HTMLInputElement;
		const N_spinner = document.querySelector(`#${this.idTab} #spinner`) as HTMLElement;

		N_file.addEventListener('change', () => {
			const reader = new FileReader();

			reader.addEventListener('load', async (e) => {
				const fileReader = e.target as FileReader;

				if (N_file.files) {
					const name = N_file.files[0].name;

					const newData = {
						name,
						_attachments: {
							[name]: {
								content_type: N_file.files[0].name,
								data: btoa((fileReader.result) as string)
							}
						}
					};

					const { data } = await S_File.getInstance().save(newData);

					this.fileData[data.data._id] = true;

					if (global.IS_MOBILE) {
						const N_container = this.N_el!.querySelector('.grid') as HTMLElement;
						N_container.appendChild(this.cardMobile(data.data));
					} else {
						this._gridOptions.api?.applyTransaction({
							add: [data.data]
						});
					}

					N_file.value = '';

					this.callback();

					N_spinner.classList.add('d-none');
				}
			});

			if (N_file.files) {
				N_spinner.classList.remove('d-none');

				reader.readAsBinaryString(N_file.files[0]);
			}
		});
	}

	private async setData() {
		this.users = await S_Users.getInstance().getAll();

		const result = [];

		for (const key in this.fileData) {
			if (this.fileData[key]) {
				const tmp = await S_File.getInstance().getById(key);

				result.push(tmp);
			}
		}

		if (global.IS_MOBILE) {
			this.initMobile(result);
		} else {
			this._gridOptions.api?.setRowData(result);
		}
	}

	private initMobile(result: { [key: string]: string }[]) {
		const N_container = this.N_el!.querySelector(`#${this.idTab} .grid`) as HTMLElement;

		if (result.length) {
			N_container.innerHTML = '';

			for (const item of result) {
				N_container.appendChild(this.cardMobile(item));
			}
		} else {
			const N_noData = document.createElement('div');

			N_noData.classList.add('text-muted', 'd-flex', 'justify-content-center', 'h5', 'mt-2');

			N_noData.innerHTML = 'Aucun Fichier';

			N_container.appendChild(N_noData);
		}
	}

	private cardMobile(data: { [key: string]: any }) {
		const N_div = document.createElement('div');

		let date = '--';
		if (data.date) {
			date = moment(data.date, 'x').format('DD/MM/YYYY HH:mm');
		}

		let user = '--';
		const tmp = _.find(this.users, { _id: data.user }) as any;
		if (tmp) {
			user = `${tmp.lastname} ${tmp.firstname}`;
		}

		N_div.innerHTML = `
            <div class="card-mobile p-3 rounded-lg mb-3 flex-row">

                <div class="flex-grow-1 text-md text-truncate">
                    <div class="txt-bold text-blue-grey-900">
                        ${data.name || '&nbsp;'}
                    </div>
                    <div class="mt-2"> 
                        ${date || '&nbsp;'} - ${user || '&nbsp;'}
                    </div>
                </div>
            
                <div class="btn-group" id="btn-group">
                    <button class="h-100 py-0 btn-transparent" type="button" id="view">
                        <i class="h5 icon icon-eye"></i>
                    </button>
                    <button class="h-100 py-0 btn-transparent" confirmation type="button" id="delete">
                        <i class="text-danger h5 icon icon-trash-alt"></i>
                    </button>
                </div>
            </div>
        `;

		const N_delete = N_div.querySelector('#delete') as HTMLButtonElement;
		const N_view = N_div.querySelector('#view') as HTMLButtonElement;

		N_delete.addEventListener('click', () => {
			this.fileData[data._id] = false;

			N_div.remove();

			this.callback();
		});

		N_view.addEventListener('click', () => {
			S_File.getInstance().open(data._id, data.name);
		});

		return N_div;
	}

	public setCallback(cb: () => void) {
		this.callback = cb;
	}

	public set data(data: { [key: string]: any }) {
		this.fileData = data;
		this.setData();
	}

	public get data(): { [key: string]: any } {
		return this.fileData;
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	public static register() {
		customElements.define(FilesTab.tagName, FilesTab);
	}
}

export default FilesTab;
