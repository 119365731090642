// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../../tpl/modals/editPage/detailsCommand.html';

// LIBS
import Modal from '@libs/Modal';

// CUSTOM_ELEMENT
import CE_OrderCustomer from '../../customElements/OrderCustomer';
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_P_Order from '@services/Provider/ProviderOrderService';
import S_Sites from '@services/Site/SiteService';

type DetailsCommandData = {
	infos: {
		businessName: string,
		commandsCustomer: string[],
		sites: string[],
	}
};

class DetailsCommand extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 } = {};

	private form: Form | null;

	private newMaterials: any[] = [];

	private newOrders: any[] = [];

	constructor(data: DetailsCommandData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(data);
			this.postInit();
			this.postInitCommandsCustomer();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const data = this.form?.getData() as { [key: string]: any };

					data.infos.sites = await this.getSites(data.infos.sites);
					data.infos.commandsCustomer = await this.getCommandsCustomer(data.infos.commandsCustomer);

					const additionalData = {
						newMaterials: this.newMaterials,
						newOrders: this.newOrders
					};

					const res = _.merge(additionalData, data);

					this.resolve(res);
				}));
			});
		});
	}

	private init() {
		const N_sites = this.element.querySelector('[name="infos.sites"]') as CE_Select2;

		N_sites.create(this.element, { multiple: true });

		this.selectPostinit['infos.sites'] = N_sites;
	}

	private postInitCommandsCustomer() {
		const N_commandsCustomer = this.element.querySelector('ap-order-customer') as CE_OrderCustomer;

		N_commandsCustomer.postInit(async (materials, idOrder) => {
			const { data } = await S_P_Order.getInstance().getDataToModal('', {
				type: 'command',
				materials,
				idOrder
			});

			this.newMaterials.push(...data.data.materials);

			const orderCustomer = data.data.infos.commandsCustomer;

			this.newOrders.push(...orderCustomer);

			N_commandsCustomer.addValues(orderCustomer);

			this.form?.setDataByName('infos.sites', data.data.infos.sites);
		});
	}

	private async setData(data: DetailsCommandData) {
		const res: { [key: string]: any } = {
			infos: {
				businessName: data.infos.businessName,
				commandsCustomer: await this.getCommandsCustomer(data.infos.commandsCustomer),
				sites: await this.getSites(data.infos.sites)
			}
		};

		this.form?.setData(res);
	}

	private async getCommandsCustomer(commands: string[]): Promise<{ id: string, text: string }[]> {
		const result: { id: string, text: string }[] = [];

		for (const command of commands) {
			result.push(await S_C_Order.getInstance().getDataToSelect2ByID(command));
		}

		return result;
	}

	private async getSites(sites: string[]): Promise<{ id: string, text: string }[]> {
		const result: { id: string, text: string }[] = [];

		for (const site of sites) {
			result.push(await S_Sites.getInstance().getDataToSelect2ByID(site));
		}

		return result;
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default DetailsCommand;
