import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

import S_History from '@js/services/History';
import S_Users from '@js/services/User/UserService';

import DatePickerRangeFilter from '@libs/agGrid/DatePickerRangeFilter';
import SelectFilter from '@libs/agGrid/SelectFilter';

import agUtils from '@libs/agGrid/french';

import CE_Traceability from '@libs/customElement/Traceability';

import _ from 'lodash';
import moment from 'moment';

class HistoryTab extends HTMLElement {
	public static readonly tagName: string = 'ap-history-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private _gridOptions: GridOptions = {};

	private N_el: HTMLElement | null = null;

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || HistoryTab.tagName;

		const type = this.getAttribute('type');

		if (type === 'page') {
			this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="history" text="Historique"></ap-page-tabs-menu-item>`;
			const N_item = this.querySelector('ap-page-tabs-menu-item');

			N_item?.addEventListener('open', () => {
				this._gridOptions.api?.sizeColumnsToFit();
			});
		} else {
			this.innerHTML = `
				<li class="nav-item border-right" ap-hidden="mobile">
					<a class="nav-link px-3 position-relative" data-toggle="tab" href="#${this.idTab}" role="tab">
						Historique
					</a>
				</li>
			`;
		}

		this.removeAttribute('type');
		this.removeAttribute('id');
	}

	/**
	 * @deprecated
	 */
	public postInit() {
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		this.N_el = document.createElement('div');

		this.N_el.classList.add('tab-pane', 'page-tabs-container');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title">
				Historique
				<div class="page-tabs-title-right">
					<ap-traceability></ap-traceability>
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>
			<div class="page-tabs-content">
				<div class="h-100 w-100 ag-theme-alpine grid"></div>
			</div>
		`;

		N_container.append(this.N_el);

		this.initGrid();
	}

	private async initGrid() {
		const users = await S_Users.getInstance().getAll();

		this._gridOptions = agUtils.french<GridOptions>({
			localeText: { noRowsToShow: 'Historique vide' },
			animateRows: true,
			rowData: this._gridOptions.rowData || [],
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowDragManaged: true,
			suppressContextMenu: true,
			defaultColDef: {
				suppressMenu: true,
				resizable: true,
				filter: true,
				sortable: true,
				floatingFilter: true
			},
			columnDefs: [
				{
					headerName: 'Personne',
					field: 'value.user',
					width: 200,
					suppressSizeToFit: true,
					filter: 'agTextColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					valueGetter: (params) => {
						const user = _.find(users, { _id: params.data.value.user }) as any;
						return `${user.lastname} ${user.firstname}`;
					}
				}, {
					headerName: 'Date',
					field: 'value.date',
					sort: 'desc',
					width: 250,
					suppressSizeToFit: true,
					filter: 'agNumberColumnFilter',
					floatingFilterComponent: DatePickerRangeFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true
					},
					cellRenderer: (params) => {
						return moment(params.value, 'x').format('DD/MM/YYYY HH:mm:ss');
					}
				}, {
					headerName: 'Événement',
					field: 'value.key',
					width: 200,
					filter: 'agTextColumnFilter',
					floatingFilterComponent: SelectFilter,
					floatingFilterComponentParams: {
						suppressFilterButton: true,
						options: {
							data: [{
								text: 'Ouverture',
								id: 'open'
							}, {
								text: 'Impression',
								id: 'print'
							}, {
								text: 'Sauvegarde',
								id: 'save'
							}, {
								text: 'Création',
								id: 'create'
							}]
						}
					},
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						let result = 'Inconnu';
						if (params.value === 'print') {
							result = 'Impression';
						}
						if (params.value === 'save') {
							result = 'Sauvegarde';
						}
						if (params.value === 'open') {
							result = 'Ouverture';
						}
						if (params.value === 'create') {
							result = 'Création';
						}
						return result;
					}
				}, {
					headerName: 'Description',
					filter: false,
					field: 'value.description'
				}
			],
			onColumnResized: (params) => {
				if (params.finished) {
					params.api.resetRowHeights();
				}
			}
		});

		new Grid(document.querySelector(`#${this.idTab} .grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	public set data(data: any[]) {
		this._gridOptions.rowData = data;
		this._gridOptions.api?.setRowData(data);
	}

	public async init(id: string, table: string) {
		if (id) {
			const data = await S_History.getInstance().getHistory(id, table);

			this.data = data;

			this.initTraceability(data);
		} else {
			this.initTraceability([]);
		}
	}

	public initTraceability(data: any) {
		const N_traceability = this.N_el!.querySelector('ap-traceability') as CE_Traceability;
		N_traceability.updateFromData(data);
	}

	public static register() {
		customElements.define(HistoryTab.tagName, HistoryTab);
	}
}

export default HistoryTab;
