import T_template from '../../tpl/blocks/template_materials.ejs';

import _ from 'lodash';

import Utils from '@libs/utils/Utils';

//services
import S_C_Order from '@services/Customer/CustomerOrderService';
import S_Customer from '@services/Customer/CustomerService';
import S_Product from '@services/Product/ProductService';
import S_Quote from '@js/services/QuoteService';
import S_Site from '@js/services/Site/SiteService';

class MaterialsByBrand {
	private products: { [key: string]: any } = {};

	constructor(id: string, content: { [key: string]: any }[] = [], products: { [key: string]: any }) {
		this.products = products;
		content = _.cloneDeep(content);
		this.init(id, content);
	}

	private async init(id: string, content: { [key: string]: any }[] = []) {
		const data = await S_C_Order.getInstance().getById(id);

		if (data.infosCustomer.customer) {
			data.infosCustomer.customer = await S_Customer.getInstance().getById(data.infosCustomer.customer);
		}

		const sitesByQuote: { [key: string]: any } = {};

		for (const item of data.orders) {
			const sites: string[] = [];

			for (const site of item.sites.sites) {
				try {
					sites.push(await S_Site.getInstance().getDisplayRefByID(site));
				} catch (e) {
				}
			}

			sitesByQuote[item.quoteID] = sites.join(', ');
		}

		for (const item of content) {
			item.product = this.products[item.productID];
		}

		content = _.sortBy(content, ['quote', 'product.brand', 'product.reference']) as any[];

		let brand = '';
		let quote = '';

		for (let i = 0; i < content.length; i++) {
			if (content[i].product) {
				content[i].product = this.convertProductToPrint(content[i].product);
			}

			if (quote !== content[i].quote) {
				content.splice(i, 0, {
					type: 'quote',
					label: await S_Quote.getInstance().getDisplayRefByID(content[i].quote) + ' Sites : ' + sitesByQuote[content[i].quote]
				});

				i++;

				quote = content[i].quote;
				brand = '';
			}

			if (brand !== content[i].product.brand) {
				content.splice(i, 0, {
					type: 'brand',
					label: content[i].product.brand
				});

				i++;

				brand = content[i].product.brand;
			}

			if (i % 54 === 0) {
				content.splice(i, 0, {
					type: 'quote',
					label: quote
				});

				i++;

				content.splice(i, 0, {
					type: 'brand',
					label: brand
				});

				i++;
			}
		}

		const N_container = document.createElement('div');

		N_container.classList.add('containerPrint');

		const chunkMaterials = _.chunk(content, 53);

		for (const chunk of chunkMaterials) {
			N_container.innerHTML += T_template({
				column: {
					reference: S_Product.getInstance().columnNameReference
				},
				...data,
				data: chunk
			});
		}

		document.body.appendChild(N_container);

		setTimeout(() => {
			window.print();

			document.body.removeChild(N_container);

			Utils.removeTooltip();
		}, 1000);
	}

	private convertProductToPrint(product: { [key: string]: any }) {
		return {
			reference: product[S_Product.getInstance().referenceKey],
			name: product.name,
			brand: product.brand || 'MARQUE NON RENSEIGNEE'
		};
	}
}

export default MaterialsByBrand;
