// CORE
import { Alert, toaster } from '@autoprog/core-client';

// NODE_MODULE
import { GetContextMenuItemsParams } from '@ag-grid-enterprise/all-modules';
import moment from 'moment';

// TEMPLATE
// LIBS
import C_Database from '@js/controllers/Database';

import C_BillsProvider from '../controllers/Bills.Provider';

// PRINTER
// UTILS
import Decimal from '@libs/utils/Decimal';

// MODAL
import M_AddPayment from '../modals/AddLinePayment';
import M_SetRelunchDate from '@libs/modals/SetRelunchDate';

// CUSTOM_ELEMENT
import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

// SERVICE
import S_P_Bill from '@services/Provider/ProviderBillService';

// EXPORT EXCEL
import Export from '@libs/export/Export';
import ExportUtils from '@libs/utils/ExportUtils';
import Utils from '@libs/utils/Utils';

class Bill extends C_Database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Factures - Fournisseurs',
			type: 'database',
			database: 'bills-providers'
		});
	}

	protected buttonEdit(params: any) {
		C_BillsProvider.open(params.data._id);
	}

	protected getRowStyle(params: any): { [key: string]: string } {
		if (params.data) {
			//litige
			if (params.data.disputes?.enabled) {
				return {
					'background-color': 'var(--ap-red-50)',
					color: 'var(--ap-red-900)'
				};
			}

			//payer à 100%
			if (params.data.stateColor === '100%') {
				return {
					'background-color': 'var(--ap-green-50)',
					color: 'var(--ap-green-900)'
				};
			}

			//paiement en retard
			if (params.data.stateColor === 'relunch') {
				return {
					'background-color': 'var(--ap-blue-50)',
					color: 'var(--ap-blue-900)'
				};
			}

			//en cours de paiement
			if (params.data.stateColor === 'payment') {
				return {
					'background-color': 'var(--ap-orange-50)',
					color: 'var(--ap-orange-900)'
				};
			}
		}

		return {
			'background-color': 'white'
		};
	}

	protected iconsColumn(params: any) {
		const N_open = document.createElement('ap-open-document') as HTMLElement;
		N_open.dataset.id = params.data._id;
		N_open.dataset.table = 'bills-providers';

		return [N_open];
	}

	protected header() {
		const N_div = document.createElement('div') as HTMLDivElement;

		N_div.classList.add('d-flex', 'flex-grow-1');

		const N_Export = document.createElement('button');
		N_Export.classList.add('btn', 'btn-export-csv', 'ml-auto');
		N_Export.innerHTML = '<i class="icon icon-solid-file-csv"></i>';
		N_Export.setAttribute('tooltip', 'Exporter les données en CSV');
		N_Export.setAttribute('permission', 'BILLS._PROVIDERS.EXPORT');

		N_Export.addEventListener('click', () => {
			const csvString = new Export()
				.included(['infos.provider', 'infos.label', 'infos.number', 'infos.command', 'infos.internalNumber', 'infos.date', 'infos.receiveDate', 'infos.datePayment', 'globalPrice', 'globalPriceTVA', 'globalPriceTTC', 'paymentPrice', 'notPaymentPrice', 'paid.state'])
				.fromTableName(this.tableName)
				.fromGridOptions(this.gridOptions)
				.createCSV();

			const a = ExportUtils.createFileLink(csvString);
			a.download = `Export-Factures-Fournisseur-${moment().format('DD-MM-YYYY')}`;
			a.click();
			a.remove();
		});

		N_div.appendChild(N_Export);
		return N_div;
	}

	protected onDataUpdate(params: any) {
		let priceTTC = new Decimal(0);
		let priceHT = new Decimal(0);
		let pricePayment = new Decimal(0);
		let notWaitingPriceTTC = new Decimal(0);
		let waitingPriceTTC = new Decimal(0);

		params.api.forEachNodeAfterFilter((node: any) => {
			priceTTC = priceTTC.plus(node.data.globalPriceTTC.value);
			priceHT = priceHT.plus(node.data.globalPrice.value);

			pricePayment = pricePayment.plus(node.data.paymentPrice.value);

			if (node.data.infos.waitingBill) {
				waitingPriceTTC = waitingPriceTTC.plus(node.data.globalPriceTTC.value);
			} else {
				notWaitingPriceTTC = notWaitingPriceTTC.plus(node.data.globalPriceTTC.value);
			}
		});

		priceTTC = priceTTC.toDecimalPlaces(2);
		priceHT = priceHT.toDecimalPlaces(2);
		pricePayment = pricePayment.toDecimalPlaces(2);

		this.setDataTitle([{
			text: 'Montant HT',
			price: priceHT.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Montant TVA',
			price: priceTTC.minus(priceHT).toDecimalPlaces(2).setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Montant TTC',
			price: priceTTC.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Montant TTC payé',
			price: pricePayment.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Montant TTC à payer',
			price: priceTTC.minus(pricePayment).toDecimalPlaces(2).setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Montant TTC factures reçues',
			price: notWaitingPriceTTC.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Montant TTC factures non reçues',
			price: waitingPriceTTC.setSuffixAndHumanizeNumber('€')
		}]);
	}

	protected getContextMenu(params: GetContextMenuItemsParams) {
		if (params.node) {
			const priceTotal = Decimal.setDisplayNumber(params.node.data.globalPriceTTC.value).toDecimalPlaces(2);
			const pricePayment = Decimal.setDisplayNumber(params.node.data.paymentPrice.value).toDecimalPlaces(2);

			params.node.data.relunch = params.node.data.relunch || {};

			return [
				...super.getContextMenu(params),
				'separator',
				{
					name: 'Définir prochaine relance',
					icon: '<i class="icon icon-solid-redo"></i>',
					disabled: priceTotal.equals(pricePayment) || moment(params.node.data.relunch.date || params.node.data.infos.datePayment, 'x').isSameOrAfter(moment(), 'day'),
					action: async () => {
						new M_SetRelunchDate('bills-providers', params.node.data._id).open().then(() => {
							this.refreshData();
						});
					}
				}, 'separator', {
					name: 'Ajout paiement',
					icon: '<i class="icon icon-solid-euro-sign"></i>',
					disabled: priceTotal.equals(pricePayment),
					action: async () => {
						new M_AddPayment('bills-providers', params.node.data._id).open().then(async (payment) => {
							const data = await S_P_Bill.getInstance().getById(params.node.data._id);

							data.payment = data.payment || [];
							data.payment.push(payment);

							await S_P_Bill.getInstance().save(data);

							toaster.success('Sauvegarde réussi');

							this.refreshData();
						});
					}
				}, {
					name: 'Créer un avoir',
					disabled: params.node.data.isCredit,
					action: () => {
						C_BillsProvider.open(null, { type: 'credit', idBill: params.node.data._id, idCommand: params.node.data.infos.idCommand });
					}
				}, {
					name: !params.node.data.disputes?.enabled ? 'Mettre en litige' : 'Clôturer le litige',
					icon: '<ap-icon name="alert" class="text-red"></ap-icon>',
					action: () => {
						if (!params.node.data.disputes?.enabled) {
							Alert.prompt('Litige', 'Commentaire', {
								type: 'textarea',
								value: ' ',
								confirmColor: 'validate-modal',
								cancelColor: 'close-modal',
								cancelText: 'Fermer'
							}).then(async (value) => {
								const data = await S_P_Bill.getInstance().getById(params.node.data._id);

								data.disputes = {
									enabled: true,
									comments: (value as string).trim()
								};

								data.notes = data.notes || [];
								data.notes.push({
									user: Utils.userID,
									date: Date.now().toString(),
									text: 'Mise en litige de la facture.'
								});

								await S_P_Bill.getInstance().save(data);

								toaster.success('Sauvegarde réussi');

								this.refreshData();
							});
						} else {
							Alert.confirm('Litige', 'Souhaitez-vous clôturer le litige ?').then(async () => {
								const data = await S_P_Bill.getInstance().getById(params.node.data._id);

								data.disputes.enabled = false;

								data.notes = data.notes || [];
								data.notes.push({
									user: Utils.userID,
									date: Date.now().toString(),
									text: 'Suppression du litige'
								});

								await S_P_Bill.getInstance().save(data);

								toaster.success('Sauvegarde réussi');

								this.refreshData();
							});
						}
					}
				}
			];
		} else {
			return [];
		}
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: '_createBy_',
					type: 'switch',
					filters: [
						{
							value: Utils.userID,
							text: 'Facture personnelle'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'infos.waitingBill',
					type: 'switch',
					filters: [{
						value: 'true',
						text: 'Attente réception facture'
					}]
				}, {
					type: 'separator'
				}, {
					column: 'disputes.enabled',
					type: 'switch',
					filters: [{
						value: 'true',
						text: 'Litige',
						color: 'red-200'
					}]
				}, {
					type: 'separator'
				}, {
					column: 'relunch.state',
					type: 'switch',
					filters: [{
						value: 'true',
						text: 'À relancer',
						color: 'blue-200'
					}]
				}, {
					type: 'separator'
				}, {
					type: 'title',
					title: 'État paiement : '
				}, {
					column: 'paid.state',
					type: 'checkbox',
					filters: [{
						value: '0',
						text: 'Pas commencé',
						color: 'white'
					}, {
						value: '1',
						text: 'En cours de paiement',
						color: 'orange-200'
					}, {
						value: '2',
						text: 'Payée à 100%',
						color: 'green-200'
					}]
				}
			]
		];
	}
}

export default Bill;
