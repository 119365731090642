
import GenericService from '../GenericService';
import Utils from '@libs/utils/Utils';

class CustomerOrderService extends GenericService {
	private static instance: CustomerOrderService | null = null;

	constructor() {
		super('commands-customer');
	}

	public static getInstance(): CustomerOrderService {
		if (!CustomerOrderService.instance) {
			CustomerOrderService.instance = new CustomerOrderService();
		}

		return CustomerOrderService.instance;
	}

	public async cancelOrder(id: string) {
		return this.put_function('cancelOrder', { id, user: Utils.userID });
	}

	public async getProductToDelivery(id: string) {
		return this.get_function('getProductToDelivery', { id });
	}

	public async deleteQuoteToOrder(id_order: string, id_quote: string) {
		return this.put_function('deleteQuoteToOrder', { id_order, id_quote, user: Utils.userID });
	}

	public getDataToRecap(startDate: number, endDate: number) {
		return this.get_function('dataToRecap', { user: Utils.userID, startDate, endDate, companyID: Utils.companyID });
	}

	public getDataToMaterial(stateBill: string) {
		return this.get_function('dataToMaterial', { stateBill, companyID: Utils.companyID });
	}

	public getOrderByNumberAndCompany(customer: string, number: string, addonNumberCommand: string) {
		return this.get_function('getOrderByNumberAndCompany', { customer, number, addonNumberCommand, companyID: Utils.companyID });
	}

	public async getStockById(idOrder: string) {
		return this.get_function('getStockById', { idOrder });
	}

	public async getDeliveriesDetail(idOrder: string) {
		return this.get_function('getDeliveriesDetail', { idOrder });
	}

	public saveStockEvent(idOrder: string, stockData: { [key: string]: any }[]) {
		return this.post_function('saveStockEvent', { id_order: idOrder, user: Utils.userID, stocks: stockData });
	}
}

export default CustomerOrderService;
