// NODE_MODULE
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../tpl/modals/detailsDeliveries.html';

// LIBS
import Modal from '@libs/Modal';

import NumericCellRenderer from '@libs/agGrid/cellRenderer/NumericCellRenderer';
import ProductCellRenderer from '@modules/Products/js/libs/ProductCellRenderer';

import CE_Aggrid from '@libs/customElement/AgGrid';

import S_C_Order from '@services/Customer/CustomerOrderService';

class DetailsDeliveries extends Modal {
	constructor(idOrder: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.on('opened', async () => {
			const N_grid = this.element.querySelector<CE_Aggrid>('#grid')!;

			N_grid.setGridOptions({
				columnDefs: [
					{
						headerName: 'Produit',
						width: 150,
						field: 'productID',
						floatingFilter: true,
						filter: 'agTextColumnFilter',
						floatingFilterComponentParams: {
							suppressFilterButton: true
						},
						filterParams: {
							textFormatter: (result: string) => {
								if (result === null) return null;
								return _.deburr(result.toLowerCase());
							},
							debounceMS: 200
						},
						cellRenderer: ProductCellRenderer
					}, {
						headerName: 'Quantité commande',
						suppressSizeToFit: true,
						width: 200,
						cellRenderer: NumericCellRenderer,
						cellRendererParams: {
							decimalNumber: 0
						}
					}, {
						headerName: 'Quantité livrée',
						suppressSizeToFit: true,
						width: 200,
						field: 'deliveredQuantity',
						cellRenderer: NumericCellRenderer,
						cellRendererParams: {
							decimalNumber: 0
						}
					}, {
						headerName: 'Quantité restante',
						suppressSizeToFit: true,
						width: 200,
						valueGetter: (params) => {
							return params.data.quantity - params.data.deliveredQuantity;
						},
						cellRenderer: NumericCellRenderer,
						cellRendererParams: {
							decimalNumber: 0
						}
					}
				],
				defaultColDef: {
					suppressMenu: true,
					resizable: true
				}
			});

			const data = await S_C_Order.getInstance().getDeliveriesDetail(idOrder);
			N_grid.value = data;
		});
	}
}

export default DetailsDeliveries;
