import { Module, Modules, Router, global } from '@autoprog/core-client';

import C_Module from './js/controllers/Help';

import T_module from './tpl/help.html';

class HelpModule extends Module {
	public async preInit() {

	}

	public async init() {
		const router = Router.getInstance();

		const route = {
			url: '/module/help',
			controller: C_Module as any,
			tpl: T_module,
			requireValidUser: true
		};

		router.addRoute(route);
	}

	public async postInit() {
		if (!global.IS_MOBILE) {
			//const N_navbar = document.querySelector('#navbar-right-content') as HTMLElement;
			const N_div = document.createElement('div');

			N_div.innerHTML = '<ap-button class="btn-navbar" icon="question/line"></ap-button>';

			N_div.addEventListener('click', () => {
				Router.getInstance().navigate('#module/help');
			});

			//N_navbar.appendChild(N_div);
		}
	}
}

Modules.registerModule('Aide', HelpModule);
