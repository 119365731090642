
import '@css/customElements/dashboardUser.scss';

class DashboardUser extends HTMLElement {
	public static readonly tagName: string = 'ap-dashboard-user';

	private static colors = [
		'green', 'red',
		'pink', 'light-green',
		'purple', 'lime',
		'deep-purple', /*'yellow',*/
		'indigo', 'amber',
		'blue', /*'orange',*/
		'light-blue', 'deep-orange',
		'cyan', 'brown',
		'teal', 'grey'
	];

	private static index = 0;
	private static cacheByText: { [key: string]: number } = {};

	public connectedCallback() {
		const text = this.getAttribute('text') || '';

		if (text) {
			const chunk = text?.split(' ');

			chunk[0] = chunk[0] || '';
			chunk[1] = chunk[1] || '';

			this.innerHTML = `
				<div class="badge">${chunk[0][0]} ${chunk[1][0]}</div>
				<div class="value">${text}</div>
			`;

			this.getColor(text);
		} else {
			this.innerHTML = '';
		}
	}

	private getColor(text: string) {
		text = text.toLowerCase().replace(/\s/gmi, '');

		if (DashboardUser.cacheByText[text] === undefined) {
			DashboardUser.cacheByText[text] = Number(DashboardUser.index.toString());
			DashboardUser.index++;
			DashboardUser.index = DashboardUser.index % DashboardUser.colors.length;
		}

		const color = DashboardUser.colors[DashboardUser.cacheByText[text]];

		const N_badge = this.querySelector<HTMLElement>('.badge')!;

		N_badge.style.setProperty('--background-color', `var(--ap-${color}-100)`);
		N_badge.style.setProperty('--color', `var(--ap-${color}-500)`);
	}

	public static register() {
		customElements.define(DashboardUser.tagName, DashboardUser);
	}

	public static clearCache() {
		DashboardUser.cacheByText = {};
		DashboardUser.index = 0;
	}
}

export default DashboardUser;
