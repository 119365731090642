import { DOM } from '@autoprog/core-client';

import '@css/customElements/select.scss';

export type itemData = {
	id: string
	text: string
};

type options = {
	data?: itemData[]
	ajax?: {
		url: string,
		getParams: (search: string) => { search: string, [key: string]: any }
	}
};

class Select extends HTMLElement {
	public static readonly tagName: string = 'ap-select';

	private _isOpen: boolean;
	private _options: options;
	private _values: itemData[];

	private noSearchBar: boolean;

	private _id: string | null;
	private _name: string;

	private _isMultiple: boolean;

	private _abortController: AbortController;

	private N_dropdown: HTMLDivElement;

	private _required: boolean;

	constructor() {
		super();

		this._id = this.getAttribute('id') || null;
		this._isMultiple = this.getAttribute('multiple') !== null;
		this._isOpen = false;
		this._options = {};
		this._values = [];
		this._name = this.getAttribute('name') || '';
		this._required = !!this.hasAttribute('required');
		this.noSearchBar = !!this.hasAttribute('no-search-bar');

		this._abortController = new AbortController();

		this.N_dropdown = document.createElement('div');
		this.N_dropdown.classList.add('dropdown-select');

		this.innerHTML = '';
		this.init();
	}

	private getParentElement() {
		let N_el = this.parentElement;

		while (N_el) {
			if (N_el.classList.contains('modal')) {
				return N_el;
			}

			N_el = N_el.parentElement;
		}

		return document.body;
	}

	public get N_containerDropdown() {
		const parent = this.getParentElement();

		let N_el = parent.querySelector('.container-select');

		if (!N_el) {
			N_el = document.createElement('div');
			N_el.classList.add('container-select');
			parent.append(N_el);
		}

		return N_el;
	}

	public connectedCallback() {
		this.initExternalEvent();
	}

	public disconnectedCallback() {
		//this._abortController.abort('destroy');
		//this.N_dropdown.remove();
	}

	private init() {
		this.innerHTML = `
			<div class="container-selection">
				<div class="selection"></div> 
			</div>
		`;

		this.N_dropdown.innerHTML = `
			<div class="separator"></div>
			<div class="container-search">
				<input placeholder="Recherche" id="search">
				<div class="container-loader">
					<ap-icon class="loader-input" name="loader-4/line"></ap-icon>
				</div>
			</div>
			<div class="result">
			</div>
		`;

		if (this.noSearchBar) {
			this.N_dropdown.setAttribute('no-search-bar', '');
		}

		this.N_containerDropdown.append(this.N_dropdown);

		this.initEventSelection();

		this.initSearch();
	}

	private initEventSelection() {
		const N_container_selection = this.querySelector('.container-selection') as HTMLElement;

		this.checkValidity();

		N_container_selection.addEventListener('click', () => {
			this._isOpen = !this._isOpen;
			if (this._isOpen) {
				this.open();
				this.dispatchEvent(new CustomEvent('open'));
			} else {
				this.close();
				this.dispatchEvent(new CustomEvent('close'));
			}
		});

		this.N_containerDropdown.addEventListener('click', (e) => {
			if (this._isOpen && e.target && (this.N_containerDropdown.isEqualNode(e.target as HTMLElement) || !this.N_containerDropdown.contains(e.target as HTMLElement))) {
				this.close();
				this.dispatchEvent(new CustomEvent('close'));
			}
		}, {
			signal: this._abortController.signal
		});
	}

	public open() {
		const N_search = this.N_dropdown.querySelector('#search') as HTMLInputElement;

		const rect = this.getBoundingClientRect();
		const rectBody = window.document.body.getBoundingClientRect();

		const N_result = this.N_dropdown.querySelector('.result')!;

		const maxHeight = parseFloat(getComputedStyle(N_result).maxHeight);

		this._isOpen = true;

		const isReverse = (rect.top + maxHeight) > rectBody.height;

		if (isReverse) {
			this.N_dropdown.classList.add('open-reverse');
			this.N_dropdown.style.bottom = (rectBody.height - rect.top - 2) + 'px';
			this.N_dropdown.style.top = '';
		} else {
			this.N_dropdown.classList.remove('open-reverse');
			this.N_dropdown.style.bottom = '';
			this.N_dropdown.style.top = (rect.top + rect.height - 2) + 'px';
		}

		this.N_dropdown.style.width = rect.width + 'px';
		this.N_dropdown.style.left = rect.left + 'px';

		if (rect.width < parseInt(getComputedStyle(this.N_dropdown).minWidth)) {
			this.N_dropdown.classList.add('dropdown-to-long');
		} else {
			this.N_dropdown.classList.remove('dropdown-to-long');
		}

		N_search.value = '';

		DOM.nextTick().then(() => {
			N_search.focus();
		});

		this.updateRenderResult();

		this.N_dropdown.classList.add('open');
	}

	private close() {
		this.N_dropdown.classList.remove('open');
		this._isOpen = false;
		this.checkValidity();
	}

	private async initSearch() {
		const N_search = this.N_dropdown.querySelector('#search') as HTMLInputElement;
		N_search.addEventListener('input', () => {
			this.updateRenderResult();
		});
	}

	private async filterData() {
		const N_search = this.N_dropdown.querySelector('#search') as HTMLInputElement;
		const N_container_loader = this.N_dropdown.querySelector('.container-loader') as HTMLElement;

		N_container_loader.classList.add('active');

		const search = N_search.value;

		let result: itemData[] = [];

		if (this._options.ajax) {
			result = await fetch(this._options.ajax.url + '?' + new URLSearchParams(this._options.ajax.getParams(search)), {
				method: 'GET'
			}).then(async (response) => {
				return (await response.json()).data;
			});
		} else if (this._options.data) {
			result = this._options.data.filter(item => item.text.toLowerCase().includes(search.toLowerCase()));
		}

		N_container_loader.classList.remove('active');

		return result.slice(0, 25);
	}

	private renderItem(item: itemData) {
		const N_div = document.createElement('div');
		N_div.classList.add('item');
		N_div.dataset.id = item.id;

		N_div.innerHTML = item.text;

		const indexInValues = this._values.findIndex(el => el.id === item.id);
		N_div.classList.toggle('active', indexInValues !== -1);

		N_div.addEventListener('click', () => {
			if (!this._isMultiple) {
				this._values = [item];
				this.close();
				this.resetSelection();
			} else {
				const indexInValues = this._values.findIndex(el => el.id === item.id);
				if (indexInValues === -1) {
					this._values.push(item);
				} else {
					this._values.splice(indexInValues, 1);
				}
			}

			N_div.classList.toggle('active');

			this.dispatchEvent(new CustomEvent('change', { detail: this.value }));

			this.renderSelection();
		});

		return N_div;
	}

	private renderResult(data: itemData[]) {
		const N_result = this.N_dropdown.querySelector('.result') as HTMLElement;

		N_result.innerHTML = '';

		if (data && data.length) {
			for (const item of data) {
				N_result.append(this.renderItem(item));
			}
		} else {
			const N_div = document.createElement('div');
			N_div.classList.add('item', 'disabled');
			N_div.innerHTML = 'Aucun résultat';
			N_result.append(N_div);
		}
	}

	private renderSelection() {
		const N_selection = this.querySelector('.selection') as HTMLElement;
		N_selection.innerHTML = this._values.map(item => item.text).join(', ');
		this.checkValidity();
	}

	private resetSelection() {
		const N_activeItems = this.querySelectorAll('.item.active') as NodeListOf<HTMLElement>;

		for (const N_activeItem of N_activeItems) {
			N_activeItem.classList.remove('active');
		}
	}

	public get value(): null | string | string[] {
		const res = this._values.map(item => item.id);

		if (this._isMultiple) {
			return res;
		} else {
			return res?.[0] || null;
		}
	}

	private convertValue(item: string | itemData) {
		if (typeof item === 'string') {
			if (this._options.data) {
				const itemFind = this._options.data.find(i => i.id === item);

				if (itemFind) {
					return itemFind;
				}
			}

			return { id: item, text: item };
		}

		if (this._options.data) {
			const itemFind = this._options.data.find(i => i.id === item.id);

			if (itemFind) {
				return itemFind;
			}
		}

		return item;
	}

	/**
	 * Initialise les événements bind sur des élements extérieures au composant comme une balise label
	 */
	private initExternalEvent() {
		const label = document.querySelector<HTMLLabelElement>(`label[for="${this._id}"]`);

		if (label) {
			label.addEventListener('click', () => {
				this.open();
			});
		}
	}

	private checkValidity() {
		if (this._required && this._values.length === 0) {
			this.classList.add('invalid');
		} else {
			this.classList.remove('invalid');
		}
	}

	public set value(values: string | string[] | itemData | itemData[] | null) {
		this._values = [];

		if (values) {
			if (Array.isArray(values)) {
				for (const item of values) {
					this._values.push(this.convertValue(item));
				}
			} else {
				this._values.push(this.convertValue(values));
			}
			this.renderSelection();
		} else {
			this.renderSelection();
		}
	}

	public get name() {
		return this._name;
	}

	public set name(value: string) {
		this._name = value;
	}

	private async updateRenderResult() {
		const data = await this.filterData();
		this.renderResult(data);
	}

	private updateValues() {
		const values = this._values;

		if (this._options.data) {
			this._values = [];

			for (const item of values) {
				const tmp = this._options.data.find(el => el.id === item.id);
				tmp && this._values.push(tmp);
			}
		}
	}

	public set options(options: options) {
		this._options = options;
		this.updateValues();
		this.renderSelection();
		this.updateRenderResult();
	}

	public static register() {
		customElements.define(Select.tagName, Select);
	}
}

export default Select;
