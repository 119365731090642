
// CORE
import agUtils from '@libs/agGrid/french';
import { toaster } from '@autoprog/core-client';

// NODE_MODULE
import { AllModules, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';

// LIBS
import AggridUtils from '@libs/utils/Aggrid';
import Decimal from '@libs/utils/Decimal';
import ModalManager from '@managers/ModalManager';
import Select2Utils from '@libs/utils/select2Utils';
import SelectEditor from '@libs/agGrid/SelectEditor';

// MODAL
import M_SelectProduct from '@modules/Products/js/modals/SelectProduct';

// SERVICE
import S_ProductProvider from '@services/Product/ProductProviderService';
import S_Products from '@services/Product/ProductService';

class ContentDeliveriesTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-provider-content-tab';

	private selectorTab: string = '';
	private idTab: string = '';
	private _isLock: boolean = false;
	private _update = (): void => { };
	private _form = (): any => { return {}; };
	private _deleteDeliveryprice = (): void => { };

	private _gridOptions: GridOptions = {};

	private N_el: HTMLElement | null = null;

	public async connectedCallback() {
		this.idTab = ContentDeliveriesTab.tagName;
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="file-edit/line" icon-active="file-edit/fill" text="Contenus"></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this._gridOptions.api?.sizeColumnsToFit();
			this.getInfos();
		});
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;
		this.N_el = document.createElement('div');

		this.N_el.classList.add('page-tabs-container', 'tab-pane');
		this.N_el.id = this.idTab;
		this.N_el.innerHTML = `
			<div class="page-tabs-title" display="desktop">
				Contenus
				<div class="page-tabs-title-right">
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>

			<div class="page-tabs-content">
				<div id="grid" class="ag-theme-alpine h-100 w-100"></div>
			</div>
        `;

		N_container.append(this.N_el);

		this.initGrid();
	}

	public set isLock(value: boolean) {
		this._isLock = value;
	}

	private get provider() {
		return this._form().infos.provider;
	}

	private get deliveryPrice() {
		return this._form().infos.deliveryPrice;
	}

	private async getProductProvider(product: string) {
		if (this.provider && product) {
			try {
				const data = await S_ProductProvider.getInstance().getByProductAndProvider(product, this.provider);

				return data;
			} catch (e) {
				toaster.error('Produit Fournisseur introuvable');
			}
		}

		return {};
	}

	private initGrid() {
		const instanceSelectProduct = new Select2Utils('products');

		instanceSelectProduct.setRefCallback('provider', () => {
			return this.provider;
		});

		this._gridOptions = agUtils.french<GridOptions>({
			animateRows: true,
			suppressDragLeaveHidesColumns: true,
			suppressRowClickSelection: true,
			rowDragManaged: true,
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				editable: (params) => {
					return !params.node.rowPinned && !params.node.data.typeLine && !this._isLock;
				},
				resizable: true
			},
			columnDefs: [
				{
					headerName: '#',
					width: 80,
					rowDrag: true,
					cellRenderer: (params) => {
						if (params.data.quoteCustomer) {
							const tooltip = params.data.quoteCustomerTooltip;
							return `<i tooltip="${tooltip}" class="h5 ml-2 icon icon-solid-file-contract"></i>`;
						}

						return '';
					}
				},
				{
					headerName: S_Products.getInstance().columnNameReference,
					field: 'reference',
					suppressSizeToFit: true,
					cellEditor: SelectEditor,
					cellEditorParams: {
						options: {
							table: 'products',
							isAsync: true,
							rootElement: this
						}
					},
					cellRenderer: (params) => {
						return S_Products.getInstance().cellRendererReference(params.value);
					},
					editable: (params) => {
						return !params.data.quoteCustomer && !this._isLock;
					}
				},
				{
					headerName: 'Marque',
					field: 'brand',
					width: 95,
					suppressSizeToFit: true
				},
				{
					field: 'label',
					cellClass: (params) => {
						const result: string[] = [];

						params.data.style = params.data.style || {};

						if (params.data.style) {
							if (params.data.style.bold) {
								result.push('font-weight-bold');
							}

							if (params.data.style.title) {
								result.push('h4');
							}
						}

						return result;
					},
					headerName: 'Désignation',
					cellRenderer: (params) => {
						if (params.data.typeLine === 'bill-address') {
							return '<span class="text-muted">Adresse Facturation</span>';
						} else if (params.data.typeLine === 'delivery-address') {
							return '<span class="text-muted">Adresse Livraison</span>';
						} else if (params.data.typeLine === 'delivery-price') {
							return '<span class="text-muted">Frais de port</span>';
						} else {
							return params.value;
						}
					}
				},
				{
					field: 'quoteBase',
					headerName: 'Devis de référence',
					width: 200,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (!params.node.rowPinned) {
							const N_div = document.createElement('div');

							N_div.classList.add('d-flex', 'w-100');

							N_div.innerHTML = params.value || '';

							const N_refresh = document.createElement('button');

							N_refresh.classList.add('h-100', 'py-0', 'btn-transparent', 'ml-auto');
							N_refresh.setAttribute('confirmation', '');
							N_refresh.setAttribute('tooltip', 'Actualiser le devis de référence');
							N_refresh.type = 'button';

							if (!params.data.reference) {
								N_refresh.disabled = true;
								N_refresh.classList.add('cursor-not-allowed');
								N_refresh.innerHTML = '<i class="text-muted h5 icon icon-solid-undo"></i>';
							} else {
								N_refresh.innerHTML = '<i class="text-dark h5 icon icon-solid-undo"></i>';
							}

							N_refresh.addEventListener('click', async () => {
								const data = await this.getProductProvider(params.data.reference);

								if (data.quoteBase) {
									params.node.setDataValue('quoteBase', data.quoteBase);
									params.api?.refreshCells({ force: true, rowNodes: [params.node] });
								} else {
									toaster.error('Devis introuvable');
								}
							});

							if (!this._isLock) {
								N_div.append(N_refresh);
							}

							return N_div;
						}

						return '';
					}
				},
				{
					field: 'unit',
					headerName: 'U',
					width: 50,
					suppressSizeToFit: true
				},
				{
					field: 'quantity',
					headerName: 'Qté',
					width: 80,
					cellClass: 'text-right',
					suppressSizeToFit: true,
					cellClassRules: {
						'text-muted': (params: any) => {
							return params.data.typeLine;
						}
					},
					cellRenderer: (params) => {
						if (params.data.typeLine === 'delivery-price') {
							return '1';
						}

						return params.value;
					}
				},
				{
					field: 'price',
					headerName: 'P.U.',
					width: 200,
					cellClass: 'text-monospace text-right',
					suppressSizeToFit: true,
					cellClassRules: {
						'text-red': (params: any) => {
							return !params.value && !params.node.rowPinned && !params.data.typeLine;
						},
						'text-muted': (params: any) => {
							return params.data.typeLine;
						}
					},
					cellRenderer: (params) => {
						if (!params.node.rowPinned) {
							if (params.data.typeLine === 'delivery-price') {
								return Decimal.setDisplayNumber(this.deliveryPrice).setSuffixAndHumanizeNumber('€');
							}

							if (params.data.reference) {
								if (params.value) {
									return Decimal.setDisplayNumber(params.value).toDecimalPlaces(4).setSuffixAndHumanizeNumber('€', 4);
								} else {
									return 'Prix non renseigné';
								}
							} else {
								if (params.value) {
									return Decimal.setDisplayNumber(params.value).toDecimalPlaces(4).setSuffixAndHumanizeNumber('€', 4);
								} else {
									return '';
								}
							}
						}

						return '';
					}
				},
				{
					headerName: 'Montant H.T.',
					width: 200,
					cellClass: 'text-monospace text-right',
					editable: false,
					suppressSizeToFit: true,
					cellClassRules: {
						'text-muted': (params: any) => {
							return params.data.typeLine;
						}
					},
					cellRenderer: (params) => {
						if (params.node.rowPinned) {
							return params.data.value;
						} else {
							if (params.data.typeLine === 'delivery-price') {
								return Decimal.setDisplayNumber(this.deliveryPrice).setSuffixAndHumanizeNumber('€');
							}

							if (params.data.price) {
								let result = Decimal.setDisplayNumber(params.data.price);
								result = result.times(params.data.quantity || 0);

								if (params.data.type === 'discount') {
									result = result.times(-1);
								}

								return result.toDecimalPlaces(2).setSuffixAndHumanizeNumber('€', 2);
							}

							return '';
						}
					}
				},
				{
					field: '#',
					headerName: 'Action',
					width: 110,
					editable: false,
					suppressSizeToFit: true,
					cellRenderer: (params) => {
						if (!params.node.rowPinned && !this._isLock) {
							const N_div = document.createElement('div');

							const N_delete = document.createElement('button');

							N_delete.classList.add('h-100', 'py-0', 'btn-transparent');
							N_delete.innerHTML = '<i class="text-danger h5 icon icon-trash-alt"></i>';
							N_delete.setAttribute('confirmation', '');
							N_delete.setAttribute('tooltip', 'Supprimer');
							N_delete.type = 'button';

							N_delete.addEventListener('click', () => {
								params.api.updateRowData({
									remove: [params.node.data]
								});

								if (params.data.typeLine === 'delivery-price') {
									this._deleteDeliveryprice();
								}

								this._update();
							});

							const N_refreshPrice = document.createElement('button');

							N_refreshPrice.classList.add('h-100', 'py-0', 'btn-transparent');
							N_refreshPrice.setAttribute('confirmation', '');
							N_refreshPrice.setAttribute('tooltip', 'Remettre le prix par défaut');
							N_refreshPrice.type = 'button';

							if (!params.data.reference) {
								N_refreshPrice.disabled = true;
								N_refreshPrice.classList.add('cursor-not-allowed');
								N_refreshPrice.innerHTML = '<i class="text-muted h5 icon icon-solid-undo"></i>';
							} else {
								N_refreshPrice.innerHTML = '<i class="text-dark h5 icon icon-solid-undo"></i>';
							}

							N_refreshPrice.addEventListener('click', () => {
								if (this.provider && params.data.reference) {
									S_ProductProvider.getInstance().getByProductAndProvider(params.data.reference, this.provider).then((data) => {
										if (data.purchase_price) {
											params.node.setDataValue('price', data.purchase_price);

											params.api?.refreshCells({ force: true, rowNodes: [params.node] });

											this._update();
										} else {
											toaster.error('Prix introuvable');
										}
									}).catch(() => {
										toaster.error('Prix introuvable');
									});
								}
							});

							const N_discount = document.createElement('button');

							N_discount.classList.add('h-100', 'py-0', 'btn-transparent');

							if (params.data.typeLine) {
								N_discount.disabled = true;
								N_discount.classList.add('cursor-not-allowed');
							}

							if (params.data.type !== 'discount') {
								N_discount.innerHTML = '<i class="h5 text-danger icon icon-solid-tag"></i>';
							} else {
								N_discount.innerHTML = '<i class="h5 text-success icon icon-solid-tag"></i>';
							}

							N_discount.setAttribute('confirmation', '');
							N_discount.setAttribute('tooltip', 'Remise');
							N_discount.type = 'button';

							N_discount.addEventListener('click', () => {
								params.data.type = params.data.type === 'discount' ? '' : 'discount';
								params.node.setData(params.data);
								params.api?.refreshCells({ force: true, rowNodes: [params.node] });

								this._update();
							});

							N_div.appendChild(N_discount);
							N_div.appendChild(N_delete);
							N_div.appendChild(N_refreshPrice);

							return N_div;
						} else {
							return '';
						}
					}
				}
			],
			getContextMenuItems: (params) => {
				const result: any[] = [
					...AggridUtils.contextMenuAddLines(params, () => {
						return {};
					}, this._isLock),
					{
						name: 'Produits',
						disabled: this._isLock,
						icon: '<i class="icon icon-solid-box-open"></i>',
						subMenu: [{
							name: 'Sélectionner',
							action: () => {
								new M_SelectProduct().open().then(async ({ products, groups }) => {
									const data = [];

									for (const id in products) {
										const product = products[id].product;
										const quantity = products[id].quantity;

										const productProvider = await this.getProductProvider(product._id);

										data.push({
											style: {},
											reference: product._id,
											quantity,
											unit: product.unit || 'U',
											brand: product.brand,
											price: productProvider.purchase_price || '',
											label: productProvider.provider_label || product.name || '',
											quoteBase: productProvider.quoteBase || ''
										});
									}

									for (const key in groups) {
										data.push({
											label: groups[key][0].name
										});

										for (const item of groups[key]) {
											const product = item.product;
											const quantity = item.quantity;

											if (product._id) {
												const productProvider = await this.getProductProvider(product._id);

												data.push({
													style: {},
													reference: product._id,
													quantity,
													unit: product.unit || 'U',
													brand: product.brand,
													price: productProvider.purchase_price || '',
													label: productProvider.provider_label || product.name || '',
													quoteBase: productProvider.quoteBase || ''
												});
											}
										}
									}

									params.api?.applyTransaction({
										add: data
									});

									params.api?.setPinnedBottomRowData([{}]);
								});
							}
						}, {
							name: 'Éditer',
							disabled: !params.node?.data.reference,
							action: () => {
								ModalManager.getInstance().open('products', params.node.data.reference);
							}
						}]
					}
				];

				if (params.node && !this._isLock) {
					result.push({
						name: 'Style',
						subMenu: [{
							name: 'Gras',
							action: () => {
								params.node.data.style = params.node.data.style || {};
								params.node.data.style.bold = !params.node.data.style.bold;

								params.node.setData(params.node.data);

								params.api?.redrawRows({ rowNodes: [params.node] });
							}
						}, {
							name: 'Titre',
							action: () => {
								params.node.data.style = params.node.data.style || {};
								params.node.data.style.title = !params.node.data.style.title;

								params.node.setData(params.node.data);

								params.api?.redrawRows({ rowNodes: [params.node] });
							}
						}]
					});
				}

				result.push({
					name: 'Ajout Frais de port',
					disabled: this._isLock,
					action: () => {
						params.api?.applyTransaction({
							add: [{
								typeLine: 'delivery-price'
							}]
						});
						params.api?.setPinnedBottomRowData([{}]);
					}
				});

				result.push({
					name: 'Ajout adresse',
					disabled: this._isLock,
					subMenu: [{
						name: 'Facturation',
						action: () => {
							params.api?.applyTransaction({
								add: [{
									typeLine: 'bill-address'
								}]
							});
						}
					}, {
						name: 'Livraison',
						action: () => {
							params.api?.applyTransaction({
								add: [{
									typeLine: 'delivery-address'
								}]
							});
						}
					}]
				});

				return result;
			},
			onRowDataUpdated: () => {
				this._update();
			},
			onCellEditingStopped: async (params) => {
				if (['label'].includes(params.column.getColId())) {
					params.node.setDataValue(params.column.getColId(), params.newValue.trim());
				}

				// Au changement d'un produit
				if (params.column.getColId() === 'reference') {
					if (!params.data.quantity) {
						params.node.setDataValue('quantity', 1);
					}

					const product = await S_Products.getInstance().getById(params.data.reference) || {};

					params.node.setDataValue('unit', product.unit || 'U');
					params.node.setDataValue('brand', product.brand || '');

					const data = await this.getProductProvider(params.data.reference);

					params.node.setDataValue('price', data.purchase_price || '');
					params.node.setDataValue('label', data.provider_label || product.name || '');
					params.node.setDataValue('quoteBase', data.quoteBase || '');
				}

				if (params.column.getColId() === 'quantity') {
					if (params.data.quoteCustomer && Number(params.data.quantity) > Number(params.data.maxQuantity)) {
						params.node.setDataValue('quantity', params.data.maxQuantity);
					}
				}

				if (['price', 'quantity', 'reference'].includes(params.column.getColId())) {
					params.api?.refreshCells({ force: true, rowNodes: [params.node] });
				}

				this._update();
			},
			rowData: []
		});

		const N_grid = this.N_el!.querySelector(`#${this.idTab} #grid`) as HTMLElement;

		new Grid(N_grid, this._gridOptions, { modules: AllModules });
	}

	public set data(content: any) {
		this._gridOptions.rowData = content;
		this._gridOptions.api?.setRowData(content);
		this._gridOptions.api?.setPinnedBottomRowData([{}]);
		this.getInfos();
	}

	public get gridOptions() {
		return this._gridOptions;
	}

	public get data(): any {
		const results: any[] = [];

		this._gridOptions.api?.stopEditing();

		this._gridOptions.api?.forEachNode((node: any) => {
			results.push(node.data);
		});

		return results;
	}

	public getInfos() {
		let globalPrice = new Decimal(0);

		this._gridOptions.api?.forEachNode((node: any) => {
			if (node.data.typeLine === 'delivery-price') {
				const tmp = Decimal.setDisplayNumber(this.deliveryPrice);
				globalPrice = globalPrice.plus(tmp);
			} else if (node.data.price && node.data.quantity) {
				const tmp = Decimal.setDisplayNumber(node.data.price).toDecimalPlaces(4).times(node.data.quantity || 0).toDecimalPlaces(2);

				if (node.data.type === 'discount') {
					globalPrice = globalPrice.minus(tmp);
				} else {
					globalPrice = globalPrice.plus(tmp);
				}
			}
		});

		globalPrice = globalPrice.toDecimalPlaces(2);

		this._gridOptions.api?.setPinnedBottomRowData([{ value: globalPrice.setSuffixAndHumanizeNumber('€') }]);

		return { globalPrice };
	}

	public updateDeliveryPrice() {
		let findDeliveryPriceLine = false;

		this._gridOptions.api?.forEachNode((node: any) => {
			if (node.data.typeLine === 'delivery-price') {
				findDeliveryPriceLine = true;
			}
		});

		if (!findDeliveryPriceLine) {
			this._gridOptions.api?.applyTransaction({
				add: [{
					typeLine: 'delivery-price'
				}]
			});
		}

		this._gridOptions.api?.refreshCells({ force: true });

		this.getInfos();
	}

	public updateMaterials() {
		if (this.provider) {
			this._gridOptions.api?.forEachNode(async (node) => {
				if (node.data.reference) {
					const data = await this.getProductProvider(node.data.reference);

					node.setDataValue('price', data.purchase_price || '');
					node.setDataValue('label', data.provider_label || node.data.label || '');

					node.setDataValue('quoteBase', data.quoteBase || '');
				}
			});

			this._update();
		}
	}

	public updateCommandsCustomer(newMaterials: any[]) {
		this._gridOptions.api?.applyTransaction({
			add: newMaterials
		});

		this.updateMaterials();
	}

	public enableTab() {
		const contentLink = this.querySelector('a') as HTMLElement;
		contentLink?.classList.remove('disabled');
	}

	public setOnUpdate(cb: () => any) {
		this._update = cb;
	}

	public setGetForm(cb: () => string) {
		this._form = cb;
	}

	public setDeleteDeliveryPrice(cb: () => void) {
		this._deleteDeliveryprice = cb;
	}

	public static register() {
		customElements.define(ContentDeliveriesTab.tagName, ContentDeliveriesTab);
	}
}

export default ContentDeliveriesTab;
