import { Module, Modules, Router } from '@autoprog/core-client';

import C_BillsProvider from './js/controllers/Bills.Provider';
import T_BillsProvider from './tpl/bills.provider.html';

import CE_BillsTab from './js/customElements/Bills';
import CE_PaymentTab from './js/customElements/Payment';

class BillProviderModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_PaymentTab.register();
		CE_BillsTab.register();
	}

	public async preInit() {

	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/billsPage/provider',
			controller: C_BillsProvider as any,
			tpl: T_BillsProvider,
			requireValidUser: true
		});
	}

	public async postInit() {

	}
}

Modules.registerModule('Factures-Fournisseur', BillProviderModule);
