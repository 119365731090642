import { LoggedUser, global } from '@autoprog/core-client';

import { GridOptions } from '@ag-grid-enterprise/all-modules';

import Decimal from './Decimal';

import _ from 'lodash';

class Utils {
	private constructor() { }

	public static convertDisplayNumber(text: string | number = '') {
		return Number((text || '').toString().replace(',', '.').replace(/[\s€]/gmi, ''));
	}

	public static getPriceFilterParams() {
		const priceFilterParams = {
			allowedCharPattern: '\\d\\-\\,\\.\\€',
			numberParser: (text: string) => {
				return text === null ? null : Decimal.setDisplayNumber(text).toNumber();
			}
		};
		return priceFilterParams;
	}

	public static humanizeNumber(number: string | number, numberDecimal: number | string = 2) {
		number = Utils.convertDisplayNumber(number).toString();
		numberDecimal = Number(numberDecimal);

		if (isNaN(parseFloat(number))) {
			return '';
		} else {
			let result = '';

			const chunkNumber = number.split('.');

			const tmp = chunkNumber[0].split('').reverse();

			for (let i = tmp.length - 1; i >= 0; i--) {
				result += tmp[i];

				if (i % 3 === 0 && i !== 0) {
					result += ' ';
				}
			}

			if (numberDecimal === -1) {
				chunkNumber[1] = chunkNumber[1] || '';

				if (chunkNumber[1].length) {
					result = result + global.DECIMAL_SEPARATOR + chunkNumber[1];
				}
			} else if (numberDecimal) {
				chunkNumber[1] = chunkNumber[1] || '';

				chunkNumber[1] = (chunkNumber[1] + _.fill(new Array(numberDecimal), '0').join('')).slice(0, numberDecimal);

				result = result + global.DECIMAL_SEPARATOR + chunkNumber[1];
			}

			return result;
		}
	}

	public static humanizePercent(number: number | string, ignoreDecimal = false) {
		const array = number.toString().replace('NaN', '0').split('.');
		array[0] = (array[0] || '0');
		array[1] = (array[1] || '');

		let value = `${('###' + array[0]).slice(-3)}${global.DECIMAL_SEPARATOR}${(array[1] + '00').slice(0, 2)}`;

		if (ignoreDecimal) {
			value = `${('###' + array[0]).slice(-3)}`;
		}

		return value.replace(/#/gmi, '&nbsp;');
	}

	public static setSuffix(value: string | number, suffix: string) {
		if (value === undefined || value === null) {
			value = '';
		}

		value = value.toString();

		if (value) {
			return value + ' ' + suffix;
		}

		return value;
	}

	/**
	 * @deprecated passer pas Decimal
	 * @param value 
	 * @param suffix 
	 * @param numberDecimal 
	 * @returns 
	 */
	public static setSuffixAndHumanizeNumber(value: string | number, suffix: string, numberDecimal: number | string = 2) {
		if (value === undefined || value === null) {
			value = '';
		}

		return Utils.setSuffix(Utils.humanizeNumber(value, numberDecimal), suffix);
	}

	public static roundUp(numberStr: string | number, decimalNumber = 2): number {
		const pow = Math.pow(10, decimalNumber);

		let number = parseFloat(numberStr.toString());
		number = Math.ceil(parseFloat((number * pow).toFixed(1)));
		number = parseFloat((number / pow).toString());

		return number;
	}

	public static removeTooltip() {
		const N_tooltip = document.querySelectorAll('.tooltip');
		const N_tooltipDescribed = document.querySelectorAll('[aria-describedby]');

		for (const N_el of N_tooltip) {
			N_el.remove();
		}

		for (const N_el of N_tooltipDescribed) {
			//@ts-ignore
			$(N_el).tooltip('hide');
		}
	}

	public static cancelEditingAggrid(gridOptions: GridOptions = {}, abortSignal: AbortSignal) {
		window.addEventListener('mousedown', (event) => {
			let stopEditing = true;
			let pointer = event.srcElement as HTMLElement | SVGElement;

			while (pointer && stopEditing) {
				let className: string = pointer.className || '';

				if (pointer.className instanceof SVGAnimatedString) {
					className = pointer.className.baseVal;
				}

				if (className.includes('dropdown-select') || className.includes('ag-input') || pointer.tagName === 'TEXTAREA') {
					stopEditing = false;
				}

				if (className.includes('ag-body') || className.includes('ag-header')) {
					pointer = document as unknown as HTMLElement;
				}

				pointer = pointer.parentNode as HTMLElement;
			}

			if (stopEditing && gridOptions?.api) {
				gridOptions.api.stopEditing();

				gridOptions.api.forEachNode((node) => {
					if (node.detailNode && node.detailNode.detailGridInfo && node.detailNode.detailGridInfo.api) {
						node.detailNode.detailGridInfo.api.stopEditing();
					}
				});
			}
		}, {
			signal: abortSignal
		});
	}

	public static chunkPage(content: any[], total: any[], NUMBER_LINE_FIRST_PAGE: number, NUMBER_LINE_PAGE: number) {
		const chunkContent: { [key: string]: any }[][] = [];

		while (content.length) {
			const tmp: { [key: string]: any }[] = [];

			let max = NUMBER_LINE_PAGE;

			if (!chunkContent.length) {
				max = NUMBER_LINE_FIRST_PAGE;
			}

			for (let i = 0; i < max; i++) {
				if (content[0]) {
					tmp.push(content.shift());
				} else {
					break;
				}
			}
			chunkContent.push(tmp);
		}

		if (chunkContent.length !== 0) {
			let max = NUMBER_LINE_PAGE;
			if (chunkContent.length === 1) {
				max = NUMBER_LINE_FIRST_PAGE;
			}

			if (chunkContent[chunkContent.length - 1].length + total.length > max) {
				chunkContent.push(total);
			} else {
				chunkContent[chunkContent.length - 1] = chunkContent[chunkContent.length - 1].concat(total);
			}
		}

		return chunkContent;
	}

	public static generateId() {
		return _.uniqueId(Date.now().toString(36) + '_');
	}

	public static get userID() {
		return LoggedUser.getInstance().get('id') || '';
	}

	public static get companyID(): string {
		const companyUsersSelectionJSON = localStorage.getItem('companyUsersSelectionJSON');
		const companyUserSelectionObject: { [key: string]: string } = companyUsersSelectionJSON ? JSON.parse(companyUsersSelectionJSON) : {};

		return companyUserSelectionObject[Utils.userID] || '';
	}
}

export default Utils;
