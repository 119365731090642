import moment from 'moment';

import { Form } from '@autoprog/core-client';

import FormControllerPageID from '@libs/FormControllerPageID';
import Modal from '@libs/Modal';

import T_modal from '../../tpl/modals/editQuote.html';

import '../../css/editQuoteOrderCustomer.scss';

import CE_GroupQuotesTab from '../customElements/GroupQuotesTab';
import CE_UsersTab from '../customElements/UsersTab';

import CE_AddressDelivery from '@libs/customElement/AddressDelivery';
import CE_AddressDeliveryReadOnly from '@libs/customElement/AddressDeliveryReadonly';
import CE_Button from '@libs/customElement/Button';
import CE_PageTabsMenu from '@libs/customElement/PageControllerID/PageTabsMenu';
import CE_Select2 from '@libs/customElement/Select2';
import CE_SitesFinalCustomer from '@libs/customElement/Sites-FinalCustomer';
import CE_SitesFinalCustomerReadonly from '@libs/customElement/Sites-FinalCustomer-Readonly';

import S_C_Address from '@services/Customer/CustomerAddressService';
import S_C_Contact from '@services/Customer/CustomerContactService';
import S_Customer from '@services/Customer/CustomerService';
import S_Quote from '@services/QuoteService';
import S_Site from '@services/Site/SiteService';

import { date } from '@js/types/_app/date';
import { sites } from '@js/types/_app/sites';

type editQuoteOrderCustomerData = {
	quoteID: string
	contact: string
	label: string
	description: string
	deliveryAddress: {
		type: string
		text: string
		site: string
		address: string
		GPSCoordinates: string
	}
	entryDate: date
	date: date
	deliveryDate: date
	finalCustomer: string
	sites: sites
	constributors: string[]
	informedPeople: string[]
	purchaseManager: string
	planningManager: string
	price: number
	selectedGroups: {
		[groupID: string]: number
	}
};

class EditQuoteOrderCustomer extends Modal {
	private formReadonly: { [key: string]: FormControllerPageID } = {};
	private form: { [key: string]: Form } = {};
	protected selectPostinit: { [key: string]: CE_Select2 | CE_AddressDelivery } = {};

	private customer: string;

	private currentEdit: { [key: string]: boolean } = {};

	private mode: string;

	private data: editQuoteOrderCustomerData | null = null;
	private quoteID: string | null = null;

	constructor(customer: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.customer = customer;

		this.mode = '';

		this.on('opened', async () => {
			this.element.classList.add('loading');

			const customerData = await S_Customer.getInstance().getDataToSelect2ByID(customer);

			const N_customer = this.element.querySelector<HTMLElement>('#customer')!;
			N_customer.innerHTML = customerData.text;

			this.preInit();

			this.initEdit('dates');
			this.initEdit('infos');
			this.initEdit('address');

			if (this.mode === 'add') {
				await this.initAddData();
			}

			await this.setData(this.data!);

			const N_addontTitle = this.element.querySelector<HTMLElement>('.modal-title-addon')!;
			const quoteText = await S_Quote.getInstance().getDisplayRefByID(this.quoteID!);

			N_addontTitle.innerHTML = quoteText;

			const N_GroupQuotesTab = this.element.querySelector<CE_GroupQuotesTab>(CE_GroupQuotesTab.tagName)!;
			N_GroupQuotesTab.setParentElement(this.element);

			const N_UsersTab = this.element.querySelector<CE_UsersTab>(CE_UsersTab.tagName)!;
			N_UsersTab.setParentElement(this.element);

			const N_PageTabsMenu = this.element.querySelector<CE_PageTabsMenu>(CE_PageTabsMenu.tagName)!;
			N_PageTabsMenu.setActive(`#${CE_GroupQuotesTab.tagName}`);

			N_UsersTab.initData({
				constributors: this.data!.constributors,
				informedPeople: this.data!.informedPeople,
				purchaseManager: this.data!.purchaseManager,
				planningManager: this.data!.planningManager
			});

			N_GroupQuotesTab.initData(this.data!.quoteID, this.data!.selectedGroups);

			const N_save = this.element.querySelector<HTMLButtonElement>('#save')!;

			N_save.addEventListener('click', () => {
				const data = {
					quoteID: this.quoteID,
					...this.formReadonly.infos.getData(),
					...this.formReadonly.dates.getData(),
					...this.formReadonly.address.getData(),
					selectedGroups: N_GroupQuotesTab.selectedGroups,
					price: N_GroupQuotesTab.price,
					...N_UsersTab.data
				};

				if (data.entryDate) {
					data.entryDate = data.entryDate.valueOf();
				} else {
					data.entryDate = '';
				}

				if (data.date) {
					data.date = data.date.valueOf();
				} else {
					data.date = '';
				}

				if (data.deliveryDate) {
					data.deliveryDate = data.deliveryDate.valueOf();
				} else {
					data.deliveryDate = '';
				}

				this.resolve(data);
			});

			this.postInit();

			this.element.classList.remove('loading');
		});
	}

	private async initAddData() {
		const data = await S_Quote.getInstance().convertToOrder(this.quoteID!);
		this.data = data;
	}

	public setEditData(data: editQuoteOrderCustomerData) {
		this.mode = 'edit';
		this.data = data;
		this.quoteID = data.quoteID;
		return this;
	}

	public setAddData(data: string) {
		this.mode = 'add';
		this.quoteID = data;
		return this;
	}

	private preInit() {
		const N_contact = this.element.querySelector<CE_Select2>('ap-select2-button[name="contact"]')!;
		const N_addressDelivery = this.element.querySelector<CE_AddressDelivery>(CE_AddressDelivery.tagName)!;

		N_contact.setRef({ id_customer: this.customer! });
		N_contact.create(this.element);

		N_addressDelivery.create(this.customer, this.element);

		this.selectPostinit.contact = N_contact;
		this.selectPostinit.deliveryAddress = N_addressDelivery;
	}

	private async setData(data: editQuoteOrderCustomerData) {
		const dataInfos = await this.convertDataInfos(data);

		this.formReadonly.infos.setData(dataInfos);
		this.form.infos.setData(dataInfos);

		const N_finalCustomerReadonly = this.element.querySelector<CE_SitesFinalCustomerReadonly>(CE_SitesFinalCustomerReadonly.tagName)!;
		N_finalCustomerReadonly.update(data.sites.hasFinalCustomer);

		const N_finalCustomer = this.element.querySelector<CE_SitesFinalCustomer>(CE_SitesFinalCustomer.tagName)!;
		N_finalCustomer.setFinalCustomer();

		const dataDates = {
			entryDate: moment(data.entryDate, 'x'),
			date: moment(data.date, 'x'),
			deliveryDate: moment(data.deliveryDate, 'x')
		};

		this.formReadonly.dates.setData(dataDates);
		this.form.dates.setData(dataDates);

		const dataAddress = await this.convertDataAdress(data);

		this.formReadonly.address.setData(dataAddress);
		this.form.address.setData(dataAddress);

		const N_AddressDelivery = this.element.querySelector<CE_AddressDelivery>(CE_AddressDelivery.tagName)!;
		N_AddressDelivery.update(data.deliveryAddress.type);

		const N_AddressDeliveryReadOnly = this.element.querySelector<CE_AddressDeliveryReadOnly>(CE_AddressDeliveryReadOnly.tagName)!;
		N_AddressDeliveryReadOnly.update(data.deliveryAddress.type);
	}

	private async convertDataInfos(data: { [key: string]: any }) {
		const sites: { id: string, text: string }[] = [];

		for (const site of data.sites.sites) {
			sites.push(await S_Site.getInstance().getDataToSelect2ByID(site));
		}

		return {
			contact: await S_C_Contact.getInstance().getDataToSelect2ByID(data.contact),
			label: data.label,
			description: data.description,
			finalCustomer: data.finalCustomer,
			sites: {
				sites,
				siteCustom: data.sites.siteCustom,
				hasFinalCustomer: data.sites.hasFinalCustomer
			}
		};
	}

	private async convertDataAdress(data: { [key: string]: any }) {
		return {
			deliveryAddress: {
				type: data.deliveryAddress.type,
				text: data.deliveryAddress.text,
				site: await S_Site.getInstance().getDataToSelect2ByID(data.deliveryAddress.site),
				address: await S_C_Address.getInstance().getDataToSelect2ByID(data.deliveryAddress.address),
				GPSCoordinates: data.deliveryAddress.GPSCoordinates
			}
		};
	}

	private initEdit(id: string) {
		const N_btnEdit = this.element.querySelector<CE_Button>(`[data-btn-edit="${id}"]`)!;
		const N_saveEdit = this.element.querySelector<CE_Button>(`[data-save-edit="${id}"]`)!;
		const N_cancelEdit = this.element.querySelector<CE_Button>(`[data-cancel-edit="${id}"]`)!;

		const N_readonly = this.element.querySelector<HTMLElement>(`[data-readonly-edit="${id}"]`)!;
		const N_container = this.element.querySelector<HTMLElement>(`[data-container-edit="${id}"]`)!;

		this.formReadonly[id] = new FormControllerPageID(N_readonly);
		this.form[id] = new Form(N_container as HTMLFormElement);

		const toggleEdit = () => {
			N_readonly.classList.toggle('d-none');
			N_container.classList.toggle('d-none');
			N_btnEdit.classList.toggle('d-none');
			N_saveEdit.classList.toggle('d-none');
			N_cancelEdit.classList.toggle('d-none');

			this.currentEdit[id] = !this.currentEdit[id];

			this.updateSaveButton();
		};

		N_btnEdit.addEventListener('click', () => {
			toggleEdit();
		});

		N_saveEdit.addEventListener('click', async () => {
			let data = this.form[id].getData() as { [key: string]: any };

			if (id === 'infos') {
				data = await this.convertDataInfos(data);
			}

			if (id === 'address') {
				data = await this.convertDataAdress(data);
			}

			this.formReadonly[id].setData(data);

			if (id === 'address') {
				const N_AddressDelivery = this.element.querySelector<CE_AddressDelivery>(CE_AddressDelivery.tagName)!;
				N_AddressDelivery.update(data.deliveryAddress.type);
			}

			toggleEdit();
		});

		N_cancelEdit.addEventListener('click', () => {
			toggleEdit();
		});

		N_container.classList.toggle('d-none');
		N_saveEdit.classList.toggle('d-none');
		N_cancelEdit.classList.toggle('d-none');
	}

	private updateSaveButton() {
		const N_save = this.element.querySelector<HTMLButtonElement>('#save')!;

		for (const id in this.currentEdit) {
			if (this.currentEdit[id]) {
				N_save.disabled = true;
				return;
			}
		}

		N_save.disabled = false;
	}

	protected postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default EditQuoteOrderCustomer;
