// CORE
import { Form } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';
import _ from 'lodash';

// TEMPLATE
import T_modal from '../../../tpl/modals/editPage/additionalInformation.html';

// LIBS
import DeadlinePaymentUtils from '@libs/utils/DeadlinePaymentUtils';
import Modal from '@libs/Modal';

// TYPES
import { address } from '@js/types/_app/address';
import { deadlinePayment } from '@js/types/_app/deadlinePayment';
import { relaunch } from '@js/types/_app/relaunch';
import { sites } from '@js/types/_app/sites';

// CUSTOM_ELEMENT
import CE_Address from '@libs/customElement/Address';
import CE_Select2 from '@libs/customElement/Select2';
import CE_SitesFinalCustomer from '@libs/customElement/Sites-FinalCustomer';

// SERVICE
import S_C_Address from '@services/Customer/CustomerAddressService';
import S_Customer from '@services/Customer/CustomerService';
import S_Sites from '@services/Site/SiteService';

type AdditionalInformationData = {
	infos: {
		customer: string
		autoliquidation: boolean
		description: string
		commentFinish: string
		comments: string
		finalCustomer: string
		number: string
		date: Moment
		datePayment: Moment
		sendDate: Moment
		email: string
	},
	sites: sites
	address: address
	addressDelivery: address
	deadlinePayment: deadlinePayment,
	relaunch: Pick<relaunch, 'date'>
};

class AdditionalInformation extends Modal {
	private selectPostinit: { [key: string]: CE_Select2 | CE_Address } = {};

	private form: Form | null;

	private oldData: AdditionalInformationData;

	constructor(data: AdditionalInformationData) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.form = null;
		this.oldData = data;

		this.on('opened', async () => {
			this.init();

			this.form = new Form(this.element.querySelector('form') as HTMLFormElement);

			await this.setData(this.oldData);
			this.initFinalCustomer();
			this.initEvents();
			this.postInit();

			const N_save = this.element.querySelector('#save') as HTMLButtonElement;

			N_save.addEventListener('click', async () => {
				N_save.loading(new Promise(async () => {
					const formData = this.form?.getData() as AdditionalInformationData;

					const resForm: { [key: string]: any } = formData;

					resForm.infos.customer = await S_Customer.getInstance().getDataToSelect2ByID(formData.infos.customer);
					resForm.address.id = await S_C_Address.getInstance().getDataToSelect2ByID(formData.address.id);
					resForm.addressDelivery.id = await S_C_Address.getInstance().getDataToSelect2ByID(formData.addressDelivery.id);

					const sites: any[] = [];
					for (const item of formData.sites.sites) {
						const tmp = await S_Sites.getInstance().getDataToSelect2ByID(item);
						sites.push(tmp);
					}

					resForm.sites.sites = sites;

					const res = _.merge(this.oldData, resForm);
					this.resolve(res);
				}));
			});
		});
	}

	private initEvents() {
		const N_deadlinePayment = this.element.querySelector('ap-deadline-payment') as HTMLInputElement;

		N_deadlinePayment.addEventListener('change', async () => {
			const deadlinePayment = this.form?.getDataByName('deadlinePayment.day') as number;
			const fixedDateDeadline = this.form?.getDataByName('deadlinePayment.fixedDate') as number;
			const typeDeadlinePayment = this.form?.getDataByName('deadlinePayment.type') as string;

			if (typeDeadlinePayment === '3') {
				const sendDate = this.oldData.infos.sendDate;

				if (sendDate) {
					const paidDate = DeadlinePaymentUtils.calculateDate(sendDate, deadlinePayment, fixedDateDeadline, typeDeadlinePayment);

					this.form?.setData({
						infos: {
							datePayment: paidDate.clone()
						},
						relaunch: {
							date: paidDate.clone().add(1, 'week')
						}
					});
				}
			} else {
				const date = this.oldData.infos.date;

				const paidDate = DeadlinePaymentUtils.calculateDate(date, deadlinePayment, fixedDateDeadline, typeDeadlinePayment);

				this.form?.setData({
					infos: {
						datePayment: paidDate.clone()
					},
					relaunch: {
						date: paidDate.clone().add(1, 'week')
					}
				});
			}
		});
	}

	private init() {
		const N_customer = this.element.querySelector('[name="infos.customer"]') as CE_Select2;
		const N_sites = this.element.querySelector('[name="sites.sites"]') as CE_Select2;
		const N_address = this.element.querySelector('[name-select="address.id"]') as CE_Address;
		const N_addressDelivery = this.element.querySelector('[name-select="addressDelivery.id"]') as CE_Address;

		N_customer.create(this.element, { disabled: true });

		N_address.setRef({ id_customer: N_customer.selectElement!, bill: true });
		N_address.create(this.element);

		N_addressDelivery.setRef({ id_customer: N_customer.selectElement! });
		N_addressDelivery.create(this.element);

		N_sites.setRef({ customer: N_customer.selectElement! });
		N_sites.create(this.element, { multiple: true });

		this.selectPostinit['infos.customer'] = N_customer;
		this.selectPostinit['address.id'] = N_address;
		this.selectPostinit['addressDelivery.id'] = N_addressDelivery;
		this.selectPostinit['sites.sites'] = N_sites;
	}

	private initFinalCustomer() {
		const N_sites = this.element.querySelector('ap-sites-final-customer') as CE_SitesFinalCustomer;
		N_sites.setFinalCustomer();
	}

	private async setData(data: AdditionalInformationData) {
		const sites: any[] = [];

		for (const item of data.sites.sites) {
			const tmp = await S_Sites.getInstance().getDataToSelect2ByID(item);
			sites.push(tmp);
		}

		const res: { [key: string]: any } = {
			infos: {
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infos.customer),
				autoliquidation: data.infos.autoliquidation,
				description: data.infos.description,
				commentFinish: data.infos.commentFinish,
				comments: data.infos.comments,
				finalCustomer: data.infos.finalCustomer,
				number: data.infos.number,
				datePayment: data.infos.datePayment,
				sendDate: data.infos.sendDate,
				email: data.infos.email
			},
			sites: {
				sites,
				siteCustom: data.sites.siteCustom,
				hasFinalCustomer: data.sites.hasFinalCustomer
			},
			address: {
				id: await S_C_Address.getInstance().getDataToSelect2ByID(data.address.id),
				text: data.address.text
			},
			addressDelivery: {
				id: await S_C_Address.getInstance().getDataToSelect2ByID(data.addressDelivery.id),
				text: data.addressDelivery.text
			},
			deadlinePayment: {
				day: data.deadlinePayment.day,
				type: data.deadlinePayment.type,
				fixedDate: data.deadlinePayment.fixedDate
			},
			relaunch: {
				date: data.relaunch.date
			}
		};

		this.form?.setData(res);
	}

	private postInit() {
		for (const key in this.selectPostinit) {
			this.selectPostinit[key].postInit();
		}
	}
}

export default AdditionalInformation;
