import { Router } from '@autoprog/core-client';

import C_database from '@js/controllers/Database';

// LIBS
import { GetContextMenuItemsParams, MenuItemDef } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import h from 'hyperscript';

import ProductImageCellRenderer from '../libs/ProductImageCellRenderer';

// MODAL
import M_print from '../modals/PrintProducts';

import ProductContextMenu from '../libs/ProductContextMenu';

import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

class ProductsTabs extends C_database {
	constructor(el: HTMLElement) {
		super(el, {
			name: 'Produits',
			database: 'products'
		});
	}

	protected header() {
		const router = Router.getInstance();

		const N_div = document.createElement('div');

		N_div.classList.add('d-flex', 'flex-grow-1');

		N_div.innerHTML = `
			<div class="d-flex flex-wrap align-items-center">
				<span class="font-weight-bold text-grey ml-2">Légende :</span>
					
				<small class="d-flex align-items-center ml-3 text-red-900">
					<div class="badge-legend bg-red-100"></div>
					Coût de revient > Prix de vente
				</small>

				<small class="d-flex align-items-center ml-3 text-orange-900">
					<div class="badge-legend bg-orange-100"></div>
					Produit en double
				</small>
			</div>

			<div class="ml-auto d-flex" id="container_button_header">
				<div permission="PRODUCTS.UPDATE_PRICE">
					<div class="dropdown ml-2 d-inline-flex h-100">
						<button class="btn btn-grey-200" type="button" data-toggle="dropdown">
							<i class="icon icon-solid-ellipsis-h"></i>
						</button>
						<div class="dropdown-menu mt-2" style="border-radius:5px">
							<div class="text-center font-weight-bold">
								Mise à jours prix
							</div>	
							<div class="dropdown-divider"></div>
							<div class="dropdown-item cursor-pointer" id="buying_price">Prix d'achat</div>
							<div class="dropdown-item cursor-pointer" id="purchase_price">Prix de vente</div>
						</div>
					</div>
				</div>
				<button class="btn btn-print ml-2" id="print" tooltip="Imprimer">
					<i class="icon icon-printer"></i>
				</button>
			</div>
        `;

		const N_print = N_div.querySelector('#print') as HTMLButtonElement;
		N_print.addEventListener('click', () => {
			const data: { [key: string]: any }[] = [];

			this.gridOptions.api.forEachNodeAfterFilterAndSort((node: any) => {
				data.push(node.data);
			});

			new M_print(data, this.gridOptions.columnApi.getColumnState()).open();
		});

		const N_buying = N_div.querySelector('#buying_price') as HTMLButtonElement;

		N_buying?.addEventListener('click', () => {
			router.navigate('#module/apps/updateProducts/buyingPrice');
		});

		const N_purchase = N_div.querySelector('#purchase_price') as HTMLButtonElement;

		N_purchase?.addEventListener('click', () => {
			router.navigate('#module/apps/updateProducts/price');
		});

		return N_div;
	}

	protected getContextMenu(params: GetContextMenuItemsParams): MenuItemDef[] {
		const product = params.node.data;

		return [
			...super.getContextMenu(params),
			'separator',
			ProductContextMenu.orderProduct([product], !params.node)
		];
	}

	protected getRowStyle(params: any) {
		if (params.node.data.errorPrice) {
			return {
				'background-color': 'var(--ap-red-50)',
				color: 'var(--ap-red-900)'
			};
		}

		if (params.node.data.duplicate) {
			return {
				'background-color': 'var(--ap-orange-50)',
				color: 'var(--ap-orange-900)'
			};
		}

		return {};
	}

	protected iconsColumn(params: any) {
		const result: HTMLElement[] = [];

		if (params.data.base64_photo || params.data.url_photo) {
			const cellRenderer = new ProductImageCellRenderer();
			cellRenderer.init({ params, value: params.data._id });

			result.push(cellRenderer.getGui());
		}

		//recupere le nombre de fichier non supprimer
		const numberAttachements = _.compact(_.values(params.data.attachments || {})).length;

		if (numberAttachements) {
			const N_icon = h('div#fileIndicator');

			N_icon.innerHTML = `
                <i class="icon h5 icon-file" tooltip="${numberAttachements} pièces jointes"></i>
                <span class="badge custom-badge" id="numberFile">${numberAttachements}</span>
            `;

			result.push(N_icon as HTMLElement);
		}

		return result;
	}

	protected get configFilter(): ConfigFilter {
		return [
			[
				{
					column: 'errorPrice',
					type: 'switch',
					filters: [
						{
							value: 'true',
							text: 'Coût de revient > Prix de vente',
							color: 'red-100'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'duplicate',
					type: 'switch',
					filters: [
						{
							value: 'true',
							text: 'Produit en double',
							color: 'orange-100'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'deprecated',
					type: 'switch-3',
					filters: [
						{
							values: ['false', 'true'],
							text: 'Obsolète'
						}
					]
				}
			]
		];
	}
}

export default ProductsTabs;
