import T_modal from '../../../tpl/modals/editPage/amounts.html';

import EditPageModal from '@libs/EditPageModal';

import CE_Select from '@libs/customElement/Select';

import S_Taxes from '@modules/Comptabilité/js/services/TaxesService';

type AmountsData = {
	infos: {
		tva: string
	}
};

class Amounts extends EditPageModal<AmountsData> {
	constructor(data: AmountsData) {
		super(data, T_modal);
	}

	protected async getDataForm(data: { [key: string]: any }) {
		data.infos.tva = await new S_Taxes().getDataToSelect2ByID(data.infos.tva);
		return data;
	}

	protected preInit() {
		const N_select_tva = this.element.querySelector<CE_Select>('[name="infos.tva"]')!;

		N_select_tva.options = {
			ajax: {
				url: new S_Taxes().createSelect2URL(),
				getParams: (search: string) => {
					return {
						search,
						refData: JSON.stringify({ type: 'purchase' })
					};
				}
			}
		};
	}

	protected async setData(data: AmountsData) {
		const res: { [key: string]: any } = {
			infos: {
				tva: await new S_Taxes().getDataToSelect2ByID(data.infos.tva)
			}
		};

		this._setFormData(res);
	}
}

export default Amounts;
