
import Options from '../../libs/GridOptions';

import Modal from '@libs/Modal';

import CE_Aggrid from '@libs/customElement/AgGrid';

import T_Modal from '../../../tpl/modals/AddQuote/DuplicateMultiple.html';

class DuplicateMultiple extends Modal {
	private indexID: number = 0;
	private optionsGrid: Options;

	constructor(data: { [key: string]: any }[]) {
		super({
			tpl: T_Modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.optionsGrid = new Options();
		this.optionsGrid.init();

		this.on('opened', () => {
			const options = this.optionsGrid.gridDetails('modal');

			options.columnDefs[0].checkboxSelection = true;
			options.columnDefs[0].rowDrag = false;

			options.columnDefs.pop();

			const N_grid = this.element.querySelector<CE_Aggrid>('#grid')!;

			N_grid.setGridOptions({
				suppressContextMenu: true,
				suppressRowClickSelection: true,
				rowSelection: 'multiple',
				columnDefs: options.columnDefs
			});

			N_grid.value = data;

			const N_save = this.element.querySelector('#save') as HTMLElement;

			N_save.addEventListener('click', () => {
				const rowNodes = N_grid.api?.getSelectedNodes() || [];
				const res: any[] = [];

				for (const item of rowNodes) {
					item.data._idGrid = this.generateID();

					res.push(item.data);
				}

				this.resolve({
					data: res,
					end: false
				});
			});

			const N_saveAtEnd = this.element.querySelector('#saveAtEnd') as HTMLElement;

			N_saveAtEnd.addEventListener('click', () => {
				const rowNodes = N_grid.api?.getSelectedNodes() || [];
				const res: any[] = [];

				for (const item of rowNodes) {
					item.data._idGrid = this.generateID();

					res.push(item.data);
				}

				this.resolve({
					data: res,
					end: true
				});
			});
		});
	}

	private generateID() {
		this.indexID++;
		return Date.now().toString(36) + '_' + this.indexID;
	}
}

export default DuplicateMultiple;
