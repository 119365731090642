import GenericService from '../GenericService';
import Utils from '@libs/utils/Utils';

import { Moment } from 'moment';

class ProviderOrderService extends GenericService {
	private static instance: ProviderOrderService | null = null;

	private prefix = 'CF';

	constructor() {
		super('commands-provider');
	}

	public static getInstance(): ProviderOrderService {
		if (!ProviderOrderService.instance) {
			ProviderOrderService.instance = new ProviderOrderService();
		}

		return ProviderOrderService.instance;
	}

	public async cancelOrder(id: string) {
		return this.put_function('cancelOrder', { id, user: Utils.userID });
	}

	public updateNumberByDate(number: string, date: Moment) {
		const chunkNumber = number.replace(`${this.prefix}-`, '').split('-');

		return `${this.prefix}-${date.format('YYYY-MM')}-${chunkNumber.slice(2).join('-')}`;
	}

	public getDataToRecap(startDate: number, endDate: number) {
		return this.get_function('dataToRecap', { user: Utils.userID, startDate, endDate, companyID: Utils.companyID });
	}

	public getDataByOrderCustomer(id_order_customer: string) {
		return this.get_function('dataByOrderCustomer', { id: id_order_customer });
	}
}

export default ProviderOrderService;
