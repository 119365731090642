import { Alert, SocketIO } from '@autoprog/core-client';

import '../../../css/open-documents.scss';

import Utils from '@libs/utils/Utils';

import moment from 'moment';

import S_Users from '@services/User/UserService';

class OpenDocuments extends HTMLElement {
	public static readonly tagName: string = 'ap-open-documents';
	private static dataServer: { [key: string]: any }[] = [];

	private container: HTMLDivElement | null = null;

	private currentFilter: string = '';

	private filters: {
		[key: string]: {
			typeText: string,
			iconClass: string,
			count: number
		}
	} = {};

	public static async checkOpen(id: string, table: string) {
		let find = false;
		let user = '';

		for (const item of OpenDocuments.dataServer) {
			if (item.id === id && item.table === table) {
				user = item.user;
				find = true;
				break;
			}
		}

		if (find) {
			user = await S_Users.getInstance().getDisplayRefByID(user);
			await Alert.confirm('Document déja ouvert.', `Ouvert par ${user} <br><br> Voulez vous l'ouvrir ?`, {
				noColor: 'close-modal',
				yesColor: 'validate-modal'
			});
		}
	}

	public async connectedCallback() {
		this.innerHTML = `
			<span class="badge custom-badge d-none" style="z-index:1" id="number-open-documents">0</span>
			<ap-button class="btn-navbar" id="open-documents-button" icon="folder-open/line" tooltip="Documents ouvert"></ap-button>
        `;

		this.currentFilter = 'all';

		this.init();
		this.initFilters();
		this.initItems();
		this.initEvents();
		this.updateFilter();
		this.countData();
	}

	private init() {
		this.container = document.createElement('div');
		this.container.id = 'open-documents-container';
		this.container.innerHTML = `
            <div class="h4 d-flex font-weight-bold">
                Documents ouverts
                <i class="icon icon-solid-times cursor-pointer ml-auto" id="close"></i>
            </div>
            <div id="filters">
                <div class="active" data-filter="all">Tous</div>
            </div>
            <div class="flex-grow-1 scroll-y mt-2" id="items-container"></div>
        `;

		const N_app = document.querySelector('#floating-modals-container');

		N_app?.appendChild(this.container);

		SocketIO.getInstance().on('openDocuments.update', (data: any) => {
			OpenDocuments.dataServer = data;

			this.updateNumberFilters();
			this.initItems();
			this.updateFilter();
			this.countData();
		});
	}

	private initFilters() {
		this.filters = {
			quotes: {
				typeText: 'Devis',
				iconClass: 'icon-solid-file-contract',
				count: 0
			},
			orders: {
				typeText: 'Commandes',
				iconClass: 'icon-solid-file-alt',
				count: 0
			},
			bills: {
				typeText: 'Factures',
				iconClass: 'icon-solid-file-invoice-dollar',
				count: 0
			},
			deliveries: {
				typeText: 'Bons de livraisons',
				iconClass: 'icon-solid-truck',
				count: 0
			}
		};

		const N_filters = this.container?.querySelector('#filters') as HTMLElement;
		for (const filter in this.filters) {
			const N_filter = document.createElement('div');
			N_filter.setAttribute('data-filter', filter);
			N_filter.setAttribute('tooltip', this.filters[filter].typeText);

			N_filter.innerHTML = `
				<i class="icon ${this.filters[filter].iconClass}"></i>
				<span class="badge d-none" id="number-open-documents-${filter}">0</span>
			`;

			N_filters?.append(N_filter);
		}
	}

	private updateNumberFilters() {
		for (const filter in this.filters) {
			const N_filters = this.container?.querySelector(`#filters #number-open-documents-${filter}`) as HTMLElement;

			N_filters.innerHTML = this.filters[filter].count.toString();
		}
	}

	private countData() {
		/**
		 * On compte et affiche le nombre de documents ouverts au total
		 */

		const N_number_open_documents = this.querySelector('#number-open-documents') as HTMLSpanElement;

		if (OpenDocuments.dataServer.length) {
			N_number_open_documents.classList.remove('d-none');
		} else {
			N_number_open_documents.classList.add('d-none');
		}

		N_number_open_documents.innerHTML = OpenDocuments.dataServer.length.toString();

		/**
		 * On compte et affiche le nombre de documents ouverts par type
		 */
		for (const filter in this.filters) {
			const N_number_filter = this.container!.querySelector('#number-open-documents-' + filter) as HTMLSpanElement;

			if (N_number_filter) {
				let count = 0;

				for (const item of OpenDocuments.dataServer) {
					if (item.type === filter) {
						count++;
					}
				}

				if (count > 0) {
					N_number_filter.classList.remove('d-none');
				} else {
					N_number_filter.classList.add('d-none');
				}

				N_number_filter.innerHTML = count.toString();
			}
		}
	}

	private initEvents() {
		/**
		 * Évenement au clic sur le bouton des documents ouverts : on ouvre (si fermé) ou ferme (si ouvert)
		 */
		const N_button = this.querySelector('#open-documents-button');
		N_button?.addEventListener('click', () => this.toggle());

		/**
		 * Évenement au clic sur la croix : fermeture
		 */
		const N_close = this.container!.querySelector('#close');
		N_close?.addEventListener('click', () => this.close());

		/**
		 * Événement au clic sur le filtre 'Tous' : reset des filtres + changement du filtre actuel
		 */
		const N_viewAll = this.container!.querySelector('[data-filter="all"]') as HTMLButtonElement;
		N_viewAll.addEventListener('click', () => {
			this.updateDisplayFilter('all');
		});

		for (const filter in this.filters) {
			this.initFiltersEvents(filter);
		}
	}

	private initItems() {
		const N_items_container = this.container!.querySelector('#items-container') as HTMLDivElement;

		if (OpenDocuments.dataServer.length === 0) {
			N_items_container.innerHTML = '<div class="text-muted">Aucun document ouvert</div>';
			N_items_container.classList.add('d-flex', 'align-items-center', 'justify-content-center');
		} else {
			N_items_container.classList.remove('d-flex', 'align-items-center', 'justify-content-center');
			this.renderItems(N_items_container);
		}
	}

	private renderItems(itemsContainer: HTMLDivElement) {
		itemsContainer.innerHTML = '';
		for (const key in OpenDocuments.dataServer) {
			itemsContainer.append(this.renderItem(OpenDocuments.dataServer[key]));
		}
	}

	private renderItem(item: { [key: string]: any } = {}) {
		const N_div = document.createElement('div');
		N_div.classList.add('open-documents-item');
		N_div.setAttribute('data-type', item.type);

		N_div.innerHTML = `
			<div class="mr-2">
				<i class="icon ${this.filters[item.type].iconClass}" style="line-height:20px"></i>
			</div>
			<div class="w-100">
				<div class="mb-1 d-flex">
					<div class="open-documents-item-title">${item.title}</div>
					<div class="open-documents-item-buttons d-none ml-auto">
						<button>
							<i class="icon icon-solid-folder-open"></i>
						</button>
						<button>
							<i class="icon icon-solid-save"></i>
						</button>
						<button>
							<i class="icon icon-solid-window-close"></i>
						</button>
					</div>
				</div>
				<div class="text-muted text-sm w-100">Ouvert par ${item.user}</div>
				<div class="text-muted text-sm w-100">${moment(item.date).format('DD/MM/YYYY HH:mm')}<span class="mx-2">&bull;</span>${item.number}</div>
				${item.description ? '<div class="text-muted text-xs w-100">' + item.description + '</div>' : ''}
			<div>
		`;

		return N_div;
	}

	private initFiltersEvents(filter: string) {
		const N_filter = this.container!.querySelector('[data-filter="' + filter + '"]') as HTMLButtonElement;
		N_filter.addEventListener('click', () => {
			Utils.removeTooltip();
			this.updateDisplayFilter(filter);
		});
	}

	private updateDisplayFilter(value: string) {
		this.currentFilter = value;

		const N_filters = this.container!.querySelectorAll('[data-filter]') as NodeListOf<HTMLInputElement>;
		N_filters.forEach((N_el) => {
			N_el.classList.remove('active');
		});

		const N_filter = this.container!.querySelector('[data-filter="' + value + '"]') as HTMLButtonElement;
		N_filter.classList.add('active');

		this.updateFilter();
	}

	private updateFilter() {
		const N_documents = document.querySelectorAll('#items-container [data-type]') as NodeListOf<HTMLElement>;

		if (this.currentFilter === 'all') {
			N_documents.forEach((N_el) => {
				N_el.classList.remove('d-none');
			});
		} else {
			N_documents.forEach((N_el) => {
				const type = N_el.getAttribute('data-type');
				if (type === this.currentFilter) {
					N_el.classList.remove('d-none');
				} else {
					N_el.classList.add('d-none');
				}
			});
		}
	}

	/**
	 * Ouvre la modale des documents ouverts
	 */
	public open() {
		Utils.removeTooltip();
		this.updateDisplayFilter('all');
		this.container!.classList.add('active');
	}

	/**
	 * Ferme la modale des documents ouverts
	 */
	public close() {
		Utils.removeTooltip();
		this.container!.classList.remove('active');
	}

	/**
	 * Ouvre (si elle est fermée) ou ferme (si elle est ouverte) la modale des documents ouverts
	 */
	public toggle() {
		Utils.removeTooltip();
		this.updateDisplayFilter('all');
		this.container!.classList.toggle('active');
	}

	public static register() {
		customElements.define(OpenDocuments.tagName, OpenDocuments);
	}
}

export default OpenDocuments;
