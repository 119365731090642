// CORE
import { Form, global } from '@autoprog/core-client';

// TEMPLATE
import T_modal from '../../../tpl/modals/receipts/infos.html';

// LIBS
import ModalDocument from '@libs/modals/ModalDocument';

// UTILS
import Utils from '@libs/utils/Utils';

// CUSTOM_ELEMENT
import CE_ContentReceipt from '../../libs/customElement/ContentReceiptGrid';
import CE_Select2 from '@libs/customElement/Select2';
import CE_TrackingProductTab from '@modules/TrackingProduct/js/customElement/TrackingProductTab';

// SERVICE
import S_Receipt from '@services/ReceiptService';

class Infos extends ModalDocument {
	private form: Form | null = null;

	private N_TrackingProductTab: CE_TrackingProductTab | null = null;

	private _options: { [key: string]: any };

	private N_ContentReceipt: CE_ContentReceipt | null = null;

	private events: EventListenerCanceller[] = [];

	private companyRefObject: { id: string, text: string } = { id: '', text: '' };

	constructor(options: { [key: string]: any }) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this._options = options;

		this.on('opened', async () => {
			const N_modalDialog = this.element.querySelector('.modal-dialog') as HTMLElement;
			N_modalDialog.style.maxWidth = global.IS_MOBILE ? '' : '80%';

			this.init();

			this.form = new Form(this.N_form!);

			this.initProduct();

			await this.getData();

			this.postInit();

			Utils.cancelEditingAggrid(this.N_ContentReceipt!.gridOptions, this.abortSignal);
		});

		this.on('closed', () => {
			for (const event of this.events) {
				event();
			}
		});
	}

	private async getData() {
		const { data } = await S_Receipt.getInstance().getDataToModal(this._options.id, this._options);

		if (data.data.companyID) {
			this.companyRefObject = data.data.companyID;
			data.data.companyID = this.companyRefObject.text;
		}

		this.form?.setData(data.data);

		this.N_TrackingProductTab!.data = data.data.trackingProducts;
		this.N_FilesTab!.data = data.data.attachments || {};
		this.N_NotesTab!.data = data.data.notes || [];
		this.N_ContentReceipt!.data = {
			locations: data.locations,
			products: data.data.products
		};

		return data;
	}

	protected async save() {
		const formData = this.form?.getData() as any;

		const newData: { [key: string]: any } = {
			_id: this._options.id,
			...formData,
			products: this.N_ContentReceipt!.data
		};

		newData.attachments = this.N_FilesTab!.data;
		newData.notes = this.N_NotesTab!.data;
		newData.trackingProducts = this.N_TrackingProductTab!.data;

		newData.companyID = this.companyRefObject.id;

		await S_Receipt.getInstance().save(newData);

		return newData;
	}

	protected init() {
		super.init();

		const N_provider = this.element.querySelector('[name="infos.provider"]') as CE_Select2;
		const N_command = this.element.querySelector('[name="infos.command"]') as CE_Select2;

		N_provider.create(this.element, { disabled: true });

		N_command.create(this.element, { disabled: true });

		this.selectPostinit['infos.provider'] = N_provider;
		this.selectPostinit['infos.command'] = N_command;

		this.initTrackingProduct();
	}

	protected postInit() {
		this.N_FilesTab!.postInit();
		this.N_TrackingProductTab!.postInit();

		this.N_ContentReceipt?.active();

		let stopevent = false;
		this.selectPostinit['infos.command'].on('change', async (value) => {
			if (!stopevent) {
				stopevent = true;
				const data = await S_Receipt.getInstance().getByOrder(value as string);
				if (data.length) {
					this._options = { id: data[0]._id };
				} else {
					this._options = {
						type: 'provider',
						data: [value]
					};
				}
				await this.getData();
				stopevent = false;
			}
		});

		super.postInit();
	}

	private async initProduct() {
		this.N_ContentReceipt = this.element.querySelector(CE_ContentReceipt.tagName) as CE_ContentReceipt;

		this.N_ContentReceipt.setParentElement(this.element);
	}

	private initTrackingProduct() {
		this.N_TrackingProductTab = this.element.querySelector('ap-tracking-product-tab') as CE_TrackingProductTab;

		this.N_TrackingProductTab.setParentElement(this.element, CE_TrackingProductTab.receptionMode);
	}
}

export default Infos;
