
import Modal from '@libs/Modal';

import T_modal from '../../tpl/modals/editBillingRequest.html';

import '../../css/editBillingRequest.scss';

import CE_RecapLine from '../customElements/editBillingRequest/RecapLine';
import CE_RecapOrder from '../customElements/editBillingRequest/RecapOrder';
import CE_Select from '@libs/customElement/Select';
import CE_ViewGroupQuote from '../customElements/editBillingRequest/ViewGroupQuote';

import S_C_Bill from '@services/Customer/CustomerBillService';
import S_C_BillingRequest from '@services/Customer/CustomerBillingRequestService';
import S_C_Order from '@services/Customer/CustomerOrderService';

import C_BillsCustomer from '@modules/BillsCustomer/js/controllers/Bills.Customer';

import { dataRecapQuote } from '../customElements/editBillingRequest/RecapQuote';

class EditBillingRequest extends Modal {
	private id_order: string;
	private id_edit: string | undefined;

	private mode: string = 'billing-request';

	constructor(idOrder: string, id_edit?: string) {
		super({
			tpl: T_modal,
			keyboard: false,
			backdrop: 'static'
		});

		this.id_order = idOrder;
		this.id_edit = id_edit;

		this.on('opened', async () => {
			this.element.classList.add('loading');

			const N_recapOrder = this.element.querySelector<CE_RecapOrder>(CE_RecapOrder.tagName)!;
			const N_viewGroupQuote = this.element.querySelector<CE_ViewGroupQuote>(CE_ViewGroupQuote.tagName)!;
			const N_selectQuotes = this.element.querySelector<CE_Select>('#quotes');
			const N_title = this.element.querySelector<HTMLElement>('#title');

			const N_date = this.element.querySelector<HTMLInputElement>('#date');
			const N_dateContainer = this.element.querySelector<HTMLElement>('#date-container');
			const N_designation = this.element.querySelector<HTMLInputElement>('#designation');
			const N_comment = this.element.querySelector<HTMLTextAreaElement>('#comment');
			const N_type = this.element.querySelector<HTMLTextAreaElement>('#type');
			const N_labelType = this.element.querySelector<HTMLTextAreaElement>('[for="type"]');
			const N_labelDesignation = this.element.querySelector<HTMLTextAreaElement>('[for="designation"]');

			N_recapOrder.mode = this.mode;

			if (this.mode === 'billing-request') {
				N_title!.innerHTML = 'Demande de facturation';
			}

			if (this.mode === 'create-bill') {
				N_title!.innerHTML = 'Création de facture';
				N_dateContainer?.classList.add('d-none');
			}

			if (this.mode === 'create-credit') {
				N_title!.innerHTML = 'Création d’un avoir pour la facture';
				N_labelType!.innerHTML = 'Type d\'avoir';
				N_labelDesignation!.innerHTML = 'Motif de l’avoir';
				N_dateContainer?.classList.add('d-none');
			}

			this.updateOptionsType();

			const N_save = this.element.querySelector<HTMLButtonElement>('#save');

			N_save!.disabled = true;

			N_selectQuotes?.addEventListener('change', () => {
				if (N_selectQuotes.value === 'all') {
					N_recapOrder.scrollIntoView({ behavior: 'smooth' });
				} else {
					const N_quote = this.element.querySelector<HTMLElement>(`[id-quote="${N_selectQuotes.value}"]`)!;
					N_quote.scrollIntoView({ behavior: 'smooth' });
				}
			});

			const order = await S_C_Order.getInstance().getById(this.id_order);
			let priceBygroup = {};

			if (this.mode === 'create-credit') {
				priceBygroup = await S_C_Bill.getInstance().getBillStateByID(this.id_edit!);
			} else {
				priceBygroup = await S_C_Bill.getInstance().getBillStateByOrder(this.id_order);
			}

			N_designation!.value = order.label;

			this.updateTitle(order);

			N_type?.addEventListener('change', () => {
				if (N_type.value === 'last') {
					N_recapOrder.setLastPrice();
				}

				N_recapOrder.toggleLastPrice(N_type.value === 'last');
			});

			const dataToRecap: dataRecapQuote[] = [];

			for (const item of order.orders) {
				dataToRecap.push({
					quoteID: item.quoteID,
					selectedGroups: item.selectedGroups
				});
			}

			N_recapOrder.addEventListener('register.quotes', ((e: CustomEvent<{ id: string, text: string }[]>) => {
				N_selectQuotes!.options = {
					data: [{
						id: 'all',
						text: `Tous (${e.detail.length})`
					}, ...e.detail]
				};
				N_selectQuotes!.value = 'all';
			}) as EventListener);

			N_recapOrder.addEventListener('select.group', ((e: CustomEvent<any>) => {
				const N_lines = this.element.querySelectorAll<CE_RecapLine>('[id-group]');

				for (const N_line of N_lines) {
					N_line.unselect();
				}

				const N_line = this.element.querySelector<CE_RecapLine>(`[id-quote="${e.detail._idQuote}"] [id-group="${e.detail._idGrid}"][id-tva="${e.detail.tva}"]`);
				N_line?.select();

				N_viewGroupQuote.value = e.detail;
			}) as EventListener);

			N_recapOrder.addEventListener('check.error', () => {
				this.updateButtonSave();
			});

			await N_recapOrder.setData(dataToRecap, priceBygroup);

			if (this.id_edit && this.mode === 'billing-request') {
				const { data } = (await S_C_BillingRequest.getInstance().getDataToModal(this.id_edit)).data;

				N_date!.value = data.date;
				N_designation!.value = data.designation;
				N_comment!.value = data.comment;
				N_type!.value = data.type;
				N_recapOrder!.value = data.details;
			}

			this.element.classList.remove('loading');

			N_save?.addEventListener('click', async () => {
				const data: { [key: string]: any } = {
					_id: this.id_edit,
					type: N_type!.value,
					billingStartDate: N_date!.valueAsDate?.getTime(),
					designation: N_designation!.value,
					comment: N_comment!.value,
					details: N_recapOrder.value,
					id_order: idOrder
				};

				if (this.mode === 'billing-request') {
					await S_C_BillingRequest.getInstance().save(data);
				}

				if (this.mode === 'create-bill') {
					this.ignoreChangeRoute = true;
					C_BillsCustomer.open(null, {
						idOrder,
						billingRequest: data
					});
				}

				if (this.mode === 'create-credit') {
					this.ignoreChangeRoute = true;

					data._id = undefined;

					data.id_bill = this.id_edit;

					C_BillsCustomer.open(null, {
						idOrder,
						billingRequest: data
					});
				}

				this.resolve();
			});

			this.updateButtonSave();
		});
	}

	public setMode(value: 'billing-request' | 'create-bill' | 'create-credit') {
		this.mode = value;
		return this;
	}

	private updateButtonSave() {
		const N_recapOrder = this.element.querySelector<CE_RecapOrder>(CE_RecapOrder.tagName)!;

		const N_save = this.element.querySelector<HTMLButtonElement>('#save');

		N_save!.disabled = N_recapOrder.error;
	}

	private updateOptionsType() {
		const N_options_advance = this.element.querySelector<HTMLOptionElement>('#type option[value="advance"]')!;
		const N_options_normal = this.element.querySelector<HTMLOptionElement>('#type option[value="normal"]')!;
		const N_options_last = this.element.querySelector<HTMLOptionElement>('#type option[value="last"]')!;
		const N_options_credit = this.element.querySelector<HTMLOptionElement>('#type option[value="credit"]')!;
		const N_options_creditError = this.element.querySelector<HTMLOptionElement>('#type option[value="credit-error"]')!;

		if (this.mode === 'create-credit') {
			N_options_advance.remove();
			N_options_normal.remove();
			N_options_last.remove();
		} else {
			N_options_credit.remove();
			N_options_creditError.remove();
		}
	}

	private async updateTitle(order: { [key: string]: any }) {
		const N_addonTitle = this.element.querySelector<HTMLElement>('.modal-title-addon')!;

		if (this.mode === 'create-credit') {
			const bill = await S_C_Bill.getInstance().getById(this.id_edit!);
			N_addonTitle!.innerHTML = bill.infos.number;
		} else {
			N_addonTitle!.innerHTML = order.internalNumber;
		}
	}
}

export default EditBillingRequest;
