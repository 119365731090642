
import tippy, { Instance, Props } from 'tippy.js';

import { ICellRenderer } from '@ag-grid-enterprise/all-modules';

import '../../css/productCellRenderer.scss';

class ProductImageCellRenderer implements ICellRenderer {
	private eGui: HTMLElement;
	private value: string;

	private instanceTooltip: Instance<Props> | null = null;

	constructor() {
		this.eGui = document.createElement('div');
		this.eGui.classList.add('productCellRenderer', 'no-image');
		this.eGui.innerHTML = `
			<img class="image"/>
		`;

		this.value = '';
	}

	public init(params: any) {
		this.value = params.value;

		if (this.value) {
			const N_image = this.eGui.querySelector<HTMLImageElement>('.image')!;

			N_image.src = `/function/products/image?id=${this.value}`;

			this.instanceTooltip?.destroy();

			N_image.addEventListener('load', () => {
				this.eGui.classList.remove('no-image');

				this.instanceTooltip = tippy(N_image, {
					trigger: 'mouseenter',
					placement: 'bottom-start',
					theme: 'light',
					content: `<ap-product-image-previewer src="/function/products/image?id=${this.value}"></ap-product-image-previewer>`,
					allowHTML: true
				});
			});
		}
	}

	public getGui() {
		return this.eGui;
	}

	public refresh() {
		return false;
	}
}

export default ProductImageCellRenderer;
