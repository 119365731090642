
import { GetContextMenuItemsParams, GridOptions, MenuItemDef } from '@ag-grid-enterprise/all-modules';

type itemAddLine = (index: number) => { [key: string]: any };

class AggridUtils {
	private constructor() { }

	public static contextMenuAddLines(params: GetContextMenuItemsParams, item: itemAddLine = () => { return {}; }, lock: boolean = false) {
		const addLine = {
			name: 'Ajouter ligne(s)',
			disabled: lock,
			subMenu: [{
				name: '1 ligne',
				action: () => {
					this.addLine(params, item(0));
				}
			}, {
				name: '5 lignes',
				action: () => {
					const data: any[] = [];
					for (let i = 0; i < 5; i++) {
						data.push(item(i));
					}

					this.addLines(params, data);
				}
			}, {
				name: '10 lignes',
				action: () => {
					const data: any[] = [];
					for (let i = 0; i < 10; i++) {
						data.push(item(i));
					}

					this.addLines(params, data);
				}
			}, {
				name: '20 lignes',
				action: () => {
					const data: any[] = [];
					for (let i = 0; i < 20; i++) {
						data.push(item(i));
					}

					this.addLines(params, data);
				}
			}]
		};

		const insertLine = {
			name: 'Insérer une ligne',
			disabled: lock,
			action: () => {
				this.insertLine(params, item(0));
			}
		};

		const res: MenuItemDef[] = [addLine];

		if (params.node) {
			res.push(insertLine);
		}

		return res;
	}

	public static addLine(params: GridOptions, data: { [key: string]: any }) {
		let index = 0;

		params.api?.forEachNode(() => {
			index++;
		});

		params.api?.applyTransaction({
			add: [data]
		});

		params.api?.ensureIndexVisible(index - 1);
	}

	public static addLines(params: GridOptions, data: { [key: string]: any }[]) {
		let index = 0;

		params.api?.forEachNode(() => {
			index++;
		});

		params.api?.applyTransaction({
			add: data
		});

		params.api?.ensureIndexVisible(index - 1);
	}

	public static insertLine(params: GetContextMenuItemsParams, data: { [key: string]: any }) {
		params.api?.applyTransaction({
			add: [data],
			addIndex: (params.node.rowIndex || 0) + 1
		});
	}

	public static insertLines(params: GetContextMenuItemsParams, data: { [key: string]: any }[]) {
		params.api?.applyTransaction({
			add: data,
			addIndex: (params.node.rowIndex || 0) + 1
		});
	}
}

export default AggridUtils;
