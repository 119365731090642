// NODE_MODULE
import { Moment } from 'moment';

// TEMPLATE
import T_modal from '../../../tpl/modals/editPage/additionalInformation.html';

// LIBS
import Modal from '@libs/EditPageModal';

// CUSTOM_ELEMENT
import CE_Address from '@libs/customElement/Address';
import CE_Select2 from '@libs/customElement/Select2';

// SERVICE
import S_C_Address from '@services/Customer/CustomerAddressService';
import S_Customer from '@services/Customer/CustomerService';

import { address } from '@js/types/_app/address';

type AdditionalInformationData = {
	infosCustomer: {
		customer: string,
	}
	autoliquidation: boolean,
	billAddress: address
	billDeliveryAddress: address
	deadlinePayment: {
		day: number
		type: string
		fixedDate: Moment
	}
	finalCustomer: string,
	detailsBill: string,
	comment: string
};

class AdditionalInformation extends Modal<AdditionalInformationData> {
	constructor(data: AdditionalInformationData) {
		super(data, T_modal);
	}

	protected preInit() {
		const N_customer = this.element.querySelector('[name="infosCustomer.customer"]') as CE_Select2;

		const N_billAddress = this.element.querySelector('[name-select="billAddress.id"]') as CE_Address;
		const N_billDeliveryAddress = this.element.querySelector('[name-select="billDeliveryAddress.id"]') as CE_Address;

		N_customer.create(this.element, { disabled: true });

		N_billAddress.setRef({ id_customer: N_customer.selectElement!, bill: true });
		N_billAddress.create(this.element);

		N_billDeliveryAddress.setRef({ id_customer: N_customer.selectElement! });
		N_billDeliveryAddress.create(this.element);

		this.selectPostinit['billAddress.id'] = N_billAddress;
		this.selectPostinit['billDeliveryAddress.id'] = N_billDeliveryAddress;
	}

	protected async setData(data: AdditionalInformationData) {
		const res: { [key: string]: any } = {
			infosCustomer: {
				customer: await S_Customer.getInstance().getDataToSelect2ByID(data.infosCustomer.customer)
			},
			autoliquidation: data.autoliquidation,
			billAddress: {
				id: await S_C_Address.getInstance().getDataToSelect2ByID(data.billAddress.id),
				text: data.billAddress.text
			},
			billDeliveryAddress: {
				id: await S_C_Address.getInstance().getDataToSelect2ByID(data.billDeliveryAddress.id),
				text: data.billDeliveryAddress.text
			},
			deadlinePayment: {
				day: data.deadlinePayment.day,
				type: data.deadlinePayment.type,
				fixedDate: data.deadlinePayment.fixedDate
			},
			finalCustomer: data.finalCustomer,
			detailsBill: data.detailsBill,
			comment: data.comment
		};

		this.form?.setData(res);
	}

	protected async getDataForm(data: { [key: string]: any }) {
		data.infosCustomer.customer = await S_Customer.getInstance().getDataToSelect2ByID(data.infosCustomer.customer);

		data.billAddress.id = await S_C_Address.getInstance().getDataToSelect2ByID(data.billAddress.id);
		data.billDeliveryAddress.id = await S_C_Address.getInstance().getDataToSelect2ByID(data.billDeliveryAddress.id);

		return data;
	}
}

export default AdditionalInformation;
