import { Router } from '@autoprog/core-client';
import tippy from 'tippy.js';

import { GetContextMenuItemsParams } from '@ag-grid-enterprise/all-modules';

import C_OrdersCustomer from '@modules/OrdersCustomer/js/controllers/Orders.Customer';
import Decimal from '@libs/utils/Decimal';
import Utils from '@libs/utils/Utils';

import { Config as ConfigFilter } from '@libs/customElement/FilterButton';

import M_EditBillingRequest from '../modals/EditBillingRequest';
import M_ListBillingRequest from '../modals/ListBillingRequest';

import DashboardController, { configColumns, optionsColumnsGrid } from '@js/controllers/DashboardController';
import Settings from '@modules/Settings/js/libs/Settings';

class ListCommand extends DashboardController {
	constructor(el: HTMLElement) {
		super(el, 'Commandes - clients', 'ORDERS._CUSTOMERS', 'commands-customer');
	}

	protected initHeader() {
		super.initHeader();

		const router = Router.getInstance();
		const N_recap = this.N_header.querySelector<HTMLButtonElement>('#recap')!;
		N_recap.addEventListener('click', () => {
			router.navigate('module/commands_export/customer');
		});

		const N_materials = this.N_header.querySelector<HTMLButtonElement>('#material')!;
		N_materials.addEventListener('click', () => {
			router.navigate('module/commands_export/material/customer');
		});
	}

	protected buttonAdd() {
		C_OrdersCustomer.open(null);
	}

	protected buttonEdit(id: string) {
		C_OrdersCustomer.open(id);
	}

	protected cellRendererActionIcons(params: any) {
		const N_div = document.createElement('div');
		N_div.classList.add('container-icons-aggrid');

		const N_billstate = document.createElement('div');
		N_billstate.classList.add('icon-state');
		N_billstate.innerHTML = '<ap-icon name="bill-euro/line"></ap-icon>';

		let billStateTooltip = '';
		let stateTooltip = '';

		//facturer a 100%
		if (params.data.bill.state.value === '2') {
			billStateTooltip = 'Facturée à 100%';
			N_billstate.style.setProperty('--color', 'var(--ap-green-500)');
			N_billstate.style.setProperty('--bg-color', 'var(--ap-green-100)');
		}

		//non valorisé
		if (params.data.bill.state.value === '3') {
			billStateTooltip = 'Commande non valorisé';
			N_billstate.style.setProperty('--color', 'var(--ap-teal-500)');
			N_billstate.style.setProperty('--bg-color', 'var(--ap-teal-100)');
		}

		//pas facturer
		if (params.data.bill.state.value === '0') {
			billStateTooltip = 'Facturation pas commencée';
			N_billstate.style.setProperty('--color', 'var(--ap-red-500)');
			N_billstate.style.setProperty('--bg-color', 'var(--ap-red-100)');
		}

		//en cours de facturation
		if (params.data.bill.state.value === '1') {
			billStateTooltip = 'Facturation en cours';
			N_billstate.style.setProperty('--color', 'var(--ap-orange-500)');
			N_billstate.style.setProperty('--bg-color', 'var(--ap-orange-100)');
		}

		const N_state = document.createElement('div');
		N_state.classList.add('icon-state');

		if (params.data.deliveries.state.value === '0') {
			stateTooltip = 'À préparer';
			N_state.innerHTML = '<ap-icon name="file-edit/line"></ap-icon>';
			N_state.style.setProperty('--color', 'var(--ap-black)');
			N_state.style.setProperty('--bg-color', 'var(--ap-grey-100)');
		}

		if (params.data.deliveries.state.value === '1') {
			stateTooltip = 'En cours de préparation';
			N_state.innerHTML = '<ap-icon name="product/line"></ap-icon>';
			N_state.style.setProperty('--color', 'var(--ap-blue-500)');
			N_state.style.setProperty('--bg-color', 'var(--ap-blue-100)');
		}

		if (params.data.deliveries.state.value === '2') {
			stateTooltip = 'Prête partiellement';
			N_state.innerHTML = '<ap-icon name="product/fill"></ap-icon>';
			N_state.style.setProperty('--color', 'var(--ap-orange-500)');
			N_state.style.setProperty('--bg-color', 'var(--ap-orange-100)');
		}

		if (params.data.deliveries.state.value === '3') {
			stateTooltip = 'Prête en totalité';
			N_state.innerHTML = '<ap-icon name="product/fill"></ap-icon>';
			N_state.style.setProperty('--color', 'var(--ap-green-500)');
			N_state.style.setProperty('--bg-color', 'var(--ap-green-100)');
		}

		if (params.data.deliveries.state.value === '4') {
			stateTooltip = 'Expédiée partiellement';
			N_state.innerHTML = '<ap-icon name="truck/line"></ap-icon>';
			N_state.style.setProperty('--color', 'var(--ap-orange-500)');
			N_state.style.setProperty('--bg-color', 'var(--ap-orange-100)');
		}

		if (params.data.deliveries.state.value === '5') {
			stateTooltip = 'Expédiée en totalité';
			N_state.innerHTML = '<ap-icon name="truck/line"></ap-icon>';
			N_state.style.setProperty('--color', 'var(--ap-green-500)');
			N_state.style.setProperty('--bg-color', 'var(--ap-green-100)');
		}

		tippy(N_billstate, {
			content: billStateTooltip,
			arrow: false,
			placement: 'right'
		});

		tippy(N_state, {
			content: stateTooltip,
			arrow: false,
			placement: 'right'
		});

		N_div.append(N_billstate, N_state);

		return N_div;
	}

	protected _getContextMenu(params: GetContextMenuItemsParams) {
		if (params.node) {
			const _id = params.node.data._id.value;
			const objBill = {
				name: 'Créer une facture',
				icon: '<ap-icon name="bill-euro/line"></ap-icon>',
				disabled: params.node.data.bill.state.value === '2', // || params.node.data.compta.error,
				action: () => {
					if (params.node.data.billingRequest.priceHT.value !== 0) {
						new M_ListBillingRequest(_id).open();
					} else {
						new M_EditBillingRequest(_id).setMode('create-bill').open();
					}
				}
			};

			const billingRequest = {
				name: 'Créer une demande de facturation',
				icon: '<ap-icon name="bill/line"></ap-icon>',
				disabled: params.node.data.bill.state.value === '2',
				action: () => {
					new M_EditBillingRequest(_id).setMode('billing-request').open().then(() => {
						this.getData();
					});
				}
			};

			return [
				...super._getContextMenu(params),
				'separator',
				objBill,
				'separator',
				billingRequest
			];
		} else {
			return [];
		}
	}

	protected async _onDataUpdate(params: any) {
		let priceTotal = new Decimal(0);
		let priceBill = new Decimal(0);
		let priceCredit = new Decimal(0);
		let priceRequest = new Decimal(0);

		params.api.forEachNodeAfterFilter(async (node: any) => {
			priceTotal = priceTotal.plus(node.data.priceHT.value);
			priceBill = priceBill.plus(node.data.bill.priceHT.value);
			priceCredit = priceCredit.plus(node.data.bill.priceCredit.value);
			priceRequest = priceRequest.plus(node.data.billingRequest.priceHT.value);
		});

		const notBill = priceTotal.minus(priceBill).toDecimalPlaces(2);

		priceTotal = priceTotal.toDecimalPlaces(2);
		priceBill = priceBill.toDecimalPlaces(2);
		priceCredit = priceCredit.toDecimalPlaces(2);
		priceRequest = priceRequest.toDecimalPlaces(2);

		this.setDataTitle([{
			text: 'Global HT',
			price: priceTotal.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Facturé HT',
			price: priceBill.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Restant à facturer HT',
			price: notBill.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'À facturer HT',
			price: priceRequest.setSuffixAndHumanizeNumber('€')
		}, {
			text: 'Avoir HT',
			price: priceCredit.setSuffixAndHumanizeNumber('€')
		}]);
	}

	protected getTitleContextMenu(data: { [key: string]: any }): string {
		return `${data.internalNumber.formattedValue} / ${data.infosCustomer.customer.formattedValue}`;
	}

	protected _getRowStyle(params: any): { [key: string]: string } {
		//teminée
		if (params.data.finish.value) {
			return {
				'background-color': 'var(--ap-green-50)',
				color: 'var(--ap-green-900)'
			};
		}

		//demande de facturation
		if (params.data.billingRequest.priceHT.value !== 0) {
			return {
				'background-color': 'var(--ap-blue-50)',
				color: 'var(--ap-blue-900)'
			};
		}

		return {
			'background-color': 'white'
		};
	}

	protected get configColumns(): configColumns {
		const enabledCompta = Settings.getInstance().get('accountings').enabled;

		return [
			{
				key: '_id',
				type: 'primaryKey',
				name: 'ID'
			},
			{
				key: 'internalNumber',
				type: 'string',
				name: 'N° Affaire'
			},
			{
				key: 'infosCustomer.customer',
				type: 'string',
				name: 'Client'
			},
			{
				key: 'infosCustomer.contact',
				type: 'string',
				name: 'Contact'
			},
			{
				key: 'infosCustomer.orderNumber',
				type: 'string',
				name: 'N° Commande Client'
			},
			{
				key: 'infosCustomer.addonNumber',
				type: 'string',
				name: 'Complément'
			},
			{
				key: 'date',
				type: 'date',
				name: 'Date Commande'
			},
			{
				key: 'entryDate',
				type: 'date',
				name: 'Date Commande'
			},
			{
				key: 'deliveryDate',
				type: 'date',
				name: 'Date Livraison'
			},
			{
				key: 'sites',
				type: 'string',
				name: 'Site(s)'
			},
			{
				key: 'quotes',
				type: 'string',
				name: 'Devis'
			},
			{
				key: 'affected',
				type: 'boolean',
				hide: true,
				name: 'Affectée'
			},
			{
				key: 'priceHT',
				type: 'number',
				name: 'Montant'
			},
			{
				key: 'bill.priceCredit',
				type: 'number',
				name: 'Montant avoir commercial'
			},
			{
				key: 'bill.priceHT',
				type: 'number',
				name: 'Montant facturé'
			},
			{
				key: 'bill.notBilled',
				type: 'number',
				name: 'Montant restant à facturer'
			},
			{
				key: 'bill.percent',
				type: 'string',
				name: 'Facturé'
			},
			{
				key: 'bill.state',
				type: 'object',
				filter: 'multiple',
				hide: true,
				object: {
					0: 'Pas commencée',
					1: 'En cours',
					2: 'Facturée à 100%',
					3: 'Non valorisé'
				},
				name: 'État facturation'
			},
			{
				key: 'billingRequest.priceHT',
				type: 'number',
				name: 'Montant à facturer'
			},
			{
				key: 'billingRequest.state',
				type: 'object',
				filter: 'multiple',
				hide: true,
				object: {
					none: 'Aucune demande',
					advance: 'Acompte / avancement',
					last: 'Définitive'
				},
				name: 'État demande de facturation'
			},
			{
				key: 'billingRequest.active',
				type: 'boolean',
				name: 'Demande de facturation en attente',
				hide: true
			},
			{
				key: 'deliveries.state',
				type: 'object',
				filter: 'multiple',
				object: {
					0: 'À préparer',
					1: 'En cours de préparation',
					2: 'Prête partiellement',
					3: 'Prête en totalité',
					4: 'Expédiée partiellement',
					5: 'Expédiée en totalité'
				},
				hide: true,
				name: 'État Livraison'
			},
			{
				key: 'manager',
				type: 'string',
				name: 'Responsable'
			},
			{
				key: 'finish',
				type: 'boolean',
				name: 'Fini',
				hide: true
			},
			{
				key: 'compta.error',
				type: 'boolean',
				name: 'Erreur Compta',
				hide: !enabledCompta
			}
		];
	}

	protected get configFilter(): ConfigFilter {
		const enabledCompta = Settings.getInstance().get('accountings').enabled;

		return [
			[
				{
					column: '_createBy_',
					type: 'switch',
					filters: [
						{
							value: Utils.userID,
							text: 'Commande personnelle'
						}
					]
				}, {
					type: 'separator'
				}, {
					column: 'affected',
					type: 'switch',
					filters: [{
						value: 'true',
						text: 'Commande affectée'
					}]
				}, {
					type: 'separator'
				}, {
					type: 'title',
					title: 'Demande facturation : '
				}, {
					column: 'billingRequest.state',
					type: 'checkbox',
					filters: [{
						value: 'none',
						text: 'Aucune demande'
					}, {
						value: 'advance',
						text: 'Acompte / avancement'
					}, {
						value: 'last',
						text: 'Définitive'
					}]
				}, {
					type: 'separator',
					hide: !enabledCompta
				}, {
					type: 'title',
					title: 'Compta :',
					hide: !enabledCompta
				}, {
					column: 'compta.error',
					type: 'switch',
					hide: !enabledCompta,
					filters: [{
						value: 'true',
						text: 'Erreur'
					}]
				}
			], [
				{
					column: 'finish',
					type: 'switch-3',
					filters: [{
						values: ['false', 'true'],
						text: 'Terminée'
					}]
				}, {
					type: 'separator'
				}, {
					type: 'title',
					title: 'État :'
				}, {
					column: 'deliveries.state',
					type: 'checkbox',
					filters: [{
						value: '0',
						text: 'À préparer',
						icon: 'icon-ri-file-edit-line',
						color: 'dark'
					}, {
						value: '1',
						text: 'En cours de préparation',
						icon: 'icon-solid-box-open',
						color: 'blue-600'
					}, {
						value: '2',
						text: 'Prête partiellement',
						icon: 'icon-solid-box',
						color: 'orange-400'
					}, {
						value: '3',
						text: 'Prête en totalité',
						icon: 'icon-solid-box',
						color: 'green-400'
					}, {
						value: '4',
						text: 'Expédiée partiellement',
						icon: 'icon-ri-truck-line',
						color: 'orange-400'
					}, {
						value: '5',
						text: 'Expédiée en totalité',
						icon: 'icon-ri-truck-line',
						color: 'green-400'
					}]
				}, {
					type: 'separator'
				}, {
					type: 'title',
					title: 'État facturation : '
				}, {
					column: 'bill.state',
					type: 'checkbox',
					filters: [{
						value: '0',
						text: 'Facturation pas commencée',
						icon: 'icon-outline-file-invoice-dollar',
						color: 'red-400'
					}, {
						value: '1',
						text: 'En cours de facturation',
						icon: 'icon-outline-file-invoice-dollar',
						color: 'orange-400'
					}, {
						value: '2',
						text: 'Facturée à 100%',
						icon: 'icon-outline-file-invoice-dollar',
						color: 'green-400'
					}]
				}
			]
		];
	}

	protected get optionsColumnsGrid(): optionsColumnsGrid {
		return {
			columns: {
				hidden: {
					icon: false,
					selection: true
				}
			},
			buttons: {
				hidden: {
					add: false,
					edit: false,
					duplicate: true,
					delete: true,
					print: true
				}
			}
		};
	}
}

export default ListCommand;
