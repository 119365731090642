import { global } from '@autoprog/core-client';

import GenericService from '@services/GenericService';
import ServiceManager from '@managers/ServiceManager';
import _ from 'lodash';

import Utils from '@libs/utils/Utils';

import CE_Select2Mobile from '../customElement/Select2Mobile';

import { DataFormat, GroupedDataFormat, Options } from 'select2';

type Result = DataFormat | GroupedDataFormat;
type RemoteResult = any;
export type OverrideOptions = Options<Result, RemoteResult>;

/**
 * Utilitaire pour la creation de Select2
 */
class Select2Utils {
	private service: GenericService;
	private element: JQuery<HTMLSelectElement> | null = null;

	private _refElement: { [key: string]: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement } = {};
	private _refData: { [key: string]: any } = {};
	private _refCallback: { [key: string]: () => any } = {};

	constructor(table: string, element?: HTMLSelectElement) {
		const Cls = ServiceManager.get(table);

		if (element) {
			this.element = $(element);
		}

		if (!Cls) {
			throw new Error(`Service ${table} non existant dans le Service Manager !`);
		}

		if (Cls!.getInstance !== undefined) {
			this.service = Cls!.getInstance();
		} else {
			this.service = new Cls();
		}
	}

	public setSelect(element: HTMLSelectElement) {
		this.element = $(element);
	}

	public setRefElement(key: string, element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) {
		this._refElement[key] = element;
	}

	public setRefValue(key: string, value: any) {
		this._refData[key] = value;
	}

	public setRefCallback(key: string, cb: () => any) {
		this._refCallback[key] = cb;
	}

	public get refData() {
		const result: any = {};

		for (const key in this._refElement) {
			_.set(result, key, this._refElement[key].value);
		}

		for (const key in this._refData) {
			_.set(result, key, this._refData[key]);
		}

		for (const key in this._refCallback) {
			_.set(result, key, this._refCallback[key]());
		}

		return result;
	}

	public getOptions(root: HTMLElement, overrideOption: OverrideOptions = {}) {
		return {
			ajax: {
				url: this.service.createSelect2URL(),
				method: 'GET',
				dataType: 'json',
				contentType: 'application/json',
				//delay: 250,
				data: (params: any) => {
					return {
						search: params.term || '',
						refData: JSON.stringify(this.refData),
						companyID: Utils.companyID
					};
				},
				processResults: (res: any) => {
					return {
						results: res.data
					};
				}
			} as any,
			dropdownParent: $(root),
			placeholder: '',
			allowClear: true,
			...overrideOption
		};
	}

	/**
	 * Crée un Select2 en Ajax.
	 * Si un ID de société est donné, les données affichées dans le select seront filtrées pour n'afficher que celles de cette société.
	 * 
	 * @param parent l'element parent
	 * @param overrideOptions (facultatif) les options pour l'écrasement
	 * @param companyID (facultatif) l'ID de la société
	 * @returns l'element Select2 en Ajax
	 */
	public create(parent?: HTMLElement, overrideOptions: Options<Result, RemoteResult> = {}): JQuery<HTMLElement> | null {
		if (global.IS_MOBILE) {
			if (this.element) {
				const N_el = this.element.get(0)!;
				const N_select2Mobile = document.createElement('ap-select2-mobile') as CE_Select2Mobile;

				overrideOptions.disabled = true;

				this.element.select2(this.getOptions(parent || N_el, overrideOptions) as any);

				N_el.parentNode?.appendChild(N_select2Mobile);

				N_select2Mobile.setOption(this.getOptions(parent || N_el, overrideOptions));
				N_select2Mobile.setSelect(this.element);

				return this.element;
			}

			return null;
		} else {
			if (this.element) {
				this.element.select2(this.getOptions(parent || this.element.get(0)!, overrideOptions) as any);

				return this.element;
			}

			return null;
		}
	}

	public async getDisplayRefValue(id: string) {
		return await this.service.getDataToSelect2ByID(id);
	}

	public static getOptions(table: string, root: HTMLElement, overrideOption: Options<Result, RemoteResult> = {}, companyID?: string) {
		const serviceClass = ServiceManager.get(table);

		if (!serviceClass) {
			throw new Error('Instance ' + table + ' non exist dans Service Manager');
		}

		const service = serviceClass.getInstance();

		return {
			ajax: {
				url: service.createSelect2URL(),
				method: 'GET',
				dataType: 'json',
				contentType: 'application/json',
				delay: 250,
				data: (params: any) => {
					return {
						search: params.term || '',
						...(companyID && { companyID })
					};
				},
				processResults: (res: any) => {
					return {
						results: res.data
					};
				}
			} as any,
			dropdownParent: $(root),
			placeholder: '',
			allowClear: true,
			...overrideOption
		};
	}
}

export default Select2Utils;
