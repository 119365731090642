import { LoggedUser, Module, Modules, Router, global } from '@autoprog/core-client';

import h from 'hyperscript';

import C_User from './js/controllers/User';
import T_User from './tpl/user.html';

import Utils from '@libs/utils/Utils';

import C_SettingsUserGroup from './js/controllers/Settings.UserGroup';
import T_SettingsUserGroup from './tpl/settings.UserGroup.html';

import CE_Button from '@libs/customElement/Button';

import SettingsRegister from '@modules/Settings/js/libs/SettingsRegister';

import './css/companies.scss';
import './css/user.scss';
import './css/additionalInformations.scss';
import './css/profilePicture.scss';

class ProfileModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);
	}

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/profile/user',
			controller: C_User as any,
			tpl: T_User,
			requireValidUser: true
		});

		const settingsRegister = SettingsRegister.getInstance();
		settingsRegister.addLink({
			id: 'users',
			Controller: C_SettingsUserGroup,
			tpl: T_SettingsUserGroup,
			icon: 'group/line',
			order: 0,
			category: 'general',
			name: 'Utilisateurs / Groupes'
		});
	}

	public async postInit() {
		if (!global.IS_MOBILE) {
			const loggedUser = LoggedUser.getInstance();

			const N_navbarRightContent = document.querySelector('#navbar-right-content') as HTMLElement;

			const N_username = h<CE_Button>('ap-button.btn-navbar-text', { attrs: { icon: 'user/line' } });

			const N_separator = h('ap-navbar-separator');

			N_navbarRightContent.append(N_separator, N_username);

			N_username.addEventListener('click', () => {
				C_User.open(null);
			});

			if (loggedUser.get('firstname') && loggedUser.get('lastname')) {
				N_username.text = loggedUser.get('firstname') + ' ' + loggedUser.get('lastname')[0] + '.';

				if (this.isModeAdmin()) {
					document.body.classList.add('is-admin');
				} else {
					document.body.classList.remove('is-admin');
				}
			}

			loggedUser.on('login', () => {
				if (loggedUser.get('firstname') && loggedUser.get('lastname')) {
					N_username.text = loggedUser.get('firstname') + ' ' + loggedUser.get('lastname')[0] + '.';

					if (this.isModeAdmin()) {
						document.body.classList.add('is-admin');
					} else {
						document.body.classList.remove('is-admin');
					}
				}
			});

			loggedUser.on('logout', () => {
				N_username.text = '';
			});
		}
	}

	private isModeAdmin() {
		return Utils.userID === '1' && !window.location.host.includes('localhost') && !window.location.host.includes('127.0.0.1');
	}
}

Modules.registerModule('Profil', ProfileModule);
