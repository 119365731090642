// CORE
import agUtils from '@libs/agGrid/french';
import { global } from '@autoprog/core-client';

// NODE_MODULE
import { AllModules, ColDef, ColGroupDef, Grid, GridOptions } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';

// TEMPLATE

// LIBS
import Decimal from '@libs/utils/Decimal';
import ModalManager from '@managers/ModalManager';

// PRINTER
// UTILS
// MODAL
import M_Group from '@modules/Products/js/modals/groupsProducts/GroupsProducts';

// CUSTOM_ELEMENT
// SERVICE
import S_Groups from '@services/Product/GroupsService';
import S_Product from '@services/Product/ProductService';
import S_StockEvent from '@services/StockEventService';
import S_Stocks from '@services/StockService';

class SelectProductsTabs extends HTMLElement {
	public static readonly tagName: string = 'ap-select-products-tabs';

	private mods: string[] | undefined;

	private N_productsNumber: HTMLElement | null = null;
	private N_productsTitle: HTMLElement | null = null;

	private N_groupsNumber: HTMLElement | null = null;
	private N_groupsTitle: HTMLElement | null = null;

	private gridOptionsProduct: GridOptions = {};
	private gridOptionsGroup: GridOptions = {};

	private _selectProducts: { [key: string]: any } = {};
	private _selectGroups: { [key: string]: any } = {};

	private isFilterEnabled: boolean = true;

	private isElectron = global.IS_ELECTRON;

	public async connectedCallback() {
		// Valeurs prises en comptes dans l'attribut 'mod' :
		// - "no-stock" (ne charge pas et n'affiche les informations concernant le stock)
		this.mods = this.getAttribute('mod')?.split(/\s+/);

		if (this.isElectron) {
			this.isFilterEnabled = false;
		}

		this.innerHTML = `
			<div class="d-flex flex-column h-100">
				<div class="d-flex mb-3">

					<ul class="nav nav-pills d-flex" role="tablist">
						<li class="nav-item border-right">
							<a class="nav-link px-3 active" data-toggle="tab" href="#product" role="tab">Produits</a>
						</li>
						<li class="nav-item border-right">
							<a class="nav-link px-3" data-toggle="tab" href="#group" role="tab">Groupes</a>
						</li>
					</ul>

					<div class="flex-grow-1 px-3 d-none" id="search_container">
						<input class="form-control" placeholder="Recherche" id="search">
					</div>

					<div class="d-flex ml-auto">
						<div class="d-flex align-items-center pr-2">
							<span class="mr-1" id="products-title">Produit selectionné :</span><span id="products-number"> 0</span>
						</div>
						<div class="d-flex align-items-center px-2 border-left">
							<span class="mr-1" id="groups-title">Groupe selectionné :</span><span id="groups-number"> 0</span>
						</div>
						<div class="pl-2 border-left" id="add_buttons">
							<button class="btn btn-info" id="add_product">
								Créer Produit
							</button>

							<button class="btn btn-info mr-2" id="add_group">
								Créer Groupe
							</button>
						</div>
					</div>
				
				</div>

				<div class="tab-content flex-grow-1">

					<div class="tab-pane  h-100 fade show active" id="product" role="tabpanel">
						<div class="w-100 h-100" id="content">
							<div id="grid_product" class="h-100 ag-theme-alpine">
							</div>
						</div>
					</div>

					<div class="tab-pane h-100 fade" id="group" role="tabpanel">

						<div class="w-100 h-100" id="content">
							<div id="grid_group" class="h-100 ag-theme-alpine"></div>
						</div>

					</div>
				</div>

			</div>
			
        `;

		const N_search = this.querySelector('#search') as HTMLInputElement;
		const N_search_container = this.querySelector('#search_container') as HTMLInputElement;

		if (this.isElectron) {
			this.removeAddButtons();
			N_search_container.classList.remove('d-none');
		} else {
			this.initAddButtons();
		}

		this.N_productsNumber = this.querySelector('#products-number') as HTMLElement;
		this.N_productsTitle = this.querySelector('#products-title') as HTMLElement;

		this.N_groupsNumber = this.querySelector('#groups-number') as HTMLElement;
		this.N_groupsTitle = this.querySelector('#groups-title') as HTMLElement;

		let timeout: any = null;
		N_search.addEventListener('input', () => {
			timeout && clearTimeout(timeout);
			timeout = setTimeout(() => {
				this.gridOptionsProduct.api?.setQuickFilter(N_search.value);
				this.gridOptionsGroup.api?.setQuickFilter(N_search.value);
			}, 200);
		});

		await this.initProduct();
		await this.initGroup();
	}

	private removeAddButtons() {
		const N_addButtons = this.querySelector('#add_buttons') as HTMLDivElement;
		N_addButtons.remove();
	}

	private initAddButtons() {
		const N_addProduct = this.querySelector('#add_product') as HTMLButtonElement;

		N_addProduct.addEventListener('click', async () => {
			const data = await ModalManager.getInstance().openWithReturnData('products');

			this._selectProducts[data._id] = data;

			this.gridOptionsProduct.api?.applyTransaction({
				add: [data]
			});
		});

		const N_addGroup = this.querySelector('#add_group') as HTMLButtonElement;

		N_addGroup.addEventListener('click', () => {
			new M_Group().open().then((data) => {
				this._selectGroups[data.name] = data;

				this.getDataGroup();
			});
		});
	}

	public static register() {
		customElements.define(SelectProductsTabs.tagName, SelectProductsTabs);
	}

	private async initProduct() {
		let stocksLocations: any[] = [];

		this.gridOptionsProduct = agUtils.french<GridOptions>({
			rowSelection: 'multiple',
			suppressDragLeaveHidesColumns: true,
			onRowClicked: (e) => {
				e.node.setSelected(!e.node.isSelected());
			},
			columnDefs: [
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: S_Product.getInstance().referenceKey,
					checkboxSelection: true
				},
				{
					headerName: 'Désignation',
					field: 'name'
				},
				{
					headerName: 'Marque',
					field: 'brand',
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					}
				},
				{
					headerName: 'Prix',
					field: 'price',
					cellClass: ['text-monospace', 'text-right'],
					cellRenderer: (params) => {
						return params.value.formattedValue;
					}
				}
			],
			floatingFilter: this.isFilterEnabled,
			suppressRowClickSelection: true,
			defaultColDef: {
				filter: 'agTextColumnFilter',
				filterParams: {
					newRowsAction: 'keep'
				},
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true,
				suppressMenu: true,
				resizable: true
			},
			onGridReady: async (params) => {
				const data = await S_Product.getInstance().getDataToAgGrid();

				data.rowData = data.rowData.filter((item: any) => !item.deprecated);

				params.api.setRowData(data.rowData);
				params.api.sizeColumnsToFit();

				if (!this.mods?.includes('no-stock')) {
					this.initStock(stocksLocations);
				}
			},
			onModelUpdated: (params) => {
				params.api.forEachNode((node) => {
					if (node.data) {
						node.setSelected(!!this._selectProducts[node.data._id]);
					}
				});
			},
			onRowSelected: (params) => {
				if (params.node.isSelected()) {
					this._selectProducts[params.node.data._id] = {
						product: params.node.data,
						quantity: 1
					};
				} else {
					delete this._selectProducts[params.node.data._id];
				}

				this.N_productsNumber && (this.N_productsNumber.innerHTML = Object.keys(this._selectProducts).length.toString());

				if (Object.keys(this._selectProducts).length > 1) {
					this.N_productsTitle && (this.N_productsTitle.innerHTML = 'Produits selectionnés : ');
				} else {
					this.N_productsTitle && (this.N_productsTitle.innerHTML = 'Produit selectionné : ');
				}
			}

		});

		if (!this.mods?.includes('no-stock')) {
			const tmp = await this.getStockColumn();
			stocksLocations = tmp.locations;
			this.gridOptionsProduct.columnDefs?.push(tmp.colDefs);
		}

		const N_grid = this.querySelector('#grid_product');

		new Grid(N_grid as HTMLElement, this.gridOptionsProduct, { modules: AllModules });

		$('[data-toggle="tab"]').on('shown.bs.tab', (e) => {
			if (e.target.getAttribute('href') === '#product') {
				this.gridOptionsProduct.api?.forEachNode((node) => {
					node.setSelected(!!this._selectProducts[node.data._id]);
				});
			}
		});
	}

	private async getStockColumn(): Promise<{ colDefs: ColGroupDef, locations: any[] }> {
		const childrenStock: ColDef[] = [];

		const locations = await S_Stocks.getInstance().getStockToCommandCustomer();

		for (const item of locations) {
			((item: any) => {
				childrenStock.push({
					headerName: item.name,
					width: 100,
					field: 'currentStock.' + item._id + '.quantity',
					cellClass: ['text-monospace', 'text-right'],
					suppressSizeToFit: true,
					valueGetter: (params: any) => {
						params.data.currentStock = params.data.currentStock || {};
						params.data.currentStock[item._id] = params.data.currentStock[item._id] || {};
						return params.data.currentStock[item._id].quantity;
					},
					cellRenderer: (params) => {
						return _.isUndefined(params.data.currentStock[item._id].quantity) ? '<i class="icon icon-solid-spinner icon-spin"></i>' : params.data.currentStock[item._id].quantity;
					}
				});
			})(item);
		}

		return {
			colDefs: { headerName: 'Stock', children: childrenStock },
			locations
		};
	}

	private async initStock(locations: any[]) {
		const { rowData } = await S_StockEvent.getInstance().getCurrentStock();

		const stock: { [key: string]: any } = {};
		for (const item of rowData) {
			stock[item.product._id] = stock[item.product._id] || {};
			stock[item.product._id][item.stock] = stock[item.product._id][item.stock] || { quantity: 0 };
			stock[item.product._id][item.stock].quantity = item.quantity.formattedValue;
		}

		this.gridOptionsProduct.api?.forEachNode((node) => {
			for (const item of locations) {
				stock[node.data._id] = stock[node.data._id] || {};
				node.data.currentStock = node.data.currentStock || {};
				node.data.currentStock[item._id] = stock[node.data._id][item._id] || { quantity: 0 };
			}

			node.setData(node.data);
		});
	}

	private async initGroup() {
		this.gridOptionsGroup = agUtils.french<GridOptions>({
			rowData: [],
			animateRows: true,
			floatingFilter: this.isFilterEnabled,
			suppressDragLeaveHidesColumns: true,
			suppressContextMenu: true,
			groupMultiAutoColumn: true,
			groupSelectsChildren: true,
			suppressRowClickSelection: true,
			rowSelection: 'multiple',
			onRowClicked: (e) => {
				if (e.node.group) {
					e.node.setSelected(!e.node.isSelected());
				}
			},
			defaultColDef: {
				suppressMenu: true,
				suppressMovable: true,
				filter: 'agTextColumnFilter',
				floatingFilterComponentParams: {
					suppressFilterButton: true
				},
				sortable: true,
				resizable: true,
				filterParams: {
					newRowsAction: 'keep'
				}
			},
			columnDefs: [
				{
					field: 'name',
					headerName: 'Nom',
					rowGroup: true,
					hide: true
				},
				{
					headerName: S_Product.getInstance().columnNameReference,
					field: `product.${S_Product.getInstance().referenceKey}`
				},
				{
					headerName: 'Désignation',
					field: 'product.name'
				},
				{
					headerName: 'Marque',
					field: 'product.brand',
					filter: 'agSetColumnFilter',
					floatingFilterComponentParams: {
						suppressFilterButton: false
					}
				},
				{
					headerName: 'Quantité',
					field: 'quantity',
					cellClass: ['text-monospace', 'text-right']
				}, {
					headerName: 'Prix',
					headerTooltip: 'Prix',
					field: 'product.price',
					filter: 'agNumberColumnFilter',
					cellClass: ['text-monospace', 'text-right'],
					comparator: (valueA, valueB) => {
						valueA = valueA?.value || '';
						valueB = valueB?.value || '';
						return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
					},
					floatingFilterComponentParams: {
						suppressFilterButton: false
					},
					valueGetter: (params) => {
						return params.data.product.price.value;
					},
					cellRenderer: (params) => {
						if (params.node.group) {
							let price = new Decimal(0);

							for (const node of params.node.childrenAfterGroup) {
								const tmpPrice = Decimal.setDisplayNumber(node.data.product.price.value).times(node.data.quantity || 0);
								price = price.plus(tmpPrice);
							}

							return `<span class="font-weight-bold">${price.setSuffixAndHumanizeNumber('€')}</span>`;
						} else {
							return params.data.product.price.formattedValue;
						}
					}
				}
			],
			autoGroupColumnDef: {
				headerName: 'Nom',
				cellRendererParams: {
					suppressCount: true,
					checkbox: true
				},
				filter: 'agTextColumnFilter',
				filterValueGetter: params => params.data.name
			},
			onRowSelected: (params) => {
				if (params.node.group) {
					const key = params.node.key || '';
					if (params.node.isSelected()) {
						this._selectGroups[key] = params.node.childrenAfterGroup?.map(item => item.data);
					} else {
						delete this._selectGroups[key];
					}
				}

				this.N_groupsNumber && (this.N_groupsNumber.innerHTML = Object.keys(this._selectGroups).length.toString());

				if (Object.keys(this._selectGroups).length > 1) {
					this.N_groupsTitle && (this.N_groupsTitle.innerHTML = 'Groupes selectionnés : ');
				} else {
					this.N_groupsTitle && (this.N_groupsTitle.innerHTML = 'Groupe selectionné : ');
				}
			}
		});

		const N_grid = this.querySelector('#grid_group') as HTMLElement;

		new Grid(N_grid, this.gridOptionsGroup, { modules: AllModules });

		await this.getDataGroup();

		$('[data-toggle="tab"]').on('shown.bs.tab', (e) => {
			if (e.target.getAttribute('href') === '#group') {
				this.gridOptionsGroup.api?.sizeColumnsToFit();
			}
		});
	}

	private async getDataGroup() {
		const data = await S_Groups.getInstance().getDataToAgGrid();

		this.gridOptionsGroup.api?.setRowData(data.rowData);

		this.gridOptionsGroup.api?.forEachNode((node) => {
			if (node.group) {
				node.setSelected(!!this._selectGroups[node.key || '']);
			}
		});
	}

	public get data() {
		return {
			products: this._selectProducts,
			groups: this._selectGroups
		};
	}

	public get onlyProducts() {
		const results: { [key: string]: any } = {};

		for (const id in this._selectProducts) {
			const product = this._selectProducts[id].product;
			const quantity = this._selectProducts[id].quantity;

			results[product._id] = results[product._id] || {};

			results[product._id].product = product;

			results[product._id].quantity = results[product._id].quantity || 0;
			results[product._id].quantity += Number(quantity);
		}

		for (const key in this._selectGroups) {
			for (const item of this._selectGroups[key]) {
				const product = item.product;
				const quantity = item.quantity;

				if (product._id) {
					results[product._id] = results[product._id] || {};

					results[product._id].product = product;

					results[product._id].quantity = results[product._id].quantity || 0;
					results[product._id].quantity = Number(quantity);
				}
			}
		}

		return results;
	}

	public resetSelection() {
		this.gridOptionsProduct.api?.forEachNode((node) => {
			node.setSelected(false);
		});
		this.gridOptionsGroup.api?.forEachNode((node) => {
			node.setSelected(false);
		});
		this._selectProducts = {};
		this._selectGroups = {};
	}
}

export default SelectProductsTabs;
