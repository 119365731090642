// CORE
import { LoggedUser, Router, utils } from '@autoprog/core-client';

// NODE_MODULE
import { Moment } from 'moment';
import { sha256 } from 'js-sha256';

// LIBS
import ControllerPageID from '@js/controllers/ControllerPageID';
import Utils from '@libs/utils/Utils';

// MODAL
import M_AdditionalInformations from '../modals/users/editPage/AdditionalInformations';
import M_Companies from '../modals/users/editPage/Companies';
import M_GeneralInformations from '../modals/users/editPage/GeneralInformations';
import M_Identifiers from '../modals/users/editPage/Identifiers';

// CUSTOM_ELEMENT
import CE_CompanySelect from '@libs/customElement/CompanySelect';

// SERVICE
import S_Companies from '@services/CompaniesService';

// TYPES
import { Company } from '@modules/Settings/js/types/company';

class UserCtrl extends ControllerPageID {
	private static options: { [key: string]: any } = {};

	private companies: Company[] = [];

	constructor(el: HTMLElement) {
		super(el);

		let id = Utils.userID;

		const query = utils.getQuery();
		id = query.id || id;

		this.options = UserCtrl.options || {};

		if (this.options.add) {
			id = null;
		}

		UserCtrl.options = {};

		this.init('users', id);
	}

	public static open(id: string | null, options: { [key: string]: any } = {}) {
		UserCtrl.options = options || {};

		if (id) {
			Router.getInstance().navigate(`/module/profile/user?id=${id}`);
		} else {
			Router.getInstance().navigate('/module/profile/user');
		}
	}

	protected async init(table: string, id: string) {
		await super.init(table, id);

		const data = await this.getData();

		this.companies = await S_Companies.getInstance().getAll() as Company[];

		const normalizedData = this.normalizeData(data);

		this.setData(normalizedData);

		this.postInit();

		this.initFullscreen();
		this.initEditButtons();

		this.updateCompaniesTab();
		this.updateLogoutDelayInfo();
	}

	private updateCompaniesTab() {
		const companies = this.form?.getDataByName('companies') as string[];

		const N_NoCompany = this.el.querySelector('#no-company') as HTMLDivElement;
		const N_UserCompanies = this.el.querySelector('#user-companies') as HTMLDivElement;

		if (companies) {
			N_NoCompany.classList.toggle('d-none', companies.length > 0);
			N_UserCompanies.classList.toggle('d-none', !(companies.length > 0));
			N_UserCompanies.classList.toggle('d-flex', companies.length > 0);
		} else {
			N_UserCompanies.classList.toggle('d-none', true);
			N_UserCompanies.classList.toggle('d-flex', false);
		}
	}

	private normalizeData(data: any): any {
		if (data.data.hiddenProductPhoto === undefined) {
			data.data.hiddenProductPhoto = false;
		}

		if (data.data.logoutAutoEnabled === undefined) {
			data.data.logoutAutoEnabled = false;
		}

		if (data.data.logoutDelay === undefined) {
			data.data.logoutDelay = 0;
		}

		if (data.data.quotesManager === undefined) {
			data.data.quotesManager = false;
		}

		if (data.data.manager === undefined) {
			data.data.manager = false;
		}

		if (data.data.deleted === undefined) {
			data.data.deleted = false;
		}

		if (data.data.isSuperAdmin === undefined) {
			data.data.isSuperAdmin = false;
		}

		if (data.data.hiddenInSearch === undefined) {
			data.data.hiddenInSearch = false;
		}

		return data;
	}

	private initEditButtons() {
		const N_edit_GeneralInformations = this.el.querySelector('[data-edit="generalInformations"]') as HTMLButtonElement;
		const N_edit_Identifiers = this.el.querySelector('[data-edit="identifiers"]') as HTMLButtonElement;
		const N_edit_AdditionalInformations = this.el.querySelector('[data-edit="additionalInformations"]') as HTMLButtonElement;
		const N_edit_Companies = this.el.querySelector('[data-edit="companies"]') as HTMLButtonElement;

		N_edit_GeneralInformations.addEventListener('click', () => {
			const res = {
				lastname: this.form?.getDataByName('lastname') as string,
				firstname: this.form?.getDataByName('firstname') as string,
				birthday: this.form?.getDataByName('birthday') as Moment,
				email: this.form?.getDataByName('email') as string,
				phone: this.form?.getDataByName('phone') as string,
				job: this.form?.getDataByName('job') as string
			};

			new M_GeneralInformations(res).open().then((data) => {
				this.setDataForm(data);
				this.updateSaveButton();
			});
		});

		N_edit_Identifiers.addEventListener('click', () => {
			const res = {
				login: this.form?.getDataByName('login') as string,
				password: this.form?.getDataByName('password') as string,
				groups: this.form?.getDataByName('groups') as string[]
			};

			new M_Identifiers(res).open().then((data) => {
				this.setDataForm(data);
				this.updateSaveButton();
			});
		});

		N_edit_AdditionalInformations.addEventListener('click', () => {
			const res = {
				hiddenProductPhoto: this.form?.getDataByName('hiddenProductPhoto') as boolean,
				logoutAutoEnabled: this.form?.getDataByName('logoutAutoEnabled') as boolean,
				logoutDelay: this.form?.getDataByName('logoutDelay') as number,
				quotesManager: this.form?.getDataByName('quotesManager') as boolean,
				manager: this.form?.getDataByName('manager') as boolean,
				deleted: this.form?.getDataByName('deleted') as boolean,
				isSuperAdmin: this.form?.getDataByName('isSuperAdmin') as boolean,
				hiddenInSearch: this.form?.getDataByName('hiddenInSearch') as boolean
			};

			new M_AdditionalInformations(res).open().then((data) => {
				this.setDataForm(data);
				this.updateLogoutDelayInfo();
				this.updateSaveButton();
			});
		});

		N_edit_Companies.addEventListener('click', () => {
			const res = {
				companies: (this.form?.getDataByName('companies') as string[]) || []
			};

			new M_Companies(res, this.companies).open().then((data) => {
				this.setDataForm(data);
				this.updateSaveButton();
				this.updateCompaniesTab();
			});
		});
	}

	private updateLogoutDelayInfo() {
		const isLogoutAutoEnabled = this.form?.getDataByName('logoutAutoEnabled') as boolean;

		const N_LogoutDelayInfo = this.el.querySelector('#logout-delay-info') as HTMLDivElement;
		N_LogoutDelayInfo.classList.toggle('d-none', !isLogoutAutoEnabled);
		N_LogoutDelayInfo.classList.toggle('d-flex', isLogoutAutoEnabled);
	}

	protected getPageData(newData: { [key: string]: any; }): { [key: string]: any; } {
		if (newData.password !== "C'est raté ! :)") {
			newData.password = sha256(newData.password);
		} else {
			delete newData.password;
		}

		return newData;
	}

	protected setUrl() {
		if (LoggedUser.getInstance().hasPermission('MAIN_SETTINGS.USERS_SYSTEM.USERS.EDIT')) {
			super.setUrl();
		}
	}

	/**
	 * Méthode appelée après la sauvegarde
	 */
	protected async postSave() {
		this.reloadCompanySelect();
	}

	/**
	 * Recharge le Custom Element CompanySelect de la barre de navigation en haut pour voir les modifications du nom en temps réel
	 */
	private reloadCompanySelect() {
		const N_CompanySelect = document.querySelector('#navbar ap-company-select') as CE_CompanySelect;
		N_CompanySelect.reload();
	}
}

export default UserCtrl;
