import { Module, Modules, Router } from '@autoprog/core-client';

import C_OrdersProvider from './js/controllers/Orders.Provider';
import T_OrdersProvider from './tpl/orders.provider.html';

import C_RecapProvider from './js/controllers/Recap.provider';
import T_RecapProvider from './tpl/recap.provider.html';

import CE_ContentTabs from './js/customElements/ContentTab';
import CE_OrderCustomer from './js/customElements/OrderCustomer';
import CE_ProviderOrderTabs from './js/customElements/ProviderOrderTabs';

import Notifications from '@modules/Apps/js/libs/Notifications';

class OrderProviderModule extends Module {
	public constructor(options: Record<string, any>) {
		super(options);

		CE_ProviderOrderTabs.register();
		CE_ContentTabs.register();
		CE_OrderCustomer.register();
	}

	public async preInit() { }

	public async init() {
		const router = Router.getInstance();

		router.addRoute({
			url: '/module/ordersPage/provider',
			controller: C_OrdersProvider as any,
			tpl: T_OrdersProvider,
			requireValidUser: true
		});

		router.addRoute({
			url: '/module/commands_export/provider',
			controller: C_RecapProvider as any,
			tpl: T_RecapProvider,
			requireValidUser: true
		});

		Notifications.addCategory(['command-providers'], {
			textFilter: 'Commande Fournisseur',
			icon: 'icon-solid-file-alt',
			action: async (item: { [key: string]: any }) => {
				if (item.data.id) {
					C_OrdersProvider.open(item.data.id, { notification: item._id });
				}
			}
		});
	}

	public async postInit() { }
}

Modules.registerModule('Commandes-Fournisseur', OrderProviderModule);
