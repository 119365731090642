import '@css/customElements/switch.scss';

class Switch extends HTMLElement {
	public static readonly tagName: string = 'ap-switch';

	public connectedCallback() {
		const name = this.getAttribute('name') as string;

		this.innerHTML = `
			<label class="ap-switch">
				<input type="checkbox" name=${name}>
				<span class="slider round"></span>
			</label>
        `;

		const N_input = this.querySelector<HTMLInputElement>('input');
		N_input?.addEventListener('change', () => {
			this.dispatchEvent(new Event('change'));
		});

		this.removeAttribute('name');
	}

	public get checked() {
		const N_input = this.querySelector<HTMLInputElement>('input');
		return N_input!.checked;
	}

	public set checked(value: boolean) {
		const N_input = this.querySelector<HTMLInputElement>('input');
		N_input!.checked = value;
	}

	public static register() {
		customElements.define(Switch.tagName, Switch);
	}
}

export default Switch;
