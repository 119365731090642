import tippy, { Instance, Props } from 'tippy.js';

import '@css/customElements/button.scss';

class Button extends HTMLElement {
	public static readonly tagName: string = 'ap-button';

	private _tooltip: string;
	private _icon: string | null = null;
	private _type: string | null = null;
	private _originHTML: string = '';

	private hasConfirmation: boolean = false;

	private instanceTooltip: Instance<Props> | null = null;

	constructor() {
		super();

		this._tooltip = '';

		this.addEventListener('click', (e) => {
			//cancel de l'event click quand le boouton est desactiver
			if (this.disabled) {
				e.preventDefault();
				e.stopPropagation();
				e.stopImmediatePropagation();
			}
		});
	}

	public connectedCallback() {
		const hasDisabled = this.hasAttribute('disabled');
		this._tooltip = this.getAttribute('tooltip') || '';
		this.hasConfirmation = this.hasAttribute('confirmation');
		this._type = this.getAttribute('type') as string;

		this._originHTML = this.innerHTML;

		this.render();

		if (hasDisabled) {
			this.disabled = true;
		}

		this.renderTooltip(this._tooltip);

		this.removeAttribute('disabled');
		this.removeAttribute('tooltip');
		this.removeAttribute('type');
	}

	private render() {
		this.getIcon();
		this.setClass();

		const toggle = this.dataset.toggle || '';

		this.innerHTML = `
			${this._icon ? `<ap-icon name="${this._icon}"></ap-icon>` : ''}
			${this._originHTML || this.getText()}
			${toggle === 'dropdown' ? '<ap-icon name="arrow-down-s/line"></ap-icon>' : ''}
        `;
	}

	private setClass() {
		this._type && this.classList.add(`btn-${this._type}`);
	}

	private getIcon() {
		let icon = this.getAttribute('icon') as string;

		if (!icon) {
			switch (this._type) {
				case 'edit':
					icon = 'edit/line';
					break;

				case 'print':
					icon = 'printer/line';
					break;

				case 'delete':
					icon = 'delete-bin/line';
					break;

				case 'add':
					icon = 'add/line';
					break;

				case 'open':
					icon = 'folder-open/line';
					break;

				case 'duplicate':
					icon = 'file-copy/line';
					break;

				case 'save':
					icon = 'save-2/line';
					break;

				case 'export':
					icon = 'download/line';
					break;

				case 'reload':
					icon = 'refresh/line';
					break;

				case 'recap':
					icon = 'file-chart/line';
					break;

				default:
					break;
			}
		}

		this._icon = icon || this._icon;

		this.removeAttribute('icon');
	}

	public getText() {
		const type = this.getAttribute('type') as string;

		if (!this.classList.contains('btn-icon') && !this.classList.contains('btn-action-aggrid')) {
			switch (type) {
				case 'edit':
					return 'Modifier';

				case 'print':
					return 'Imprimer';

				case 'delete':
					return 'Supprimer';

				case 'add':
					return 'Ajouter';

				case 'open':
					return 'Ouvrir';

				case 'save':
					return 'Enregistrer';

				case 'duplicate':
					return 'Dupliquer';

				case 'export':
					return 'Exporter';

				case 'reload':
					return 'Actualiser';

				case 'recap':
					return 'Récapitulatif';

				default:
					break;
			}
		}

		return '';
	}

	public set disabled(value: boolean) {
		this.classList.toggle('disabled', value);

		if (value) {
			this.renderTooltip('');
			this.removeAttribute('confirmation');
		} else {
			this.renderTooltip(this._tooltip);
			if (this.hasConfirmation) {
				this.setAttribute('confirmation', '');
			}
		}
	}

	public get disabled() {
		return this.classList.contains('disabled');
	}

	public set icon(value: string) {
		this._icon = value;

		const N_icon = this.querySelector<HTMLElement>('ap-icon');
		N_icon?.setAttribute('name', value);
	}

	public set type(value: string) {
		this._type = value;
		this.render();
	}

	public set text(value: string) {
		this._originHTML = value;
		this.render();
	}

	public set tooltip(value: string) {
		this._tooltip = value;

		if (this.disabled) {
			this.renderTooltip('');
		} else {
			this.renderTooltip(this._tooltip);
		}
	}

	private renderTooltip(value: string) {
		if (!this.instanceTooltip) {
			this.instanceTooltip = tippy(this, {
				theme: 'material',
				arrow: false
			});
		}

		if (value) {
			this.instanceTooltip.enable();
			this.instanceTooltip.setContent(value);
		} else {
			this.instanceTooltip.disable();
		}
	}

	public set confirmation(value: boolean) {
		if (value) {
			this.setAttribute('confirmation', '');
		} else {
			this.removeAttribute('confirmation');
		}
	}

	public switchOn() {
		this.classList.add('on');
		this.classList.remove('off');
	}

	public switchOff() {
		this.classList.remove('on');
		this.classList.add('off');
	}

	public loading(promise: Promise<any>) {
		if (promise) {
			return new Promise((resolve, reject) => {
				promise.then((arg) => {
					//TODO:
					//this.loadingSuccess();
					resolve(arg);
				}).catch((arg) => {
					//TODO:
					//this.loadingError();
					reject(arg);
				});
			});
		}
	}

	public static register() {
		customElements.define(Button.tagName, Button);
	}
}

export default Button;
