import { AllModules, Grid, GridOptions, MenuItemDef } from '@ag-grid-enterprise/all-modules';
import agUtils from '@libs/agGrid/french';

import C_BillsProvider from '../controllers/Bills.Provider';

class BillsTab extends HTMLElement {
	public static readonly tagName: string = 'ap-order-bills-provider-tab';

	private selectorTab: string = '';

	private idTab: string = '';

	private _gridOptions: GridOptions = {};

	// eslint-disable-next-line unused-imports/no-unused-vars
	private idCommand: () => string = () => { return ''; };

	public async connectedCallback() {
		this.selectorTab = this.dataset.tabContainer || '.tab-content';

		this.idTab = this.id || BillsTab.tagName;

		this.innerHTML = `<ap-page-tabs-menu-item href="#${this.idTab}" icon="bill-euro/line" icon-active="bill-euro/fill" text="Factures" hasNumber></ap-page-tabs-menu-item>`;

		const N_item = this.querySelector('ap-page-tabs-menu-item');

		N_item?.addEventListener('open', () => {
			this.gridOptions.api?.sizeColumnsToFit();
		});

		this.id = '';
	}

	public setParentElement(parent: HTMLElement) {
		const N_container = parent.querySelector(this.selectorTab) as HTMLElement;

		const N_div = document.createElement('div');

		N_div.classList.add('page-tabs-container', 'tab-pane');
		N_div.id = this.idTab;
		N_div.innerHTML = `
			<div class="page-tabs-title" display="desktop">
				Factures
				<div class="page-tabs-title-right">
					<button class="btn btn-transparent d-none" type="button" data-type="fullscreen"></button>
				</div>
			</div>

			<div class="page-tabs-content">
				<div id="grid" class="ag-theme-alpine h-100 w-100"></div>
			</div>
        `;

		N_container.append(N_div);

		this.initGrid();
	}

	public setIdCommand(cb: () => string) {
		this.idCommand = cb;
	}

	private async initGrid() {
		this._gridOptions = agUtils.french<GridOptions>({
			localeText: { noRowsToShow: 'Aucune Facture' },
			defaultColDef: {
				suppressMenu: true,
				sortable: true,
				resizable: true,
				cellRenderer: (params) => {
					return params.value;
				}
			},
			columnDefs: [
				{
					headerName: 'Index',
					field: 'infos.index',
					width: 100,
					sort: 'asc',
					valueGetter: (params) => {
						if (!params.data.infos) {
							return '';
						} else {
							return Number(params.data.infos.index);
						}
					}
				}, {
					headerName: 'Numéro',
					field: 'infos.internalNumber'
				}, {
					headerName: 'Type',
					field: 'infos.type'
				}, {
					headerName: 'Date',
					field: 'infos.date'
				}, {
					headerName: 'Montant HT (€)',
					field: 'globalPrice',
					cellClass: 'text-right  text-monospace',
					cellRenderer: (params) => {
						return params.value.formattedValue;
					}
				}, {
					headerName: 'Montant TTC (€)',
					field: 'globalPriceTTC',
					cellClass: 'text-right  text-monospace',
					cellRenderer: (params) => {
						return params.value.formattedValue;
					}
				}, {
					headerName: 'Montant à payer (€)',
					field: 'notPaid',
					cellClass: 'text-right  text-monospace',
					cellRenderer: (params) => {
						return params.value.formattedValue;
					}
				}, {
					headerName: 'Montant payé (€)',
					field: 'paid',
					cellClass: 'text-right text-monospace',
					cellRenderer: (params) => {
						return params.value.formattedValue;
					}
				}, {
					headerName: 'Action',
					pinned: 'right',
					headerClass: 'ag-theme-custom-text-center',
					cellClass: 'text-center',
					sortable: false,
					resizable: false,
					width: 100,
					suppressSizeToFit: true,
					cellRenderer: (params: any) => {
						if (params.node.rowPinned) {
							return '';
						} else {
							const N_div = document.createElement('div');

							const N_edit = document.createElement('button');

							N_edit.classList.add('h-100', 'py-0', 'btn-transparent');

							N_edit.innerHTML = '<i class="text-info h5 icon icon-edit"></i>';
							N_edit.setAttribute('tooltip', 'Éditer');
							N_edit.type = 'button';
							N_edit.setAttribute('permission', 'BILLS._PROVIDERS.EDIT');

							N_edit.addEventListener('click', () => {
								C_BillsProvider.open(params.data._id);
							});

							N_div.appendChild(N_edit);

							return N_div;
						}
					}
				}
			],
			getContextMenuItems: (params) => {
				if (params.node) {
					const result: (MenuItemDef | string)[] = [
						{
							name: `N° ${params.node.data.infos.internalNumber}`,
							disabled: true,
							cssClasses: ['title-context-menu']
						}, {
							name: 'Éditer',
							icon: '<i class="icon icon-edit"></i>',
							disabled: !params.node,
							action: () => {
								C_BillsProvider.open(params.node.data._id);
							}
						}
					];

					result.push({
						name: 'Créer un avoir',
						icon: '<i class="icon icon-solid-plus"></i>',
						disabled: params.node.data.isCredit,
						action: () => {
							C_BillsProvider.open(null, {
								type: 'credit',
								idBill: params.node.data._id,
								idCommand: this.idCommand()
							});
						}
					});

					return result;
				} else {
					return [];
				}
			},
			onRowDataChanged: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			},
			onRowDataUpdated: (params: any) => {
				let number = 0;

				params.api?.forEachNode(() => {
					number++;
				});

				this.updateNumber(number);
			}
		});

		new Grid(document.querySelector(`#${this.idTab} #grid`) as HTMLDivElement, this._gridOptions, { modules: AllModules });
	}

	private updateNumber(number: number) {
		const N_number = this.querySelector('#number') as HTMLElement;

		if (number) {
			N_number.innerHTML = number.toString();
			N_number.classList.remove('d-none');
		} else {
			N_number.classList.add('d-none');
		}
	}

	public set data(data: { data: any[], sum: { [key: string]: any } }) {
		this.gridOptions.api?.setRowData(data.data);
		this.gridOptions.api?.setPinnedBottomRowData([data.sum]);
	}

	public get gridOptions(): GridOptions {
		return this._gridOptions;
	}

	/**
	 * Retourne le montant total facturé
	 * @retruns le montant tital facturé
	 */
	public getTotalBilledPrice(): number {
		return this.gridOptions.api?.getPinnedBottomRow(0).data.globalPrice.value;
	}

	public static register() {
		customElements.define(BillsTab.tagName, BillsTab);
	}
}

export default BillsTab;
