import h from 'hyperscript';

import '../../../css/viewGroupQuote.scss';

import Decimal from '@libs/utils/Decimal';

import S_Product from '@services/Product/ProductService';

class ViewGroupQuote extends HTMLElement {
	public static readonly tagName: string = 'ap-billing-request-view-group-quote';

	public connectedCallback() {
		this.innerHTML = `
			<div class="container-no-value">
				<div>AFFICHAGE</div>
				<div>CONTENU</div>
				<div>DU</div>
				<div>GROUPE</div>
				<div>SELECTIONNÉ</div>
			</div>
			<div class="container-value"></div>
		`;
	}

	public set value(value: any) {
		this.classList.add('value', 'loading');

		const N_value = this.querySelector<HTMLElement>('.container-value')!;

		N_value.innerHTML = '';

		const N_title = h('div.title-name', 'Description');
		const N_description = h('div.name', value.name);

		N_value.append(N_title, N_description);
		N_value.append(h('div.separator'));

		const promises: Promise<void>[] = [];

		for (const detail of value.details) {
			const tmp = this.getLineDetails(detail);
			tmp.promise && promises.push(tmp.promise);
			N_value.append(tmp.div);
			N_value.append(h('div.separator'));
		}

		Promise.all(promises).then(() => {
			this.classList.remove('loading');
		});
	}

	private getLineDetails(detail: any) {
		const N_reference = h('div.reference.cell-loading', 'je suis une reference non charger');
		const N_label = h('div.price.cell-loading', Decimal.setDisplayNumber(detail.price).setSuffixAndHumanizeNumber('€', -1));
		const N_price = h('div.label.cell-loading', detail.label || '');

		let promise: Promise<any> | null = null;
		if (detail.reference) {
			promise = S_Product.getInstance().getById(detail.reference).then((data) => {
				N_reference.innerHTML = data[S_Product.getInstance().referenceKey] + ' ' + data.brand;
			});
		}

		return {
			div: h('div.detail', N_reference, N_price, N_label),
			promise
		};
	}

	public static register() {
		customElements.define(ViewGroupQuote.tagName, ViewGroupQuote);
	}
}

export default ViewGroupQuote;
